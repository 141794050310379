import CallApi from "../../services/request-helper";

const API_CHALLENGES_LIST_TOP_USER = process.env.REACT_APP_CHALLENGES_1;
const API_CHALLENGES_STATISTIC_CHALLENGE = process.env.REACT_APP_CHALLENGES_2;
const API_CHALLENGES_CHALLENGE_CATEGORY = process.env.REACT_APP_CHALLENGES_3;
const API_CHALLENGES_AVERAGE_VIEW = process.env.REACT_APP_CHALLENGES_4;
const API_CHALLENGES_AVERAGE_TYPE = process.env.REACT_APP_CHALLENGES_5;

const getTopUser = async (year) => {
  const url = API_CHALLENGES_LIST_TOP_USER;
  const response = await CallApi({ url, method: "POST", data: year });
  return response;
};

const getStatisticChallenge = async (year) => {
    const url = API_CHALLENGES_STATISTIC_CHALLENGE
    const response = await CallApi({ url, method: "POST", data: year });
    return response;
};

const getStatisticChallengeType = async (year) => {
  const url = API_CHALLENGES_AVERAGE_TYPE
  const response = await CallApi({ url, method: "POST", data: year });
  return response;
};

const getChallengeCategory = async (year) => {
    const url = API_CHALLENGES_CHALLENGE_CATEGORY
    const response = await CallApi({ url, method: "POST", data: year });
    return response;
};

const getAverageView = async (year) => {
    const url = API_CHALLENGES_AVERAGE_VIEW
    const response = await CallApi({ url, method: "POST", data: year });
    return response;
};


const AllChallengesRepo = {
  getTopUser,
  getStatisticChallenge,
  getChallengeCategory,
  getAverageView,
  getStatisticChallengeType
};

export default AllChallengesRepo;