import React, { useState, useEffect } from 'react'

const CookieNotification = () => {
    const [accepted, setAccepted] = useState(false);

    const cookiesAccept = () => {
        // Set a cookie to remember user's choice
        document.cookie = 'cookieConsent=accepted; max-age=31536000'; // Expires in 1 year
        setAccepted(true);
        console.log("This website uses cookies to ensure you get the best experience on our website.");
    };


    useEffect(() => {
        cookiesAccept();
    }, [])


    return (
        <div className="cookie-consent">
        </div>
    )
}

export default CookieNotification