import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../sidebar/Sidebar'
import Navbar from '../navbar/Navbar'
import NavbarSm from '../navbar/NavbarSm'

function AdminLayout() {
  return (
    <main className="flex flex-col h-screen">
      <div className="flex bg-white drop-shadow-md h-16 px-4 py-2 z-[50] hidden md:block lg:block">
        <Navbar />
      </div>
      <div className="flex bg-white drop-shadow-md h-16 px-4 py-2 z-[50] md:hidden lg:hidden">
        <NavbarSm />
      </div>
      <div className="flex flex-1 overflow-hidden">
        <div className="flex hidden md:block lg:block">
          <Sidebar />
        </div>
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 overflow-y-auto paragraph">
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  )
}

export default AdminLayout
