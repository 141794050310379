import CallApi from "../../services/request-helper";

const API_UPDATE_PROGRESS = process.env.REACT_APP_UPDATESTATUSIDEA;
const API_SUBMITTED = process.env.REACT_APP_GETSTATICIDEA;

const updateProgressIdeas = async (data) => {
  const url = API_UPDATE_PROGRESS;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const getCardIdeas = async ({
  offset,
  limit,
  year,
  evaluation,
  search,
}) => {
    const data = {};
    data.offset = offset ? offset : 0;
    data.limit = limit ? limit : 10;
    data.year = year;
    if (evaluation === "all") {
    } else if (evaluation) {
      data.evaluation = evaluation;
    }
    if (search) {
      data.search = search;
    }
  const url = API_SUBMITTED;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};



const CardIdeasRepo = {
    getCardIdeas,
    updateProgressIdeas,
  }
  
  export default CardIdeasRepo;