// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
    width: 10px;
    /*background-color: #9FA3AC;*/
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #f1f1f1
}

::-webkit-scrollbar-thumb {
    background-color: #888
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin-page/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,mDAAmD;IACnD;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["::-webkit-scrollbar {\r\n    width: 10px;\r\n    /*background-color: #9FA3AC;*/\r\n}\r\n\r\n::-webkit-scrollbar-track {\r\n    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);\r\n    background-color: #f1f1f1\r\n}\r\n\r\n::-webkit-scrollbar-thumb {\r\n    background-color: #888\r\n}\r\n\r\n::-webkit-scrollbar-thumb:hover {\r\n    background: #555;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
