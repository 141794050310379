import React, { useState, useEffect,useCallback } from "react";
import { useParams } from "react-router-dom";
import ContentDetailIdeas from "./components/ContentDetailIdeas";
import DetailIdeasRepo from "../../../../repositories/adm-repo/DetailIdeasRepo";
import GeneralUtility from "../../../../utility/GeneralUtility";
import DateUtility from "../../../../utility/date-utility";
import InfiniteScroll from "react-infinite-scroller";
import Breadcrumbs from "./components/Breadcrumbs";
import DragDropAtch from "../../../../components/DragDropAtch";
import Comments from "../../../../components/comments/Comments";
import ConfirmPopup from "../../../../components/ConfirmPopup";
import AlertUtility from "../../../../utility/alert-utility";
import CommentRepo from "../../../../repositories/user-repo/CommentRepo";
import Banner from "../../../../components/Banner";
import ChallengeRepo from "../../../../repositories/user-repo/ChallengeRepo";
import Skeleton from "../../../../components/skeleton/Skeleton";
import IsLoading from "../../../../components/loading/IsLoading";
import RichTextEditor from "../../../../components/RichTextEditor";
import RichTextComment from "../../../../components/RichTextComment";

const DetailViewIdeas = () => {
  let { id } = useParams();
  const [limit] = useState(3);
  const [detail, setDetail] = useState(null);
  const [commentary, setCommentary] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [manager, setManagers] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [page, setPage] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [editorKey, setEditorKey] = useState(4);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [detailChallenge,setDetailChallenge] = useState(null)

  /**
   * function re-render the editor to empty comment field
   */
  const clearEditor = () => {
    const newKey = editorKey * 33;
    setEditorKey(newKey);
    setMessage("");
  };

  const getDetailIdea = async () => {
    setLoading(true);
    
    try{

      const result = await DetailIdeasRepo.getDetailIdeas(id);
      if (result.status !== 404) {
        let detailView = result.data.data;
        let getDetailViewAnswer = result.data.data.answers;
        let getDetailViewManager = result.data.data.managers;
        setDetail(detailView);
        setAnswers(getDetailViewAnswer);
        setManagers(getDetailViewManager);
        console.log('success get idea');
        
      } else {
        setDetail(null);
      }
      setLoading(false);
    }catch(error){
      setLoading(false);
    }
  };

  /**
   * post new comments
   * @param {*} e
   */
  const addComment = async (e) => {
    e.preventDefault();
    let data = { message, submited_id: { id: id }, documents };
    setShow(false);
    const res = await CommentRepo.postComment(data);
    if (res.status !== 200) {
      AlertUtility.errorAlert({
        title: "Sorry, there is some problems. Please try again.",
      });
    }
    clearEditor();
    setDocuments([]);
    setCommentary([]);
    setPageNumber(0);
    setHasMore(true);
    getComentaryIdea(0, []);
  };

  const getComentaryIdea = async (pageNumber, commentary) => {
    const result = await CommentRepo.getComment({
      offset: pageNumber,
      limit: limit,
      id: id,
    });
    if (result.status !== 404) {
      let listComment = result.data.data.data;
      if (listComment.length > 0) {
        setPageNumber(pageNumber + 1);
        let resultPaginate = result.data.data;
        setPage(resultPaginate);
        setCommentary([...commentary, ...listComment]);
      } else {
        setHasMore(false);
      }
    }
  };

  const fileDownload = async (source, name) => {
    const downloadLink = document.createElement("a");
    downloadLink.target = "_blank";
    downloadLink.href = source;
    downloadLink.download = name;
    downloadLink.click();
  };

  const getDetailChallenge = async (i) => {
    try{
      const result = await ChallengeRepo.getDetailChallenges({ id: i });
      
      setDetailChallenge(result)
    }catch(error){
    }
  }

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getComentaryIdea(0, commentary);
    getDetailIdea();
  }, []);
  
  useEffect(() => {
    if(detail !== null){
      getDetailChallenge(detail.challengeId);
    }
  },[getDetailChallenge])

  return (
    <>
      {loading ?
  <IsLoading/>
    :
      detail !== null  ? (
        <div className="container mx-auto">
          <Breadcrumbs title={detail.title} />
          <div>
            <Banner
              detail={{ banner: detail.banner_card, title: detail.title }}
              loading={loading}
            />
          </div>
          <ContentDetailIdeas
            detail={detail}
            answers={answers}
            manager={manager}
            loading={loading}
          />

          <div className="m-6 pb-[50px]">
            <div className="bg-baseGray p-6 space-y-4 ">
              <h4 className="font-bold text-lg">
                {commentary?.length > 0
                  ? `Comments (${page.total_data})`
                  : "Idea Comments (0)"}
              </h4>
              <div
                className={`${page.length === 0 ? "hidden" : " "} ${
                  page.total_data === 1 ? "h-[10em]" : "h-[20em]"
                } overflow-y-auto space-y-3`}
              >
                {commentary?.length < 1 ? (
                  <div className="bg-white shadow-sm p-3">No Comments</div>
                ) : (
                  <InfiniteScroll
                    loadMore={async () => {
                      getComentaryIdea(pageNumber, commentary);
                    }}
                    hasMore={hasMore}
                    useWindow={false}
                    threshold={100}
                  >
                    {commentary?.map((items, index) => (
                      <div key={index} className="bg-white shadow-sm p-3 mb-3">
                        <div className="flex flex-row items-center space-x-2">
                          <div className="h-8 w-8 rounded-full">
                            <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-bold justify-center">
                              {GeneralUtility.getInitials(items.user_name)}
                            </div>
                          </div>
                          <p className="font-bold text-[10px] lg:text-xs">
                            {items.user_name}{" "}
                          </p>
                          <p className="text-[10px] lg:text-xs">
                            {DateUtility.formatDate(items.created_at)}
                          </p>
                        </div>
                        <div className="text-sm p-3">
                          <div
                            dangerouslySetInnerHTML={{ __html: items.message }}
                          />
                        </div>
                        <div className="p-3 space-y-2">
                          {items.document.map((docs, index) => (
                            <div
                              key={index}
                              className="flex border border-gray-300 h-12 w-56 mb-4"
                            >
                              <div
                                onClick={() =>
                                  fileDownload(docs.source, docs.filename)
                                }
                                className="flex p-1 items-center w-full space-x-3 cursor-pointer"
                                name={docs.filename}
                              >
                                <img
                                  src={` ${
                                    docs.type_document === "pdf"
                                      ? "/assets/global/img/pdfIco.svg"
                                      : ""
                                  } ${
                                    docs.type_document === "pptx" ||
                                    docs.type_document === "ppt"
                                      ? "/assets/global/img/pptxIco.svg"
                                      : ""
                                  } ${
                                    docs.type_document === "xlsx" ||
                                    docs.type_document === "xls" ||
                                    docs.type_document === "csv"
                                      ? "/assets/global/img/xlsxIco.svg"
                                      : ""
                                  } ${
                                    docs.type_document === "docx" ||
                                    docs.type_document === "doc"
                                      ? "/assets/global/img/docxIco.svg"
                                      : ""
                                  } ${
                                    docs.type_document === "png" ||
                                    docs.type_document === "jpg" ||
                                    docs.type_document === "jpeg"
                                      ? "/assets/global/img/imageIco.png"
                                      : ""
                                  }`}
                                  className="h-8 w-8"
                                  alt="ico"
                                />
                                <div className="text-xs text-gray-500">
                                  <h6 className="font-semibold">
                                    {docs.filename
                                      ? docs.filename.length > 15
                                        ? docs.filename.substring(0, 15) +
                                          "..." +
                                          docs.type_document
                                        : docs.filename
                                      : ""}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                )}
              </div>
                { detailChallenge !== null && detailChallenge.status === "Active" ?

              <div className="bg-white shadow-sm p-3 space-y-3">
                  <div className="flex w-full space-x-2">
                  <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-bold justify-center">
                    {GeneralUtility.getUserInitials()}
                  </div>
                  <div className="w-full justify-between">
                    {/* <Comments
                      editorKey={editorKey}
                      value={message}
                      onChange={(content) => {
                        setMessage(content);
                      }}
                    /> */}
                         <RichTextComment
                    value={message}
                    onChange={(content) => {
                      setMessage(content);
                    }}
                  />
                    <div className="mt-3">
                      <DragDropAtch docs={documents} setDocs={setDocuments} />
                    </div>
                  </div>
                </div>
                <div className="w-full text-right">
                  {message !== "" ? (
                    <button
                      onClick={(e) => setShow(true)}
                      className="bg-gradient-to-r from-green-500 to-freshGreen text-white px-4 py-1 text-sm tracking-[0.5px]"
                    >
                      COMMENT
                    </button>
                  ) : (
                    <button className="bg-gray-300 text-white px-4 py-1 text-sm tracking-[0.5px]">
                      COMMENT
                    </button>
                  )}
                </div>
                </div>

                :

                  <div>-</div>
               }
           

            </div>
          </div>
          <ConfirmPopup
            show={show}
            setShow={setShow}
            onSave={addComment}
            message="Are you sure you want to add the comment?"
          />
        </div>
      ) : (
        <div className="container mx-auto">
          <div className="text-xl font-bold text-center mt-10">Idea Not Found</div>
        </div>
      )}
    </>
  );
};

export default DetailViewIdeas;
