import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ChallengeRepo from "../../../repositories/user-repo/ChallengeRepo";
import PersonalRepo from "../../../repositories/user-repo/PersonalRepo";
import Challenges from "../LatestChallenges/components/Challenges";
import PersonalIdeaList from "../MyIdeas/components/PersonalIdeaList";
import GeneralUtility from "../../../utility/GeneralUtility";

const SearchPage = () => {
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [challenge, setChallenges] = useState([]);
  const [limitChall] = useState(12);
  const [limitPersonal] = useState(12);
  const [listPersonal, setListPersonal] = useState([]);
  const { state } = useLocation();
  const { searchText } = state;

  /**
   *
   * @param {*} param
   */
  const getAllChallenge = async searchText => {
    setLoading(true);
    const result = await ChallengeRepo.getChallenges({
      offset: 0,
      limit: limitChall,
      search_home: searchText,
    });
    let listChallenges = result !== null ? result.data : [];
    setChallenges(listChallenges);
    setLoading(false);
  };

  /**
   * get data Personal Ideas
   */
  const getPersonalIdeas = async searchText => {
    setLoad(true);
    const result = await PersonalRepo.getPersonalIdea({
      offset: 0,
      limit: limitPersonal,
      search_home: searchText,
    });
    let listResult = result !== null ? result.data : [];
    setListPersonal(listResult);
    setLoad(false);
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getAllChallenge(searchText);
    getPersonalIdeas(searchText);
  }, []);

  return (
    <>
      <div className="container mx-auto">
        <div className="bg-baseGray m-7 p-6">
          <div className="mx-auto mt-8 px-6 space-y-5">
            <h3 className="font-bold py-3 text-2xl tracking-[0.5px]">Challenges</h3>
            <Challenges challenges={challenge} loading={loading} />
            <h3 className="font-bold py-3 text-2xl tracking-[0.5px]">Ideas</h3>
            <PersonalIdeaList
              personalList={listPersonal}
              loading={load}
              getPersonalIdeas={getPersonalIdeas}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchPage;
