import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import RouteName from "../../../../../services/routename";
import RichText from "../../../../../components/RichText";
import PersonalRepo from "../../../../../repositories/user-repo/PersonalRepo";
import ModalPersonal from "./ModalPersonal";
import DragDropSq from "../../../../../components/DragDropSq";
import BtnDropdown from "../../../../../components/buttons/BtnDropdown";
import Breadcrumbs from "./Breadcrumbs";
import AlertUtility from "../../../../../utility/alert-utility";
import GeneralUtility from "../../../../../utility/GeneralUtility";
import RichTextQuill from "../../../../../components/RichTextQuill";
import RichTextEditor from "../../../../../components/RichTextEditor";

const EditPersonalIdea = () => {
  let { id } = useParams();
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState("");
  const [detail, setDetail] = useState("");
  const [title, setTitle] = useState("");
  //eslint-disable-next-line
  const [docs, setDocs] = useState([]);
  const [getDocuments, setDocuments] = useState([]);
  const [getLinks, setGetLinks] = useState([]);
  const [linksRow, setLinksRow] = useState([]);
  const [error, setError] = useState([]);
  const navigate = useNavigate();

  /**
   * function for get the detail idea
   */
  const getDetailIdea = async () => {
    const result = await PersonalRepo.editPersonalIdea(id);

    let detailIdea = result.data.data;
    let detailLinks = result.data.data.links;
    let detailDocs = result.data.data.documents;
    let detailDesc = result.data.data.description;
    detailLinks.forEach(function (v) {
      delete v.id;
    });

    setGetLinks(detailLinks);
    setDetail(detailIdea);
    setTitle(detailIdea.title);
    setDescription(detailDesc);
    setDocuments(detailDocs);
  };

  /**
   *
   * function for duplicate idea
   * @param {*} e
   */
  const duplicateIdea = async (e) => {
    AlertUtility.loadingAlert();
    const result = await PersonalRepo.duplicatePersonalIdea({ id: e });
    if (result.status === 200) {
      Swal.close();
      AlertUtility.successAlert({
        title: "Your idea was successfully duplicated",
      });
      navigate(`${RouteName.personalIdeas}`);
    } else {
      Swal.close();
      AlertUtility.errorAlert({
        title: "There is some problem. Please try again",
      });
    }
  };

  /**
   * function for delete idea
   * @param {*} e
   */
  const deleteIdea = async (e) => {
    AlertUtility.loadingAlert();
    const result = await PersonalRepo.deletePersonalIdea({ id: e });
    if (result.status === 200) {
      Swal.close();
      AlertUtility.successAlert({
        title: "Your idea was successfully deleted",
      });
      navigate(`${RouteName.personalIdeas}`);
    } else {
      Swal.close();
      AlertUtility.errorAlert({
        title: "There is some problem. Please try again",
      });
    }
  };

  /**
   *
   * function for handle update
   * @param {*} e
   */
  const saveUpdate = async (e) => {
    let err = validate(description, title, linksRow);
    if (Object.keys(err).length === 0) {
      let id = e;
      let links = getLinks.concat(linksRow);
      let documents = getDocuments.concat(docs);
      let data = { id, title, links, description, documents };
      AlertUtility.loadingAlert();
      const res = await PersonalRepo.saveEditPersonalIdea(data);
      if (res.status === 200) {
        Swal.close();
        AlertUtility.successAlert({ title: "The changes has been saved." });
        navigate(`${RouteName.personalIdeas}`);
      } else {
        Swal.close();
        AlertUtility.errorAlert({
          title: "There is some problem. Please try again.",
        });
      }
    }
  };

  /**
   * function to add row links
   */
  const handleAddLinks = () => {
    const values = [...linksRow];
    values.push({
      resourceName: "",
      source: "",
    });
    setLinksRow(values);
  };

  /**
   * function to remove row links
   * @param {*} index
   */
  const handleRemoveLinks = (index) => {
    const values = [...linksRow];
    values.splice(index, 1);
    setLinksRow(values);
  };

  /**
   * funtion to handle links value
   * @param {*} index
   * @param {*} event
   */
  const handleInputChange = (index, event) => {
    const values = [...linksRow];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    setLinksRow(values);
  };

  /**
   * delete old links
   * @param {*} index
   */
  const deleteOldLinks = async (index) => {
    let data = [...getLinks];
    data.splice(index, 1);
    setGetLinks(data);
  };

  const validate = (description, title, linksRow) => {
    const errors = {};
    if (!title) {
      errors.title = "Title is required!";
    }
    if (!description) {
      errors.description = "Description is required!";
    }

    setError(errors);
    return errors;
  };

  /**
   *
   * function for handle update
   * @param {*} e
   */
  const submitUpdate = async (e) => {
    let err = validate(description, title, linksRow);
    if (Object.keys(err).length === 0) {
      let id = e;
      let links = getLinks.concat(linksRow);
      let documents = getDocuments.concat(docs);
      let data = { id, title, links, description, documents };
      const res = await PersonalRepo.saveEditPersonalIdea(data);
      setShow(!show);
    }
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getDetailIdea();
  }, []);

  const handleChangeDesc = (e) => {
    setDescription(e);
  };
  return (
    <div className="container mx-auto">
      <div className="m-6 space-y-6 bx_form_edit_idea">
        <div>
          <div className="mx-auto">
            <Breadcrumbs detail={detail} />
          </div>
        </div>
        <div className="tx_top_page_latest lg:hidden">My Ideas</div>
        <div className="bg-baseGray p-4 md:p-6 space-y-6">
          <div className="flex justify-between">
            <div className="w-full pr-5 space-y-2">
              <h3 className="font-semibold text-black"> Idea Title </h3>
              <input
                autoComplete="off"
                type="text"
                name="ideTitle"
                className="border-none shadow-md w-full text-sm valAutoComp"
                placeholder="Enter a title with a maximum length of 100 characters"
                max={100}
                defaultValue={detail.title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <p className="text-red-500 text-sm">{error.title}</p>
            </div>
            <div className="mr-4 md:mr-10">
              <BtnDropdown
                isDelete={true}
                duplicate={(e) => duplicateIdea(id)}
                del={(e) => deleteIdea(id)}
              />
            </div>
          </div>
          {/* <div className="space-y-3">
            <h3 className="font-semibold text-black"> Description </h3>
            <RichText
              onChange={content => {
                setDescription(content);
              }}
              initialValue={detail.description}
            />
            <p className="text-red-500 w-48 text-sm">{error.description}</p>
          </div> */}

          <div>
            <h3 className="font-semibold text-black"> Description </h3>
            <RichTextEditor value={description} onChange={handleChangeDesc} />
          </div>
          {/* TO DO hidden this phase */}
          <div className="space-y-2 hidden">
            <h3 className="font-semibold text-black"> Links </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 items-center">
              {getLinks.map((items, index) => (
                <div
                  id={index}
                  key={index}
                  className="flex p-1 border border-gray-300 h-12 w-full justify-between"
                >
                  <div className="flex p-1 items-center space-x-3">
                    <img
                      src="/assets/global/img/link.png"
                      className="h-8 w-8"
                      alt="link"
                    />
                    <div className="text-xs text-gray-500">
                      <h6 className="font-semibold">
                        {items.resourceName.length > 20
                          ? items.resourceName.substring(0, 20) + "..."
                          : items.resourceName}
                      </h6>
                      <p className="text-xs">
                        {items.source.length > 20
                          ? items.source.substring(0, 20) + "..."
                          : items.source}
                      </p>
                    </div>
                  </div>
                  <img
                    className="w-2 h-2 mr-2 my-2"
                    onClick={(e) => deleteOldLinks(index)}
                    src="/assets/global/img/close.svg"
                    alt="close"
                  />
                </div>
              ))}
            </div>

            <div className="space-y-2">
              {linksRow.length > 0 && (
                <>
                  {linksRow.map((field, index) => (
                    <div key={index} className="flex space-x-3 items-center">
                      <div className=" basis-4/12" id="resourceName">
                        <input
                          autoComplete="off"
                          className="border-none shadow-md w-full valAutoComp"
                          type="text"
                          name="resourceName"
                          placeholder="Resources Name"
                          value={field.resourceName}
                          onChange={(event) => handleInputChange(index, event)}
                        />
                      </div>
                      <div className=" basis-7/12" id="source">
                        <input
                          autoComplete="off"
                          className="border-none shadow-md w-full valAutoComp"
                          type="text"
                          name="source"
                          placeholder="Paste URL"
                          value={field.source}
                          onChange={(event) => handleInputChange(index, event)}
                        />
                      </div>
                      <div className="basis-1/12 pl-4">
                        <img
                          onClick={() => handleRemoveLinks(index)}
                          className="cursor-pointer basis-1/12"
                          src="/assets/global/img/bin.svg"
                          alt="trash"
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className="flex space-x-3">
              <p className="text-red-500 basis-4/12 text-sm">
                {error.resourceName}
              </p>
              <p className="text-red-500 basis-7/12 text-sm">{error.source}</p>
            </div>

            <button onClick={() => handleAddLinks()}>
              <img
                src="/assets/global/img/squarePlus.png"
                className="w-14 h-14"
                alt="add"
              />
            </button>
          </div>

          {/* TO DO hidden this phase */}
          <div className="space-y-2 hidden">
            <h3 className="font-semibold text-black"> Attachment </h3>
            <div>
              <DragDropSq docs={getDocuments} setDocs={setDocuments} />
            </div>
          </div>
        </div>
        <div className="flex space-x-4 pb-12">
          <button
            className="border border-1 border-green-400 bg-gradient-to-r from-green-500 to-freshGreen py-2 px-5 text-white text-xs inline-flex items-center hover:bg-none hover:text-green-400 uppercase"
            onClick={(e) => saveUpdate(id)}
          >
            SAVE
          </button>
          <button
            className="border border-1 border-green-400 text-center text-xs p-3 py-2 inline-flex items-center text-freshGreen hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white uppercase"
            onClick={(e) => submitUpdate(id)}
          >
            SUBMIT IDEA
          </button>
        </div>
      </div>
      <ModalPersonal show={show} setShow={setShow} id={id} />
    </div>
  );
};

export default EditPersonalIdea;
