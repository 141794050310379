import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
import RouteName from "../../../services/routename";
import GeneralUtility from '../../../utility/GeneralUtility';
import ConfirmPopup from '../../ConfirmPopup';
import './Sidebar.css'
import Cookies from "js-cookie";


const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);

  const Menus = [
    { title: "Home", src: "home", route: RouteName.userHome },
    { title: "Latest Challenges", src: "launch", route: RouteName.userLatestChallenges },
    { title: "Archived Challenges", src: "archive", route: RouteName.userArchivedChallenges },
    { title: "My Ideas", src: "lightbulb", route: RouteName.userMyIdeas },
    //{ title: "Coming Soon", src: "team", route: RouteName.userMyTeams },
    // { title: "Brainstorming", src: "hub", route: RouteName.userBrainstorming },
    // { title: "Leader Board", src: "trophy", route: RouteName.userLeaderBoard },
    // { title: "Resources", src: "document", route: RouteName.userResources },
    // { title: "How It Works", src: "settings", route: RouteName.userHowItWorks },
    // { title: "Polls", src: "settings", route: RouteName.userPolls }
  ];


  const removeCookie = () => {
    Cookies.remove("ideasYear");
    Cookies.remove("ideasPage");
    Cookies.remove("challengePage");

  }

  const handleLogout = async () => {
    GeneralUtility.logout();
  }

  return (
    <div className="flex font-Montserrat">
      <div
        className={` ${open ? "w-52" : "w-20"
          } bg-heritageGreen h-screen py-5 pt-8 relative duration-300`}
      >
        <img
          alt=""
          src="/assets/global/img/collapse.png"
          className={`absolute cursor-pointer -right-3 top-5 w-7 ${!open && "rotate-180"
            }`}
          onClick={() => setOpen(!open)}
        />
        <ul className="pt-6">
          {Menus.map((Menu, index) => (
            <NavLink
              key={index}
              to={Menu.route}
              onClick={() => removeCookie()}
              className="font-normal my-1 ml-2 text-white p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex"
            >
              <div className={`text-sm flex items-center gap-x-1`}>
                <div className="h-5 w-5">
                  <img
                    className={`${index === 3 ? "w-[21px] h-[21px]" : ""} `}
                    src={`/assets/global/img/${Menu.src}.svg`}
                    alt={`${Menu.title}`}
                  />
                </div>
                <span
                  className={`${!open && "hidden"
                    } origin-left duration-200 ml-2 text-xs`}
                >
                  {Menu.title}
                </span>
              </div>
            </NavLink>
          ))}
          <div
            onClick={e => setShow(true)}
            className="font-normal my-1 ml-2 text-white p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex text-xs"
          >
            <div className={`text-sm flex items-center gap-x-1`}>
              <div className="h-5 w-5">
                <img
                  src={`/assets/global/img/logout.svg`}
                  alt="logout"
                />
              </div>
              <span
                className={`${!open && "hidden"
                  } origin-left duration-200 ml-2 text-xs`}
              >
                Log Out
              </span>
            </div>
          </div>
        </ul>
      </div>
      <ConfirmPopup show={show} setShow={setShow} onSave={handleLogout} message="Are you sure you want to log out?" />
    </div >
  );
}

export default Sidebar
