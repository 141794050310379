import CallApi from "../../services/request-helper";

const API_LEADERBOARD = process.env.REACT_APP_LEADERBOARD;
const API_TOTAL_LEADERBOARD = process.env.REACT_APP_TOT_LEADERBOARD;

const getLeaderboard = async ({ year, offset, limit, search, param }) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const data = {};
  data.offset = offset ? offset : 0;
  data.limit = limit ? limit : 10;
  data.year = year ? year : currentYear;
  data.search = search ? search : "";
  data.param = param ? param : "points";
  const url = API_LEADERBOARD;
  const response = await CallApi({ url, method: "POST", data });
  const res2 = response !== undefined ? response.data : null;
  const res3 = res2 !== null ? res2.data : null;
  return res3;
};

const getTotalPoints = async ({ year }) => {
  const data = {};
  data.year = year ? year : 2023;
  const url = API_TOTAL_LEADERBOARD;
  const response = await CallApi({ url, method: "POST", data });
  const res2 = response !== undefined ? response.data : null;
  return res2;
};

const LeaderboardRepo = {
  getLeaderboard,
  getTotalPoints,
};

export default LeaderboardRepo;
