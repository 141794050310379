import React from "react";
import Chart from "react-apexcharts";

const ChartBar = ({
  categories,
  colors,
  data,
  width,
  height,
  optWidth,
  optLegend,
}) => {
  let options = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: optLegend,
        horizontalAlign: "left",
        offsetX: -10,
        width: optWidth,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          display: false,
        },
      },
      colors: colors,
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        type: "category",
        categories: categories,
        tooltip: {
          enabled: true,
          
        },
      },
      yaxis: {
        show: false,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        colors: colors,
      },
    },
    series: [
      {
        name: "",
        data: data,
      },
    ],
  };
  return (
    <>
      <div className="mixed-chart">
        <Chart
          options={options.options}
          series={options.series}
          type="bar"
          width={width}
          height={height}
        />
      </div>
      {!optLegend ? (
        <div className="grid grid-flow-row gap-2 mt-[-15px] p-3">
          {colors.map((res, index) => {
            return (
              <div
                className="text-[12px] flex flex-row capitalize justify-between items-center"
                key={res}
              >
                <div className="flex flex-row justify-center align-middle items-center">
                  <div className={`bg-[${res}] h-3 w-3 mr-3 ml-2`}> </div>
                  {categories[index]}
                </div>
                <div className="ml-1">{data[index]}</div>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default ChartBar;
