import React from "react";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/green.css";

const DateRange = ({
  value,
  onOpen,
  onChange,
  placeholder,
  inputClass,
  disabled,
  minDate,
}) => {
  const threeYearsAgo = new Date();
  threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);
  const today = new Date();
  return (
    <DatePicker
      className="green"
      value={value}
      onOpen={() => {
        onOpen();
      }}
      onChange={(dateObject) => {
        onChange(dateObject);
      }}
      format="DD/MMM/YYYY"
      placeholder={placeholder}
      inputClass={inputClass}
      maxDate={today}
      minDate={minDate ? minDate : threeYearsAgo}
      disabled={disabled}
    />
  );
};

export default DateRange;
