import React, { useState, useEffect } from "react";
import DropdownCard from "../../../../components/card/DropdownCard";
import AllOverviewRepo from "../../../../repositories/admin-repo/OverviewRepo";
import LeaderboardRepo from "../../../../repositories/adm-repo/LeaderboardRepo";
import Paginate from "../../../../components/paginate/Paginate";
import AlertUtility from "../../../../utility/alert-utility";
import Swal from "sweetalert2";
import moment from "moment";
import IsLoading from "../../../../components/loading/IsLoading";

const Leaderboard = ({ onShow }) => {
  const [listYear, setListYear] = useState([]);
  const [open, setOpen] = useState(false);
  const yearNow = moment(new Date()).year();
  const [selected, setSelected] = useState({ year: yearNow });
  const [limit] = useState(10);
  const [defPage, setDefPage] = useState(0);
  const [listLeaderboard, setListLeaderboard] = useState([]);
  const [page, setPage] = useState([]);
  const [search, setSearch] = useState("");
  const [tabAct, setTabAct] = useState("Points");
  const [isLoad,setIsLoad] = useState(false);
  const [param, setParam] = useState("points");
  const [currentPage,setCurrentPage] = useState(1);

  const now = new Date();
  const currentYear = now.getFullYear();

  const getListYear = async () => {
    const responseListYear = await AllOverviewRepo.getListYear();
    setListYear(responseListYear.data.data);
  };

  // Change Page
  const paginate = (event) => {
    const selectedPage = event.selected === 0 ? 1 : event.selected + 1;
    getLeaderboard(event.selected);
    setCurrentPage(selectedPage)
    setDefPage(event.selected)
  };
  

  const getLeaderboard = async (pageNumber) => {
    setIsLoad(true)
      try{
        let year = selected.year;
        const resLeaderboard = await LeaderboardRepo.getLeaderboard({
          year,
          limit,
          offset: pageNumber,
          search: search,
          param: param,
        });
        let list = resLeaderboard !== null ? resLeaderboard.data : [];
        setListLeaderboard(list);
        setPage(resLeaderboard);
        setIsLoad(false)
      }catch(error){
        setIsLoad(false);
        setListLeaderboard([])
      }
  };

  const exportLeaderboard = async () => {
    let year = selected === "" ? currentYear : selected.year;
    AlertUtility.loadingAlert();
    let res = await AllOverviewRepo.exportLeaderboard(year);
    if (res.status === 200) {
      const contentDisposition = res.headers["content-disposition"];
     
      const filenameMatch =
        contentDisposition && contentDisposition.match(/filename=([^;]+)/);

        const filename = filenameMatch
        ? filenameMatch[1]
        : "downloaded-file.xlsx";

      // Create a Blob URL for the blob
      const blobUrl = window.URL.createObjectURL(new Blob([res.data]));

      // Create a link element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger a click on the link to initiate the download
      Swal.close();
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);

      // Revoke the Blob URL to release resources
      window.URL.revokeObjectURL(blobUrl);
    } else {
      Swal.close();
      Swal.fire("Sorry...", "Fail to get file", "error");
    }
  };

  const tab = [
    { param: "points", name: "Points" },
    { param: "creators", name: "Creators" },
    { param: "contributors", name: "Contributors" },
  ];

  const handleTab = (items)=>{
    setTabAct(items.name);
    setParam(items.param);
    setCurrentPage(1)
    setDefPage(0)
    
  }

  useEffect(() => {
    getListYear();
    getLeaderboard(0);
  }, [selected, search, param]);

  
  const getOrderNumber = (index) => {
    return (currentPage - 1) * limit + (index + 1);
  };
  
  return (
    <>
      <div className="ml-16 pb-6">
        <div className="text-xl font-[500] mb-3">Leader Board</div>
        <div
          className={`${onShow ? "hidden" : "grid grid-cols-1 md:grid-cols-2 justify-between gap-3"
            } `}
        >
          <div className="flex flex-row text-sm space-x-3">
            {tab.map((items) => (
              <button
                onClick={() => handleTab(items)}
                key={items.name}
                className={`${tabAct === items.name
                  ? "bg-gradient-to-r from-[#44ad5c] via-[#66bf40] to-[#85c240] text-white"
                  : "text-[#51b848] border border-[#51b848]"
                  } px-2.5 justify-center uppercase items-center text-xs flex h-9`}
              >
                {items.name}
              </button>
            ))}
            <div
              className={`bg-gradient-to-r text-xs from-[#44ad5c] via-[#66bf40] to-[#85c240] text-white w-[250px] justify-center uppercase items-center flex h-9 cursor-pointer px-2`}
              onClick={() => {
                exportLeaderboard();
              }}
            >
              Download Points Report
            </div>
          </div>

          <div className="flex flex-row space-x-3 md:justify-end">
            <div className="relative block">
              <input
                autoComplete="off"
                type="text"
                placeholder="Search by name, surname..."
                className="w-[280px] px-4 pr-10 py-2 text-gray-400 border-solid border-gray-300 border-2 h-9 text-sm"
                onChange={(e) => setSearch(e.target.value)}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-2 bottom-0 w-6 h-6 text-gray-400 left-[250px] right-0 cursor-pointer"
                fill="none"
                viewBox="0 0 28 28"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <div className="flex md:justify-self-end h-9">
              <div
                className={`items-center bg-white h-9 ${open ? "2xl:h-auto" : "h-9"
                  } w-24 lg:w-44 py-2 border border-gray-400 text-[10px] z-10 bx_filter_sortby`}
              >
                <div
                  className="flex items-center flex-row cursor-pointer h-[20px] justify-between w-fill font-Montserrat"
                  onClick={() => setOpen(!open)}
                >
                  <span className="uppercase px-2">
                    Sort By Year : {selected.year}{" "}
                  </span>
                  <img
                    src="/assets/global/img/up-arrow.svg"
                    className={`h-2 w-4 stroke-black ${open
                      ? "transition-width duration-300 easy rotate-0"
                      : "transition-width duration-300 easy rotate-180"
                      }`}
                    alt="logo"
                  />
                </div>
                {open ? (
                  <div style={{ border: "0.5px solid #a3a3a3",borderTop: "none" }} className="bg-white mt-1">
                    {listYear &&
                      listYear.map((res, index) => {
                        return (
                          <div
                            key={index}
                            style={{paddingTop: "3px",  paddingBottom: "3px"}
                            }
                            className="flex flex-row uppercase cursor-pointer hover:font-semibold"
                            onClick={() => setSelected({ year: res.year })}
                          >
                            <img
                              src={`${selected.year === res.year
                                ? "/assets/global/img/toggle - check.svg"
                                : "/assets/global/img/toggle.svg"
                                }`}
                              className={`h-4 w-6 stroke-black`}
                              alt="selected"
                            />
                            <p className="text-[10px]">
                              {res.year ? res.year : ""}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-16 pb-6">
        {isLoad ?
        <IsLoading/>
        :
        listLeaderboard.length > 0 ? (
          listLeaderboard.map((res, indx) => {
            return (
              <div key={indx}>
                <DropdownCard onShow={onShow} res={res} indx={indx} orderNumber={getOrderNumber} />
              </div>
            );
          })
        ) : !isLoad && listLeaderboard.length === 0 && (
          <div className="text-center">No data available</div>
        )}
      </div>
      {/* {!onShow ? (
        <div className="ml-16 flex justify-center my-5 w-[300px] xs:w-[450px] sm:w-[600px] md:w-[700px] lg:w-full">
          <Paginate paginate={paginate} page={page} defPage={0} />
        </div>
      ) : (
        <div></div>
      )} */}
      <div className="ml-16 flex justify-center my-5 w-[300px] xs:w-[450px] sm:w-[600px] md:w-[700px] lg:w-full">
      <Paginate paginate={paginate} page={page} defPage={defPage} />
      </div>

    </>
  );
};

export default Leaderboard;
