const disableComment = (status,challengeStatus) => {
    if(status === "Generation" || status === "Validation"  || status === "Commercialisation" || status === "Experimentation"){
        if(challengeStatus === "Active"){
            return true;
        } else{
            return false;
        }
    } else {
        return false;
    }
}

export default disableComment;


