import CallApi from "../../services/request-helper";

const API_NOTIFICATION_LIST = process.env.REACT_APP_NOTIFICATIONLIST;
const API_NOTIFICATION_DETAIL_LIST = process.env.REACT_APP_DETAILLIST;
const API_UPDATE_NOTIFICATION = process.env.REACT_APP_UPDATENOTIF;
const API_SETTING_NOTIFICATION =process.env.REACT_APP_SETTING_NOTIFICATION;

/**
 * notification repo
 * @returns 
 */
const getNotificationList = async (id) => {
    const url = API_NOTIFICATION_LIST;
    const response = await CallApi({url, method: "GET"});
    return response;
}

const getDetailList = async (id) => {
  const url = `${API_NOTIFICATION_DETAIL_LIST}/${id}`;
  const response = await CallApi({url, method: "GET"});
  return response;
}

const updateNotification = async (body) => {
  const url = API_UPDATE_NOTIFICATION;
  const response = await CallApi({url, method: "POST", data: body});
  return response;
}

/**
 * setting notification launch challenge
 * @returns 
 */
const settingNotification = async () => {
  const url = API_SETTING_NOTIFICATION;
  const response = await CallApi({url, method: "GET"});
  const dat = response !== undefined ? response.data : null;
  const dat2 = dat.data !== undefined ? dat.data : null;
  return dat2;
}

const NotificationRepo = {
  getNotificationList,
  getDetailList,
  updateNotification,
  settingNotification
}

export default NotificationRepo