import CallApi from "../../services/request-helper";

const API_HOMEPAGE = process.env.REACT_APP_HOMEPAGE_USER;

const getContent = async () => {
  const url = API_HOMEPAGE;
  const response = await CallApi({ url, method: "GET" });
  const response2 = response !== undefined ? response : null; 
  return response2;
};

const HomeRepo = {
    getContent
  };
  
  export default HomeRepo;