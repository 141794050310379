import React, { useState, useEffect } from "react";
import RouteName from "../../../../../services/routename";
import { Link, useNavigate } from "react-router-dom";
import LikeChallengeRepo from "../../../../../repositories/user-repo/LikeChallengeRepo";
import PersonalRepo from "../../../../../repositories/user-repo/PersonalRepo";
import ModalDetail from "./ModalDetail";
import GeneralUtility from "../../../../../utility/GeneralUtility";
import Skeleton from "../../../../../components/skeleton/Skeleton";

function OverviewDetail({
  detail,
  id,
  slug,
  manager,
  detailChallenge,
  loading,
}) {
  const [show, setShow] = useState(false);
  const [sort, setSort] = useState({ id: "1", name: "Recent" });
  const [listView, setlistView] = useState(true);
  const [listed, setList] = useState([]);
  const [limitPersonal] = useState(12);
  const [page, setPage] = useState([]);
  const [like, setLike] = useState({ status: "", id: "" });
  const navigate = useNavigate();

  /**
   * Function get personal ideas
   * @param {*} pageNumber
   */
  const getPersonalIdeas = async (pageNumber) => {
    const result = await PersonalRepo.getPersonalIdea({
      offset: pageNumber,
      limit: limitPersonal,
      sort: sort.id,
      drafted: 1,
    });
    let listResult = result !== null ? result.data : [];
    let listPage = result !== null ? result : [];
    setList(listResult);
    setPage(listPage);
  };

  /**
   * Function to Change Page
   * @param {*} event
   */
  const paginate = (event) => {
    getPersonalIdeas(event.selected);
  };

  /**
   * Function for go to create new idea
   * @param {*} e
   */
  const createNewIdea = (e) => {
    navigate(RouteName.createIdea + "/" + e);
  };

  /**
   * funtion to get status like challenge
   */
  const getStatusLike = async () => {
    const resultLike = await LikeChallengeRepo.getStatusChallenge(id);
    if (resultLike !== null) {
      setLike({ status: resultLike.status, id: resultLike.id });
    } else {
      setLike(null);
    }
  };

  /**
   * function to post like
   * @param {*} id
   */
  const postLike = async (id) => {
    setLike({ status: true, id: id });
    const result = await LikeChallengeRepo.postLikeChallenge({
      challange: { id: id },
    });
    if (result.status === 200) {
      getStatusLike();
      detailChallenge();
    }
  };

  /**
   * function to post dislike
   * @param {*} id
   */
  const postDisLike = async (id) => {
    setLike({ status: false, id: id });
    const result = await LikeChallengeRepo.postDislikeChallenge({
      challange: { id: id },
    });
    if (result.status === 200) {
      getStatusLike();
      detailChallenge();
    }
  };

  /**
   * post to unLike or unDislike idea
   * @param {*} id
   */
  const unLike = async (id, status) => {
    if (!!status) {
      setLike(null);
    } else {
      setLike({ status: false, id: id });
    }
    const result = await LikeChallengeRepo.postUnlikeChallenge({ id: id });
    if (result.status === 200) {
      getStatusLike();
      detailChallenge();
    }
  };

  useEffect(() => {
    if (!loading) {
      getPersonalIdeas();
      getStatusLike();
    }
  }, [sort, detail]);

  return (
    <>
      <div className="flex flex-col-reverse md:flex-row gap-y-3 gap-3">
        {!loading ? (
          <>
            <div className="bg-baseGray p-3 space-y-4 md:basis-3/4">
              <h3 className="font-semibold text-md md:text-lg">
                Challenge Overview
              </h3>
              <div id="desc" className="text-sm">
                <div dangerouslySetInnerHTML={{ __html: detail.description }} />
              </div>
              <div className="flex flex-col-reverse md:flex-row md:justify-between">

                <div className="flex space-x-3 justify-center mt-3 md:mt-0">
                  <div
                    className={`${
                      detail.status === "Up coming" ? "hidden" : ""
                    } ${
                      detail.status === "Closed" ? "hidden" : ""
                    } dropdown inline-block relative`}
                  >
                    <button className="border uppercase border-freshGreen text-sm h-full text-freshGreen py-1.5 px-2.5 inline-flex items-center hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white btn_overview_addidea">
                      <span className="text-sm font-semibold">ADD IDEA</span>
                    </button>

                    <ul className="dropdown-menu absolute hidden border border-gray-100 bg-white text-gray-700 p-1 w-60 font-semibold">
                      <div onClick={() => createNewIdea(slug.id)}>
                        <p className="hover:bg-gray-100 py-2 px-4 block whitespace-no-wrap text-[12px] cursor-pointer mb-1">
                          Create New Idea
                        </p>
                      </div>
                      <div onClick={() => setShow(!show)}>
                        <p className="hover:bg-gray-100 py-2 px-4 block whitespace-no-wrap text-[12px] cursor-pointer">
                          Select Idea from My Ideas
                        </p>
                      </div>
                    </ul>
                  </div>

                  <button className="border uppercase border-freshGreen text-sm text-freshGreen py-1.5 px-2.5 inline-flex items-center hover:bg-gradient-to-r from-green-600 to-freshGreen h-full hover:text-white btn_overview_addidea">
                  <Link to={`${RouteName.sumbitedIdea}/${detail.id}?isview=0&evaluation=&page=0`}>
                  <span className="text-sm font-semibold">
                        View Submitted Ideas
                      </span>
                    </Link>
                  </button>
                </div>

                <div className="space-x-3 bx_overview_like">
                  {like === null ? (
                    <>
                      <button onClick={(e) => postDisLike(id)}>
                        <img
                          src="/assets/global/img/dislike1.svg"
                          className="w-8 h-8"
                          alt="dislike"
                        />
                      </button>
                      <button onClick={(e) => postLike(id)}>
                        <img
                          src="/assets/global/img/like1.svg"
                          className="w-8 h-8"
                          alt="like"
                        />
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          if (like.status === false) {
                            unLike(like.id, like.status);
                          } else {
                            postDisLike(id);
                          }
                        }}
                      >
                        {like.status === false ? (
                          <img
                            src="/assets/global/img/dislike2.svg"
                            className="w-8 h-8"
                            alt=""
                          />
                        ) : (
                          <img
                            src="/assets/global/img/dislike1.svg"
                            className="w-8 h-8"
                            alt=""
                          />
                        )}
                      </button>
                      <button
                        onClick={() => {
                          if (like.status === true) {
                            unLike(like.id, like.status);
                          } else {
                            postLike(id);
                          }
                        }}
                      >
                        {like.status === true ? (
                          <img
                            src="/assets/global/img/like2.svg"
                            className="w-8 h-8"
                            alt=""
                          />
                        ) : (
                          <img
                            src="/assets/global/img/like1.svg"
                            className="w-8 h-8"
                            alt=""
                          />
                        )}
                      </button>
                    </>
                  )}
                </div>
              </div>

              {/* <div className="inline-flex w-full justify-center space-x-3 md:hidden lg:hidden">
                <div
                  className={`${
                    detail.status === "Up coming" ? "hidden" : ""
                  } ${detail.status === "Closed" ? "hidden" : ""}  ${
                    detail.status === "Invalid" ? "hidden" : ""
                  } dropdown inline-block relative`}
                >
                  <button className="border border-freshGreen text-sm text-freshGreen py-1.5 px-2.5 inline-flex items-center hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white btn_overview_addidea">
                    <span className="mr-1 text-sm font-semibold">ADD IDEA</span>
                  </button>

                  <ul className="dropdown-menu absolute hidden border border-gray-100 bg-white text-gray-700 p-1 w-60 font-semibold">
                    <div onClick={() => createNewIdea(slug.id)}>
                      <p className="hover:bg-gray-100 py-2 px-4 block whitespace-no-wrap text-[12px] cursor-pointer mb-1">
                        Create New Idea
                      </p>
                    </div>
                    <div onClick={() => setShow(!show)}>
                      <p className="hover:bg-gray-100 py-2 px-4 block whitespace-no-wrap text-[12px] cursor-pointer">
                        Select Idea from My Ideas
                      </p>
                    </div>
                  </ul>
                </div>

                <Link to={RouteName.sumbitedIdea + "/" + detail.id}>
                  <button className="border border-freshGreen text-sm text-freshGreen py-1 px-2 inline-flex items-center hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white btn_overview_addidea">
                    <span className="mr-1 text-sm font-semibold">
                      View Submitted Ideas
                    </span>
                  </button>
                </Link>
              </div> */}

              <ModalDetail
                show={show}
                setShow={setShow}
                listed={listed}
                listView={listView}
                setlistView={setlistView}
                sort={sort}
                setSort={setSort}
                paginate={paginate}
                page={page}
                id={id}
                slug={slug}
              />
            </div>
            <div className="bg-baseGray p-3 space-y-4 md:basis-1/4">
              <h3 className="font-semibold text-md md:text-lg">
                Challenge Managers
              </h3>
              <div className="space-y-3">
                <div className="space-y-3">
                  {manager.map((item, index) => (
                    <div key={index} className="flex space-x-3 items-center">
                      <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                        {GeneralUtility.getInitials(item.fullname)}
                      </div>
                      <p className="text-xs md:text-sm font-medium">
                        {item.fullname}
                      </p>
                    </div>
                  ))}
                </div>

                <div id="line" className="bg-gray-200 w-full h-0.5"></div>

                <div className="flex flex-row md:flex-col">
                  <div className="md:basis-1/2 flex-1">
                    <div className="flex space-x-3 items-center mt-2">
                      <div className="w-7 justify-center self-center flex">
                        <img
                          src="/assets/global/img/avatar.png"
                          className="w-4 h-4"
                          alt="avatar"
                        />
                      </div>
                      <p className="text-xs md:text-sm text-gray-700">
                        {detail.total_contributor} Contributors
                      </p>
                    </div>
                    <div className="flex space-x-3 items-center mt-2">
                      <div className="w-7 justify-center self-center flex">
                        <img
                          src="/assets/global/img/visible.svg"
                          className="w-[18px] h-4"
                          alt="visible"
                        />
                      </div>
                      <p className="text-xs md:text-sm text-gray-700">
                        {detail.total_view} Views
                      </p>
                    </div>
                    <div className="flex space-x-3 items-center mt-2">
                      <div className="w-7 justify-center self-center flex">
                        <img
                          src="/assets/global/img/lightbulbGray.png"
                          className="w-4 h-5"
                          alt=""
                        />
                      </div>
                      <p className="text-xs md:text-sm text-gray-700">
                        {detail.total_submited} Ideas
                      </p>
                    </div>
                  </div>
                  <div className="md:basis-1/2 flex-1">
                    <div className="flex space-x-3 items-center mt-2">
                      <div className="w-7 justify-center self-center flex">
                        <img
                          src="/assets/global/img/like.svg"
                          className="w-4 h-4"
                          alt="like"
                        />
                      </div>
                      <p className="text-xs md:text-sm text-gray-700">
                        {detail.total_like} Likes
                      </p>
                    </div>
                    <div className="flex space-x-3 items-center mt-2">
                      <div className="w-7 justify-center self-center flex">
                        <img
                          src="/assets/global/img/dislike.svg"
                          className="w-4 h-4"
                          alt="like"
                        />
                      </div>
                      <p className="text-xs md:text-sm text-gray-700">
                        {detail.total_dislike} Dislikes
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="p-3 space-y-4 md:basis-3/4">
              <Skeleton col="1" height="300" />
            </div>
            <div className="p-3 space-y-4 md:basis-1/4">
              <Skeleton col="1" height="300" />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default OverviewDetail;
