import CallApi from "../../services/request-helper";

const API_SUBMITTED = process.env.REACT_APP_SUBMITTEDIDEA;

const getSubmittedIdea = async ({
  offset,
  limit,
  challange,
  status,
  evaluation,
  sort
}) => {
  const data = {};
  data.offset = offset ? offset : 0;
  data.limit = limit ? limit : 10;
  data.challange = challange ? challange : "";
  if ((status !== {}) && (status !== '')) {
    data.status = status;
  }
  if ((evaluation !== {}) && (evaluation !== '')) {
    data.evaluation = evaluation;
  }
  data.sort = sort;
  const url = API_SUBMITTED;
  const response = await CallApi({ url, method: "POST", data });
  const dat = response !== undefined ? response.data : null;
  const dat2 = dat.data !== undefined ? dat.data : null;
  return dat2;
};

const getAdmSubmittedIdea = async ({
  offset,
  limit,
  challange,
  status,
  evaluation,
  sort
}) => {
  const data = {};
  data.offset = offset ? offset : 0;
  data.limit = limit ? limit : 10;
  data.challange = challange ? challange : "";
  if ((status !== {}) && (status !== '')) {
    data.status = status;
  }
  if ((evaluation !== {}) && (evaluation !== '')) {
    data.evaluation = evaluation;
  }
  data.sort = sort;
  const url = API_SUBMITTED;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};


const SubmittedIdeaRepo = {
  getSubmittedIdea,
  getAdmSubmittedIdea
}

export default SubmittedIdeaRepo;