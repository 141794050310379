import React from 'react'

const BtnGreen = ({ onClick, message }) => {
    return (
        <>
            <button
                onClick={onClick}
                className="border border-1 uppercase font-[500] border-green-400 bg-gradient-to-r from-green-500 to-freshGreen py-2 px-5 text-white inline-flex items-center hover:bg-none hover:text-heritageGreen tracking-tight"
            >
                {message}
            </button>
        </>
    )
}

export default BtnGreen