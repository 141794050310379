import CallApi from "../../services/request-helper";

const API_DETAIL = process.env.REACT_APP_DETAILSUBMITTED;
const API_COUNTVIEW = process.env.REACT_APP_VIEWIDEA;

const getDetail = async id => {
  const url = API_DETAIL;
  let data = { id: id };
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const postView = async (data) => {
  const url = API_COUNTVIEW;
  const response = await CallApi({ url, method: "POST", data });
  return response;
}

const DetailSubmittedRepo = {
  getDetail,
  postView
};

export default DetailSubmittedRepo;
