import React from "react";
import GeneralUtility from "../../../../../utility/GeneralUtility";
import DateUtility from "../../../../../utility/date-utility";
import Skeleton from "../../../../../components/skeleton/Skeleton";

const GridSubmitted = ({ listSubmitted, detailSubmitted, loading }) => {
  return (
    <div>
      <div className="bg-baseGray p-5">
        <h3 className="font-semibold text-xl p-3 text-center">
          Submitted Ideas
        </h3>
        {!loading ? (
          <div>
            {listSubmitted.length === 0 ? (
              <div className="text-center font-semibold text-md p-6">
                No Submitted Ideas
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
                {listSubmitted.map((items) => (
                  <div
                    key={items.id}
                    onClick={() => detailSubmitted(items.id)}
                    className="bg-white border border-gray-200 shadow-md relative pb-4  cursor-pointer"
                  >
                    <div
                      className="w-full h-[200px] bg-cover bg-center bg-no-repeat mb-4 md:h-[200px]"
                      style={{
                        backgroundImage: "url(" + items.card_image + ")",
                      }}
                    >
                      <div className="float-right">
                        <p
                          id="status"
                          className={`bg-white w-[170px] py-[3px] text-center text-xs font-semibold uppercase tracking-[0.5px] 
                          ${
                            items.status === "Experimentation"
                              ? "text-heritageGreen"
                              : ""
                          } 
                          ${
                            items.status === "Validation" ? "text-naartjie" : ""
                          } 
                          ${
                            items.status === "Generation"
                              ? " text-freshGreen"
                              : ""
                          } 
                          ${
                            items.status === "Commercialisation"
                              ? " text-blueSky"
                              : ""
                          } 
                          ${items.status === "Declined" ? " text-red-700" : ""} 
                          ${items.status === "Closed" ? "  text-cerise" : ""}
                          ${
                            items.status === "On Hold"
                              ? "  text-orange-900"
                              : ""
                          } 
                          `}
                        >
                          {items.status}
                        </p>
                      </div>
                    </div>
                    <div className="p-3 space-y-5">
                      <div className="flex space-x-1 items-center">
                        <div className="basis-1/5 ">
                          <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-bold justify-center">
                            {GeneralUtility.getInitials(items.fullname)}
                          </div>
                        </div>
                        <div>
                          <p className="text-xs basis-4/5 break-all font-semibold text-gray-700">
                            {items.fullname}
                          </p>
                          <p className="text-[10px] text-gray-700">
                            {DateUtility.formatDate(items.created_at)}
                          </p>
                        </div>
                      </div>
                      <div id="content">
                        <h3
                          title={
                            items.title_idea !== null ? items.title_idea : ""
                          }
                          className="mb-3 text-sm font-semibold text-gray-700"
                        >
                          {items.title_idea !== null
                            ? items.title_idea.length > 30
                              ? items.title_idea.substring(0, 30) + "..."
                              : items.title_idea
                            : ""}
                        </h3>
                      </div>
                      <div className="space-x-2 flex text-sm justify-between bottom-3">
                        <div className="flex items-center space-x-2 text-gray-400">
                          <div className="flex items-center space-x-2">
                            <img
                              src="/assets/global/img/visible.svg"
                              className="h-3 w-4"
                              alt="visible"
                            />
                            <p className="text-sm">{items.total_view}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <img
                              src="/assets/global/img/chat.svg"
                              className="h-2.5 w-4"
                              alt="commentIco"
                            />
                            <p className="text-sm">{items.total_commentary}</p>
                          </div>
                        </div>
                        <div className="flex items-center space-x-2 text-gray-400">
                          <div className="flex items-center space-x-2">
                            <img
                              src="/assets/global/img/like.svg"
                              className="h-3 w-3"
                              alt="likeIco"
                            />
                            <p>{items.total_like}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <img
                              src="/assets/global/img/dislike.svg"
                              className="h-3 w-3"
                              alt="dislikeIco"
                            />
                            <p>{items.total_dislike}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className="h-screen text-center">
            <Skeleton col="4" height="300" />
          </div>
        )}
      </div>
    </div>
  );
};

export default GridSubmitted;
