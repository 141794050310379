import React, { useEffect, useState } from "react";
import "./css/edit_page.css";
import "./css/style_home.css";
import "./css/pages.css";
import Swal from "sweetalert2";
import EditPageRepo from "../../../repositories/adm-repo/EditPageRepo";
import GeneralUtility from "../../../utility/GeneralUtility";
import RewardsToolTip from "./components/RewardsToolTip";
import NewsHome from "./components/NewsHome";
import InsightHome from "./components/Insight/InsightHome";
import Banner from "./components/Banner";
import ModalTerms from "./components/TermsAndConditions/ModalTerms";
import TitleReward from "./components/TitleReward";
import ButtonPreview from "./components/Button/ButtonPreview";
import PreviewTerms from "./components/TermsAndConditions/PreviewTerms";
import BtnWhite from "../../../components/buttons/BtnWhite";
import AlertUtility from "../../../utility/alert-utility";
const refsTitle = React.createRef();
const refsSubTitle = React.createRef();

const EditPage = ({ refcapture, showpage = true }) => {
  const [loadingBanner, setLoadingBanner] = useState(false);
  const [loadingReward, setLoadingReward] = useState(false);
  const [banner, setBanner] = useState();
  const [titleReward, setTitleReward] = useState();
  const [subTitleReward, setSubTitleReward] = useState();
  const [bannerInfo, setBannerInfo] = useState();
  const [newsInfo, setNewsInfo] = useState();
  const [titleInsight, setTitleInsight] = useState();
  const [terms, setTerms] = useState();
  const [initialTerms, setInitialTerms] = useState();
  const [previewTerms, setPreviewTerms] = useState();
  const [insights, setInsights] = useState([]);
  const [history, setHistory] = useState([]);
  const [showInsight, setShowInsight] = useState(false);
  const [showModePreview, setShowModePreview] = useState(false);
  const [showPreviewTerms, setShowPreviewTerms] = useState(false);
  const [showButtonPublish, setShowButtonPublish] = useState(false);
  const [id, setId] = useState();
  const [titleTooltip, setTitleTooltip] = useState([1, 1, 1, 1]);
  const [btnShow, setBtnShow] = useState([0, 0]);
  const [editedbtn, setEditedBtn] = useState(1);
  const [deleted, setDeleted] = useState(0);
  const [rewards, setRewards] = useState([]);
  const [contentDefault, setContentDefault] = useState();
  const [prev, setPrev] = useState([]);
  const [future, setFuture] = useState([]);
  const [imgBanner, setImgBanner] = useState();
  const [imgBannerInfo, setImgBannerInfo] = useState();
  const [getDataStatus, setGetDataStatus] = useState(true);
  const [editStatus, setEditStatus] = useState([]);
  const [titleInputRewards, setTitleInputRewards] = useState(["", ""]);
  const getContentDraft = async () => {
    setLoadingBanner(true);
    setLoadingReward(true);

    const resultContentDraft = await EditPageRepo.getContentDraft({
      offset: 0,
      limit: 10,
    });

    if (resultContentDraft.status === "OK") {
      const data = resultContentDraft.data.data[0];
      setBanner(data.banner);
      setTitleReward(data.titleReward);
      setSubTitleReward(data.subTitleReward);
      setRewards(data.rewards);
      setNewsInfo(data.newsInfo);
      setBannerInfo(data.bannerInfo);
      setTitleInsight(data.titleInsight);
      setInsights(data.insights);
      setInitialTerms(data.terms);
      setPreviewTerms(data.terms);
      setId(data.id);
      setShowInsight(true);
      setLoadingBanner(false);
      setLoadingReward(false);

      titleInputRewards[0] = data.titleReward;
      titleInputRewards[1] = data.subTitleReward;
      setTitleInputRewards(titleInputRewards);
      setPrev([
        ...prev,
        {
          banner: data.banner,
          titleRewards: data.titleReward,
          subTitleRewards: data.subTitleReward,
          rewards: [
            {
              title: data.rewards[0].title,
              description: data.rewards[0].description,
              reward: data.rewards[0].reward,
            },
            {
              title: data.rewards[1].title,
              description: data.rewards[1].description,
              reward: data.rewards[1].reward,
            },
            {
              title: data.rewards[2].title,
              description: data.rewards[2].description,
              reward: data.rewards[2].reward,
            },
            {
              title: data.rewards[3].title,
              description: data.rewards[3].description,
              reward: data.rewards[3].reward,
            },
          ],
          bannerInfo: data.bannerInfo,
          newsInfo: data.newsInfo,
          titleInsight: data.titleInsight,
          insights: [
            {
              title: data.insights[0].title,
              source: data.insights[0].source,
              content: data.insights[0].content,
            },
            {
              title: data.insights[1].title,
              source: data.insights[1].source,
              content: data.insights[1].content,
            },
            {
              title: data.insights[2].title,
              source: data.insights[2].source,
              content: data.insights[2].content,
            },
          ],
        },
      ]);
    } else {
      setGetDataStatus(false);
    }
  };

  const getContentDefault = async () => {
    AlertUtility.loadingAlert();
    const resultContentDefault = await EditPageRepo.getContentDefault();
    if (resultContentDefault.status === "OK") {
      setContentDefault(resultContentDefault);
    } else {
      setGetDataStatus(false);
    }
  };

  const triggerSave = async (cond) => {
    const result = await Swal.fire({
      title: cond === "save" ? "Confirm Save" : "Confirm Publish",
      text:
        cond === "save"
          ? "Are you sure you want to save the data?"
          : "Are you sure you want to publish the data ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: cond === "save" ? "Yes, save it!" : "Yes, publish it!",
      cancelButtonText: "No, cancel!",
    });
    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        html: '<i class="fas fa-spinner fa-spin"></i>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showLoaderOnConfirm: true,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      let titleRewards = refsTitle.current.innerText;
      let subTitleRewards = refsSubTitle.current.innerText;
      let isDefaultBanner = 1;
      let isDefaultBannerInfo = 1;
      let data = new FormData();
      if (banner === contentDefault.data.banner) {
        isDefaultBanner = 0;
      }
      if (bannerInfo === contentDefault.data.bannerInfo) {
        isDefaultBannerInfo = 0;
      }

      if (checkFileOrImage(banner)) {
        data.append("banner", banner);
      }
      if (checkFileOrImage(bannerInfo)) {
        data.append("bannerInfo", bannerInfo);
      }

      data.append("isDefaultBanner", isDefaultBanner);
      data.append("titleReward", titleRewards);
      data.append("subTitleReward", subTitleRewards);
      data.append("rewards[0].title", rewards[0].title);
      data.append("rewards[0].description", rewards[0].description);
      data.append("rewards[0].reward", rewards[0].reward);
      data.append("rewards[0].indexValue", 1);
      data.append("rewards[1].title", rewards[1].title);
      data.append("rewards[1].description", rewards[1].description);
      data.append("rewards[1].reward", rewards[1].reward);
      data.append("rewards[1].indexValue", 2);
      data.append("rewards[2].title", rewards[2].title);
      data.append("rewards[2].description", rewards[2].description);
      data.append("rewards[2].reward", rewards[2].reward);
      data.append("rewards[2].indexValue", 3);
      data.append("rewards[3].title", rewards[3].title);
      data.append("rewards[3].description", rewards[3].description);
      data.append("rewards[3].reward", rewards[3].reward);
      data.append("rewards[3].indexValue", 4);
      data.append("info", newsInfo);
      data.append("isDefaultBannerInfo", isDefaultBannerInfo);
      data.append("titleInsight", titleInsight);
      data.append("insights[0].source", insights[0].source);
      data.append("insights[0].title", insights[0].title);
      data.append("insights[0].content", insights[0].content);
      data.append("insights[0].indexValue", 1);
      data.append("insights[1].source", insights[1].source);
      data.append("insights[1].title", insights[1].title);
      data.append("insights[1].content", insights[1].content);
      data.append("insights[1].indexValue", 2);
      data.append("insights[2].source", insights[2].source);
      data.append("insights[2].title", insights[2].title);
      data.append("insights[2].content", insights[2].content);
      data.append("insights[2].indexValue", 3);
      data.append("drafted", cond === "save" ? "1" : "0");
      data.append("id", id);

      const resultReward = await EditPageRepo.editPublishPage(data);
      if (resultReward.data.status == "OK") {
        Swal.close();
        const results = Swal.fire({
          title: cond == "save" ? "Save!" : "Publish!",
          text:
            cond == "save"
              ? "Your Data Has Been Saved"
              : "Your Data Has Been Published",
          icon: "success",
          confirmButtonText: "OK",
        }).then((results) => {
          if (results.isConfirmed) {
            getContentDefault();
            getContentDraft();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        });
      }
    }
  };

  const saveTerms = async (cond) => {
    const result = await Swal.fire({
      title: "Confirm Save",
      text: "Are you sure you want to save the data?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, save it!",
      cancelButtonText: "No, cancel!",
    });
    if (result.isConfirmed) {
      AlertUtility.loadingAlert();
      let param = {
        id: id,
        value: terms,
      };
      const resultTerms = await EditPageRepo.editTerms(param);

      if (resultTerms.status === 200) {
        setInitialTerms(terms);
        setPreviewTerms(terms);
        Swal.close();
        Swal.fire("Save!", "Your data has been Saved", "success").then(() => {
          setModalIsOpen(false);
        });
      } else {
        Swal.close();
        Swal.fire("Sorry...", "Your data fail to save", "error");
      }
    }
  };
  const triggerPreview = async () => {
    setShowButtonPublish(false);
    setShowModePreview(true);
    btnShow[0] = 1;
    setBtnShow(btnShow);
  };
  const triggerPublish = async () => {
    setShowModePreview(true);
    setShowButtonPublish(true);
    btnShow[1] = 1;
    setBtnShow(btnShow);
  };
  const returnToEditMode = async () => {
    setShowModePreview(false);
    setShowButtonPublish(false);
    btnShow[0] = 0;
    btnShow[1] = 0;
    setBtnShow(btnShow);
  };
  const triggerUndo = async () => {
    if (prev.length > 1) {
      const newPast = prev.slice(0, prev.length - 1);
      const newState = prev[prev.length - 1];
      const newFuture = [...future, newState];

      setPrev(newPast);
      setFuture(newFuture);

      setHistory(newState);
      refsTitle.current.innerText =
        newPast.length == 0 ? "" : newPast[newPast.length - 1].titleRewards;
      refsSubTitle.current.innerText =
        newPast.length == 0 ? "" : newPast[newPast.length - 1].subTitleRewards;
      if (
        newPast[newPast.length - 1].rewards != null ||
        newPast[newPast.length - 1].rewards != undefined
      ) {
        for (var index = 0; index < 4; index++) {
          rewards[index].title =
            newPast.length == 0
              ? ""
              : newPast[newPast.length - 1].rewards[index].title;
          rewards[index].reward =
            newPast.length == 0
              ? ""
              : newPast[newPast.length - 1].rewards[index].reward;
          rewards[index].description =
            newPast.length == 0
              ? ""
              : newPast[newPast.length - 1].rewards[index].description;
        }
      }
      await mapUndoRedo(newPast);
    }
  };
  const triggerRedo = async () => {
    if (future.length > 0) {
      const newFuture =
        future.length == 1 ? [future[0]] : future.slice(0, future.length - 1);
      const newState = future[future.length - 1];
      const newPast = [...prev, newState];

      setFuture(newFuture);
      setHistory(newState);
      setPrev(newPast);
      refsTitle.current.innerText =
        newPast.length == 0 ? "" : newPast[newPast.length - 1].titleRewards;
      refsSubTitle.current.innerText =
        newPast.length == 0 ? "" : newPast[newPast.length - 1].subTitleRewards;
      if (
        newFuture[newFuture.length - 1].rewards != null ||
        newFuture[newFuture.length - 1].rewards != undefined
      ) {
        for (var index = 0; index < 4; index++) {
          rewards[index].title =
            newPast.length == 0
              ? ""
              : newPast[newPast.length - 1].rewards[index].title;
          rewards[index].reward =
            newPast.length == 0
              ? ""
              : newPast[newPast.length - 1].rewards[index].reward;
          rewards[index].description =
            newPast.length == 0
              ? ""
              : newPast[newPast.length - 1].rewards[index].description;
        }
      }
      await mapUndoRedo(newFuture);
    }
  };
  const mapUndoRedo = async (storedData) => {
    if (storedData.length !== 0) {
      let storedBanner = storedData[storedData.length - 1].banner;
      let storedBannerInfo = storedData[storedData.length - 1].bannerInfo;
      if (checkFileOrImage(storedBanner)) {
        const base64 = await fileToBase64(storedBanner);
        setImgBanner(base64);
      } else {
        setImgBanner(storedBanner);
      }
      if (checkFileOrImage(storedBannerInfo)) {
        const base64 = await fileToBase64(storedBannerInfo);
        setImgBannerInfo(base64);
      } else {
        setImgBannerInfo(storedBannerInfo);
      }
      setBanner(storedData[storedData.length - 1].banner);
      setBannerInfo(storedData[storedData.length - 1].bannerInfo);
      setNewsInfo(storedData[storedData.length - 1].newsInfo);
      setTitleInsight(storedData[storedData.length - 1].titleInsight);
      setInsights([...storedData[storedData.length - 1].insights]);
    }
  };
  const checkFileOrImage = (data) => {
    return !String(data).startsWith("http") && String(data) != null;
  };
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const [isModalOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const closeTerms = () => {
    setShowPreviewTerms(false);
  };
  const openTerms = () => {
    setShowPreviewTerms(true);
  };
  const headTitleRewards = () => {
    titleInputRewards[0] = refsTitle.current.textContent;
    setTitleInputRewards(titleInputRewards);
    setPrevData();
  };
  const headSubTitleRewards = () => {
    titleInputRewards[1] = refsSubTitle.current.textContent;
    setTitleInputRewards(titleInputRewards);
    setPrevData();
  };
  const triggerEdit = async (stateEdit, index) => {
    if (deleted == 0) {
      switch (stateEdit) {
        case "edit_title":
          if (editedbtn == 1) {
            elements("hometitle", "contenteditable", true, 1, null);
            elements("subtitle", "contenteditable", true, 1, null);
            elements(
              stateEdit,
              "style",
              "background-color:rgb(0 150 119);",
              1,
              null
            );
            setEditedBtn(0);
          } else {
            elements("hometitle", "contenteditable", false, 1, null);
            elements("subtitle", "contenteditable", false, 1, null);
            elements(stateEdit, "style", "background-color:#58595B", 1, null);
            setEditedBtn(1);
          }
          break;
        case "edit_rewards":
          if (titleTooltip[index] == 1) {
            elements("title_" + index, "disabled", "", 3, null);
            elements(
              "elem_" + index,
              "style",
              "background-color:rgb(0 150 119);",
              1,
              null
            );
            elements("reward_" + index, "disabled", "", 3, null);
            elements("description_" + index, "disabled", "", 3, null);
            titleTooltip[index] = 0;
            setTitleTooltip(titleTooltip);
          } else {
            elements("title_" + index, "disabled", true, 1, null);
            elements(
              "elem_" + index,
              "style",
              "background-color:#58595B",
              1,
              null
            );
            elements("description_" + index, "disabled", true, 1, null);
            elements("reward_" + index, "disabled", true, 1, null);
            titleTooltip[index] = 1;
            setTitleTooltip(titleTooltip);
          }
          break;
      }
    }
  };

  const setPrevData = () => {
    setPrev([
      ...prev,
      {
        banner: banner,
        titleRewards: titleInputRewards[0],
        subTitleRewards: titleInputRewards[1],
        rewards: [
          {
            title: rewards[0].title,
            description: rewards[0].description,
            reward: rewards[0].reward,
          },
          {
            title: rewards[1].title,
            description: rewards[1].description,
            reward: rewards[1].reward,
          },
          {
            title: rewards[2].title,
            description: rewards[2].description,
            reward: rewards[2].reward,
          },
          {
            title: rewards[3].title,
            description: rewards[3].description,
            reward: rewards[3].reward,
          },
        ],
        newsInfo: newsInfo,
        bannerInfo: bannerInfo,
        titleInsight: titleInsight,
        insights: [
          {
            title: insights[0].title,
            source: insights[0].source,
            content: insights[0].content,
          },
          {
            title: insights[1].title,
            source: insights[1].source,
            content: insights[1].content,
          },
          {
            title: insights[2].title,
            source: insights[2].source,
            content: insights[2].content,
          },
        ],
      },
    ]);
  };
  const elements = async (element, attrProp, attrValue, states, func) => {
    let elementCheck;
    switch (states) {
      case 1:
        elementCheck = document.getElementById(element);
        if (elementCheck == null || elementCheck == undefined) {
          return;
        }
        elementCheck.setAttribute(attrProp, attrValue);
        break;
      case 2:
        console.log(document.getElementById(element).value);
        break;
      case 3:
        elementCheck = document.getElementById(element);
        if (elementCheck == null || elementCheck == undefined) {
          return;
        }
        elementCheck.removeAttribute(attrProp);
        break;
      case 4:
        elementCheck = document.getElementById(element);
        if (elementCheck == null || elementCheck == undefined) {
          return;
        }
        elementCheck.addEventListener(attrProp, func);
        break;
      case 5:
        elementCheck = document.getElementById(element);
        if (elementCheck == null || elementCheck == undefined) {
          return;
        }
        elementCheck.remove();
        break;
    }
  };
  const triggerDelete = async (stateDelete, index) => {
    let data = contentDefault;
    if (data.status === "OK") {
      setDeleted(1);
      switch (stateDelete) {
        case "delete_title":
          refsTitle.current.innerText = data.data.titleReward;
          refsSubTitle.current.innerText = data.data.subTitleReward;
          setTitleReward(data.data.titleReward);
          setSubTitleReward(data.data.subTitleReward);
          setDeleted(0);
          break;
        case "delete_rewards":
          const values = [...rewards];
          values[index]["reward"] = GeneralUtility.removeNonNumeric(
            data.data.rewards[index].reward
          );
          values[index]["title"] = data.data.rewards[index].title;
          values[index]["description"] = data.data.rewards[index].description;
          setRewards(values);
          setDeleted(0);
          break;
      }
    }
  };
  const handleInputChange = (index, event) => {
    const values = [...rewards];
    const updatedValue = event.target.name;
    let readIndexs;
    if (updatedValue === "reward") {
      values[index][updatedValue] = GeneralUtility.removeNonNumeric(
        event.target.value
      );
    } else {
      values[index][updatedValue] = event.target.value;
    }
    setRewards(values);
  };
  useEffect(() => {
    AlertUtility.loadingAlert();
    GeneralUtility.hitRefreshToken();
    getContentDraft();
    getContentDefault();
    if (getDataStatus) {
      Swal.close();
    } else {
      Swal.close();
      AlertUtility.warningAlert(0);
    }
    if (refcapture) {
      setShowModePreview(true);
      setTimeout(() => {
        elements(
          "rewards-map",
          "style",
          "margin-left:1%;margin-right:2%;height:10%;width:100%;",
          1,
          null
        );
        elements(
          "controller-edit",
          "style",
          "width:15%;margin-right:1%;font-size:12px;",
          1,
          null
        );
        elements("titleInsight", "style", "font-size:12px;", 1, null);
        elements(
          "contentInsight",
          "style",
          "height:50px;width:100%;word-wrap:break-word;overflow:auto;",
          1,
          null
        );
        elements("titleInsightSm_1", "style", "font-size:12px;", 1, null);
        elements(
          "contentInsightSm_1",
          "style",
          "height:50px;width:100%;word-wrap:break-word;overflow:auto;",
          1,
          null
        );
        elements("titleInsightSm_2", "style", "font-size:12px;", 1, null);
        elements(
          "contentInsightSm_2",
          "style",
          "height:50px;width:100%;word-wrap:break-word;overflow:auto;",
          1,
          null
        );
        elements("cancel-return", null, null, 5, null);
      }, 5000);
    }
  }, []);
  return (
    <>
      <div
        className={showpage ? "container mx-auto" : "hidden"}
        id="child-component"
        ref={refcapture}
      >
        <div className="col-12 col-md-12 headgreen h-18">
          <div className="col-12 col-md-12 w-20 text-center m-1 ml-4"></div>
          <div className="col-md-3 w-auto h-7 text-center m-2 btnPrevRedo ml-20">
            <button
              type="button"
              className="btn btn-primary pt-1 pb-1 w-10 h-auto pl-3 btnPrev"
              onClick={() => triggerUndo()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="18.595"
                viewBox="0 0 15 6.595"
              >
                <path
                  id="undo"
                  d="M9.459,8.233A7.668,7.668,0,0,0,4.4,10.138L1.765,7.5v6.6h6.6L5.707,11.442A5.841,5.841,0,0,1,15.028,14.1l1.737-.572A7.7,7.7,0,0,0,9.459,8.233Z"
                  transform="translate(-1.765 -7.5)"
                  fill="#808285"
                />
              </svg>
            </button>
            <button
              type="button"
              className="btn btn-primary pt-1 pb-1 w-10 btnRedo h-auto pl-3"
              onClick={() => triggerRedo()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="18.598"
                viewBox="0 0 15 6.598"
              >
                <path
                  id="redo"
                  d="M14.131,10.139A7.677,7.677,0,0,0,1.77,13.526L3.5,14.1a5.844,5.844,0,0,1,9.326-2.654L10.172,14.1h6.6V7.5Z"
                  transform="translate(-1.77 -7.5)"
                  fill="#808285"
                />
              </svg>
            </button>
          </div>
          <div className="titleHome sm:ml-5">
            <div className="col-md-3 text-center">
              <h1>HOME</h1>
            </div>
          </div>
          <div className="updateButton">
            <button
              type="button"
              className="btn btn-primary h-auto w-20 btnUpdate bg-heritageGreen m-1"
            >
              UPDATE
            </button>
          </div>
        </div>
        <div className="wrapper-edit">
          <div className="controller-edit" id="controller-edit">
            <div className="title">
              <h1>Edit Home Page</h1>
            </div>
            <div className="editor">
              <div
                className={
                  btnShow[0] == 1
                    ? "editorbtn h-[4.5rem] w-[4.5rem] opacity-50"
                    : "editorbtn h-[4.5rem] w-[4.5rem]"
                }
                onClick={() => triggerPreview()}
              >
                <div className="w-[4.5rem] flex justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                  >
                    <g id="_01_align_center" data-name="01 align center">
                      <path d="M23.821,11.181v0C22.943,9.261,19.5,3,12,3S1.057,9.261.179,11.181a1.969,1.969,0,0,0,0,1.64C1.057,14.739,4.5,21,12,21s10.943-6.261,11.821-8.181A1.968,1.968,0,0,0,23.821,11.181ZM12,19c-6.307,0-9.25-5.366-10-6.989C2.75,10.366,5.693,5,12,5c6.292,0,9.236,5.343,10,7C21.236,13.657,18.292,19,12,19Z" />
                      <path d="M12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />
                    </g>
                  </svg>
                </div>
                <div className="w-[4.5rem] text-center text-sm pt-1">Preview</div>
              </div>
              <div
                className="editorbtn h-[4.5rem] w-[4.5rem]"
                id="savebtn"
                onClick={() => triggerSave("save")}
              >
                <div className="w-16 flex justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Outline"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                  >
                    <path d="M12,10a4,4,0,1,0,4,4A4,4,0,0,0,12,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,16Z" />
                    <path d="M22.536,4.122,19.878,1.464A4.966,4.966,0,0,0,16.343,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7.657A4.966,4.966,0,0,0,22.536,4.122ZM17,2.08V3a3,3,0,0,1-3,3H10A3,3,0,0,1,7,3V2h9.343A2.953,2.953,0,0,1,17,2.08ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2V3a5.006,5.006,0,0,0,5,5h4a4.991,4.991,0,0,0,4.962-4.624l2.16,2.16A3.02,3.02,0,0,1,22,7.657Z" />
                  </svg>
                </div>
                <div className="w-[4.5rem] text-center text-sm pt-1">Save</div>
              </div>
              <div
                className={
                  btnShow[1] == 1
                    ? "editorbtn h-[4.5rem] w-[4.5rem] opacity-50"
                    : "editorbtn h-[4.5rem] w-[4.5rem]"
                }
                id="publishbtn"
                onClick={() => triggerPublish()}
              >
                <div className="w-[4.5rem] flex justify-center rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Outline"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                  >
                    <path d="M9.878,18.122a3,3,0,0,0,4.244,0l3.211-3.211A1,1,0,0,0,15.919,13.5l-2.926,2.927L13,1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1l-.009,15.408L8.081,13.5a1,1,0,0,0-1.414,1.415Z" />
                    <path d="M23,16h0a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17A1,1,0,0,0,23,16Z" />
                  </svg>
                </div>
                <div className="w-[4.5rem] text-center text-sm pt-1">Publish</div>
              </div>
            </div>
          </div>
          <div className="content" id="content">
            {showModePreview ? (
              <>
                {" "}
                {showPreviewTerms ? (
                  <PreviewTerms value={previewTerms} closeTerms={closeTerms} />
                ) : (
                  <>
                    {" "}
                    <div className="w-full mt-5">
                      <Banner
                        type="homepage"
                        banner={banner}
                        loading={loadingBanner}
                        setBanner={setBanner}
                        defaultBanner={contentDefault}
                        showModePreview={showModePreview}
                        setPrevData={setPrevData}
                        img={imgBanner}
                        setImg={setImgBanner}
                        fileToBase64={fileToBase64}
                        setEditStatus={setEditStatus}
                        editStatus={editStatus}
                      />
                    </div>
                    <div className="bg-baseGray p-6 m-5 bx_home_reward w-full">
                      <div
                        className={
                          showModePreview != true
                            ? "h-7 w-30 controlhover"
                            : "hidden"
                        }
                      >
                        <div
                          className="mr-2 listedit pt-2"
                          id="edit_title"
                          onClick={() => triggerEdit("edit_title", 0)}
                          title="Edit"
                        >
                          <svg
                            id="edit_-_icon"
                            data-name="edit - icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="13"
                            viewBox="0 0 12.857 12.857"
                          >
                            <path
                              id="Path_16"
                              data-name="Path 16"
                              d="M3,16.77H5.677l7.9-7.9L10.9,6.192,3,14.092Zm1.429-2.086L10.9,8.213l.657.657L5.085,15.342H4.427Z"
                              transform="translate(-2.999 -3.913)"
                              fill="#fff"
                            />
                            <path
                              id="Path_17"
                              data-name="Path 17"
                              d="M17.443,3.208a.711.711,0,0,0-1.007,0L15.129,4.515l2.679,2.679,1.307-1.307a.711.711,0,0,0,0-1.007Z"
                              transform="translate(-6.466 -2.999)"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                        <div
                          className="mr-2 listedit pt-2"
                          title="Delete"
                          onClick={() => triggerDelete("delete_title", 0)}
                          id="delete_title"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="13"
                            viewBox="0 0 10 12.857"
                          >
                            <path
                              id="bin"
                              d="M5.714,14.429a1.433,1.433,0,0,0,1.429,1.429h5.714a1.433,1.433,0,0,0,1.429-1.429V5.857H5.714ZM7.143,7.286h5.714v7.143H7.143ZM12.5,3.714,11.786,3H8.214L7.5,3.714H5V5.143H15V3.714Z"
                              transform="translate(-5 -3)"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                      </div>
                      <TitleReward
                        titleValue={titleReward}
                        refsTitle={refsTitle}
                        refsSubTitle={refsSubTitle}
                        subTitleValue={subTitleReward}
                      />
                    </div>
                    <div className="w-full">
                      <div className="mx-auto mt-3">
                        <div
                          className="ml-2 mr-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3"
                          id="rewards-map"
                        >
                          {rewards.map((items, index) => (
                            <>
                              <div
                                className="flex justify-center bg-white w-full"
                                key={index}
                              >
                                <div className="shadow-md w-full">
                                  <RewardsToolTip
                                    onClickRewards={() => {
                                      triggerEdit("edit_rewards", index);
                                    }}
                                    onClickDelete={() =>
                                      triggerDelete("delete_rewards", index)
                                    }
                                    idrewards={"elem_" + index}
                                    isShowModalPreview={showModePreview}
                                  />
                                  <div
                                    className={`${
                                      index === 0
                                        ? "bg-gradient-to-r from-orange-600 to-pink-600"
                                        : ""
                                    }
                            ${
                              index === 1
                                ? "bg-gradient-to-r from-futureGreen to-heritageGreen"
                                : ""
                            }
                            ${
                              index === 2
                                ? "bg-gradient-to-r from-yellow-200 to-blueSky"
                                : ""
                            }
                            ${
                              index === 3
                                ? "bg-gradient-to-r from-orange-300 to-yellow-300"
                                : ""
                            } h-24 w-full flex items-center bg-cover`}
                                  >
                                    <div
                                      className="bg-cover h-full w-full flex items-center justify-between"
                                      style={{
                                        backgroundImage: `url("/assets/global/img/Pattern2.png")`,
                                      }}
                                    >
                                      <div className="h-full w-full flex items-center justify-between p-3">
                                        <div className="flex items-center">
                                          <div className="w-7 h-7">
                                            <img
                                              src={`${
                                                index === 0
                                                  ? "/assets/global/img/lightbulb.svg"
                                                  : ""
                                              }
                                        ${
                                          index === 1
                                            ? "/assets/global/img/check.png"
                                            : ""
                                        }
                                        ${
                                          index === 2
                                            ? "/assets/global/img/science-white.png"
                                            : ""
                                        }
                                        ${
                                          index === 3
                                            ? "/assets/global/img/diamond.png"
                                            : ""
                                        }`}
                                              alt="image_ico"
                                              className="mt-1"
                                            />
                                          </div>
                                          <input
                                            autoComplete="off"
                                            className="bg-transparent font-semibold p-0 ml-2 mr-3 text-white text-md w-full border-t-transparent border-l-transparent border-r-transparent focus:fill-none border-b-2 border-b-white"
                                            type="text"
                                            name="title"
                                            id={"title_" + index}
                                            value={items.title}
                                            disabled={true}
                                            onChange={(event) =>
                                              handleInputChange(index, event)
                                            }
                                          />
                                        </div>
                                        <div className="text-white mr-3">
                                          <div className="flex items-center">
                                            <label className="text-md font-semibold">
                                              R
                                            </label>
                                            <input
                                              autoComplete="off"
                                              className="bg-transparent p-0 w-20 text-white text-md border-none focus:fill-none"
                                              type="text"
                                              name="reward"
                                              id={"reward_" + index}
                                              placeholder={items.reward}
                                              disabled={true}
                                              value={GeneralUtility.addCommas(
                                                items.reward
                                              )}
                                              onChange={(event) =>
                                                handleInputChange(index, event)
                                              }
                                            />
                                          </div>
                                          <div
                                            className={
                                              refcapture == null
                                                ? "text-sm border-t-2"
                                                : "text-xs border-t-2 w-14"
                                            }
                                          >
                                            REWARD
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-center">
                                    <input
                                      autoComplete="off"
                                      type="hidden"
                                      name="steps"
                                      value={items.index}
                                      disabled={true}
                                    />
                                    <textarea
                                      className="w-full h-20 border-none shadow-md text-[10px] text-center"
                                      type="text"
                                      name="description"
                                      placeholder={items.description}
                                      value={items.description}
                                      id={"description_" + index}
                                      disabled={true}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <NewsHome
                        loading={loadingBanner}
                        newsInfo={newsInfo}
                        bannerInfo={bannerInfo}
                        setBannerInfo={setBannerInfo}
                        setNewsInfo={setNewsInfo}
                        defaultNews={contentDefault}
                        fileToBase64={fileToBase64}
                        img={imgBannerInfo}
                        setImg={setImgBannerInfo}
                        setPrevData={setPrevData}
                        showModePreview={showModePreview}
                        setEditStatus={setEditStatus}
                        editStatus={editStatus}
                      />
                    </div>
                    <div className="w-full">
                      {showInsight ? (
                        <InsightHome
                          listInsight={insights}
                          titleInsight={titleInsight}
                          showModePreview={showModePreview}
                          setTitleInsight={setTitleInsight}
                          defaultContent={contentDefault}
                          setPrevData={setPrevData}
                          setInsight={setInsights}
                          setEditStatus={setEditStatus}
                          editStatus={editStatus}
                        />
                      ) : null}
                    </div>
                    <div className="text-center w-full bg-baseGray ml-5 mr-5 mb-5 pb-6">
                      <BtnWhite
                        onClick={showModePreview ? openTerms : openModal}
                        message="Terms and Conditions"
                      />
                    </div>
                    <ButtonPreview
                      showButtonPublish={showButtonPublish}
                      returnToEditMode={returnToEditMode}
                      saveTriggers={triggerSave}
                      publishTriggers={triggerSave}
                    />
                  </>
                )}{" "}
              </>
            ) : (
              <>
                {" "}
                <div className="w-full mt-5">
                  <Banner
                    type="homepage"
                    banner={banner}
                    loading={loadingBanner}
                    setBanner={setBanner}
                    defaultBanner={contentDefault}
                    showModePreview={showModePreview}
                    setPrevData={setPrevData}
                    img={imgBanner}
                    setImg={setImgBanner}
                    fileToBase64={fileToBase64}
                    setEditStatus={setEditStatus}
                    editStatus={editStatus}
                  />
                </div>
                <div className="bg-baseGray p-6 m-5 bx_home_reward w-full">
                  <div
                    className={
                      showModePreview != true
                        ? "h-7 w-30 controlhover"
                        : "hidden"
                    }
                  >
                    <div
                      className="mr-2 listedit pt-2"
                      id="edit_title"
                      onClick={() => triggerEdit("edit_title", 0)}
                      title="Edit"
                    >
                      <svg
                        id="edit_-_icon"
                        data-name="edit - icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="13"
                        viewBox="0 0 12.857 12.857"
                      >
                        <path
                          id="Path_16"
                          data-name="Path 16"
                          d="M3,16.77H5.677l7.9-7.9L10.9,6.192,3,14.092Zm1.429-2.086L10.9,8.213l.657.657L5.085,15.342H4.427Z"
                          transform="translate(-2.999 -3.913)"
                          fill="#fff"
                        />
                        <path
                          id="Path_17"
                          data-name="Path 17"
                          d="M17.443,3.208a.711.711,0,0,0-1.007,0L15.129,4.515l2.679,2.679,1.307-1.307a.711.711,0,0,0,0-1.007Z"
                          transform="translate(-6.466 -2.999)"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                    <div
                      className="mr-2 listedit pt-2"
                      title="Delete"
                      onClick={() => triggerDelete("delete_title", 0)}
                      id="delete_title"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="13"
                        viewBox="0 0 10 12.857"
                      >
                        <path
                          id="bin"
                          d="M5.714,14.429a1.433,1.433,0,0,0,1.429,1.429h5.714a1.433,1.433,0,0,0,1.429-1.429V5.857H5.714ZM7.143,7.286h5.714v7.143H7.143ZM12.5,3.714,11.786,3H8.214L7.5,3.714H5V5.143H15V3.714Z"
                          transform="translate(-5 -3)"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                  </div>
                  <TitleReward
                    titleValue={titleReward}
                    refsTitle={refsTitle}
                    refsSubTitle={refsSubTitle}
                    subTitleValue={subTitleReward}
                    blurheadTitle={() => {
                      headTitleRewards();
                    }}
                    blurSubTitle={() => {
                      headSubTitleRewards();
                    }}
                  />
                </div>
                <div className="w-full">
                  <div className="mx-auto mt-3">
                    <div className="ml-2 mr-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                      {rewards.map((items, index) => (
                        <>
                          <div
                            className="flex justify-center bg-white w-full"
                            key={index}
                          >
                            <div className="shadow-md w-full">
                              <RewardsToolTip
                                onClickRewards={() => {
                                  triggerEdit("edit_rewards", index);
                                }}
                                onClickDelete={() =>
                                  triggerDelete("delete_rewards", index)
                                }
                                idrewards={"elem_" + index}
                                isShowModalPreview={showModePreview}
                              />
                              <div
                                className={`${
                                  index === 0
                                    ? "bg-gradient-to-r from-orange-600 to-pink-600"
                                    : ""
                                }
                        ${
                          index === 1
                            ? "bg-gradient-to-r from-futureGreen to-heritageGreen"
                            : ""
                        }
                        ${
                          index === 2
                            ? "bg-gradient-to-r from-yellow-200 to-blueSky"
                            : ""
                        }
                        ${
                          index === 3
                            ? "bg-gradient-to-r from-orange-300 to-yellow-300"
                            : ""
                        } h-24 w-full flex items-center bg-cover`}
                              >
                                <div
                                  className="bg-cover h-full w-full flex items-center justify-between"
                                  style={{
                                    backgroundImage: `url("/assets/global/img/Pattern2.png")`,
                                  }}
                                >
                                  <div className="h-full w-full flex items-center justify-between p-3">
                                    <div className="flex items-center">
                                      <div className="w-7 h-7">
                                        <img
                                          src={`${
                                            index === 0
                                              ? "/assets/global/img/lightbulb.svg"
                                              : ""
                                          }
                                    ${
                                      index === 1
                                        ? "/assets/global/img/check.png"
                                        : ""
                                    }
                                    ${
                                      index === 2
                                        ? "/assets/global/img/science-white.png"
                                        : ""
                                    }
                                    ${
                                      index === 3
                                        ? "/assets/global/img/diamond.png"
                                        : ""
                                    }`}
                                          alt="image_ico"
                                          className="mt-1"
                                        />
                                      </div>
                                      <input
                                        autoComplete="off"
                                        className="bg-transparent font-semibold p-0 ml-2 mr-3 text-white text-md w-full border-t-transparent border-l-transparent border-r-transparent focus:fill-none border-b-2 border-b-white"
                                        type="text"
                                        name="title"
                                        id={"title_" + index}
                                        value={items.title}
                                        disabled={true}
                                        onChange={(event) =>
                                          handleInputChange(index, event)
                                        }
                                        onBlur={() => {
                                          setPrevData();
                                        }}
                                      />
                                    </div>
                                    <div className="text-white mr-3">
                                      <div className="flex items-center">
                                        <label className="text-md font-semibold">
                                          R
                                        </label>
                                        <input
                                          autoComplete="off"
                                          className="bg-transparent p-0 w-20 text-white text-md border-none focus:fill-none"
                                          type="text"
                                          name="reward"
                                          id={"reward_" + index}
                                          placeholder={items.reward}
                                          disabled={true}
                                          value={GeneralUtility.addCommas(
                                            items.reward
                                          )}
                                          onChange={(event) =>
                                            handleInputChange(index, event)
                                          }
                                          onBlur={() => {
                                            setPrevData();
                                          }}
                                        />
                                      </div>
                                      <div className="text-sm border-t-2">
                                        REWARD
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="text-center">
                                <input
                                  autoComplete="off"
                                  type="hidden"
                                  name="steps"
                                  value={items.index}
                                  disabled={true}
                                />
                                <textarea
                                  className="w-full h-20 border-none shadow-md text-[10px] text-center"
                                  type="text"
                                  name="description"
                                  placeholder={items.description}
                                  value={items.description}
                                  id={"description_" + index}
                                  disabled={true}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                  onBlur={() => {
                                    setPrevData();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <NewsHome
                    loading={loadingBanner}
                    newsInfo={newsInfo}
                    bannerInfo={bannerInfo}
                    setBannerInfo={setBannerInfo}
                    setNewsInfo={setNewsInfo}
                    defaultNews={contentDefault}
                    fileToBase64={fileToBase64}
                    img={imgBannerInfo}
                    setImg={setImgBannerInfo}
                    setPrevData={setPrevData}
                    showModePreview={showModePreview}
                    setEditStatus={setEditStatus}
                    editStatus={editStatus}
                  />
                </div>
                <div className="w-full">
                  {showInsight ? (
                    <InsightHome
                      listInsight={insights}
                      titleInsight={titleInsight}
                      showModePreview={showModePreview}
                      setTitleInsight={setTitleInsight}
                      defaultContent={contentDefault}
                      setPrevData={setPrevData}
                      setInsight={setInsights}
                      setEditStatus={setEditStatus}
                      editStatus={editStatus}
                    />
                  ) : null}
                </div>
                <div className="text-center w-full bg-baseGray ml-5 mr-5 mb-5 pb-6">
                  <BtnWhite
                    onClick={showModePreview ? openTerms : openModal}
                    message="Terms and Conditions"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ModalTerms
        isOpen={isModalOpen}
        onClose={closeModal}
        initialTerms={initialTerms}
        setTerms={setTerms}
        saveTerms={saveTerms}
      />
    </>
  );
};

export default EditPage;
