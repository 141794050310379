import React, { useRef, useState } from 'react'
import AlertUtility from '../../../../utility/alert-utility';

const DragDropLC = ({ docs, setDocs }) => {
    // drag state
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);

    // delete links row links
    const deleteFile = (index, id) => {
        let data = [...docs];
        if (id === null) {
            data.splice(index, 1);
            setDocs(data);
        } else {
            let obj = data.find((o, i) => {
                if (o.id === id) {
                    data[i] = { id: o.id, source: null };
                    return true; // stop searching
                }
            });
            setDocs(data)
        }
    };

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                if (e.dataTransfer.files[i].size < 5000000) {
                    const array = e.dataTransfer.files[i].name.split(".");
                    const result = array.pop();
                    setDocs(docs => [...docs, { id: null, source: e.dataTransfer.files[i], filename: e.dataTransfer.files[i].name, type_Document: result }])
                } else {
                    AlertUtility.errorAlert({ title: `File ` + e.dataTransfer.files[i].name + ` More Than 5 Mb.` })
                }
            }
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i].size < 5000000) {
                    const array = e.target.files[i].name.split(".");
                    const result = array.pop();
                    setDocs(docs => [...docs, { id: null, source: e.target.files[i], filename: e.target.files[i].name, type_Document: result }])
                } else {
                    AlertUtility.errorAlert({ title: `File ` + e.target.files[i].name + ` More Than 5 Mb.` })
                }
            }
        }
    };

    return (
        <>
            <ul className="text-gray-600 space-y-3 mt-3 -ml-3 mb-3">
                {docs && docs.length > 0 && docs.map((items, index) => (
                    <div key={index} className={`flex relative border w-72 border-gray-300 h-12 ${items.source === null ? 'hidden' : ""}`}>
                        <div
                            className="flex p-1 items-center space-x-3 cursor-pointer"
                        >
                            <img
                                src={` ${items.type_Document === "pdf"
                                    ? "/assets/global/img/pdfIco.svg"
                                    : ""
                                    } ${(items.type_Document === "pptx") || (items.type_Document === "ppt")
                                        ? "/assets/global/img/pptxIco.svg"
                                        : ""
                                    } ${(items.type_Document === "xlsx") || (items.type_Document === "xls")
                                        ? "/assets/global/img/xlsxIco.svg"
                                        : ""
                                    } ${(items.type_Document === "docx") || (items.type_Document === "doc")
                                        ? "/assets/global/img/docxIco.svg"
                                        : ""
                                    } ${(items.type_Document === "png") || (items.type_Document === "jpg") || (items.type_Document === "jpeg")
                                        ? "/assets/global/img/imageIco.png"
                                        : ""
                                    }`}
                                className="h-8 w-8"
                                alt="ico"
                            />
                            <div className="text-xs text-gray-500">
                                <h6 className="font-semibold">{items.filename.length > 45 ? items.filename.substring(0, 45) + "..." + items.type_Document : items.filename}</h6>
                            </div>
                        </div>
                        <img
                            className="right-1.5 top-1.5 absolute w-2 h-2 cursor-pointer icon_ideas_close_fileupload"
                            onClick={() => deleteFile(index, items.id)}
                            src="/assets/global/img/close.svg"
                            alt="close"
                        />
                    </div>
                ))}
            </ul>

            <form className='w-full h-44' id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} accept=".jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx" />
                <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                    <div className='text-[#58595B]'>
                        <img
                            src="/assets/global/img/upload.png"
                            className="justify-center self-stretch mx-auto"
                            alt="upload"
                        />
                        <button className="upload-button inline-flex font-semibold" onClick={onButtonClick}> Click to upload or drag and drop </button>
                        <p>Pdf, png, jpg, doc, docx, xls, xlsx, ppt, pptx (5 MB max file size)</p>
                    </div>
                </label>
                {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
            </form>
            <ul className="text-gray-600 space-y-3 mt-3">
            </ul>
        </>
    )
}

export default DragDropLC;
