import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import PersonalRepo from "../../../repositories/user-repo//PersonalRepo";
import IdeaDashboard from "./components/IdeaDashboard";
import PersonalIdeaList from "./components/PersonalIdeaList";
import RouteName from "../../../services/routename";
import { Link } from "react-router-dom";
import DropdownFilter from "../../../components/DropdownFilter";
import BtnSubmit from "../../../components/buttons/BtnSubmit";
import Skeleton from "../../../components/skeleton/Skeleton";
import GeneralUtility from "../../../utility/GeneralUtility";

const MyIdeas = () => {
  const [personalList, setPersonal] = useState([]);
  const [loading, setLoading] = useState([]);
  const [sort, setSort] = useState({ id: "2", name: "Recent" });

  const listFilter = [
    { id: "2", name: "Recent" },
    { id: "1", name: "oldest" },
  ]

  let filterTimeout;
  
  const getPersonalIdeas = async ({ search }) => {
    setLoading(true);
    const result = await PersonalRepo.getPersonalIdea({
      offset: 0,
      limit: 4,
      sort: sort.id,
      search: search,
    });
    let listPersonal = result !== null ? result.data : [];
    setPersonal(listPersonal);
    setLoading(false);
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    GeneralUtility.countViewPage("MyIdeas_Page");
    getPersonalIdeas({ search: "" });
  }, [sort]);

  return (
    <div className="container mx-auto">
      <div className="m-6 bx_myidea_top">
        <div className="tx_top_page_latest lg:hidden">
          My Ideas
        </div>
        <div className="mx-auto mt-8">
          <div className="mx-auto mt-8">
            <div className="flex justify-center bg-white w-full">
              <div className="w-full">
                <div className="grid grid-cols-2 justify-between gap-3">
                  <div className="relative w-28 lg:w-60">
                    <input
                      autoComplete="off"
                      type="text"
                      className="h-[35px] 2xl:h-[30px] border border-gray-300 text-gray-900 text-xs rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 p-2"
                      placeholder="What are you looking for? ..."
                      onChange={e => {
                        clearTimeout(filterTimeout);
                        filterTimeout = setTimeout(() => {
                          getPersonalIdeas({ search: e.target.value });
                        }, 500);
                      }}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="flex justify-self-end">
                    <div
                      name="sortby"
                      className="py-0 h-10 text-xs border-none "
                    >
                      <DropdownFilter title="SORT BY :" selected={sort} setSelected={setSort} resList={listFilter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-baseGray m-6 p-6 bx_myidea_personal">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 my-5 space-y-3 items-center">
          <div className="text-xl font-bold">Personal Ideas</div>
          <div className="lg:justify-self-end">
            <Link to={RouteName.createPersonal}>
              <button className="inline-flex text-freshGreen text-sm p-1 px-2 space-x-2 border border-1 border-freshGreen hover:text-white hover:bg-gradient-to-r from-green-600 to-freshGreen">
                <img
                  src="/assets/global/img/plus.png"
                  className="text-center mt-1"
                  alt=""
                />
                <p> CREATE NEW IDEAS</p>
              </button>
            </Link>
          </div>
        </div>

        {!loading ? (
          <PersonalIdeaList
            personalList={personalList}
            getPersonalIdeas={getPersonalIdeas}
          />
        ) : (
          <div className="mx-auto">
            <Skeleton col={4} height={230} />
          </div>
        )}

        <div className="text-center mt-4 py-9">
          <Link to={RouteName.personalIdeas}>
            <BtnSubmit title="view more" />
          </Link>
        </div>
      </div>

      <IdeaDashboard />
    </div>
  );
};

export default MyIdeas;
