import React from "react";
import Chart from "react-apexcharts";
import Column from "../../../components/chart/Column";
import Donut from "../../../components/chart/Donut";

const Challenges = (params) => {


  let tempName = [];
  let tempValue = [];
  let nameSet = new Set();
  
  if (params !== null && params.average !== null && params.average.top_view.length > 0) {
    params.average.top_view.forEach((res) => {
      if (!nameSet.has(res["name"])) {
        nameSet.add(res["name"]);
        tempName.push(res["name"]);
        tempValue.push(res["total_view"]);
      }
    });
  }


  const dataValue =
    params.statistic !== null
      ? [
          params?.statistic?.total_challange,
          params?.statistic?.open_challange,
          params?.statistic?.close_challange,
        ]
      : [0, 0, 0];

  const dataName = ["Total Challenges", "Open Challenges", "Closed Challenges"];

  //list category
  let tempNameMonth = [];
  let tempStatisticCurrent = [];
  let tempStatisticPrevious = [];
  let tempStatisticOther = [];
  let tempStatisticOther2 = [];
  let tempStatisticOther3 = [];
  let tempStatisticOther4 = [];
  let tempStatisticOther5 = [];
  if (params?.challangeType !== null) {
    params?.challangeType?.map((res, index) => {
      if (res?.category_name === "Experiment Enhanchment") {
        tempStatisticCurrent.push(res.total);
      } else if (res?.category_name === "Ideation Challenge") {
        tempStatisticPrevious.push(res.total);
      } else if (res?.category_name === "Mini Challenge") {
        tempStatisticOther.push(res.total);
      } else if (res?.category_name === "Product Development") {
        tempStatisticOther2.push(res.total);
      } else if (res?.category_name === "Process Improvement") {
        tempStatisticOther3.push(res.total);
      } else if (res?.category_name === "Hackathon") {
        tempStatisticOther4.push(res.total);
      } else if (res.category_name === "Business Challenge") {
        tempStatisticOther5.push(res.total);
      }
      tempNameMonth.push(res["month"]);
    });
  }

  const tempStatisticMonth = tempNameMonth.filter(
    (value, index, self) => index === self.findIndex((t) => t === value)
  );


  var options = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: true,
        offsetX: 28,
        offsetY: 6,
        foalting: false,
        width: 300,
        height: undefined,
        horizontalAlign: "left",
        itemMargin: {
          horizontal: 6,
          vertical: 2,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          display: false,
        },
      },
      colors: ["#66bf40", "#F44336", "#6649e4"],
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        type: "category",
        categories: dataName,
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        show: false,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        colors: ["#66bf40", "#F44336", "#6649e4"],
      },
    },
    series: [
      {
        name: ["Total Data"],
        data: dataValue,
      },
    ],
  };

  var optionRadial = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      plotOptions: {
        radialBar: {
          offsetX: 35,
          hollow: {
            size: "60%",
          },
          dataLabels: {
            value: {
              offsetY: -10,
              color: "black",
              fontSize: "15px",
            },
          },
        },
      },
      colors: ["#14a8e7"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          gradientToColors: ["#6e3fe3"],
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: [""],
    },
    series: params?.average !== null ? [params?.average?.precentage] : [],
  };
  return (
    <>
      {!params.onShow ? (
        <div className="container mx-auto pr-10" id={params.rootId}>
          <div className="ml-16 bg-white min-h-[360px] 2xl:w-[91%] xl:w-[97%] lg:w-[88%] md:w-[90%] xxs:w-[84%] mt-[-20px] h-auto pb-10 drop-shadow-lg shadow-xl flex-wrap border rgba(0,0,0,.2)">
            <h1 className="py-4 px-4 2xl:text-base xl:text-base lg:text-base md:text-[15px] sm:text-[14px] font-semibold">
              Top 6 Challenges
            </h1>
            <div className="2xl:flex 2xl:flex-row xl:flex xl:flex-row lg:flex lg:flex-row xxs:grid xxs:grid-cols-2 xxs:grid-flow-row xxs:gap-4 xxs:m-4 justify-start">
              {params.listTopUser.length > 0 ? (
                params?.listTopUser?.map((res, index) => {
                  return (
                    <>
                      <div
                        key={`challangeTopUser-${index}`}
                        className="flex flex-col m-4 w-[12%]"
                      >
                        <span className="font-Montserrat text-[12px] font-semibold h-10 w-[150px]">
                          {res.title.length > 35
                            ? res.title.substring(0, 35) + "..."
                            : res.title}
                        </span>
                        <span className="font-Montserrat text-[15px] flex flex-row my-2 h-10 w-56">
                          <img
                            src="/assets/global/img/idea icon.svg"
                            className="h-[35px] w-[35px] mx-2"
                            alt=""
                          />
                          <div className="flex flex-col">
                            <div>{res.user_submited}</div>
                            <div className="text-[10px]">Ideas Submitted</div>
                          </div>
                        </span>
                        <span className="font-Montserrat text-[15px] flex flex-row my-2 h-10">
                          <img
                            src="/assets/global/img/like icon.svg"
                            className="h-[35px] w-[35px] mx-2"
                            alt=""
                          />
                          <div className="flex flex-col">
                            <div>{res.user_like}</div>
                            <div className="text-[10px]">User Likes</div>
                          </div>
                        </span>
                        <span className="font-Montserrat text-[15px] flex flex-row my-2 h-10 w-56">
                          <img
                            src="/assets/global/img/chat.png"
                            className="h-[30px] w-[30px] mx-3"
                            alt=""
                          />
                          <div className="flex flex-col">
                            <div>{res.user_comment}</div>
                            <div className="text-[10px]">User Comments</div>
                          </div>
                        </span>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="text-left xl:text-center w-full mt-5 xl:mt-10">No Data Available</div>
              )}
            </div>
          </div>
          <div className="flex 2xl:flex-row ml-8 xl:flex-row w-full lg:flex-col md:flex-col sm:flex-col xxs:flex-col xl:justify-between 2xl:justify-start">
            <div className="ml-8 mt-8 pb-4 flex flex-col bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="h-[340px] pb-10 2xl:w-[450px] xl:w-[450px] lg:w-[450px] md:w-[550px] sm:w-[500px]">
                <div className="flex text-sm mt-5 ml-5 font-semibold">
                Challenges Status
                </div>
                <div className="mixed-chart">
                  {params.statistic !== null ? (
                    <Chart
                      options={options.options}
                      series={options.series}
                      type="bar"
                      width="450"
                      height="250"
                    />
                  ) : (
                    <div className="text-center pt-24">No Data Available</div>
                  )}
                </div>
              </div>
            </div>
            <div className="h-auto 2xl:w-[600px] 2xl:ml-24 ml-10 mt-8 xl:w-[600px] lg:w-[600px] md:w-[650px] sm:w-[600px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="flex text-sm mt-5 ml-5 font-semibold">
              Percentage Challenge Views Per Challenge</div>
              <div className="flex flex-row ml-20">
                <div className="mixed-chat">
                  {params?.average !== null ? (
                  <Donut
                    height="300"
                    width="300"
                    labels={tempName}
                    value={tempValue}
                  />
                  ) : (
                    <div className="text-center pt-24 w-[300px]">
                      No Data Available
                    </div>
                  )}
                </div>
                {/* <div className="flex flex-col">
                  <span className="font-Montserrat text-xs mt-12">
                    Avg. Unique Views
                  </span>
                  <span className="font-Montserrat font-semibold text-sm">
                    Per Challenge
                  </span>
                </div> */}
              </div>
              {/* {params?.average?.top_view?.map((res, index) => {
                return (
                  <div
                    key={`challangeTopView-${index}`}
                    className="font-Montserrat text-sm ml-4 my-2 mr-4 flex flex-row justify-between"
                  >
                    <div className="text-sm w-[70%]">{res.name}</div>
                    <div className="text-sm">{`${res.total_view} ${
                      res.total_view > 1 ? "Views" : "View"
                    }`}</div>
                  </div>
                );
              })} */}
            </div>
          </div>

          <br />
          <br />
          <div
            className={`flex flex-row md:flex-col lg:flex-col xl:flex-col md:mt-[-60px] xxs:ml-16 md:ml-14 xxs:mt-[-40px] 2xl:mt-[-40px] xl:mt-[-40px] lg:mt-[-40px] lg:ml-16 xl:ml-0`}
          >
            <div className="h-[320px] 2xl:w-[91%] xl:w-[97%] lg:w-[88%] md:w-[90%] xxs:w-[84%]] overflow-x-auto [&::-webkit-scrollbar]:hidden my-8 md:ml-2 lg:ml-0 xl:ml-16 md:mr-40 2xl:ml-16 bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="flex text-sm mt-5 ml-5 font-semibold">
                Challenge Types
              </div>
              <div className="mixed-chart">
                {tempStatisticCurrent?.length > 0 ||
                tempStatisticPrevious.length > 0 ? (
                  <Column
                    height="280"
                    width="1040"
                    labels={tempStatisticMonth}
                    current={tempStatisticCurrent}
                    previous={tempStatisticPrevious}
                    other={tempStatisticOther}
                    other2={tempStatisticOther2}
                    other3={tempStatisticOther3}
                    legend={"bottom"}
                    from={"Challanges"}
                    other4={tempStatisticOther4}
                    other5={tempStatisticOther5}
                    name1={"Experiment Enhancement"}
                    name2={"Ideation Challenge"}
                    name3={"Mini Challenge"}
                    // name4={"New Product Development"}
                    // name5={"Process Improvement"}
                    // name6={"Hackathon"}
                    // name7={"Business Challenge"}
                  />
                ) : (
                  <div className="h-[250px] w-auto flex justify-center pt-24">
                    No Data Available
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mx-auto w-[1280px]" id={params.rootId}>
          <div className="ml-10 bg-white mt-[-20px] h-auto pb-4 w-[1100px] drop-shadow-lg shadow-xl flex-wrap border ">
            <h1 className="py-4 px-4 text-base font-semibold">
              Top 6 Challenges
            </h1>
            <span className="px-4 hidden">
              Total percentage of user engagement with the most popular
              challanges.
            </span>
            <div className="flex flex-row justify-start">
              {params?.listTopUser?.map((res, index) => {
                return (
                  <>
                    <div
                      key={`challangeTopUser-${index}`}
                      className="flex flex-col m-4 w-[12%]"
                    >
                      <span className="font-Montserrat text-[12px] font-semibold h-10 mb-3">
                        {res.title.length > 35
                          ? res.title.substring(0, 35) + "..."
                          : res.title}
                      </span>
                      <span className="font-Montserrat text-[15px] flex flex-row my-2 h-10 w-56">
                        <img
                          src="/assets/global/img/idea icon.svg"
                          className="h-[35px] w-[35px] mx-2"
                          alt="icon"
                        />
                        <div className="flex flex-col">
                          <div>{res.user_submited}</div>
                          <div className="text-[10px]">Ideas Submitted</div>
                        </div>
                      </span>
                      <span className="font-Montserrat text-[15px] flex flex-row my-2 h-10">
                        <img
                          src="/assets/global/img/like icon.svg"
                          className="h-[35px] w-[35px] mx-2"
                          alt="like"
                        />
                        <div className="flex flex-col">
                          <div>{res.user_like}</div>
                          <div className="text-[10px]">User Likes</div>
                        </div>
                      </span>
                      <span className="font-Montserrat text-[15px] flex flex-row my-2 h-10 w-56">
                        <img
                          src="/assets/global/img/chat.png"
                          className="h-[30px] w-[30px] mx-3"
                          alt="chat"
                        />
                        <div className="flex flex-col">
                          <div>{res.user_comment}</div>
                          <div className="text-[10px]">User Comments</div>
                        </div>
                      </span>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="flex flex-row ml-10 w-[1100px] justify-start space-x-3">
            <div className="mt-4 pb-4 flex flex-col bg-white drop-shadow-lg border">
              <div className="h-[340px] pb-10 w-[440px]">
                <div className="flex text-sm mt-5 ml-5 font-semibold">
                Challenges Status
                </div>
                <div className="mixed-chart">
                  {params.statistic !== null ? (
                    <Chart
                      options={options.options}
                      series={options.series}
                      type="bar"
                      width="450"
                      height="250"
                    />
                  ) : (
                    <div className="text-center pt-24">No Data Available</div>
                  )}
                </div>
              </div>
            </div>
            <div className="h-auto w-[600px] mt-4 bg-white drop-shadow-lg border ">
              <div className="flex text-sm mt-5 ml-5 font-semibold">
               Precentage Views Per Challenge
              </div>
              <div className="flex flex-row ml-20">
                {params?.average !== null ? (
                  <Chart
                    options={optionRadial.options}
                    series={optionRadial.series}
                    type="radialBar"
                    height="200"
                    width={"300"}
                  />
                ) : (
                  <div className="text-center pt-24 w-[300px]">
                    No Data Available
                  </div>
                )}
                <div className="flex flex-col">
                  <span className="font-Montserrat text-xs mt-12">
                    Avg. Unique Views
                  </span>
                  <span className="font-Montserrat font-semibold text-sm">
                    Per Challenge
                  </span>
                </div>
              </div>
              {params?.average?.top_view?.map((res, index) => {
                return (
                  <div
                    key={`challangeTopView-${index}`}
                    className="font-Montserrat text-sm ml-4 my-2 mr-4 flex flex-row justify-between"
                  >
                    <div className="text-sm w-[70%]">{res.name}</div>
                    <div className="text-sm">{`${res.total_view} ${
                      res.total_view > 1 ? "Views" : "View"
                    }`}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <br />
          <br />
          <div className={`flex flex-row mt-[5px] ml-10 w-[1100px]`}>
            <div className="h-[300px] w-[1100px] my-8 ml-8 bg-white drop-shadow-lg border ">
              <div className="flex text-sm mt-5 ml-5 font-semibold">
                Challenge Types
              </div>
              <div className="mixed-chart">
                {tempStatisticCurrent?.length > 0 ||
                tempStatisticPrevious.length > 0 ? (
                  <Column
                    height="280"
                    width="1040"
                    labels={tempStatisticMonth}
                    current={tempStatisticCurrent}
                    previous={tempStatisticPrevious}
                    other={tempStatisticOther}
                    other2={tempStatisticOther2}
                    other3={tempStatisticOther3}
                    legend={"bottom"}
                    from={"Challanges"}
                    other4={tempStatisticOther4}
                    other5={tempStatisticOther5}
                    name1={"Experiment Enhancement"}
                    name2={"Ideation Challenge"}
                    name3={"Mini Challenge"}
                    // name4={"New Product Development"}
                    // name5={"Process Improvement"}
                    // name6={"Hackathon"}
                    // name7={"Business Challenge"}
                  />
                ) : (
                  <div className="h-[250px] w-auto flex justify-center pt-24">
                    No Data Available
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Challenges;
