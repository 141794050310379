import React from 'react'
import RouteName from '../../../../../services/routename';
import { Link } from 'react-router-dom';
import RightIco from '../../../../../components/icons/RightIco';

const Breadcrumbs = ({ challenge, id }) => {
  return (
    <div className="m-6">
      <nav className="flex mb-3 text-[10px]" aria-label="Breadcrumb">
        <div className="inline-flex items-center space-x-1 md:space-x-3">
          <div className="inline-flex items-center">
            <Link
              to={RouteName.userHome}
              className="inline-flex items-center font-medium text-gray-500 hover:text-gray-900"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
            </Link>
          </div>
          <div>
            <div className="flex items-center">
              <RightIco />
              <Link
                to={RouteName.userLatestChallenges}
                className="ml-1  font-medium text-gray-500 hover:text-gray-900 md:ml-2"
              >
                Latest Challenges
              </Link>
            </div>
          </div>
          <div aria-current="page">
            <Link to={`${RouteName.userLatestChallenges}/${id}`}>
              <div className="flex items-center" title={challenge}>
                <RightIco />
                <span className="ml-1 font-medium text-gray-500 md:ml-2">
                  {challenge ? (challenge.length > 25 ? challenge.substring(0, 25) + "..." : challenge) : challenge}
                </span>
              </div>
            </Link>
          </div>
          <div aria-current="page">
            <div className="flex items-center">
              <RightIco />
              <span className="ml-1  font-medium text-gray-700 md:ml-2">
                Submit Idea
              </span>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Breadcrumbs
