import ArrowIco from "../../../../../components/icons/ArrowIco";
import ButtonGroupEdit from "../Button/ButtonGroupEdit";
import React from "react";
import InputText from "../../../../../components/input/InputText";
import { useEffect, useState, useRef } from "react";
const InsightCardSmall = ({
  items,
  showModePreview,
  index,
  setPrevData,
  updateChangeValue,
  onChange,
  handleDeleteCard,
  setEditStatus,
  editStatus,
}) => {
  const refTitle = React.createRef();
  const refContent = React.createRef();

  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = () => {
    const addStatus = [...editStatus];
    addStatus.push(1);
    setEditStatus(addStatus);
    setIsEdit(true);
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
    const addStatus = [...editStatus];
    addStatus.pop(1);
    setEditStatus(addStatus);
  };

  const handleOnChangeTitle = (event) => {
    updateChangeValue(index, "title", event.target.value);
  };

  const handleOnChangeContent = (event) => {
    updateChangeValue(index, "content", event.target.value);
  };

  const handleDelete = () => {
    handleDeleteCard(index);
  };
  return (
    <div className="relative">
      {showModePreview ? (
        ""
      ) : (
        <div className="flex z-[6] absolute -top-3 left-6">
          <ButtonGroupEdit
            onClickDelete={handleDelete}
            onClickEdit={isEdit ? handleCancelEdit : handleEdit}
            isEdit={isEdit}
          />
        </div>
      )}

      <div
        key={index}
        className={`flex  md:flex-row lg:h-[210px] items-center bg-white border shadow-md hover:bg-gray-100 space-y-5 lg:space-y-4 bx_home_list_insight ${
          index === 2 ? "mt-5" : ""
        }`}
      >
        <div className="lg:basis-7/12 md:basis-7/12">
          <iframe
            width="100%"
            height="210"
            src={items.source}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
          ></iframe>

          {/* <img src={items.source} className="w-full lg:h-[210px] block" />
                    <a href={items.link} target="_blank" rel="noreferrer" title={items.link}>
                      <img src="assets/global/img/ico_play.svg" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-7 w-7" alt="playbutton" />
                    </a> */}
        </div>
        <div className="flex flex-col justify-between leading-normal px-[25px] basis-6/12 w-5">
          {isEdit ? (
            <input
              title={items.title}
              className="mb-2 text-sm font-bold tracking-tight text-gray-900  ht_list_insight overflowY break-words"
              onChange={handleOnChangeTitle}
              onBlur={handleOnChangeTitle}
              defaultValue={items.title}
              value={items.title}
              maxLength={25}
            ></input>
          ) : (
            <h5
              title={items.title}
              className="mb-2 text-sm font-bold tracking-tight text-gray-900  ht_list_insight overflowY break-words"
              id={"titleInsightSm_" + index}
            >
              {items.title.length > 25
                ? items.title.substring(0, 25) + "..."
                : items.title}
            </h5>
          )}

          {isEdit ? (
            <textarea
              className="mb-2 text-xs text-gray-700 w-50 h-20 overflowY max-w-full"
              onChange={handleOnChangeContent}
              onBlur={handleOnChangeContent}
              value={items.content}
              maxLength={100}
            >
              {items.content}
            </textarea>
          ) : (
            <div
              className="mb-2 text-xs text-gray-700 w-50 h-20  break-words max-w-full"
              id={"contentInsightSm_" + index}
            >
              {items.content.length > 100
                ? items.content.substring(0, 100) + "..."
                : items.content}
            </div>
          )}
        </div>
      </div>
      {isEdit ? (
        <div className="w-full flex flex-row">
          <div className="bg-heritageGreen text-xs pl-1 pr-1 text-white ">
            URL Youtube
          </div>
          <div className="text-sm flex items-center bg-[#fff] text-gray-500 shadow-sm">
            https://www.youtube.com/embed/
          </div>
          <InputText
            placeholder="URL YOUTUBE"
            defaultValue={items.source.split("/embed/").pop()}
            onChange={(event) => onChange(index, event)}
            value={items.source.split("/embed/").pop()}
            names="source"
          />
        </div>
      ) : null}
    </div>
  );
};

export default InsightCardSmall;
