import React from "react";
import Chart from "react-apexcharts";

const ChartInternalExternal = ({
  categories,
  colors,
  data,
  width,
  height,
  optWidth,
  optLegend,
}) => {
  
  let options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    legend: {
      show: optLegend,
      horizontalAlign: "left",
      offsetX: -10,
      width: optWidth,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        display: false,
      },
    },
    colors: colors,
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      type: "category",
      categories: categories,
    },
    yaxis: {
      show: false,
      labels: {
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      colors: colors,
    },
    tooltip: {
      enabled: false, // Disabling tooltips
    },
  };

  const legendMessages = {
    "Internal Users": "User registered using an Old Mutual Domain account.",
    "External Users": "Users that do not use an Old Mutual Domain account.",
    "Active Users": "Users who are currently active.",
  };

  return (
    <>
      <div className="mixed-chart">
        <Chart
          options={options}
          series={[{ name: "", data: data }]}
          type="bar"
          width={width}
          height={height}
        />
      </div>
      {/* {!optLegend ? (
        <div className="grid grid-flow-row gap-2 mt-[-15px] p-3">
          {colors.map((res, index) => {
            const label = categories[index];
            const message = legendMessages[label] || "";
            return (
              <div
                className="text-[12px] flex flex-row capitalize justify-between items-center"
                key={res}
              >
                <div className="flex flex-row justify-center align-middle items-center">
                  <div className={`bg-[${res}] h-3 w-3 mr-3 ml-2`} />
                  {label} ({message})
                </div>
                <div className="ml-1">{data[index]}dsa</div>
              </div>
            );
          })}
        </div>
      ) : null} */}
    </>
  );
};

export default ChartInternalExternal;
