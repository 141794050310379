import React from "react";
import RouteName from "../../../../services/routename";
import ChallengeRepo from "../../../../repositories/user-repo/ChallengeRepo";
import Card from "../../../../components/card/Card";
import {useLocation} from 'react-router-dom'

const Challenges = ({ challenges }) => {

  let location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const urlpage = parseInt(queryParams.get('page')) || 0; 
  const urlType = queryParams.get('type') || "all"; 
  const urlStatus  = queryParams.get('status') || "all"; 
  const urlSearch  = queryParams.get('search') || ""; 

  const handleCountView = async id => {
    const result = await ChallengeRepo.postCountView({ challange: { id: id } });
  }

  return (
    <div>
      {challenges.length === 0 ? (
        <div className="text-center h-screen">
          No data available
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {challenges.map((items) => (
            <div key={items.id}>
              <Card
                linkTo={RouteName.userLatestChallenges + "/" + encodeURIComponent(items.slug) + "?" + "type="+ urlType + "&status="+ urlStatus + "&page=" + urlpage + "&search=" + urlSearch}
                id={items.id}
                card_image={items.card_image}
                status={items.status}
                title={items.title}
                desc={items.desc}
                total_contributor={items.total_contributor}
                total_submited={items.total_submited}
                total_view={items.total_view}
                onClick={e => handleCountView(items.id)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Challenges;
