import React, { useEffect, useState } from "react";
import GeneralUtility from "../../../../utility/GeneralUtility";

const RewardEditChallenge = ({ items, index, onEditReward, icon }) => {
  const [rewardInfo, setRewardInfo] = useState({
    title: items.title,
    reward: +items.reward,
    description: items.description,
  });

  const onChange = (key, value) => {
    setRewardInfo((prevState) => {
      return {
        ...prevState,
        [key]: key === "reward" ? GeneralUtility.removeNonNumeric(value) : value,
      };
    });

    onEditReward(index, {
      ...rewardInfo,
      [key]: key === "reward" ? GeneralUtility.removeNonNumeric(value) : value,
    });
  };
  
  return (
    <div
      className="flex justify-center bg-white w-full relative"
      key={items.id}
    >
      <div className="shadow-md w-full">
        <div
          className={`${index === 0
            ? "bg-gradient-to-r from-orange-600 to-pink-600 bx_stage_1"
            : ""
            }
        ${index === 1
              ? "bg-gradient-to-r from-futureGreen to-heritageGreen bx_stage_2"
              : ""
            }
        ${index === 2
              ? "bg-gradient-to-r from-yellow-200 to-blueSky bx_stage_3"
              : ""
            }
        ${index === 3
              ? "bg-gradient-to-r from-orange-200 to-yellow-200 bx_stage_4"
              : ""
            } h-24 w-full flex items-center bg-cover`}
        >
          <div
            className="bg-cover h-full w-full flex items-center justify-between"
            style={{
              backgroundImage: `url("/assets/global/img/Pattern2.png")`,
            }}
          >
            <div className="h-full w-full flex items-center justify-between p-3">
              <div className="flex items-start ">
                <div className="w-6 h-6 ">
                  <img
                    src={`${index === 0 ? "/assets/global/img/lightbulb.svg" : ""
                      }
                  ${index === 1 ? "/assets/global/img/check.png" : ""}
                  ${index === 2 ? "/assets/global/img/science-white.png" : ""}
                  ${index === 3 ? "/assets/global/img/diamond.png" : ""}`}
                  />
                </div>
                <input
                  autoComplete="off"
                  className="bg-transparent font-semibold p-0 ml-2 mr-7 text-white text-xl w-full border-t-transparent  border-l-transparent border-r-transparent focus:fill-none border-b-2 border-b-white"
                  type="text"
                  name="title"
                  value={rewardInfo.title}
                  onChange={(e) => onChange("title", e.target.value)}
                />
              </div>
              <div className="text-white mr-4">
                <div className="flex items-center">
                  <p className="text-md font-semibold">R</p>
                  <input
                    autoComplete="off"
                    className="bg-transparent p-0 w-20 text-white text-md border-none focus:fill-none"
                    type="text"
                    name="reward"
                    value={GeneralUtility.addCommas(rewardInfo.reward)}
                    onChange={(e) => onChange("reward", e.target.value)}
                  />
                </div>
                <div className="text-sm border-t-2">REWARD</div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <textarea
            className="w-full h-20 border-none shadow-md text-[10px] text-center"
            type="text"
            name="desc"
            value={rewardInfo.description}
            onChange={(e) => onChange("description", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default RewardEditChallenge;
