import CallApi from "../../services/request-helper";

const API_COUNT = process.env.REACT_APP_COUNT_VIEWPAGE;

const countView = async (typePage) => {
  const url = API_COUNT;
  let data = { typePage: typePage };
  const response = await CallApi({ url, method: "POST", data });
  const res = response !== undefined ? response.data : null;
  return res;
  
};

const CountPageRepo = {
  countView
};

export default CountPageRepo;
