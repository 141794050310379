import React, { useEffect, useState } from "react";

const TitleReward = ({ refsTitle, refsSubTitle, titleValue, subTitleValue,blurheadTitle,blurSubTitle}) => {
  return (
    <>
      <div>
        <h2
          className="text-xl text-center pt-3 font-semibold ht_home_title"
          id="hometitle"
          suppressContentEditableWarning={true}
          ref={refsTitle}
          onBlur={blurheadTitle}
        >
          {titleValue}
        </h2>
        <p
          className="text-sm text-center pt-3 ht_home_title subtitlehead"
          id="subtitle"
          suppressContentEditableWarning={true}
          ref={refsSubTitle}
          onBlur={blurSubTitle}
        >
          {subTitleValue}
        </p>
      </div>
    </>
  );
};
export default TitleReward;
