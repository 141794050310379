import CallApi from "../../services/request-helper";

const API_USER = process.env.REACT_APP_USER;

/**
 * get user 
 * @returns 
 */
const getUsers = async () => {
    const url = API_USER;
    const response = await CallApi({ url, method: "GET" });
    return response;
}

const UsersRepo = {
    getUsers,
}

export default UsersRepo