import CallApi from "../../services/request-helper";

const API_ADDCOMMENT = process.env.REACT_APP_ADDCOMMENT;
const API_LISTCOMMENT = process.env.REACT_APP_LISTCOMMENT;
const API_LIKECOMMENT = process.env.REACT_APP_LIKECOMMENT;
const API_CHECKLIKE = process.env.REACT_APP_CHECKCOMMENT;

/**
 * get comments
 * @param {*} data 
 * @returns 
 */
const getComment = async ({ offset, limit, id }) => {
  const data = {};
  data.offset = offset ? offset : 0;
  data.limit = limit ? limit : 5;
  data.id = id;
  const url = API_LISTCOMMENT;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

/**
 * post create comment
 * @param {*} data 
 * @returns 
 */
const postComment = async (dat) => {
  let data = new FormData();
  data.append("message", dat.message);
  data.append("submited_id", dat.submited_id.id)
  if (dat.documents !== []) {
    dat.documents.forEach((value, index) => {
      data.append(`documents[${index}].source`, value);
    })
  }
  const url = API_ADDCOMMENT;
  const response = await CallApi({ url, method: "POST", data, type: "file" });

  return response;
};

/**
 * like check comment
 * @param {*} data 
 * @returns 
 */
const likeComment = async (data) => {
  const url = API_LIKECOMMENT;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

/**
 * like check comment
 * @param {*} data 
 * @returns 
 */
const checkLike = async (data) => {
  const url = API_CHECKLIKE;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const CommentRepo = {
  getComment,
  postComment,
  likeComment,
  checkLike
}

export default CommentRepo;