import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import ChallengeAdmRepo from "../../../../repositories/adm-repo/ChallengeAdmRepo";
import RouteAdm from "../../../../services/routeAdm";

const COLORS = [
  "bg-green-200 uppercase w-full border-l-4 border-l-green-600 py-1 px-2 mb-3",
  "bg-orange-200 uppercase w-full border-l-4 border-l-orange-600 py-1 px-2 mb-3 ",
  "bg-pink-200 uppercase w-full border-l-4 border-l-pink-600 py-1 px-2 mb-3 ",
  "bg-sky-200 uppercase w-full border-l-4 border-l-sky-600 py-1 px-2 mb-3 ",
  "bg-green-200 uppercase w-full border-l-4 border-l-green-600 py-1 px-2 mb-3",
];

const Calendar = () => {
  const navigate = useNavigate();
  const [listChallenge, setListChallenge] = useState([]);

  // const detailChallenge = (e) => {
  //   // countView();
  //   navigate(RouteAdm.detailViewIdeas + "/" + e);
  // };

  function getRandomColor() {
    return COLORS[Math.floor(Math.random() * COLORS.length)];
  }

  const getChallengesAdm = async ({
    status,
    search = "",
    year,
    typeChallenges,
    calendar,
  }) => {
    // setLoading(true);
    const result = await ChallengeAdmRepo.getChallengesAdm({
      offset: 0,
      limit: 12,
      status,
      year: year,
      calendar: calendar,
    });
    let resultList = result.data.data.data;
    const data = [];
    if (resultList.length > 0) {
      resultList.map((value) => {
        data.push({
          id: value.id,
          title: value.title,
          start: dayjs(value.start_date).format("YYYY-MM-DD"),
          end: dayjs(value.end_date).format("YYYY-MM-DD"),
          // backgroundColor : getRandomColor()
          // backgroundColor : "#00C0E8",
          backgroundColor: "#76828e",
        });
        return null;
      });
    }
    // let listPage = result.data.data;
    setListChallenge(data);
    // setPage(listPage);
    // setLoading(false);
  };

  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    getChallengesAdm({
      search: "",
      year: new Date().getFullYear(),
      calendar: dayjs().format("YYYY-MM-DD"),
      typeChallenges: "",
    });
  }, []);

  return (
    <div className="container m-6">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        dayMaxEvents={true}
        events={isDesktop ? listChallenge : []}
        dateClick={(info) => {
          getChallengesAdm({
            search: "",
            year: new Date().getFullYear(),
            calendar: info.dateStr,
            typeChallenges: "",
          });
        }}
        views={{
          timeGrid: {
            dayMaxEventRows: 6, // adjust to 6 only for timeGridWeek/timeGridDay
          },
        }}
        eventClick={(info) => {
          navigate(
            RouteAdm.detailChallengesAdm + "/" + info.event._def.publicId
          );
        }}
      />
      <div className="lg:hidden md:hidden mt-5">
        {listChallenge.length > 0
          ? listChallenge.map((challenge, index) => (
              <div className="py-1 px-2">
                <div className={getRandomColor()}>
                  <div className="text-xs text-black">
                    <div className="text-sm font-bold text-gray-700">
                      {challenge.title}
                    </div>
                    <div className="mb-1">
                      <div className="text-sm text-gray-700">
                        {dayjs(challenge.start).format("MMMM DD, YYYY")} -{" "}
                        {dayjs(challenge.end).format("MMMM DD, YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : "Empty"}
      </div>
    </div>
  );
};

export default Calendar;
