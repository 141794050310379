import React, { useState } from "react";
import { Modal } from "flowbite-react";
import ModalSendIdeas from "./ModalSendIdeas";
import CardIdeasRepo from "../../../../../repositories/adm-repo/CardIdeasRepo";
import { useEffect } from "react";
import Swal from "sweetalert2";
import AlertUtility from "../../../../../utility/alert-utility";
import DetailIdeasRepo from "../../../../../repositories/adm-repo/DetailIdeasRepo";

const ModalViewIdeas = ({ onChange, show, setShow, formValue, idIdeas }) => {
  const [showSend, setShowSend] = useState(false);
  const [message, setMessage] = useState("");
  /**
   * Kirim data idea
   */
  const onSubmitIdeas = async () => {
    AlertUtility.loadingAlert();
    const result = await CardIdeasRepo.updateProgressIdeas({
      id: idIdeas,
      evaluation_id: formValue.ideaType,
      messageOptional: formValue.message,
    });

    if (result.status === 200) {
      Swal.close();
      setShow(false)
      setShowSend(true);
    } else if (result.status === 400) {
      Swal.close();
      setShow(false)
      setShowSend(true);
    }
  };

  const getMessageStageIdea = async (stage) => {
    if (stage === "Generation") {
      setMessage("");
    } else {
      const result = await DetailIdeasRepo.getMessageStage(stage);
      let messageStage = result.data.data === null ? "" : result.data.data.message;
      setMessage(messageStage);
    }
  }

  useEffect(() => {
    if (show && formValue?.ideaName) {
      getMessageStageIdea(formValue?.ideaName);
    }
  }, [show]);

  return (
    <>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,700&display=swap" rel="stylesheet" />
      <div>
        <Modal className="bx_modalpop_global" show={show} popup={true} size="2xl" onClose={() => setShow(!show)}>
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-4">
              <h3 className="font-semibold text-[32px] font-Montserrat">Progress Idea</h3>
              <div id="desc" className="text-sm font-semibold font-Montserrat">
                <div className="space-paragraph" dangerouslySetInnerHTML={{ __html: message }} />
              </div>
              <div id="desc" className="text-sm font-Montserrat">
                Fill in the message field below if you wish to add a comment or
                note to the progress notification.
              </div>
              <div className="space-y-2">
                <div id="desc" className="text-sm font-Montserrat font-semibold">
                  MESSAGE (optional)
                </div>
                <textarea
                  className="w-full"
                  onChange={(e) => onChange("message", e.target.value)}
                >
                </textarea>
              </div>
              <div className="text-left mt-4 py-2">
                <button
                  onClick={async () => await onSubmitIdeas()}
                  className="text-sm font-Montserrat border border-1 border-green-400 text-center py-2 px-3 inline-flex items-center bg-gradient-to-r from-green-600 to-freshGreen text-white"
                >
                  SEND
                </button>
                <ModalSendIdeas
                  showSend={showSend}
                  setShowSend={setShowSend}
                  setShow={setShow}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalViewIdeas;
