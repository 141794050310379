import CallApi from "../../services/request-helper";
import GeneralUtility from "../../utility/GeneralUtility";

// create new challenge with form data
const API_NEW_CHALLENGE = process.env.REACT_APP_UPLOADCHALLENGE;
const API_DETAIL_CHALLENGE_DRAFTED = process.env.REACT_APP_CHALLENGE_DETAIL_DRAFTED;
const API_CHALLENGE_DRAFTED = process.env.REACT_APP_CHALLENGE_DRAFTED;

/**
 * get challenge category 
 * @returns 
 */
const postChallenge = async (dat) => {
    let data = new FormData();
    if (dat.id) {
        data.append('id', dat.id)
    }
    if (dat.documents) {
        if (dat.documents !== []) {
            //filter documents, new documents
            const docUp = dat.documents.filter(object => {
                return object.id === null;
            });

            //filter deleted documents
            const docDel = dat.documents.filter(object => {
                // return object.baseUrl === null;
                return object.source === null;
            });

            docUp.forEach((value, index) => {
                // data.append(`documents[${index}].source`, value.baseUrl);
                data.append(`documents[${index}].source`, value.source);
            });

            docDel.forEach((value, index) => {
                data.append(`remove_file[${index}]`, value.id);
            });

        }
    }
    if (dat.managerchallange) {
        dat.managerchallange.forEach((value, index) => {
            data.append(`managerchallange[${index}].user`, value.id);
        });
    }
    if (dat.questions) {
        dat.questions.forEach((value, index) => {
            data.append(`questions[${index}].question`, value.question);
            data.append(`questions[${index}].index`, index+1);
        });
    }
    if (dat.rewards) {
        dat.rewards.forEach((value, index) => {
            data.append(`rewards[${index}].title`, value.title);
            data.append(`rewards[${index}].reward`, GeneralUtility.decimalToInt(value.reward));
            data.append(`rewards[${index}].description`, value.description);
            data.append(`rewards[${index}].index`, value.index);
        });
    }

    if (dat.links !== []) {
        let link = 0;
        dat.links.forEach((value) => {
            if (value.resourceName !== "" && value.source !== "") {
                data.append(`links[${link}].resourceName`, value.resourceName);
                data.append(`links[${link}].source`, value.source);
                link = link + 1;
            }
        });
    }
    if (dat.evlTitle) {
        data.append('evaluators.title', dat.evlTitle)
    }
    if (dat.evlMsg) {
        data.append('evaluators.message', dat.evlMsg)
    }

    if (dat.statusprivacy) {
        data.append('statusprivacy.id', dat.statusprivacy.id)
    }
    if (dat.typeidea) {
        data.append('typeidea.id', dat.typeidea.id)
    }
    if (dat.category) {
        data.append('category.id', dat.category.id)
    }
    if (dat.start_date) {
        data.append('launch_date', dat.start_date)
    }
    if (dat.banner_image) {
        if (dat.banner_image.name) {
            data.append('banner_image', dat.banner_image)
        }
    }
    if (dat.card_image) {
        if (dat.card_image.name) {
            data.append('card_image', dat.card_image)
        }
    }
    data.append('overview', dat.overview)
    data.append('start_date', dat.start_date)
    data.append('end_date', dat.end_date)
    data.append('evaluation_day', dat.evaluation_day)
    // data.append('incentive', dat.incentive)
    data.append('title', dat.title)
    data.append('drafted', dat.drafted)
    const url = API_NEW_CHALLENGE;
    const response = await CallApi({ url, method: "POST", data, type: "file" });
    return response;
}

/**
 * function to post as draft challenge
 * @param {*} dat 
 * @returns 
 */
const postDraftedChallenge = async (dat) => {
    let data = new FormData();
    if (dat.id) {
        data.append('id', dat.id)
    }
    if (dat.documents) {
        if (dat.documents !== []) {

            //filter documents, new documents
            const docUp = dat.documents.filter(object => {
                return object.id === null;
            });

            //filter deleted documents
            const docDel = dat.documents.filter(object => {
                return object.source === null;
                // return object.baseUrl === null;
            });

            docUp.forEach((value, index) => {
                data.append(`documents[${index}].source`, value.source);
                // data.append(`documents[${index}].source`, value.baseUrl);
            });

            docDel.forEach((value, index) => {
                data.append(`remove_file[${index}]`, value.id);
            });

        }
    }
    if (dat.managerchallange) {
        dat.managerchallange.forEach((value, index) => {
            data.append(`managerchallange[${index}].user`, value.id);
        });
    }
    if (dat.questions) {
        dat.questions.forEach((value, index) => {
            data.append(`questions[${index}].question`, value.question);
            data.append(`questions[${index}].index`, index+1);
        });
    }
    if (dat.rewards) {
        dat.rewards.forEach((value, index) => {
            data.append(`rewards[${index}].title`, value.title);
            data.append(`rewards[${index}].reward`, GeneralUtility.decimalToInt(value.reward));
            data.append(`rewards[${index}].description`, value.description);
            data.append(`rewards[${index}].index`, value.index);
        });
    }
    if (dat.links) {
        if (dat.links !== []) {
            let link = 0;
            dat.links.forEach((value) => {
                if (value.resourceName !== "" && value.source !== "") {
                    data.append(`links[${link}].resourceName`, value.resourceName);
                    data.append(`links[${link}].source`, value.source);
                    link = link + 1;
                }
            });
        }
    }
    if (dat.statusprivacy) {
        data.append('statusprivacy.id', dat.statusprivacy.id)
    }
    if (dat.typeidea) {
        if (dat.typeidea.id !== null) {
            data.append('typeidea.id', dat.typeidea.id)
        }
    }
    if (dat.category) {
        if (dat.category.id) {
            data.append('category.id', dat.category.id)
        }
    }
    if (dat.start_date) {
        data.append('launch_date', dat.start_date)
    }
    if (dat.banner_image) {
        if (dat.banner_image.name) {
            data.append('banner_image', dat.banner_image)
        }
    }
    if (dat.card_image) {
        if (dat.card_image.name) {
            data.append('card_image', dat.card_image)
        }
    }
    if (dat.overview) {
        data.append('overview', dat.overview)
    }
    if (dat.start_date) {
        data.append('start_date', dat.start_date)
    }
    if (dat.end_date) {
        data.append('end_date', dat.end_date)
    }
    if (dat.evaluation_day) {
        data.append('evaluation_day', dat.evaluation_day)
    }
    // if (dat.incentive) {
    //     data.append('incentive', dat.incentive)
    // }
    if (dat.title) {
        data.append('title', dat.title)
    }
    if (dat.remove_banner) {
        data.append('remove_banner', dat.remove_banner)
    }
    if (dat.remove_card) {
        data.append('remove_card', dat.remove_card)
    }
    if (dat.evlTitle) {
        data.append('evaluators.title', dat.evlTitle)
    }
    if (dat.evlMsg) {
        data.append('evaluators.message', dat.evlMsg)
    }
    const url = API_CHALLENGE_DRAFTED;
    const response = await CallApi({ url, method: "POST", data, type: "file" });
    return response;
}

/**
 * function to get drafted challenge
 * @returns 
 */
const getDraftChallenge = async () => {
    const url = API_DETAIL_CHALLENGE_DRAFTED
    const response = await CallApi({ url, method: "GET" });
    const dat = response !== undefined ? response.data : null;
    const dat2 = dat !== null ? dat.data : null;
    return dat2;
}

const LaunchRepo = {
    postChallenge,
    postDraftedChallenge,
    getDraftChallenge,
}

export default LaunchRepo