import React from "react";
import { Link } from "react-router-dom";
import ArrowIco from "../../../../components/icons/ArrowIco";
import RouteName from "../../../../services/routename";
import "../css/edit_page.css";

// const InsightHome = ({ insights, insights2 }) => {
const RewardsToolTip = ({refs, onClickRewards,onClickDelete,idrewards,isShowModalPreview}) => {

  return (
    <>
      {
        isShowModalPreview!=true ?(
          <div className="relative">
          <div className="h-7 w-30 controlhover-rewards z-[6] absolute -top-3 left-1" onClick= {onClickRewards}>
          <div className="mr-2 listedit-rewards pt-2 ml-2" title="Edit" id={idrewards}>
             <svg id="edit_-_icon" data-name="edit - icon" xmlns="http://www.w3.org/2000/svg" width="30" height="13" viewBox="0 0 12.857 12.857">
                <path id="Path_16" data-name="Path 16" d="M3,16.77H5.677l7.9-7.9L10.9,6.192,3,14.092Zm1.429-2.086L10.9,8.213l.657.657L5.085,15.342H4.427Z" transform="translate(-2.999 -3.913)" fill="#fff"/>
                <path id="Path_17" data-name="Path 17" d="M17.443,3.208a.711.711,0,0,0-1.007,0L15.129,4.515l2.679,2.679,1.307-1.307a.711.711,0,0,0,0-1.007Z" transform="translate(-6.466 -2.999)" fill="#fff"/>
             </svg>
          </div>
          <div className="mr-2 listedit-rewards pt-2" title="Delete" onClick={onClickDelete}>
             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="13" viewBox="0 0 10 12.857">
               <path id="bin" d="M5.714,14.429a1.433,1.433,0,0,0,1.429,1.429h5.714a1.433,1.433,0,0,0,1.429-1.429V5.857H5.714ZM7.143,7.286h5.714v7.143H7.143ZM12.5,3.714,11.786,3H8.214L7.5,3.714H5V5.143H15V3.714Z" transform="translate(-5 -3)" fill="#fff"/>
             </svg>
          </div>
        </div> 
        </div>
        ):""
      }
    </>
  );
};

export default RewardsToolTip;
