import CallApi from "../../services/request-helper";
import CallApiBlob from "../../services/request-helper-blob";

const API_OVERVIEW_STATIC = process.env.REACT_APP_OVERVIEW_1;
const API_OVERVIEW_EVALUATION = process.env.REACT_APP_OVERVIEW_2;
const API_OVERVIEW_CHALLANGE = process.env.REACT_APP_OVERVIEW_3;
const API_OVERVIEW_STATISTIC = process.env.REACT_APP_OVERVIEW_4;
const API_OVERVIEW_LIST_YEAR = process.env.REACT_APP_OVERVIEW_5;
const API_OVERVIEW_LIST_USER = process.env.REACT_APP_OVERVIEW_6;
const API_USER_ARCHETYPE = process.env.REACT_APP_ARCHETYPE;
const API_DOWNLOAD_USER = process.env.REACT_APP_USER_EXCEL;
const API_USER_RETURNING = process.env.REACT_APP_USER_RETURN;
const API_TOTAL_VIEWPAGE = process.env.REACT_APP_TOTAL_VIEWPAGE;
const API_TOTAL_USERS = process.env.REACT_APP_TOTAL_USERS;
const API_TOTAL_COMMUNICATION = process.env.REACT_APP_TOTAL_COMMUNICATION;
const API_DOWNLOAD_LEADERBOARD = process.env.REACT_APP_LEADERBOARD_EXCEL;

const getStaticList = async (year) => {
  const url = API_OVERVIEW_STATIC;
  const response = await CallApi({ url, method: "POST", data: year });
  return response;
};

const getStaticEvaluation = async (year) => {
  const url = API_OVERVIEW_EVALUATION;
  const response = await CallApi({ url, method: "POST", data: year });
  return response;
};

const getChallange = async (year) => {
  const url = API_OVERVIEW_CHALLANGE;
  const response = await CallApi({ url, method: "POST", data: year });
  return response;
};

const getStatistic = async (year) => {
  const url = API_OVERVIEW_STATISTIC;
  const response = await CallApi({ url, method: "POST", data: year });
  return response;
};

const getListYear = async () => {
  const url = API_OVERVIEW_LIST_YEAR;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getUser = async (year) => {
  const url = API_OVERVIEW_LIST_USER;
  const response = await CallApi({ url, method: "POST", data: year });
  return response;
};

const getArchetype = async ({ startD, endD }) => {
  const data = {};
  if (startD) {
    data.startDate = startD;
  }
  if (endD) {
    data.endDate = endD;
  }
  const url = API_USER_ARCHETYPE;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const exportUserExcel = async () => {
  const url = API_DOWNLOAD_USER;
  const response = await CallApiBlob({ url, method: "POST" });
  return response;
};

const exportLeaderboard = async (year) => {
  const url = API_DOWNLOAD_LEADERBOARD;
  const response = await CallApiBlob({
    url,
    method: "POST",
    data: { year: year },
  });
  return response;
};

const getUserReturn = async ({ startD, endD }) => {
  const data = {};
  if (startD) {
    data.startDate = startD;
  }
  if (endD) {
    data.endDate = endD;
  }
  const url = API_USER_RETURNING;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const getTotalViewPage = async ({ startD, endD }) => {
  const data = {};
  if (startD) {
    data.startDate = startD;
  }
  if (endD) {
    data.endDate = endD;
  }
  const url = API_TOTAL_VIEWPAGE;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const getTotUsers = async ({ startD, endD }) => {
  const data = {};
  if (startD) {
    data.startDate = startD;
  }
  if (endD) {
    data.endDate = endD;
  }
  const url = API_TOTAL_USERS;
  const response = await CallApi({ url, method: "POST", data });
  const res = response !== undefined ? response.data : null;
  return res;
};

const getTotCommunication = async ({ startD, endD }) => {
  const data = {};
  if (startD) {
    data.startDate = startD;
  }
  if (endD) {
    data.endDate = endD;
  }
  const url = API_TOTAL_COMMUNICATION;
  const response = await CallApi({ url, method: "POST", data });
  const res = response !== undefined ? response.data : null;
  return res;
};

const AllOverviewRepo = {
  getStaticList,
  getStaticEvaluation,
  getChallange,
  getStatistic,
  getListYear,
  getUser,
  getArchetype,
  exportUserExcel,
  getUserReturn,
  getTotalViewPage,
  exportLeaderboard,
  getTotUsers,
  getTotCommunication,
};

export default AllOverviewRepo;
