import React, { useState } from 'react'
import RichText from '../../../../components/RichText'
import BtnSubmit from '../../../../components/buttons/BtnSubmit';

const Communication = ({ setPage, setComm }) => {
  const [invMsg, setInvMsg] = useState('');
  const [challengeMsg, setChallengeMsg] = useState('');

  const handleSave = async () => {
    // setPage("invite");
    console.log("save");
  }

  return (
    <>
      <div className='space-y-4'>
        <div className='space-y-3'>
          <h3 className='text-textBlack flex font-semibold pt-3 text-md'>Invitation Message</h3>
          <input autoComplete="off" type="text" className='border-none shadow-md h-10 text-textGray w-full' placeholder='Subject title' />
          <RichText onChange={(content) => setInvMsg(content)} />
        </div>
        <div className='space-y-3'>
          <h3 className='text-textBlack flex font-semibold pt-3 text-md'>Share challenge message<p className='text-heritageGreen font-bold'>*</p></h3>
          <input autoComplete="off" type="text" className='border-none shadow-md h-10 text-textGray w-full' placeholder='Subject title' />
          <RichText onChange={(content) => setChallengeMsg(content)} />
        </div>
        <div className='pt-4 text-right'>
          <BtnSubmit onClick={handleSave} title="Save & Next" />
        </div>
      </div>
    </>
  )
}

export default Communication

