import React from "react";
import style from "./isLoading.module.css";


const IsLoading = () => {
  return (
    <div className="w-full h-32 flex justify-center items-center">
       <div class={` ${style.spinner}`}></div>
    </div>
  );
};

export default IsLoading;
