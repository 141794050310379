import React from 'react'
import { Link } from 'react-router-dom'
import RightIco from '../../../../../components/icons/RightIco'
import RouteAdm from '../../../../../services/routeAdm'

const Breadcrumbs = ({ challenge }) => {
  return (
    <div>
      <nav className="flex mb-3 mt-4 text-[9px] md:text-[10px] pb-1 md:pb-0" aria-label="Breadcrumb">
        <div className="inline-flex items-center space-x-1 md:space-x-3">
          <div className="inline-flex items-center">
            <Link
              to={RouteAdm.dashboard}
              className="inline-flex items-center font-medium text-gray-500 hover:text-gray-900"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
            </Link>
          </div>
          <div className="flex items-center">
            <Link
              to={RouteAdm.challenges+ "?tab=active&page=0&year="+ new Date().getFullYear()}
              className="ml-1 flex items-center font-medium text-gray-500 hover:text-gray-900"
            >
              <RightIco />
              Challenges
            </Link>
          </div>
          <div className="flex items-center">
            <Link
              to={RouteAdm.detailChallengesAdm + "/" + challenge.id}
              className="ml-1 flex items-center font-medium text-gray-500 hover:text-gray-900"
            >
              <RightIco />
              {challenge.title}
            </Link>
          </div>
          <div className="flex items-center">
            <Link
              className="ml-1 flex items-center font-medium text-gray-900"
            >
              <RightIco />
              Submitted Ideas
            </Link>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Breadcrumbs
