import React, { useEffect, useState } from "react";
import LikeIdeaRepo from "../../../../../repositories/user-repo/LikeIdeaRepo";
import { Modal } from "flowbite-react";
import GeneralUtility from "../../../../../utility/GeneralUtility";

const SideContent = ({ detailIdea, manager, id, getDetailIdea }) => {

  const [like, setLike] = useState({});
  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState(false);
  const [url, setUrl] = useState("");

  /**
   * funtion to get status like challenge
   */
  const getStatusLike = async () => {
    const resultLike = await LikeIdeaRepo.getStatusIdea(id.id);
    if (resultLike !== null) {
      setLike({ status: resultLike.status, id: resultLike.id });
    } else {
      setLike(null);
    }
  };

  /**
   * function to post Like
   * @param {*} id 
   */
  const postLike = async id => {
    setLike({ status: true, id: id });
    const result = await LikeIdeaRepo.postLikeIdea({ submited: { id: id.id } });
    if (result.status === 200) {
      getStatusLike();
      getDetailIdea();
    }
  };

  /**
  * function to post DisLike
  * @param {*} id 
  */
  const postDisLike = async id => {
    setLike({ status: false, id: id });
    const result = await LikeIdeaRepo.postDislikeIdea({ submited: { id: id.id } });
    if (result.status === 200) {
      getStatusLike();
      getDetailIdea();
    }
  };

  /**
   * post to unLike or unDislike idea
   * @param {*} id 
   */
  const unLike = async (id, status) => {
    if (!!status) {
      setLike(null);
    } else {
      setLike({ status: false, id: id });
    }
    const result = await LikeIdeaRepo.postUnlikeIdea({ id: id });
    if (result.status === 200) {
      getStatusLike();
      getDetailIdea();
    }
  };

  /**
   * funtion to show modal
   */
  const showShare = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    setUrl(el.value);
    setShow(true);
  }

  /**
   * function to copy the url link
   */
  const copy = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  useEffect(() => {
    getStatusLike();
  }, []);

  return (
    <div className="bg-baseGray p-4 md:p-5 space-y-2">
      <div className="flex space-x-1 justify-between items-center">
        <div
          className={`pt-2 px-2 text-xs text-center w-40 h-8 font-semibold items-center inline-block align-middle uppercase 
          ${detailIdea.status_transaction === "consultation" ? "bg-heritageGreen" : ""
            } ${detailIdea.status_transaction === "experimentation" ? "bg-naartjie" : ""
            } ${detailIdea.status_transaction === "generation" ? " bg-freshGreen" : ""
            } ${detailIdea.status_transaction === "complete" ? " bg-blueSky" : ""
            } ${detailIdea.status_transaction === "" ? "  bg-cerise" : ""
            }`
          }
        >
          {detailIdea.status_transaction}
        </div>
        <div className="space-x-1 flex items-center">
          {like === null ? (
            <>
              <button
                className="w-8 h-8"
                onClick={e => postLike(id)}
              >
                <img
                  src="/assets/global/img/like1.svg"
                  className="w-8 h-8"
                  alt="like"
                />
              </button>
              <button
                className="w-8 h-8"
                onClick={e => postDisLike(id)}
              >
                <img
                  src="/assets/global/img/dislike1.svg"
                  className="w-8 h-8"
                  alt="dislike"
                />
              </button>
            </>
          ) : (
            <>
              <button
                className="w-8 h-8"
                onClick={() => {
                  if (like.status === true) {
                    unLike(like.id, like.status)
                  } else {
                    postLike(id)
                  }
                }}
              >
                {like.status === true ? (
                  <img
                    src="/assets/global/img/like2.svg"
                    className="h-8 w-8"
                    alt="like"
                  />
                ) : (
                  <img
                    src="/assets/global/img/like1.svg"
                    className="h-8 w-8"
                    alt="like"
                  />
                )}
              </button>
              <button
                className="w-8 h-8"
                onClick={() => {
                  if (like.status === false) {
                    unLike(like.id, like.status)
                  } else {
                    postDisLike(id)
                  }
                }}
              >
                {like.status === false ? (
                  <img
                    src="/assets/global/img/dislike2.svg"
                    className="h-8 w-8"
                    alt="dislike"
                  />
                ) : (
                  <img
                    src="/assets/global/img/dislike1.svg"
                    className="h-8 w-8"
                    alt="dislike"
                  />
                )}
              </button>
            </>
          )}
          <div onClick={showShare} className="w-8 h-8 cursor-pointer shareIco" />
        </div>
      </div>
      <div className="w-full bg-gray-400 h-0.5"> </div>
      <div className="space-y-3">
        {/* <h3 className="text-md font-semibold">Team Members :</h3> */}
        <h3 className="text-md font-semibold">Created By :</h3>
        <div className="space-y-3">
          <div className="flex space-x-2 items-center">
            <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
              {GeneralUtility.getInitials(detailIdea.createdBy)}
            </div>
            <p className="text-sm">{detailIdea.createdBy}</p>
          </div>
        </div>
      </div>

      <div>
        {/* <button className="flex border border-freshGreen text-sm px-2 py-1 text-freshGreen hover:text-white hover:bg-gradient-to-r from-yellow-200 to-freshGreen">
          JOIN TEAM
          <svg
            aria-hidden="true"
            className="w-4 h-4 ml-2 -mr-1 mt-0.5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button> */}
      </div>
      <div className="w-full bg-gray-400 h-0.5"> </div>
      <div className="space-y-3">
        <h3 className="text-md font-semibold">Challenge Managers:</h3>
        <div className="space-y-3">
          {manager.map((items, index) => (
            <div key={index} className="flex space-x-2 items-center">
              <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                {GeneralUtility.getInitials(items.fullname)}
              </div>
              <p className="text-sm">{items.fullname}</p>
            </div>
          ))}
        </div>
      </div>

      <Modal
        show={show}
        size="xl"
        popup={true}
        onClose={() => setShow(!show)}
        className="bx_modalpop_global"
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              <input autoComplete="off" className="rounded-sm h-12" type="text" defaultValue={url} disabled />
              <button className="bg-freshGreen text-white px-3 h-12 rounded-sm" onClick={copy}>{!copied ? "Copy link" : "Copied!"}</button>
            </h3>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SideContent;
