import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RouteName from "../../../../services/routename";
import PersonalRepo from "../../../../repositories/user-repo/PersonalRepo";
import Personal from "./components/Personal";
import Breadcrumbs from "./components/Breadcrumbs";
import DropdownFilter from "../../../../components/DropdownFilter";
import Paginate from "../../../../components/paginate/Paginate";
import Skeleton from "../../../../components/skeleton/Skeleton";
import GeneralUtility from "../../../../utility/GeneralUtility";

const PersonalIdeas = () => {
  const [loading, setLoading] = useState(false);
  const [listView, setlistView] = useState(true);
  const [list, setList] = useState([]);
  const [limit] = useState(12);
  const [page, setPage] = useState([]);
  const [sort, setSort] = useState({ id: "2", name: "Recent" });
  const listFilter = [
    { id: "2", name: "Recent" },
    { id: "1", name: "oldest" },
  ];

  // get personal idea
  const getPersonalIdeas = async (pageNumber) => {
    setLoading(true);
    const result = await PersonalRepo.getPersonalIdea({
      offset: pageNumber,
      limit: limit,
      sort: sort.id,
    });
    let listResult = result !== null ? result.data : [];
    let resultPaginate = result !== null ? result : [];
    setList(listResult);
    setPage(resultPaginate);
    setLoading(false);
  };

  // Change Page
  const paginate = (event) => {
    getPersonalIdeas(event.selected);
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getPersonalIdeas(0);
  }, [sort]);

  return (
    <div className="container mx-auto">
      <div className="m-6 bx_myidea_top">
        <div>
          <Breadcrumbs />
          <div className="tx_top_page_latest lg:hidden">My Ideas</div>
          <div className="mx-auto">
            <div className="flex justify-center bg-white w-full">
              <div className="w-full">
                <div className="grid grid-cols-2 justify-between gap-3">
                  <div className="flex space-x-2">
                    <button
                      className={` ${
                        listView
                          ? "bg-freshGreen text-baseGray"
                          : "bg-gray-300 text-freshGreen"
                      } w-8 h-8`}
                      onClick={() => setlistView(true)}
                    >
                      <img
                        src="/assets/global/img/grid.svg"
                        className="ml-1.5"
                        alt=""
                      />
                    </button>
                    <button
                      className={` ${
                        listView
                          ? "bg-gray-300 text-freshGreen"
                          : "bg-freshGreen text-baseGray"
                      } w-8 h-8`}
                      onClick={() => setlistView(false)}
                    >
                      <img
                        src="/assets/global/img/list.svg"
                        className="ml-1.5"
                        alt="list"
                      />
                    </button>
                  </div>
                  <div className="flex justify-self-end">
                    <div className="flex space-x-2">
                      <DropdownFilter
                        title="SORT BY :"
                        selected={sort}
                        setSelected={setSort}
                        resList={listFilter}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-baseGray m-6 p-6 bx_myidea_personal">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 my-5 space-y-3 items-center">
          <div className="text-xl font-bold">Personal Ideas</div>
          <div className="lg:justify-self-end">
            <Link to={RouteName.createPersonal}>
              <button className="inline-flex text-freshGreen text-sm p-1 px-2 space-x-2 border border-1 border-freshGreen hover:text-white hover:bg-gradient-to-r from-green-600 to-freshGreen">
                <img
                  src="/assets/global/img/plus.png"
                  className="text-center mt-1"
                  alt="plus"
                />
                <p> CREATE NEW IDEAS</p>
              </button>
            </Link>
          </div>
        </div>
        {!loading ? (
          <Personal
            list={list}
            listView={listView}
            getPersonalIdeas={paginate}
            page={page}
          />
        ) : (
          <div className="mx-auto">
            <Skeleton col={4} height={230} />
          </div>
        )}

        <div className="mt-5">
          <Paginate paginate={paginate} page={page} />
        </div>
      </div>
    </div>
  );
};

export default PersonalIdeas;
