import Chart from "react-apexcharts";

export default function Pie(params) {

    var pie = {
        options: {
            labels: params.labels,
            colors: ['#00c0e8', '#8cc63f'],
            legend: {
                show: false,
                formatter: function (val, opts) {
                    return [opts.w.globals.series[opts.seriesIndex], " - ", val,]
                },
                position: 'right',
            },
            dataLabels: {
                enabled: true,
                dropShadow: {
                    enabled: false,
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]

        },
        series: params.value,
    };


    return (
        <div className='pie'>
            <div className="flex flex-row my-4">
                <Chart
                    options={pie.options}
                    series={pie.series}
                    type="pie"
                    width={params.width}
                    height={params.height}
                />
                <div className="flex flex-col mt-14">
                    {params.labels.map((res, index) => (
                        <div className={`ml-[10%] w-full flex flex-row h-8 my-2 mx-2 pt-1 text-xs capitalize ${index === 0 ? "text-[#97dff3]" : "text-[#b0d77b]"}`} key={index}>
                            <div className={`${index === 0 ? "bg-[#97dff3]" : "bg-[#b0d77b]"} h-3 mr-2 w-3 mt-[3px]`} /> {res}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
