import React, { useEffect, useState } from "react";
import ProfileRepo from "../../../repositories/adm-repo/ProfileRepo";
import GeneralUtility from "../../../utility/GeneralUtility";

const Profile = () => {
  const [detail, setDetail] = useState("");

  const getDetailProfile = async (e) => {

    const resultProfile = await ProfileRepo.getProfile();
    if (
      (resultProfile.status === 200) &&
      (resultProfile.data.data !== null)
    ) {
      let dataProfile = resultProfile.data.data;
      setDetail(dataProfile);
    }
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getDetailProfile();
  }, []);

  return (
    <>
      <div className="container" key="p1">
        <div className="m-8">
          <div className=" bg-baseGray">
            <div className="flex flex-col border-2 border-[#DCDDDE] bg-white">
              <div className="m-5">
                <div className="flex space-x-2 items-center">
                  <div>
                    <div className="text-4xl inline-flex items-center rounded-full bg-heritageGreen h-32 w-32 mb-3 text-white font-semibold justify-center">
                      {GeneralUtility.getUserInitials()}
                    </div>
                  </div>
                  <div>
                    <p className="text-sm font-bold text-freshGreen ">
                      Profile Picture
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-10">
                  <div>
                    <div className="space-y-3 h-24">
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                        FIRST NAME
                      </h3>
                      <div>
                        <div className=" h-[30px] border-b-2 border-gray-700">
                          {detail.firstname}
                        </div>
                      </div>
                    </div>

                    <div className="space-y-3 h-24">
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                        EMAIL ADDRESS
                      </h3>
                      <div>
                        <div className="h-[30px] border-b-2 border-gray-700">
                          {detail.email}
                        </div>
                      </div>
                    </div>
                    <div className="space-y-3 h-24 mb-3 pt-1.5">
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                        POSITION
                      </h3>

                      <div className="h-[30px] border-b-2 border-gray-700">
                        {detail.job_title}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="space-y-3 h-24">
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                        LAST NAME
                      </h3>
                      <div>
                        <div className="h-[30px] border-b-2 border-gray-700">
                          {detail.lastname}
                        </div>
                      </div>
                    </div>
                    <div className="space-y-3 h-24">
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                        PHONE NUMBER
                      </h3>
                      <div>
                        <div className="h-[30px] border-b-2 border-gray-700">
                          {detail.phone_number === null
                            ? ""
                            : detail.phone_number}
                        </div>
                      </div>
                    </div>

                    <div className="space-y-3 pb-3 pt-1.5">
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                        DEPARTMENT
                      </h3>

                      <div className="h-[30px] border-b-2 border-gray-700">
                        {detail.departemen}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
