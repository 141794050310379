import React from 'react'
import { Link } from 'react-router-dom'
import ArrowIco from '../icons/ArrowIco'

const Card = ({ id, card_image, title, desc, status, onClick, linkTo, total_contributor, total_submited, total_view }) => {
    return (
        <>
            <div key={id}
                className="bg-white border border-gray-200 shadow-md relative pb-8 z-0 h-full"
            >
                <Link to={linkTo} state={{ id: id }}>
                    <div onClick={onClick} className='w-full h-[200px] mb-4 md:h-[200px] bx_img_list_challenges relative flex overflow-hidden justify-center'>
                        <img src={card_image} alt={title} className='w-full h-[200px] mb-4 md:h-[200px] bx_img_list_challenges items-center min-w-[300px]' />
                        <div className="absolute top-0 right-0">
                            <p id="status" className="bg-white w-32 py-[3px] text-center font-semibold text-freshGreen uppercase text-xs tracking-[0.5px]">{status} </p>
                        </div>
                    </div>
                </Link>
                <div className="p-3 space-y-3 bx_list_challenges_cont">
                    <h5 id="title"
                        className="mb-2 text-base font-bold tracking-tight text-gray-900 hidden lg:block ht_list_challenges"
                        title={title}
                    >
                        {title.length > 40 ? title.substring(0, 40) + "..." : title}
                    </h5>
                    <h5
                        id="title"
                        className="mb-2 text-base font-bold tracking-tight text-gray-900 lg:hidden ht_list_challenges"
                        title={title}
                    >
                        {title.length > 80 ? title.substring(0, 80) + "..." : title}
                    </h5>
                    <div id="content">
                        <div className="mb-3 text-xs text-gray-700">
                            {desc === null ? "" : (
                                <div className="mx-auto" dangerouslySetInnerHTML={{
                                    __html: desc.length > 200
                                        ? desc.substring(0, 200) + "..."
                                        : desc
                                }} />
                            )}
                        </div>
                    </div>

                    <Link to={linkTo} state={{ id: id }}>
                        <div onClick={onClick}
                            className="inline-flex items-center text-xs font-medium text-center hover:text-freshGreen cursor-pointer"
                            title={title}> Read more <ArrowIco />
                        </div>
                    </Link>

                    <div className="flex space-x-2.5 mt-10 absolute bottom-3 items-center bx_list_challenges_icon">
                        <div className="flex items-center space-x-1.5 text-gray-400">
                            <img
                                src="/assets/global/img/avatar.png"
                                className="h-3 w-3"
                                alt="avatar"
                            />
                            <p>{total_contributor}</p>
                        </div>
                        <div className="flex items-center space-x-1.5 text-gray-400">
                            <img
                                src="/assets/global/img/visible.png"
                                className="h-[11px] w-[18px]"
                                alt="visible"
                            />
                            <p>{total_view}</p>
                        </div>
                        <div className="flex items-center space-x-1.5 text-gray-400">
                            <img
                                src="/assets/global/img/lightbulbGray.png"
                                className="h-3.5 w-[11px]"
                                alt="bulb"
                            />
                            <p>{total_submited}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Card