// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper{
  width:100%;
}
.content{
  display:flex;
  flex-wrap:wrap;
}
.edit{
  float:right;
  margin-left:40%;
}
.center{
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
  align-items: center;
}
.preview{
  width:100%;
  background-color:rgb(252, 251, 251);
  overflow-y:auto;
  overflow-x:hidden;
  transform:scale(1,1);
}
.previews{
  width:65%;
}
.xx{
  height:100%;
  width:100%;
  background-color:#E5E7EB;
  opacity: 0.2;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin-page/Pages/css/pages.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,YAAY;EACZ,cAAc;AAChB;AACA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,YAAY;EACZ,cAAc;EACd,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,mCAAmC;EACnC,eAAe;EACf,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,SAAS;AACX;AACA;EACE,WAAW;EACX,UAAU;EACV,wBAAwB;EACxB,YAAY;AACd","sourcesContent":[".wrapper{\r\n  width:100%;\r\n}\r\n.content{\r\n  display:flex;\r\n  flex-wrap:wrap;\r\n}\r\n.edit{\r\n  float:right;\r\n  margin-left:40%;\r\n}\r\n.center{\r\n  display:flex;\r\n  flex-wrap:wrap;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n.preview{\r\n  width:100%;\r\n  background-color:rgb(252, 251, 251);\r\n  overflow-y:auto;\r\n  overflow-x:hidden;\r\n  transform:scale(1,1);\r\n}\r\n.previews{\r\n  width:65%;\r\n}\r\n.xx{\r\n  height:100%;\r\n  width:100%;\r\n  background-color:#E5E7EB;\r\n  opacity: 0.2;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
