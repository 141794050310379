import CallApi from "../../services/request-helper";

const API_IDEAS_USER = process.env.REACT_APP_IDEAS_1;
const API_IDEAS_GENERATION = process.env.REACT_APP_IDEAS_2;
const API_IDEAS_BROKEN_DOWN = process.env.REACT_APP_IDEAS_3;


const getUser = async (year) => {
  const url = API_IDEAS_USER;
  const response = await CallApi({ url, method: "POST", data: year });
  return response;
};

const getListGeneration = async (year) => {
  const url = API_IDEAS_GENERATION;
  const response = await CallApi({ url, method: "POST", data: year });
  return response;
};

const getStatusBrokenDown = async (year) => {
  const url = API_IDEAS_BROKEN_DOWN;
  const response = await CallApi({ url, method: "POST", data: year });
  return response;
};





const AllIdeasRepo = {
  getUser,
  getListGeneration,
  getStatusBrokenDown
};

export default AllIdeasRepo;