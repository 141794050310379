import React from 'react'

//Button Save
const BtnSave = ({onClick, title }) => {
  return (
    <div>
      <button
        onClick={onClick}
        className="border border-1 border-green-400 bg-gradient-to-r from-green-500 to-freshGreen py-2 px-3 text-white text-xs justify-between flex items-center hover:bg-none hover:text-green-400 uppercase"
      >
        {title}
        <svg
          aria-hidden="true"
          className="w-4 h-4 ml-2 -mr-1"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  );
}

export default BtnSave
