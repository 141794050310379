import React from "react";
import { Link } from "react-router-dom";
import ArrowIco from "../../../../components/icons/ArrowIco";
import RouteName from "../../../../services/routename";
import Skeleton from "../../../../components/skeleton/Skeleton";

const InsightHome = ({ insight0, insight1, loading, titleInsight }) => {
  return (
    <>
      <div className="bg-baseGray space-y-3 p-6 font-Montserrat bx_home_insight">
        <h2 className="text-xl font-semibold text-center pt-3 pb-7 ht_home_title ">
          {titleInsight}
        </h2>
        {!loading ? (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="bg-white h-[450px] border border-gray-200 shadow-md bx_home_list_insight">
                <iframe
                  width="100%"
                  height="300"
                  src={insight0.source}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                ></iframe>
                <div className="px-5 pt-3">
                  <div>
                    <h5
                      className="mb-2 text-md lg:text-md font-bold tracking-tight text-gray-900"
                      title={insight0.title}
                    >
                      {insight0.title}
                    </h5>
                  </div>
                  <p className="mb-2 font-normal text-gray-700 text-xs">
                    {insight0.content}
                  </p>
                </div>
              </div>
              <div>
                <div className="space-y-8 lg:space-y-8">
                  {insight1.map((items, index) => (
                    <div
                      key={index}
                      className="flex flex-col md:flex-row lg:h-[210px] items-center bg-white border shadow-md hover:bg-gray-100 space-y-2 bx_home_list_insight"
                    >
                      <div className="relative md:basis-7/12">
                        <iframe
                          width="100%"
                          height="210"
                          src={items.source}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                        ></iframe>
                      </div>
                      <div className="flex flex-col justify-between leading-normal px-[25px] basis-6/12">
                        <div className="max-h-[150px]">
                          <h5 
                            title={items.title}
                            className="mb-2 text-sm font-bold tracking-tight text-gray-900"
                          >
                            {items.title ? items.title : ""}
                          </h5>
                        </div>
                        <div className="mb-2 text-xs text-gray-700 break-all">
                           {items.content}
                        </div>
                      </div>
                    </div>
                  )) ?? ""}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <Skeleton col={2} height="300" />
          </div>
        )}
        <div className="py-4"></div>

        {/* hidden */}
        <div className="text-center mt-4 py-9 hidden">
          <Link to={RouteName.userResources}>
            <button className="border border-1 uppercase text-sm border-green-400 text-center p-3 inline-flex items-center text-freshGreen hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white">
              VIEW RESOURCES
              <ArrowIco />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default InsightHome;
