import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "flowbite-react";
import RouteAdm from "../../../../../services/routeAdm";

const ModalSendIdeas = ({ showSend, setShowSend, setShow }) => {
  const navigate = useNavigate();
  const ideasListView = () => {
    // countView();
    navigate(RouteAdm.ideas);
  };

  return (
    <>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,700&display=swap" rel="stylesheet" />
    <div>
      <Modal
        className="bx_modalpop_global"
        show={showSend}
        popup={true}
        size="xl"
        onClose={() => {
          setShowSend(!showSend);
          setShow(!showSend);
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-4">
            <div className="mx-auto flex justify-center">
              <img
                src="/assets/global/img/validateGreen.svg"
                alt=""
                className="h-11"
              />
            </div>
            <h3 className="font-bold text-lg text-center font-Montserrat ">Message Sent</h3>
            <div id="desc" className="text-[15px] text-center font-Montserrat">
              The idea creator has been notified of the idea evaluation result.
            </div>
            <div className="text-center mt-4 items-center  font-Montserrat">
              <button onClick={() => ideasListView() } className=" justify-center w-full border border-1 border-green-400 text-center py-2 px-3 inline-flex items-center bg-gradient-to-r from-green-600 to-freshGreen text-white">
                BACK TO IDEAS
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
    </>
  );
};

export default ModalSendIdeas;
