import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import DetailContentChallenges from "./components/DetailContentChallenges";
import ChallengeRepo from "../../../../repositories/user-repo/ChallengeRepo";
import Breadcrumbs from "./components/Breadcrumbs";
import Banner from "../../../../components/Banner";
import GeneralUtility from "../../../../utility/GeneralUtility";

const DetailChallengesAdm = () => {
  let { id } = useParams();
  const [detail, setDetail] = useState("");
  const [manager, setManager] = useState([]);
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(true);

  const [formValue, setFormValue] = useState({
    title: "",
    card_image: null,
    banner_image: null,
    overview: null,
    evaluation_day: "",
    evaluators: [],
    start_date: null,
    launch_date: null,
    resources: [],
    end_date: null,
    type_idea: null,
    status_privacy_id: null,
    category_id: null,
    id: null,
    questions: [],
    links: [],
    drafted: false,
    rewards: [],
  });

  const getDetailChallenge = useCallback(async () => {
    setLoading(true);
    const result = await ChallengeRepo.getDetailChallenges({ id: id });
    if(result !== null){
      let listChallenges = result;
      let links = result.links;
      let listManager = result.managerchallange;
      setManager(listManager);
      setLinks(links);
      setFormValue((prevState) => {
        return {
          links: listChallenges.links,
        };
      });
    }
    setDetail(result);
    setLoading(false);
  }, [id]);

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getDetailChallenge();
  }, [getDetailChallenge]);

  return (
    <>
      <div className="container mx-auto">
        {detail !== null ? (
          <>
            <Breadcrumbs title={detail.title} />
            <div>
              <Banner
                detail={{ banner: detail.banner_image, title: detail.title }}
                loading={loading}
              />
            </div>
            <DetailContentChallenges
              formValue={formValue}
              detail={detail}
              manager={manager}
              links={links}
              loading={loading}
            />
          </>
        ) : (
          <>
            <h2 className="text-center mt-10 text-xl font-bold">Challenge Not Found</h2>
          </>
        )}
      </div>
    </>
  );
};

export default DetailChallengesAdm;
