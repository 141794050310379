import React, { useEffect, useState } from "react";

const ButtonPublish = (props) => {
  console.log("save: "+JSON.stringify(props));
  return (
    <>
      <div>
        <button onClick={props.saveTrigger} className="h-10 w-20 mb-4 bg-baseGray border border-freshGreen border-solid border-2 text-freshGreen text-sm md:text-base  hover:text-white hover:bg-gradient-to-tr from-green-600 to-freshGreen x">
          SAVE
        </button>
      </div>
      <div className="ml-2">
        <button onClick={props.publishTrigger} className="h-10 w-20 mb-4 bg-baseGray border border-freshGreen border-solid border-2 text-freshGreen text-sm md:text-base  hover:text-white hover:bg-gradient-to-tr from-green-600 to-freshGreen">
          PUBLISH
        </button>
      </div>
    </>
  );
};
export default ButtonPublish;
