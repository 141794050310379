import React, { useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";

const RichText = ({ onChange, value, initialValue, placeholder }) => {
  const editorRef = useRef(null);
  const [description, setDescription] = useState(initialValue);

  useEffect(() => {
    onChange(description);
  }, [description]);

  return (
    <div className="z-0">
      <Editor
        // apiKey="rx4no1r1mwnsqiwgk8n4ruah0znkp4pn0n3qy6updcoi09yt"
        apiKey="lt4xr20og1e36xj9296qxlxrk7cwjn9b8v6522k7bdxm6v3o"

        outputFormat="text"
        initialValue={initialValue}
        value={value}
        onEditorChange={newText => setDescription(newText)}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          height: 250,
          menubar: "file edit view insert format tools",
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount",
          ],
          statusbar: false,
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | table bullist numlist outdent indent | " +
            "removeformat ",
          image_title: true,
          placeholder: placeholder === undefined ? "Type something..." : placeholder,
          automatic_uploads: true,
          file_picker_types: "image",
          /* for image picker */
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");

            input.onchange = function () {
              var file = this.files[0];
              var reader = new FileReader();

              reader.onload = function () {
                var id = "blobid" + new Date().getTime();
                var blobCache = editorRef.current.editorUpload.blobCache;
                var base64 = reader.result.split(",")[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                /* call the callback and populate the Title field with the file name */
                cb(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
            };
            input.click();
          },
        }}
      />
    </div>
  );
};

export default RichText;
