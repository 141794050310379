import React, { useState, useEffect } from 'react'
import BtnSubmit from '../../../../components/buttons/BtnSubmit';
import BtnBack from '../../../../components/buttons/BtnBack';
const Form = ({ setPage, setForm, error, form, type }) => {

  const [questions, setQuestions] = useState([{ question: "" }]);

  const listQ = () => {
    if (type === "2") {
      setQuestions(form.questions.length > 0 ? form.questions : [{ question: "" }])
    } else {
      setQuestions(form.questions ? form.questions : [{ question: "" }])
    }
  }

  const handleAddQuestion = () => {
    const values = [...questions];
    values.push({
      question: ""
    });
    setQuestions(values);
  };

  const handleRemoveQuestion = (index) => {
    const values = [...questions];
    values.splice(index, 1);
    setQuestions(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...questions];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;

    setQuestions(values);
  };

  const handleSave = async e => {
    let data = { questions: [...questions] };
    setForm(data);
    setPage("evaluators");
  }

  useEffect(() => {
    listQ();
    // checkErr();
  }, [])


  return (
    <>
      <div className='space-y-4'>
        <p className='text-textBlack font-semibold'>Create the idea form users will fill out when submitting their idea to this challenge.</p>
        <div id='challenges' className='space-y-2 hidden'>
          <h3 className='text-textBlack flex font-semibold pt-3 text-md'>Idea Image <p className="text-md text-heritageGreen font-bold">*</p></h3>
          <div className='flex space-x-3'>
            <div>
              <div className="text-freshGreen uppercase items-center flex space-x-2 text-sm border border-freshGreen w-40 px-2 py-1">
                <img src="/assets/global/img/camera.svg" alt="" />
                <p> banner image</p>
                <input disabled
                  autoComplete="off"
                  className="hidden"
                  name="banner_image"
                />
              </div>
            </div>
            <div>
              <div className="text-freshGreen uppercase items-center flex space-x-2 text-sm border border-freshGreen w-40 px-2 py-1">
                <img src="/assets/global/img/camera.svg" alt="" />
                <p className='text-center'>Card image</p>
                <input disabled
                  autoComplete="off"
                  type="file"
                  className="hidden"
                  name="card_image"
                />
              </div>
            </div>
          </div>
          <div className='text-textGray text-xs'>
            <p>Add images to your content to make it more engaging.</p>
          </div>
        </div>

        <div id='title' className='pt-2'>
          <input autoComplete="off" disabled className='text-textGray h-10 text-sm w-full border-none shadow-md' type="text" placeholder='Question for this challenge' />
        </div>

        <div className='question space-y-4'>
          {questions.length > 0 && (
            <div className='w-full space-y-2 relative block'>
              {questions.map((field, index) => (
                <div key={index} className="w-full flex space-x-4 space-y-2 relative block">
                  <input
                    autoComplete="off"
                    className='w-full border-none shadow-md text-sm'
                    type="text"
                    name="question"
                    placeholder={`Question ${index + 1}`}
                    value={field.question}
                    onChange={(event) =>
                      handleInputChange(index, event)
                    }
                  />
                  <span className="absolute inset-y-0 right-0 flex items-center pr-3 space-x-2">
                    <img onClick={() => handleAddQuestion()} src="/assets/global/img/duplicate.svg" className='cursor-pointer' alt="new question" title="add new question" />
                    <img onClick={() => handleRemoveQuestion(index)} src="/assets/global/img/bin.svg" className='cursor-pointer' alt="delete" title="delete this question" />
                  </span>
                </div>
              ))}
            </div>
          )}
          <p className="text-red-500 text-xs">{error.question}</p>
        </div>

        <div id='links' className='space-y-2 hidden'>
          <h3 className='text-textBlack flex font-semibold pt-3 text-md'>Add Links</h3>
          <div className='links'>
            <div className="space-y-2">
              <div className="space-y-2">
                <div className="flex space-x-3">
                  <input
                    autoComplete="off"
                    disabled
                    type="text"
                    name="resourceName"
                    placeholder="Resource Name"
                    className="border-none shadow-md w-48 text-textGray h-10 text-sm"
                  />
                  <input
                    autoComplete="off"
                    disabled
                    type="text"
                    name="source"
                    placeholder="Paste URL"
                    className="border-none shadow-md w-full text-textGray h-10 text-sm"
                  />
                  <img
                    src="/assets/global/img/bin.svg"
                    alt="trash"
                    className='cursor-pointer'
                    title="delete this resource link"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='docs' className='space-y-2 hidden'>
          <h3 className='text-textBlack flex font-semibold pt-3 text-md'>Upload Resources</h3>
          <div className="dropzone bg-white shadow-md w-full border-dashed border-2 border-gray-300 text-center justify-center cursor-pointer space-y-2 py-10">
            <div className="w-full">
              <img
                src="/assets/global/img/upload.png"
                className="justify-center self-stretch mx-auto"
                alt=""
              />
            </div>
            <p className="text-gray-600">
              <b className="text-semibold ">Click to upload</b> or drag and drop
            </p>
            <p className="text-gray-400">
              Pdf, png, jpg, doc, docx, xls, xlsx, ppt, pptx (5 MB max file size)
            </p>
          </div>
        </div>

        <div className='pt-4 flex flex-row-reverse gap-x-2'>
          <BtnSubmit onClick={handleSave} title="Save & Next" />
          <BtnBack  onClick={() => setPage("info")} title="Back" />
        </div>
      </div>
    </>
  )
}

export default Form
