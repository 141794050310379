import CallApi from "../../services/request-helper";

const API_CATEGORY = process.env.REACT_APP_CATEGORY;

/**
 * get challenge category 
 * @returns 
 */
const getCategory = async () => {
    const url = API_CATEGORY;
    const response = await CallApi({url, method: "GET"});
    const dat = response !== undefined ? response.data : null;
    const dat2 = dat.data !== undefined ? dat.data : null;
    return dat2;
}

const CategoryRepo = {
  getCategory,
}

export default CategoryRepo