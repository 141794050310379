import React from "react";


const DetailContent = ({ detailIdea, answers }) => {
  return (
    <div className="space-y-3">
      <div className="bg-baseGray p-4 md:p-5 space-y-2">
        <h3 className="text-lg font-semibold text-gray-700">
          Idea Description
        </h3>
        <div className="text-sm">
          <div dangerouslySetInnerHTML={{ __html: detailIdea.description }} />
        </div>
      </div>
      {answers.sort((a, b) => a.step_index - b.step_index).map((items, index) => (
        <div key={index} className="bg-baseGray p-4 md:p-5 space-y-2">
          <h3 className="text-lg font-semibold text-gray-600">
            {items.question.question}
          </h3>
          <div className="text-sm">
            <div dangerouslySetInnerHTML={{ __html: items.answer }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default DetailContent;
