import React from "react";
import { useEffect, useState } from "react";

const Skeleton = ({ col, height }) => {
  const [arr, setArr] = useState([]);

  const createArrayWithObjects = (items) => {
    const newArray = [];

    for (let i = 0; i < items; i++) {
      // You can define the structure of your objects here
      const newObject = {
        id: i + 1, // You can set an ID or any other property
        name: `Item ${i + 1}`, // Customize the properties as needed
      };
      newArray.push(newObject);
    }
    setArr(newArray);
  };

  useEffect(() => {
    createArrayWithObjects(col);
  }, []);
  return (
    <div
      className={`grid grid-cols-1 ${col > 1 ? "md:grid-cols-2" : "md:grid-cols-1" } lg:grid-cols-${col} h-full gap-5`}
    >
      {arr.map((items, index) => (
        <div
          className={`flex items-center justify-center h-[${height}px] bg-gray-300 animate-pulse`}
          key={items + index}
        >
          <span className="sr-only">Loading...</span>
        </div>
      ))}
    </div>
  );
};

export default Skeleton;
