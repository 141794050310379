import CallApi from "../../services/request-helper";

const API_PERSONAL = process.env.REACT_APP_IDEA_PERSONAL;
const API_DELETE_PERSONAL = process.env.REACT_APP_DELETE_PERSONAL;
const API_EDIT_PERSONAL = process.env.REACT_APP_UPDATE_PERSONAL;
const API_DUPLICATE_PERSONAL = process.env.REACT_APP_DUPLICATE_PERSONAL;

// CREATE WITH FORMDATA
const API_UPLOAD_PERSONAL = process.env.REACT_APP_UPLOAD_PERSONAL;

// GET ALL PERSONAL IDEA 
const getPersonalIdea = async ({ offset, limit, sort, search, search_home, drafted }) => {
  const data = {};
  data.offset = offset ? offset : 0;
  data.limit = limit ? limit : 10;
  if (sort) {
    data.sort = sort;
  }
  if (search) {
    data.search = search;
  }
  if (search_home) {
    data.search_home = search_home;
  }
  if (drafted) {
    data.drafted = drafted;
  }
  const url = API_PERSONAL;
  const response = await CallApi({ url, method: "POST", data });
  const dat = response !== undefined ? response.data : null;
  const dat2 = dat.data !== undefined ? dat.data : null;
  return dat2;
};

// FOR CREATE NEW PERSONAL IDEA WITH FORM DATA
const uploadPersonalIdea = async (dat) => {
  let data = new FormData();
  data.append("title", dat.title);
  data.append("description", dat.description);
  if (dat.links !== []) {
    let link = 0;
    dat.links.forEach((value) => {
      if (value.resourceName !== "" && value.source !== "") {
        data.append(`links[${link}].resourceName`, value.resourceName);
        data.append(`links[${link}].source`, value.source);
        link = link + 1;
      }
    });
  }
  if (dat.documents) {
    if (dat.documents !== []) {
      dat.documents.forEach((value, index) => {
        data.append(`documents[${index}].source`, value.file);
      });
    }
  }
  const url = API_UPLOAD_PERSONAL;
  const response = await CallApi({ url, method: "POST", data, type: "file" });
  return response;
};

// FOR DELETE PERSONAL IDEA
const deletePersonalIdea = async (data) => {
  const url = API_DELETE_PERSONAL;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

// FOR DUPLICATE PERSONAL IDEA
const duplicatePersonalIdea = async (data) => {
  const url = API_DUPLICATE_PERSONAL;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

// FOR GET DATA TO EDIT WITH ID, GET DETAIL
const editPersonalIdea = async (id) => {
  const url = API_PERSONAL + "/reqBody";
  let data = { id: id }
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

// FOR SAVE UPDATED DATA PERSONAL IDEA
const saveEditPersonalIdea = async (dat) => {
  let data = new FormData();
  data.append("id", dat.id);
  data.append("title", dat.title);
  data.append("description", dat.description);
  if (dat.links !== []) {
    let link = 0;
    dat.links.forEach((value) => {
      if (value.resourceName !== "" && value.source !== "") {
        data.append(`links[${link}].resourceName`, value.resourceName);
        data.append(`links[${link}].source`, value.source);
        link = link + 1;
      }
    });
  }
  if (dat.documents) {
    if (dat.documents !== []) {
      //filter documents, new documents
      const docUp = dat.documents.filter(object => {
        return object.id === null;
      });

      //filter deleted documents
      const docDel = dat.documents.filter(object => {
        return object.source === null;
      });

      docUp.forEach((value, index) => {
        data.append(`documents[${index}].source`, value.source);
      });

      docDel.forEach((value, index) => {
        data.append(`remove_image[${index}]`, value.id);
      });
    }
    const url = API_EDIT_PERSONAL;
    const response = await CallApi({ url, method: "POST", data, type: "file" });
    return response;
  }
}

const PersonalRepo = {
  getPersonalIdea,
  deletePersonalIdea,
  editPersonalIdea,
  saveEditPersonalIdea,
  duplicatePersonalIdea,
  uploadPersonalIdea,
};

export default PersonalRepo;
