import React, { useEffect } from "react";
import Cookies from "js-cookie";
import LoginRepo from "../../../repositories/user-repo/LoginRepo";
// import AlertUtility from '../../../utility/alert-utility';
import RouteAdm from "../../../services/routeAdm";
import RouteName from "../../../services/routename";
import ReactLoading from "react-loading";
import ProfileRepo from "../../../repositories/adm-repo/ProfileRepo";
import EncryptDecrypt from "../../../utility/encrypt-decrypt";
import GeneralUtility from "../../../utility/GeneralUtility";
import { useNavigate } from "react-router-dom";

const AuthUser = () => {

  const sessionId = new URLSearchParams(window.location.search).get("code");
  const navigate = useNavigate();

  const getAccessToken = async (navigate) => {
    const res = await LoginRepo.accessToken({ code: sessionId });
    if (res.status === 200) {

      // ===> save data to cookies
      Cookies.set("user_refresh_token", EncryptDecrypt.enc(res.data.data.refresh_token), { secure: true, httpOnly: true });
      Cookies.set("user_session_id", EncryptDecrypt.enc(res.data.data.session_id), { secure: true });
      Cookies.set("token", EncryptDecrypt.enc(res.data.data.jwtToken));
      Cookies.set("user_accessToken", EncryptDecrypt.enc(res.data.data.access_token), { secure: true  });
      Cookies.set("role", EncryptDecrypt.enc(res.data.data.role), { secure: true  });
      Cookies.set("firstname", EncryptDecrypt.enc(res.data.data.firstname, { secure: true }));
      Cookies.set("lastname", EncryptDecrypt.enc(res.data.data.lastname, { secure: true  }));
      let accessToken = res.data.data.access_token;
      // ===> save data to cookies
      if (res.data.data.email !== null || res.data.data.email !== "") {
        Cookies.set("user_email", EncryptDecrypt.enc(res.data.data.email), { secure: true });
      } else {
        const resultProfile = await ProfileRepo.postProfile({
          code: accessToken,
        });
        if (
          (resultProfile.data.status === "OK") & (resultProfile.data.data !== null)
        ) {
          let user_email = resultProfile.data.data.userPrincipalName;
          Cookies.set("user_email", EncryptDecrypt.enc(user_email), { secure: true  });
        }
        // ===> get user_email
      }

      // ===> redirect to specific role page
      if (res.data.data.firstname === "" && res.data.data.lastname === "") {
        GeneralUtility.logout();
        navigate(RouteName.login);
        
      } else {
        if (res.data.data.role === "InnovoiceAdmin") {
          window.location = RouteAdm.dashboard;
        } else if (res.data.data.role === "InnovoiceUser") {
          window.location = RouteName.userHome;
        }
      }
      // ===> redirect to specific role page
    } else {
      // window.location = RouteName.login
    }
  };

  useEffect(() => {
    getAccessToken(navigate);
  }, []);

  return (
    <div className="flex justify-center ">
      <ReactLoading
        type="spinningBubbles"
        color="#3893ce"
        height={200}
        width={100}
        className="mt-56 mx-auto"
      />
    </div>
  );
};

export default AuthUser;
