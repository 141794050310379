import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Modal } from "flowbite-react";
import RouteName from '../../../../../services/routename';
import { Link } from 'react-router-dom';
import Paginate from '../../../../../components/paginate/Paginate';
import EncryptDecrypt from '../../../../../utility/encrypt-decrypt';
import DropdownFilter from '../../../../../components/DropdownFilter';

const ModalDetail = ({
  team,
  show,
  setShow,
  listed,
  listView,
  setlistView,
  sort,
  setSort,
  paginate,
  page,
  id,
  slug
}) => {
  const listFilter = [
    { id: "2", name: "Recent" },
    { id: "1", name: "oldest" },
  ]

  //removing same id idea
  const uniqueIdea = listed.filter((user, index, self) =>
    index === self.findIndex(u => u.id === user.id)
  );

  const [selectedId, setSelectedId] = useState(0)
  const [checked, setChecked] = useState(false);

  const getIdIdea = async (id) => {
    setSelectedId(id);
    setChecked(true);
    Cookies.set("idIdea", EncryptDecrypt.enc(id), { secure: true });
  }

  const changeHandler = async (value, id) => {
    if (value) {
      setSelectedId(id);
      setChecked(true);
      Cookies.set("idIdea", EncryptDecrypt.enc(id), { secure: true  });
    } else {
      // handle select
    }
  }


  return (
    <div>
      <Modal className="bx_modalpop_global" show={show} popup={true} size="7xl" onClose={() => setShow(!show)}>
        <Modal.Header />
        <Modal.Body>
          <div className='bx_modalpop_global_min'>
            <div className="text-center text-heritageGreen text-xl md:text-2xl mb-3 ht_modal_title">
              Select Idea From My Ideas
            </div>
            <div className="mx-6 grid grid-cols-1 lg:grid-cols-2 justify-between gap-4 md:gap-3 bx_modal_filter">
              <div className="flex space-x-2">
                <button
                  className={` ${listView
                    ? "bg-freshGreen text-baseGray"
                    : "bg-gray-300 text-freshGreen"
                    } w-8 h-8`}
                  onClick={() => setlistView(true)}
                >
                  <img
                    src="/assets/global/img/grid.svg"
                    className="ml-1.5"
                    alt=""
                  />
                </button>
                <button
                  className={` ${listView
                    ? "bg-gray-300 text-freshGreen"
                    : "bg-freshGreen text-baseGray"
                    } w-8 h-8`}
                  onClick={() => setlistView(false)}
                >
                  <img
                    src="/assets/global/img/list.svg"
                    className="ml-1.5"
                    alt=""
                  />
                </button>
              </div>
              <div className="flex lg:justify-self-end bx_modal_sortby">
                <div className="flex space-x-2 ">
                  <DropdownFilter title="SORT BY :" selected={sort} setSelected={setSort} resList={listFilter} />
                </div>
              </div>
            </div>
            <div className="w-full h-full md:h-[28rem] overflow-y-auto">
              <div className={` ${listView ? "mx-auto" : "hidden"}`}>
                <div className="bg-baseGray m-6 p-6 bx_modal_listideas">
                  <h3 className="font-semibold text-xl text-center p-4">
                    Personal Ideas
                  </h3>
                  {uniqueIdea.length < 1 ? (
                    <div className='text-center h-44'>
                      No idea drafted
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                      {uniqueIdea.map(List => (
                        <div
                          onClick={e => getIdIdea(List.id)}
                          className={`${selectedId === List.id ? "shadow-md shadow-freshGreen " : ""} relative flex space-y-3 cursor-pointer`}
                          key={List.id}

                        >
                          <div className="bg-white h-40 border pb-8 border-gray-200 shadow-md w-full hover:shadow-md hover:shadow-freshGreen bx_list_select_ideas">
                            <div className="p-5 flex justify-between">
                              <h3 title={List.title} className="mb-3 text-gray-700 font-medium">
                                {List.title.length > 50 ? List.title.substring(0, 50) + "..." : List.title}
                              </h3>
                            </div>

                            <div className="space-x-2 px-5 py-1 flex text-sm absolute bottom-3">
                              <div className="flex items-center space-x-2 rounded-md text-gray-400">
                                <img
                                  src="/assets/global/img/attachment.svg"
                                  className="h-3 w-2"
                                  alt="attachment"
                                />
                                <p>{List.count_document}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="py-3 bx_listideas_pagination">
                    <Paginate paginate={paginate} page={page} />
                  </div>
                </div>
              </div>

              <div className={` ${listView ? "hidden" : "mx-auto"}`}>
                <div className="bg-baseGray m-6 p-6 bx_modal_listideas">
                  <h3 className="font-semibold text-xl text-center p-4">
                    Personal Ideas
                  </h3>
                  <div className="relative overflow-x-auto shadow-md">
                    <table className="w-full text-sm text-left text-gray-500 ">
                      <thead className="text-xs text-gray-700 uppercase bg-white">
                        <tr>
                          <th scope="col" className="p-4"></th>
                          <th scope="col" className="px-6 py-3">
                            IDEA TITLE
                          </th>
                          <th scope="col" className="px-6 py-3">
                            FILES
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {listed.map(List => (
                          <tr
                            key={List.id}
                            className="bg-white border-b hover:border-freshGreen"
                          >
                            <td className="w-4 p-4">
                              <div className="flex items-center">
                                <input
                                  autoComplete="off"
                                  value={List.id}
                                  id={`checkbox_${List.id}`}
                                  checked={List.id === selectedId ? true : false}
                                  onChange={e => changeHandler(e.target.checked, List.id)}
                                  type="checkbox"
                                  name="personalList"
                                  className="w-4 h-4 text-freshGreen bg-gray-100 border-gray-300 rounded focus:ring-freshGreen focus:ring-2"
                                />
                              </div>
                            </td>
                            <th
                              scope="row"
                              className="px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                            >

                              <h3 title={List.title} className="text-gray-700 font-medium">
                                {List.title.length > 60 ? List.title.substring(0, 60) + "..." : List.title}
                              </h3>
                            </th>
                            <td className="flex items-center px-6 py-4 space-x-3">
                              <div className="flex items-center space-x-2 rounded-md text-gray-400">
                                <img
                                  src="/assets/global/img/attachment.svg"
                                  className="h-3 w-2"
                                  alt="attachment"
                                />
                                <p>{List.count_document}</p>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="py-3">
                    <Paginate paginate={paginate} page={page} />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-4">
              <div className="flex justify-center">
                {checked === true ? (
                  <Link to={RouteName.submitPersonal + '/' + slug.id}>
                    <button className="border border-1 border-green-400 text-center text-xs p-3 py-2 inline-flex items-center text-freshGreen hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white uppercase" onClick={() => setShow(false)}>Confirm Selection</button>

                  </Link>
                ) : (
                  <button className="border border-1 border-green-400 text-center text-xs p-3 py-2 inline-flex items-center text-freshGreen hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white uppercase">Confirm Selection</button>
                )
                }
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
};

export default ModalDetail
