import React from "react";
import { Link } from "react-router-dom";
import ArrowIco from "../../../../components/icons/ArrowIco";
import RouteAdm from "../../../../services/routeAdm";

const Upcoming = ({ listChallenge,tab,page,year }) => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
        {listChallenge?.data?.length > 0
          ? listChallenge?.data?.map((items) => (
              <div
                key={items.id}
                className="bg-white border border-gray-200 shadow-md relative pb-8"
              >
                <Link to={RouteAdm.detailChallengesAdm + "/" + items.id +"?tab="+tab +"&page="+ page+ "&year="+ year}>
                  <div className="w-full h-[200px] mb-4 md:h-[200px] bx_img_list_challenges relative flex overflow-hidden justify-center">
                    <img
                      src={items.card_image}
                      alt={items.title}
                      className="w-full h-[200px] mb-4 md:h-[200px] bx_img_list_challenges items-center min-w-[300px]"
                    />
                    <div className="absolute top-0 right-0">
                      <p
                        id="status"
                        className="bg-white w-32 py-[3px] text-center font-semibold text-freshGreen uppercase text-xs tracking-[0.5px]"
                      >
                        {items.status}
                      </p>
                    </div>
                  </div>
                </Link>
                <div className="p-3 space-y-3">
                  <Link to={RouteAdm.detailChallengesAdm + "/" + items.id}>
                    <h5
                      id="title"
                      className="mb-2 text-lg font-bold tracking-tight text-gray-900"
                    >
                      {items.title.length > 35
                        ? items.title.substring(0, 35) + "..."
                        : items.title}
                    </h5>
                  </Link>
                  <div id="content">
                    <div className="mb-3 text-sm text-gray-700">
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            items.desc && items.desc.length > 200
                              ? items.desc.substring(0, 200) + "..."
                              : items.desc,
                        }}
                      />
                    </div>
                  </div>

                  <Link to={RouteAdm.editChallenge + "/" + items.id}>
                    <div
                      className="inline-flex items-center text-sm font-medium text-center hover:text-freshGreen cursor-pointer"
                    >
                      Edit
                      <ArrowIco />
                    </div>
                  </Link>

                  <div className="flex space-x-3 mt-10 absolute bottom-3 ">
                    <div className="flex items-center space-x-2 rounded-md text-gray-400">
                      <img
                        src="/assets/global/img/avatar.png"
                        className="h-3 w-3"
                        alt="avatar"
                      />
                      <p>{items.total_contributor}</p>
                    </div>
                    <div className="flex items-center space-x-2 rounded-md text-gray-400">
                      <img
                        src="/assets/global/img/visible.png"
                        className="h-3 w-5"
                        alt="visible"
                      />
                      <p>{items.total_view}</p>
                    </div>
                    <div className="flex items-center space-x-2 rounded-md text-gray-400">
                      <img
                        src="/assets/global/img/lightbulbGray.png"
                        className="h-4 w-3"
                        alt="lightbulbGray"
                      />
                      <p>{items.total_submited}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : "No Data Available"}
      </div>
    </div>
  );
};

export default Upcoming;
