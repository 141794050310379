import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../auth/Login/Login";
import RouteName from "../../services/routename";
import RouteAdm from "../../services/routeAdm";
import UserLayout from "../../components/user-page/userlayout/UserLayout";
import AdminLayout from "../../components/admin-page/adminlayout/AdminLayout";
import UserHome from "../user-page/UserHome/UserHome";
import LatestChallenges from "../user-page/LatestChallenges/LatestChallenges";
import ArchivedChallenges from "../user-page/ArchivedChallenges/ArchivedChallenges";
import MyIdeas from "../user-page/MyIdeas/MyIdeas";
import MyTeams from "../user-page/MyTeams/MyTeams";
import PersonalIdeas from "../user-page/MyIdeas/PersonalIdeas/PersonalIdeas";
import CreatePersonalIdea from "../user-page/MyIdeas/PersonalIdeas/Create/CreatePersonalIdea";
import EditPersonalIdea from "../user-page/MyIdeas/PersonalIdeas/Edit/EditPersonalIdea";
import DetailChallenge from "../user-page/LatestChallenges/DetailChallenge/DetailChallenge";
import CreateChallenge from "../user-page/LatestChallenges/Create/CreateChallenge";
import SubmitedIdea from "../user-page/LatestChallenges/SubmitedIdea/SubmitedIdea";
import DetailIdea from "../user-page/LatestChallenges/DetailIdea/DetailIdea";
import SubmitPersonal from "../user-page/MyIdeas/PersonalIdeas/Submit/SubmitPersonal";
import SearchPage from "../user-page/SearchPage/SearchPage";
import LaunchChallenge from "../admin-page/LaunchChallenge/LaunchChallenge";
import Communication from "../admin-page/Communication/Communication";
import Ideas from "../admin-page/Ideas/Ideas";
import DetailViewIdeas from "../admin-page/Ideas/DetailViewIdeas/DetailViewIdeas";
import Challenges from "../admin-page/Challenges/Challenges";
import DetailChallengesAdm from "../admin-page/Challenges/DetailChallengesAdm/DetailChallengesAdm";
import Callendar from "../admin-page/Challenges/Calendar/Calendar";
import SubmittedChallengeAdm from "../admin-page/Challenges/SubmittedChallengeAdm/SubmittedChallengeAdm";
import Overview from "../admin-page/Dashboard/Overview";
import GeneralUtility from "../../utility/GeneralUtility";
import NotFoundPage from "../../components/NotFoundPage";
import EditChallengeAdm from "../admin-page/Challenges/EditChallengeAdm/EditChallengeAdm";
import AuthUser from "../auth/Login/AuthUser";
import Profile from "../auth/Profile/Profile";
import TermsnConds from "../user-page/TermsAndCond/TermsnConds";
import DetailArcChallenge from "../user-page/ArchivedChallenges/DetailArchived/DetailArcChallenge";
import FrameBuster from "../../components/FrameBuster";
import EditPage from "../admin-page/Pages/EditPage";

const Base = () => {
  
  const role = GeneralUtility.getRole();
  const ROLE_USER = process.env.REACT_APP_ROLE_USER;
  const ROLE_ADMIN = process.env.REACT_APP_ROLE_ADMIN;

  return (
    <>
      <FrameBuster />

      <BrowserRouter>
        <Routes>
          <Route path={RouteName.login} element={<Login />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path={RouteName.authUser} element={<AuthUser />} />

          {role === ROLE_ADMIN ? (
            <Route element={<AdminLayout />}>
              <Route
                path={RouteAdm.launchChallenge}
                element={<LaunchChallenge />}
              />
              <Route path={RouteAdm.dashboard} element={<Overview />} />
              <Route
                path={RouteAdm.communication}
                element={<Communication />}
              />
              <Route path={RouteAdm.admPages} element={<EditPage />} />

              <Route path={RouteAdm.ideas} element={<Ideas />} />
              <Route
                path={RouteAdm.detailViewIdeas + "/:id"}
                element={<DetailViewIdeas />}
              />
              <Route path={RouteAdm.challenges} element={<Challenges />} />
              <Route
                path={RouteAdm.detailChallengesAdm + "/:id"}
                element={<DetailChallengesAdm />}
              />
              <Route path={RouteAdm.callendar} element={<Callendar />} />
              <Route
                path={RouteAdm.submittedChallengeAdm + "/:id"}
                element={<SubmittedChallengeAdm />}
              />
              <Route
                path={RouteAdm.editChallenge + "/:id"}
                element={<EditChallengeAdm />}
              />
              <Route path={RouteAdm.profile} element={<Profile />} />
            </Route>
          ) : (
            ""
          )}

          {role === ROLE_USER ? (
            <Route element={<UserLayout />}>
              <Route path={RouteName.searchPage} element={<SearchPage />} />
              <Route
                path={RouteName.userHome}
                element={<UserHome />}
                title="HOME"
              />
              <Route
                path={RouteName.userLatestChallenges}
                element={<LatestChallenges />}
              />
              <Route
                path={RouteName.userArchivedChallenges}
                element={<ArchivedChallenges />}
              />
              <Route
                path={RouteName.userArchivedChallenges + "/:id"}
                element={<DetailArcChallenge />}
              />
              <Route
                path={RouteName.sumbitedArcIdea + "/:id"}
                element={<SubmitedIdea />}
              />
              <Route path={RouteName.userMyIdeas} element={<MyIdeas />} />
              <Route path={RouteName.userMyTeams} element={<MyTeams />} />
              <Route
                path={RouteName.personalIdeas}
                element={<PersonalIdeas />}
              />
              <Route
                path={RouteName.createPersonal}
                element={<CreatePersonalIdea />}
              />
              <Route
                path={RouteName.editPersonal + "/:id"}
                element={<EditPersonalIdea />}
              />
              <Route
                path={RouteName.userLatestChallenges + "/:id"}
                element={<DetailChallenge />}
              />
              <Route
                path={RouteName.createIdea + "/:id"}
                element={<CreateChallenge />}
              />
              <Route
                path={RouteName.sumbitedIdea + "/:id"}
                element={<SubmitedIdea />}
              />
              {/* detail Idea */}
              <Route
                path={RouteName.detailIdea + "/:id"}
                element={<DetailIdea />}
              />
              <Route
                path={RouteName.detailViewIdea + "/:id"}
                element={<DetailIdea />}
              />
              <Route
                path={RouteName.detailArcIdea + "/:id"}
                element={<DetailIdea />}
              />
              <Route
                path={RouteName.submitPersonal + "/:id"}
                element={<SubmitPersonal />}
              />
              <Route path={RouteName.profile} element={<Profile />} />
              <Route path={RouteName.termsandcond} element={<TermsnConds />} />
            </Route>
          ) : (
            ""
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Base;
