import React, { useEffect, useState } from "react";
import RichTextTermsReadonly from "./RichTextTermsReadonly";
import "../../css/preview_terms.css";
import BtnBack from "../../../../../components/buttons/BtnBack";
const PreviewTerms = (props) => {
  return (
    <>
      <div className="m-5 w-full">
        <div>
          <BtnBack onClick={props.closeTerms} title="BACK" />
        </div>
        <div className="mt-10">
          <h1 className="mb-2 text-4xl">Terms & Conditions</h1>
        </div>
        <div id="preview-terms" className="w-full m-0">
          <RichTextTermsReadonly value={props.value} />
        </div>
      </div>
    </>
  );
};
export default PreviewTerms;
