import CallApi from "../../services/request-helper";

const API_SUBMITTED = process.env.REACT_APP_TOTALSTAGE;

const getTotalIdeas = async ({
  year,
}) => {
    const data = {};
    data.year = year;
  const url = API_SUBMITTED;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const TotalIdeasRepo = {
    getTotalIdeas,
  }
  
  export default TotalIdeasRepo;