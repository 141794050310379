import React from "react";
import Chart from "react-apexcharts";
import Donut from "../../../components/chart/Donut";
import ChartBar from "./components/ChartBar";
import ChartBarReturning from "../../../components/ChartCustom";
import ChartInternalExternal from "../../../components/ChartInternalExternal";
import DonutArchtype from "../../../components/DonutArchtype";

const Engagement = (params) => {
  
  let options = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          display: false,
          distributed: true,
        },
      },
      
      xaxis: {
        show: true,
        type: "category",
        categories: params?.numberPageViewsName,
        tooltip: {
          enabled: false,
        },
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true,
      },
      fill: {
        colors: ["#8625df", "#00c0e8", "#50b848", "#e30909", "#fff200"],
      },
    },
    series: [
      {
        name: "",
        data: params?.numberPageViewsData,
      },
    ],
  };

  return (
    <>
      {!params.onShow ? (
        <div className="pb-10" id="EngagementPage">
          <div className="flex gap-6 2xl:flex-row ml-16 xl:flex-row w-full lg:flex-col md:flex-col sm:flex-col xxs:flex-col xl:justify-between 2xl:justify-start">
            <div className="h-[360px] 2xl:w-[450px] xl:w-[450px] lg:w-[450px] md:w-[550px] sm:w-[500px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="ml-5 mt-5 flex text-sm font-Montserrat font-semibold">
                Total Users
              </div>
              {params?.totalUserName.length > 0 ? (
                <ChartInternalExternal
                  width="450"
                  height="300"
                  categories={params?.totalUserName}
                  colors={["#6649e4", "#66bf40", "#F44336"]}
                  data={params?.totalUserData}
                  optWidth={500}
                  optLegend={true}
                />
              ) : (
                <div className= "text-center pt-14" >No Data Available</div>
              )}
            </div>

            <div className="h-auto 2xl:w-[600px] xl:w-[600px] lg:w-[600px] md:w-[650px] sm:w-[600px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="ml-5 mt-5 flex text-sm font-Montserrat font-semibold">
                New Users vs Returning Users
              </div>
            
              {params?.newReturningUsersName.length > 0 ? (
                <ChartBarReturning
                  width="590"
                  height="250"
                  categories={params?.newReturningUsersName}
                  colors={["#66bf40", "#F44336"]}
                  data={params?.newReturningUsersData}
                  optWidth={undefined}
                  optLegend={true}
                  
                />
              ) : (
                <div className= "text-center pt-14" >No Data Available</div>
              )}
            </div>
          </div>

          <div className="flex gap-6 2xl:flex-row ml-16 xl:flex-row w-full lg:flex-col md:flex-col sm:flex-col xxs:flex-col xl:justify-between 2xl:justify-start pt-6">
            <div className="h-[340px] 2xl:w-[450px] xl:w-[450px] lg:w-[450px] md:w-[550px] sm:w-[500px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="ml-5 mt-5 flex text-sm font-Montserrat font-semibold">
                Users by Archetype
              </div>
              <div className="mixed-chart">
                {params?.usersByArchetypeName.length > 0 ? (
                  <DonutArchtype
                    height="300"
                    width="400"
                    labels={params?.usersByArchetypeName}
                    value={params?.usersByArchetypeData}
                    categories={params?.usersByArchetypeValue}
                    type="persentage"
                    typeLabel="row"
                  />
                ) : (
                  <div className= "text-center pt-14" >No Data Available</div>
                )}
              </div>
            </div>

            <div className="h-auto 2xl:w-[600px] xl:w-[600px] lg:w-[600px] md:w-[650px] sm:w-[600px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="ml-5 mt-5 flex text-sm font-Montserrat font-semibold">
                Number of Page Views
              </div>
              <div className="mixed-chart">
                {params?.numberPageViewsName.length > 0 ? (
                  <Chart
                    options={options.options}
                    series={options.series}
                    type="bar"
                    width="590"
                    height="250"
                  />
                ) : (
                  <div className= "text-center pt-14" >No Data Available</div>
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-6 2xl:flex-row ml-16 xl:flex-row w-full lg:flex-col md:flex-col sm:flex-col xxs:flex-col xl:justify-between 2xl:justify-start pt-6">
            <div className="h-[360px] 2xl:w-[450px] xl:w-[450px] lg:w-[450px] md:w-[550px] sm:w-[500px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="ml-5 mt-5 flex text-sm font-Montserrat font-semibold">
                Communication
              </div>
              {params?.communicationName.length > 0 ? (
                <ChartBar
                  width="450"
                  height="220"
                  categories={params?.communicationName}
                  colors={["#66bf40", "#F44336", "#6649e4"]}
                  data={params?.communicationData}
                  optWidth={150}
                  optLegend={true}
                />
              ) : (
                <div className= "text-center pt-14" >No Data Available</div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="pb-10 mx-auto w-[1280px]" id="EngagementPage">
          <div className="flex gap-6 flex-row ml-16 w-full justify-start">
            <div className="h-[360px] w-[450px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="ml-5 mt-5 flex text-sm font-Montserrat font-semibold">
                Total Users
              </div>
              {params?.totalUserName.length > 0 ? (
                <ChartBar
                  width="450"
                  height="220"
                  categories={params?.totalUserName}
                  colors={["#6649e4", "#66bf40", "#F44336"]}
                  data={params?.totalUserData}
                  optWidth={150}
                  optLegend={false}
                />
              ) : (
                <div className= "text-center pt-14" >No Data Available</div>
              )}
            </div>

            <div className="h-auto w-[600px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="ml-5 mt-5 flex text-sm font-Montserrat font-semibold">
                New Users vs Returning Users
              </div>
              {params?.newReturningUsersName.length > 0 ? (
                <ChartBar
                  width="590"
                  height="250"
                  categories={params?.newReturningUsersName}
                  colors={["#66bf40", "#F44336"]}
                  data={params?.newReturningUsersData}
                  optWidth={undefined}
                  optLegend={true}
                />
              ) : (
                <div className= "text-center pt-14" >No Data Available</div>
              )}
            </div>
          </div>

          <div className="flex gap-6 flex-row ml-16 w-full 2xl:justify-start pt-6">
            <div className="h-[340px] w-[450px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="ml-5 mt-5 flex text-sm font-Montserrat font-semibold">
                Users by Archetype
              </div>
              <div className="mixed-chart">
                {params?.usersByArchetypeName.length > 0 ? (
                  <Donut
                    height="300"
                    width="400"
                    labels={params?.usersByArchetypeName}
                    value={params?.usersByArchetypeData}
                    type="persentage"
                    typeLabel="row"
                  />
                ) : (
                  <div className= "text-center pt-14" >No Data Available</div>
                )}
              </div>
            </div>

            <div className="h-auto w-[600px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="ml-5 mt-5 flex text-sm font-Montserrat font-semibold">
                Number of Page Views
              </div>
              <div className="mixed-chart">
                {params?.numberPageViewsName.length > 0 ? (
                  <Chart
                    options={options.options}
                    series={options.series}
                    type="bar"
                    width="590"
                    height="250"
                  />
                ) : (
                  <div className= "text-center pt-14" >No Data Available</div>
                )}
              </div>
            </div>
          </div>
          <br /><br /><br /><br /><br />
          <div className="flex gap-6 flex-row ml-16 w-full justify-start pt-14">
            <div className="h-[360px] w-[450px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <div className="ml-5 mt-5 flex text-sm font-Montserrat font-semibold">
                Communication
              </div>
              {params?.communicationName.length > 0 ? (
                <ChartBar
                  width="450"
                  height="220"
                  categories={params?.communicationName}
                  colors={["#66bf40", "#F44336", "#6649e4"]}
                  data={params?.communicationData}
                  optWidth={150}
                  optLegend={false}
                />
              ) : (
                <div className= "text-center pt-14" >No Data Available</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Engagement;
