import CallApi from "../../services/request-helper";

const API_TNC = process.env.REACT_APP_TERMS_AND_COND;

const getTermsCondition = async () => {
    const url = API_TNC;
    const response = await CallApi({ url, method: "GET" });
    const data = response !== undefined ? response : null;
    return data;
}

const TermCondRepo = {
    getTermsCondition,
}

export default TermCondRepo;