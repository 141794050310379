import React from "react";
import Chart from "react-apexcharts";
import Pie from "../../../components/chart/Pie";
import Donut from "../../../components/chart/Donut";
import Column from "../../../components/chart/Column";
import Leaderboard from "./components/Leaderboard";
import TotalPoints from "./components/TotalPoints";
import GeneralUtility from "../../../utility/GeneralUtility";

const TabOverview = ({
  props,
  options,
  option,
  tempIncrease,
  tempIncreaseChallange,
  tempPercent,
  tempStatisticCurrent,
  tempName,
  tempStatisticPrevious,
  tempValue,
  tempStatisticMonth,
  tempUser,
  current_year,
  previous_year
}) => {


  return (
    <>
      {!props.onShow ? (
        <div
          className={`container max-h-[680px] max-w-[1280px]`}
          id={props.rootId}
        >
          <div className="flex xxs:flex-col xs:flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row">
            <div className="ml-16 mt-[-20px] xxs:mb-10 xxs:h-[280px] xs:h-[280px] sm:h-[300px] md:h-[280px] lg:h-fill xxs:w-[330px] xs:w-[365px] sm:w-[390px] md:w-[380px] lg:w-[400px] xl:w-[400px]  bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <span className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                Total Number of Ideas
              </span>
              {props?.ideasData?.length > 0 ? (
                <>
                  <div className="mixed-chart">
                    <Chart
                      options={options.options}
                      series={options.series}
                      type="bar"
                      width="350"
                      height="160"
                    />
                  </div>
                  <div className="flex flex-row mt-[-30px]">
                    <>
                      <img
                        src="/assets/global/img/increaseIndicator.svg"
                        className={`h-8 w-8 stroke-black mt-1 mx-2 ${GeneralUtility.isInCreaseIndicator(props?.ideasData)}`}
                        alt="icon-arrow"
                      />
                      <div className="flex flex-col">
                        <span className="text-xs">{` ${GeneralUtility.isInCreaseIndicatorBoolean(props?.ideasData) ? '-' : '+'}  ${tempIncrease} (current year vs last year)`}</span>
                        <span className="text-[12px]">Idea {GeneralUtility.isInCreaseIndicatorBoolean(props?.ideasData) ? 'decrease' : 'increase'} </span>
                      </div>
                    </>
                  </div>
                </>
              ) : (
                <div className="h-[250px] w-auto font-Montserrat flex justify-center pt-24">
                  No Data Available
                </div>
              )}
            </div>
            <div className="2xl:ml-8 lg:ml-4 xxs:ml-16 mt-[-20px] xxs:mb-10 xxs:h-[280px] xs:h-[280px] sm:h-[300px] md:h-[280px] lg:h-fill xxs:w-[330px] xs:w-[365px] sm:w-[390px] md:w-[380px] lg:w-[400px] xl:w-[400px]  bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <span className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                Total Number of Challenges
              </span>
              {props?.ideasByStages?.length > 0 ? (
                <>
                  <div className="mixed-chart">
                    <Chart
                      options={option.options}
                      series={option.series}
                      type="bar"
                      width="350"
                      height="160"
                    />
                  </div>
                  <div className="flex flex-row mt-[-30px]">
                    <>
                      <img
                        src="/assets/global/img/increaseIndicator.svg"
                        className={`h-8 w-8 stroke-black mt-1 mx-2 ${GeneralUtility.isInCreaseIndicator(props?.challange)}`}
                        alt=""
                      />
                      <div className="flex flex-col">
                        <span className="text-xs">{`${GeneralUtility.isInCreaseIndicatorBoolean(props?.challange) ? '-' :'+'} ${tempIncreaseChallange} (current year vs last year)`}</span>
                        <span className="text-[12px]">Challenge {GeneralUtility.isInCreaseIndicatorBoolean(props?.challange) ? 'decreases' :'increases'} </span>
                      </div>
                    </>
                  </div>
                </>
              ) : (
                <div className="h-[200px] w-auto font-Montserrat flex justify-center pt-24">
                  No Data Available
                </div>
              )}
            </div>
            <div className="2xl:ml-8 lg:ml-4 mt-[-20px] xxs:ml-16 xxs:mb-10 xxs:h-[300px] xs:h-[300px] sm:h-[330px] md:h-[300px] lg:h-auto xxs:w-[330px] xs:w-[365px] sm:w-[390px] md:w-[380px] lg:w-[400px] xl:w-[400px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <span className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                Type of Users
              </span>
              {tempPercent.length > 0 ? (
                <Pie
                  height="230"
                  width="230"
                  labels={tempUser}
                  value={tempPercent}
                />
              ) : (
                <div className="h-[230px] w-auto font-Montserrat flex justify-center pt-24">
                  No Data Available
                </div>
              )}
            </div>
          </div>
          <div className="flex xxs:flex-col xs:flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row pb-8">
            <div className="">
              <div className="ml-16 mt-[20px] [&::-webkit-scrollbar]:hidden xxs:overflow-scroll xxs:ml-16 xxs:mb-10 xxs:h-[300px] xs:h-[300px] sm:h-[300px] md:h-[300px] lg:h-auto xxs:w-[830px] xs:w-[830px] sm:w-[830px] md:w-[830px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
                <span className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                  Monthly Number of Ideas Submitted
                </span>
                <div className="mixed-chart">
                  <Column
                    height="370"
                    width="800"
                    labels={tempStatisticMonth}
                    name1={current_year}
                    name2={previous_year}
                    legend={"top"}
                    from={"Overview"}
                    // value={[30, 40, 45, 50, 49, 60, 70, 91, 88, 20, 32, 40]}
                    current={tempStatisticCurrent}
                    previous={tempStatisticPrevious}
                  />
                </div>
              </div>
            </div>
            <div className="2xl:ml-4 mt-[20px] xxs:ml-16 xxs:mb-10 h-auto xxs:w-[340px] xs:w-[365px] sm:w-[390px] md:w-[380px] lg:w-[450px] xl:w-[380px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <span className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                Ideas by Stages
              </span>
              {props?.ideasByStages?.length > 0 ? (
                <Donut
                  height="300"
                  width="400"
                  labels={tempName}
                  value={tempValue}
                />
              ) : (
                <div className="h-[250px] w-auto font-Montserrat flex justify-center pt-24">
                  No Data Available
                </div>
              )}
            </div>
          </div>
          <Leaderboard onShow={props.onShow} />
          <div className="ml-16 pb-16">
            <TotalPoints year={props.sortByYear} />
          </div>
        </div>
      ) : (
        <div className={`h-[680px] w-[1280px]`} id={props.rootId}>
          <div className="flex flex-row">
            <div className="ml-16 h-fill w-[350px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <span className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                Total Number of Ideas
              </span>
              {props?.ideasData?.length > 0 ? (
                <>
                  <div className="mixed-chart">
                    <Chart
                      options={options.options}
                      series={options.series}
                      type="bar"
                      width="340"
                      height="150"
                    />
                  </div>
                  <div className="flex flex-row mt-[-30px]">
                    <>
                      <img
                        src="/assets/global/img/increaseIndicator.svg"
                        className={`h-8 w-8 stroke-black mt-1 mx-2`}
                        alt=""
                      />
                      <div className="flex flex-col">
                        <span className="text-xs">{`${tempIncrease} (current year - last year)`}</span>
                        <span className="text-[12px]">Idea increases</span>
                      </div>
                    </>
                  </div>
                </>
              ) : (
                <div className="h-[250px] w-auto font-Montserrat flex justify-center pt-24">
                  No Data Available
                </div>
              )}
            </div>
            <div className="ml-4 h-fill w-[350px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <span className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                Total Number of Challenges
              </span>
              {props?.ideasByStages?.length > 0 ? (
                <>
                  <div className="mixed-chart">
                    <Chart
                      options={option.options}
                      series={option.series}
                      type="bar"
                      width="340"
                      height="150"
                    />
                  </div>
                  <div className="flex flex-row mt-[-30px]">
                    <>
                      <img
                        src="/assets/global/img/increaseIndicator.svg"
                        className={`h-8 w-8 stroke-black mt-1 mx-2`}
                        alt=""
                      />
                      <div className="flex flex-col">
                        <span className="text-xs">{`${tempIncreaseChallange} (current year - last year)`}</span>
                        <span className="text-[12px]">Challenge increases</span>
                      </div>
                    </>
                  </div>
                </>
              ) : (
                <div className="h-[200px] w-auto font-Montserrat flex justify-center pt-24">
                  No Data Available
                </div>
              )}
            </div>
            <div className="ml-4 h-auto w-[350px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <span className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                Type of Users
              </span>
              {tempPercent.length > 0 ? (
                <Pie
                  height="230"
                  width="230"
                  labels={tempUser}
                  value={tempPercent}
                />
              ) : (
                <div className="h-[230px] w-auto font-Montserrat flex justify-center pt-24">
                  No Data Available
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row pb-8">
            <div className="overflow-x-auto [&::-webkit-scrollbar]:hidden">
              <div className="ml-16 mt-[20px] [&::-webkit-scrollbar]:hidden h-auto w-[700px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
                <span className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                  Monthly Number of Ideas Submitted
                </span>
                <div className="mixed-chart">
                  <Column
                    height="370"
                    width="700"
                    labels={tempStatisticMonth}
                    name1={current_year}
                    name2={previous_year}
                    legend={"top"}
                    from={"Overview"}
                    value={[30, 40, 45, 50, 49, 60, 70, 91, 88, 20, 32, 40]}
                    current={tempStatisticCurrent}
                    previous={tempStatisticPrevious}
                  />
                </div>
              </div>
            </div>
            <div className="mt-[20px] h-auto w-[400px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
              <span className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                Ideas by Stages
              </span>
              {props?.ideasByStages?.length > 0 ? (
                <Donut
                  height="300"
                  width="400"
                  labels={tempName}
                  value={tempValue}
                  onShow={props.onShow}
                />
              ) : (
                <div className="h-[250px] w-auto font-Montserrat flex justify-center pt-24">
                  No Data Available
                </div>
              )}
            </div>
          </div>
          <br />
          <br /><br />
          <br /><br />
          <div className="pt-20 w-[1280px] mt-32">
            <Leaderboard onShow={props.onShow} />
          </div>
          <div className="ml-16">
            <TotalPoints onShow={props.onShow} year={props.sortByYear2} />
          </div>
        </div>
      )}
    </>
  );
};

export default TabOverview;
