import React, { useRef } from 'react'
import AlertUtility from '../utility/alert-utility';

const DragDropSq = ({ docs, setDocs }) => {
    const inputRef = useRef(null);

    // delete links row links
    const deleteFile = (index, id) => {
        let data = [...docs];
        if (id === null) {
            data.splice(index, 1);
            setDocs(data);
        } else {
            let obj = data.find((o, i) => {
                if (o.id === id) {
                    data[i] = { id: o.id, source: null };
                    return true; // stop searching
                }
            });
            setDocs(data)
        }

    };

    /**
     * 
     * @param {*} source 
     * @param {*} name 
     */
    const fileDownload = async (source, name) => {
        const downloadLink = document.createElement("a");
        downloadLink.target = "_blank"
        downloadLink.href = source;
        downloadLink.download = name;
        downloadLink.click();
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };


    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i].size < 5000000) {
                    const array = e.target.files[i].name.split(".");
                    const result = array.pop();
                    setDocs(docs => [...docs, { id: null, source: e.target.files[i], filename: e.target.files[i].name, type_Document: result }])
                } else {
                    AlertUtility.errorAlert({ title: `File ` + e.target.files[i].name + ` More Than 5 Mb.` })
                }
            }
        }
    };

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 items-center">
                {docs.map((items, index) => (
                    <div key={index} className={`flex border border-gray-300 h-12 ${items.source === null ? 'hidden' : ""}`}>
                        <div
                            onClick={() => fileDownload(items.source, items.filename)}
                            className="flex p-1 items-center w-full space-x-3 cursor-pointer"
                        >
                            <img
                                src={` ${items.type_Document === "pdf"
                                    ? "/assets/global/img/pdfIco.svg"
                                    : ""
                                    } ${(items.type_Document === "pptx") || (items.type_Document === "ppt")
                                        ? "/assets/global/img/pptxIco.svg"
                                        : ""
                                    } ${(items.type_Document === "xlsx") || (items.type_Document === "xls") || (items.type_Document === "csv")
                                        ? "/assets/global/img/xlsxIco.svg"
                                        : ""
                                    } ${(items.type_Document === "docx") || (items.type_Document === "doc")
                                        ? "/assets/global/img/docxIco.svg"
                                        : ""
                                    } ${(items.type_Document === "png") || (items.type_Document === "jpg") || (items.type_Document === "jpeg")
                                        ? "/assets/global/img/imageIco.png"
                                        : ""
                                    }`}
                                className="h-8 w-8"
                                alt="ico"
                            />
                            <div className="text-xs text-gray-500">
                                <h6 className="font-semibold">{items.filename ? (items.filename.length > 30 ? items.filename.substring(0, 20) + ".." + items.type_Document : items.filename) : ""}</h6>
                            </div>
                        </div>
                        <img
                            className="w-2 h-2 mr-2 my-2 cursor-pointer"
                            onClick={e => deleteFile(index, items.id)}
                            src="/assets/global/img/close.svg"
                            alt="close"
                        />
                    </div>
                ))}
            </div>
            <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} accept=".jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx" />
            <button className="upload-button inline-flex" onClick={onButtonClick}>
                <img
                    className="cursor-pointer w-14 h-14"
                    src="/assets/global/img/squarePlus.png"
                    alt="add"
                />
            </button>
        </>
    )
}

export default DragDropSq;
