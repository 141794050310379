import React, { useState, useEffect } from 'react'
import '../LaunchChallenge.css'
import Swal from 'sweetalert2';
import LaunchRepo from '../../../../repositories/admin-repo/LaunchRepo'
import ConfirmPopup from '../../../../components/ConfirmPopup';
import RouteAdm from '../../../../services/routeAdm';
import AlertUtility from '../../../../utility/alert-utility';
import BtnSave from '../../../../components/buttons/BtnSave';
import BtnSubmit from '../../../../components/buttons/BtnSubmit';
import BtnBack from '../../../../components/buttons/BtnBack';
import GeneralUtility from '../../../../utility/GeneralUtility';

const Launch = ({ info, form, evl, error, setError, start_date, setStartDate, setPage }) => {
  const [showPub, setShowPub] = useState(false);
  const [showDraft, setShowDraft] = useState(false);
  let challinfo = { ...info, ...form, ...evl, start_date };

  const validate = () => {
    const errors = {}
    if (!challinfo.card_image) {
      errors.card_image = "Card image is required! "
    }
    if (!challinfo.banner_image) {
      errors.banner_image = "Banner image is required! "
    }
    if (!challinfo.title) {
      errors.title = "Title is required! "
    }
    if (!challinfo.overview) {
      errors.overview = "Challenge overview is required! "
    }
    if (!challinfo.category) {
      errors.category = "Type is required! "
    }
    if (!challinfo.start_date) {
      errors.start_date = "Start date is required!"
    }
    if (!challinfo.end_date) {
      errors.end_date = "End date is required!"
    }
    if (!challinfo.statusprivacy) {
      errors.privacy = "Select privacy!"
    }
    if (!challinfo.typeidea.id) {
      errors.typeidea = "Select team!"
    }
    if (!challinfo.category.id) {
      errors.category = "Select type!"
    }
    if (challinfo.rewards) {
      for (let index = 0; index < challinfo.rewards.length; index++) {
        if (!challinfo.rewards[index].title) {
          errors.step = "Field cannot be blank!"
        }
        if (!challinfo.rewards[index].reward) {
          errors.reward = "Field cannot be blank!"
        }
        if (!challinfo.rewards[index].description) {
          errors.description = "Field cannot be blank!"
        }
      }
    }
    if (challinfo.questions) {
      for (let index = 0; index < challinfo.questions.length; index++) {
        if (!challinfo.questions[index].question) {
          errors.question = "Input at least 1 question"
        }
      }
    }
    if (!challinfo.questions) {
      errors.question = "Input at least 1 question"
    }
    if (challinfo.managerchallange.length === 0) {
      errors.managerchallange = "Select evaluators !"
    }
    if (!challinfo.evaluation_day) {
      errors.evaluation_day = "Evaluation day is required"
    }
    if (!challinfo.card_image || !challinfo.banner_image || !challinfo.title || !challinfo.category || !challinfo.overview || !challinfo.start_date || !challinfo.end_date || !challinfo.statusprivacy || !challinfo.typeidea.id || !challinfo.rewards || !challinfo.category.id) {
      errors.info = "Error Info"
    }

    if (!challinfo.evaluation_day || challinfo.managerchallange.length === 0) {
      errors.evaluator = "Error evaluator"
    }


    setError(errors)
    return errors;
  }

  /**
 * funtion to handle save as draft challenge
 */
  const handleSaveDraft = async () => {
    setShowDraft(false);
    let data = { ...info, ...form, ...evl, start_date, drafted: true }
    AlertUtility.loadingAlert();
    const result = await LaunchRepo.postDraftedChallenge(data);
    if (result.status === 200) {
      Swal.close();
      AlertUtility.successAlert({ title: "Your challenge has been saved as a draft." })
      window.location = RouteAdm.launchChallenge;
    } else if (result.status === 500) {
      Swal.close();
      AlertUtility.errorAlert ({title:"There was an error trying to save as a draft. Please try again later."})
    } else if (result.status === 401) {
      Swal.close();
      GeneralUtility.getNewToken();
    } else {
      Swal.close();
    }
  }

  /**
   * funtion to handle publish
   */
  const handleSavePublish = async () => {
    setShowPub(false);
    let err = validate();
    //for drafted before publish
    if (Object.keys(err).length === 0) {
      let data = { ...info, ...form, ...evl, drafted: false }
      AlertUtility.loadingAlert();
      const result = await LaunchRepo.postChallenge(data);
      if (result.status === 200) {
        Swal.close();
        AlertUtility.successAlert({ title: "Your challenge has been published." })
        window.location = RouteAdm.challenges;
      } else if (result.data.errors) {
        Swal.close();
        AlertUtility.errorAlert({ title: error })
      } else if (result.status === 401) {
        Swal.close();
        GeneralUtility.getNewToken();
      } else {
        Swal.close();
        AlertUtility.errorAlert({ title: result.data.message })
      }
    } else if (Object.keys(err).length > 0) {
      AlertUtility.errorAlert({ title: "Technical error detected. Please try again. If the issue persists, contact your system administrator." });
      let data = { ...info, ...form, ...evl, start_date, drafted: true }
      const res = await LaunchRepo.postDraftedChallenge(data);
    }
  }

  useEffect(() => {
  }, [])

  return (
    <>
      <div className='space-y-3'>
        <h3 className='text-textBlack flex font-semibold pt-3 text-md'>Confirm Launch Date</h3>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
          <div>
            <input
              autoComplete="off"
              value={start_date}
              onChange={e => setStartDate(e.target.value)}
              className='w-full basis-1/2 border-none shadow-md'
              type="date"
              name="launch_date"
              id="launch_date"
            />
          </div>
          <div className='flex space-x-2 lg:justify-self-end'>
            <BtnBack onClick={() => setPage("evaluators")} title="Back" />
            <BtnSubmit onClick={() => setShowDraft(true)} title="Save as draft" />
            <BtnSave onClick={() => setShowPub(true)} title="Publish" />
          </div>
        </div>
      </div>

      <ConfirmPopup show={showPub} setShow={setShowPub} onSave={handleSavePublish} message="Are you sure you want to publish this challenge?" />
      <ConfirmPopup show={showDraft} setShow={setShowDraft} onSave={handleSaveDraft} message="This challenge will be saved as a draft" />
    </>
  )
}

export default Launch
