import React, { useEffect, useState, useRef } from "react";
import Skeleton from "../../../../components/skeleton/Skeleton";
import AlertUtility from "../../../../utility/alert-utility";
import ButtonGroupEdit from "./Button/ButtonGroupEdit";

const Banner = ({
  img,
  setImg,
  banner,
  setBanner,
  loading,
  type,
  name,
  defaultBanner,
  showModePreview,
  setPrevData,
  fileToBase64,
  setEditStatus,
  editStatus,
}) => {
  const [isUpload, setIsUpload] = useState(false);
  const uploadImageRef = useRef(null);

  /**
   * function to handle delete
   *
   */
  const handleDelete = () => {
    setImg(defaultBanner.data.banner);
    setBanner(defaultBanner.data.banner);
  };

  /**
   * function to handle change
   * @param {e}
   */
  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size < 5000000) {
      if (file) {
        try {
          const base64 = await fileToBase64(file);
          setImg(base64);
        } catch (error) {
          console.error("Error converting file to base64:", error);
        }
      }
      setIsUpload(true);
      setBanner(file);
    } else {
      AlertUtility.errorAlert({ title: "File more than 5 Mb" });
    }
  };

  const hitImage = async () => {
    setImg(img ? img : banner === null ? "" : banner);
  };

  const handleEdit = () => {
    uploadImageRef.current.click();
  };
  const setPrevImage = () => {
    if (isUpload) {
      setPrevData();
      setIsUpload(false);
    }
  };
  useEffect(() => {
    setPrevImage();
    hitImage();
  });
  return (
    <>
      {!loading ? (
        <>
          <div className="relative top-3 mb-3 tooltip">
          <span className="tooltiptext">Image ratio : 1400 x 300 pixels. Max file : 5 MB</span>
            {showModePreview ? (
              ""
            ) : (
              <div className="flex z-[6] absolute -top-3 left-6">
                <ButtonGroupEdit
                  onClickDelete={handleDelete}
                  onClickEdit={handleEdit}
                />
              </div>
            )}

            <div
              className="c-image-container flex items-center bg-gray-200 z-[-2] overflow-hidden justify-center tooltip"
              style={{
                backgroundImage: "url(" + img + ")"
              }}
            >
              <img
                className={`${
                  type === "homepage"
                    ? "max-h-[300px] w-full"
                    : "h-[150px] md:h-[250px] lg:h-[300px] xl:h-[350px] w-full"
                } overflow-hidden bg-gray-200 m-auto`}
                src={img}
                alt="bannerimage"
              />
            
              <div className="c-text-overlay w-full text-center font-bold text-xl md:text-2xl text-white"></div>
            </div>
            <input
              style={{ display: "none" }}
              ref={uploadImageRef}
              autoComplete="off"
              type="file"
              className="hidden"
              name={name}
              accept=".jpeg, .png, .jpg"
              onChange={(e) => handleUpload(e)}
            />
          </div>
        </>
      ) : (
        <div className="c-image-container">
          <Skeleton col={1} height={300} />
        </div>
      )}
    </>
  );
};

export default Banner;
