import React from 'react'
import { useState } from 'react'

const DropdownData = ({ title, onChange, resList, selected }) => {
    const [open, setOpen] = useState(false);
    const find = resList.find(item => item.id === selected);

    return (
        <div className={`items-center bg-white h-[35px] ${open ? "2xl:h-auto" : "2xl:h-[30px]"} w-24 lg:w-44 py-2 border text-[10px] z-10 bx_filter_sortby`}>
            <div className='flex items-center flex-row cursor-pointer h-[20px] justify-between w-fill font-Montserrat' onClick={() => setOpen(!open)}>
                <span className='uppercase px-2'>{title} {find?.name || ""} </span>
                <img
                    src="/assets/global/img/up-arrow.svg"
                    className={`h-2 w-4 stroke-black ${open ? "transition-width duration-300 easy rotate-0" : "transition-width duration-300 easy rotate-180"}`}
                    alt=""
                />
            </div>
            {open ?
                (
                    <div className="bg-white space-y-2 mt-3 py-3 bx_open_filter_latest">
                        {resList && resList.map((res) => {
                            return (
                                <div key={res.id} className='transition-opacity ease-in duration-500 flex flex-row uppercase cursor-pointer hover:font-semibold' onClick={() => onChange(res)} >
                                    <img
                                        src={`${selected === res.id || selected === res.name ? "/assets/global/img/toggle - check.svg" : "/assets/global/img/toggle.svg"}`}
                                        className={`h-4 w-6 stroke-black`}
                                        alt="selected"
                                        onClick={() => onChange(res)}
                                    />
                                    <p className='text-[10px]' onClick={() => onChange(res)}>{res.name ? res.name : ""}</p>
                                </div>
                            )
                        })}
                    </div>
                ) : ""
            }
        </div>
    )
}

export default DropdownData