import React, { useState } from "react";
import RichText from "../../../components/RichText";
import NotificationRepo from "../../../repositories/admin-repo/NotificationRepo";
import AlertUtility from "../../../utility/alert-utility";
import Swal from "sweetalert2";
import BtnSave from "../../../components/buttons/BtnSave";
import Skeleton from "../../../components/skeleton/Skeleton";
import { useEffect } from "react";
import GeneralUtility from "../../../utility/GeneralUtility";
import RichTextQuill from "../../../components/RichTextQuill";
import RichTextEditor from "../../../components/RichTextEditor";

const Notification = () => {
  const [selectedTab, setSelectedTab] = useState("Stage Update");
  const [invMsg, setInvMsg] = useState([]);
  const [challengeMsg, setChallengeMsg] = useState([]);
  const [listSubmenu, setListSubmenu] = useState([]);
  const [listContent, setListContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const fetchNotificationTab = async () => {
    setLoading(true);
    const responseNotifList = await NotificationRepo.getNotificationList();
    if (
      responseNotifList.status === 200 &&
      responseNotifList.data !== undefined
    ) {
      setListSubmenu(responseNotifList.data.data);
      fetchData(responseNotifList.data.data[0].id);

    }
    setLoading(false);
  };

  const fetchData = async (id) => {
    setLoadingData(true);
    if (id !== undefined) {
      const responseDetailList = await NotificationRepo.getDetailList(id);
      if (responseDetailList.data !== undefined) {
        setListContent(responseDetailList.data.data);
      }
    }
    setLoadingData(false);
  };

  const handleChange = (arr, content) => {

    setInvMsg((prevInvMsg) =>
      prevInvMsg.map((msg) =>
        msg.id === arr.id ? { ...msg, message: content } : msg
      )
    );

    setListContent((prevInvMsg) =>
      prevInvMsg.map((msg) =>
        msg.id === arr.id ? { ...msg, message: content } : msg
      )
    );

  };
  

  const renderView = () => {
    switch (selectedTab) {
      case "Stage Update":
        return (
          <>
            {loadingData ? (
              <>
                <div className="space-y-3 w-[89%] mb-5">
                  <h3 className="text-textBlack flex font-semibold pt-3 text-md ">
                    Loading...
                  </h3>
                  <div
                    className={`flex items-center justify-center h-[30px] bg-gray-300 animate-pulse mt-3 `}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                  <Skeleton col={1} height={300} />
                </div>
                <div className="space-y-3 w-[89%] mb-5">
                  <h3 className="text-textBlack flex font-semibold pt-3 text-md ">
                    Loading...
                  </h3>
                  <div
                    className={`flex items-center justify-center h-[30px] bg-gray-300 animate-pulse mt-3 `}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                  <Skeleton col={1} height={300} />
                </div>
              </>
            ) : (
              <>
                {listContent?.map((arr, index) => (
                  <>
                    <div
                      key={index}
                      className="space-y-3 w-[89%] mb-5"
                      id={`${index}`}
                    >
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md ">
                        {arr.title}
                      </h3>
                      <input
                        autoComplete="off"
                        type="text"
                        className="border-none shadow-md h-10 w-full text-textGray"
                        key={`${arr.subjectTitle}`}
                        defaultValue={`${
                          arr.subjectTitle === null ? "" : arr.subjectTitle
                        }`}
                        onChange={(event) =>
                          setChallengeMsg([
                            ...challengeMsg,
                            {
                              id: arr.id,
                              title: event.target.value,
                              message: arr.message,
                            },
                          ])
                        }
                      />
                      <RichTextEditor
                        value={arr.message}
                        onChange={(content) => {
                            handleChange(arr,content)
                        }}
                        //initialValue={arr.message}
                      />
                    </div>
                  </>
                ))}
              </>
            )}
          </>
        );
      case "Status Update":
        return (
          <>
            {loadingData ? (
              <>
                <div className="space-y-3 w-[89%] mb-5">
                  <h3 className="text-textBlack flex font-semibold pt-3 text-md ">
                    Loading...
                  </h3>
                  <div
                    className={`flex items-center justify-center h-[30px] bg-gray-300 animate-pulse mt-3 `}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                  <Skeleton col={1} height={300} />
                </div>
                <div className="space-y-3 w-[89%] mb-5">
                  <h3 className="text-textBlack flex font-semibold pt-3 text-md ">
                    Loading...
                  </h3>
                  <div
                    className={`flex items-center justify-center h-[30px] bg-gray-300 animate-pulse mt-3 `}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                  <Skeleton col={1} height={300} />
                </div>
              </>
            ) : (
              <>
                {listContent?.map((arr, index) => (
                  <>
                    <div
                      key={index}
                      className="space-y-3 w-[89%] mb-5"
                      id={`${index}`}
                    >
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md ">
                        {arr.title}
                      </h3>
                      <input
                        autoComplete="off"
                        type="text"
                        className="border-none shadow-md h-10 w-full text-textGray"
                        key={`${arr.subjectTitle}`}
                        defaultValue={`${
                          arr.subjectTitle === null ? "" : arr.subjectTitle
                        }`}
                        onChange={(event) =>
                          setChallengeMsg([
                            ...challengeMsg,
                            {
                              id: arr.id,
                              title: event.target.value,
                              message: arr.message,
                            },
                          ])
                        }
                      />
                      <RichTextEditor
                        value={arr.message}
                        onChange={(content) => {
                          handleChange(arr,content)
                        }}
                      />
                    </div>
                  </>
                ))}
              </>
            )}
          </>
        );
      default:
        break;
    }
  };

  const save = async () => {
    const ids = invMsg.map((o) => o.id);
    const filtered = invMsg.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    let tempArray = [];
    let final = [];
    listContent?.map((res, index) => {
      const filteredFinal = challengeMsg.filter((arr) => arr.id === res.id);
      if (filteredFinal.length > 0) {
        tempArray.push(filteredFinal.pop());
      } else {
        const filteredData = filtered.filter((arr) => arr.id === res.id);
        tempArray.push(...filteredData);
      }
    });

    // eslint-disable-next-line
    const removeUndefined = tempArray.map((res, index) => {
      if (res !== undefined) {
        final.push(res);
      } else {
        return false;
      }
    });

    let dataToPush = [];
    final?.map((arr, id) => {
      const findData = filtered.find(
        (data) => data.id === arr.id && arr.id !== undefined
      );
      if (findData !== undefined) {
        arr.message = findData.message;
        dataToPush.push(arr);
      } else {
        dataToPush.push(arr);
      }
    });

    let body = { update: dataToPush };
    const responseUpdateNotif = await NotificationRepo.updateNotification(body);
    if (responseUpdateNotif.status === 200) {
      Swal.close();
      AlertUtility.successAlert({ title: "Your changes have been saved" });
    } else if (responseUpdateNotif.status === 401) {
      GeneralUtility.getNewToken();
    } else {
      Swal.close();
      AlertUtility.errorAlert({ title: responseUpdateNotif.data.message });
    }
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    fetchNotificationTab();
  }, []);

  return (
    <div className="container ml-16">
      <div className="flex flex-row gap-2">
        {loading ? (
          <>
            <div
              className={`h-10 w-[180px] py-2 px-2 mt-[-40px] border-solid border-2 border-[#50B848]`}
            ></div>
            <div
              className={`h-10 w-[180px] py-2 px-2 mt-[-40px] border-solid border-2 border-[#50B848]`}
            ></div>
          </>
        ) : (
          <>
            {listSubmenu?.map((res, index) => (
              <div
                key={index}
                className={`flex flex-row h-10 w-[180px] justify-center cursor-pointer py-2 px-2 mt-[-40px] ${
                  selectedTab === res.name
                    ? "bg-gradient-to-r from-[#44ad5c] via-[#66bf40] to-[#85c240] text-white"
                    : "border-solid border-2 border-[#50B848] text-[#50B848]"
                } font-Montserrat-medium`}
                onClick={() => [
                  setSelectedTab(res.name),
                  fetchData(res.id),
                  setInvMsg([]),
                ]}
              >
                {res.name}
              </div>
            ))}
          </>
        )}
      </div>
      <div>
        {renderView()}
        <div className="pt-10 w-[120px]">
          {loading ? (
            <BtnSave title="Save" />
          ) : (
            <BtnSave onClick={() => save()} title="Save" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;
