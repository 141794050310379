import React from "react";

const TotalIdeas = ({ listTotalIdeas, loading }) => {
  return (
    <>
      {!loading ?
        <div className="boxTotalIdeaAdmin w-[22em] sm:w-[37em] md:w-[45em] lg:w-full overflow-x-auto" >
          <div id="listTotalIdeaProc" className="bg-baseGray w-[50em] md:w-full lg:w-full p-4 space-y-2">
            <h3 className="text-black font-xs font-semibold">Total Ideas Submitted At Each Stage</h3>
            <div className="flex space-x-2">
              {listTotalIdeas.map((items, index) => (
                <div key={index} className="space-y-3 basis-1/6">
                  <h4 className="text-freshGreen text-md uppercase font-semibold">
                    STAGE {index + 1}
                  </h4>
                  <div className="flex items-start space-x-2">
                    <div className="h-5 w-5">
                      <img
                        src={`data:image/jpg;base64, ${items.icon}`}
                        alt="icon_name"
                      />
                    </div>
                    <p className="text-heritageGreen text-sm font-semibold ">{items.name === "Implementation" ? "Commercialisation" : items.name}</p>
                  </div>
                  <div className="w-full h-0.5 bg-freshGreen"></div>
                  <div className="flex space-x-2">
                    <p className="text-freshGreen text-sm"> {items.total_idea} </p>
                    <p className="text-black text-sm font-semibold">
                      {items.info}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        :
        <div className="boxTotalIdeaAdmin w-[22em] sm:w-[37em] md:w-[45em] lg:w-full overflow-x-auto" >
          <div id="listTotalIdeaProc" className="bg-baseGray w-[50em] md:w-full lg:w-full p-4 space-y-2 h-48">
            <h3 className="text-black font-xs font-semibold">Total Ideas Submitted At Each Stage</h3>
          </div>
        </div>
      }
    </>
  );
};

export default TotalIdeas;
