import React from 'react'

const Overview = ({ titleChallenge, descChallenge }) => {
  return (
    <div>
      <div className="bg-baseGray p-5 space-y-5">
        <div className="text-heritageGreen text-lg font-semibold">
          {titleChallenge}
        </div>
        <div className="text-xs">
          <div dangerouslySetInnerHTML={{
            __html: descChallenge
          }} />
        </div>
      </div>
    </div>
  );
}

export default Overview
