import React from 'react';
import GeneralUtility from '../../../../../utility/GeneralUtility';
import DateUtility from '../../../../../utility/date-utility';

const ListSubmitted = ({ listSubmitted, detailSubmitted, loading }) => {
    return (
        <div className='bg-baseGray p-5 hidden md:block lg:block'>
            {!loading ? (
                <div>
                    <h3 className="font-semibold text-xl p-3 text-center">Submitted Ideas</h3>
                    <div className="grid grid-cols-9 gap-2 font-semibold py-3 border bg-white border-b-gray-200">
                        <div className="text-center text-xs font-bold">PROFILE</div>
                        <div className="text-center text-xs font-bold col-span-2">MEMBER NAME</div>
                        <div className="text-center text-xs font-bold col-span-2">IDEA TITLE</div>
                        <div className="text-center text-xs font-bold">STATUS</div>
                        <div className="text-center text-xs font-bold">DATE</div>
                        <div className="text-center text-xs font-bold">VIEWS</div>
                        <div className="text-center text-xs font-bold">COMMENTS</div>
                    </div>
                    {listSubmitted.length === 0 ? (
                        <div className="text-center font-semibold text-md p-6">
                            No Submitted Ideas
                        </div>
                    ) : (
                        <div>
                            {listSubmitted.map((items, index) => (
                                <div key={index} onClick={() => detailSubmitted(items.id)} className="cursor-pointer grid grid-cols-9 gap-2 items-center py-3 border border-b-gray-200 bg-white hover:bg-gray-100">
                                    <div className="flex justify-center">
                                        <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-bold justify-center">
                                            {GeneralUtility.getInitials(items.fullname)}
                                        </div>
                                    </div>
                                    <div className='col-span-2'>
                                        <h3 className="text-sm text-center break-all">
                                            {items.fullname}
                                        </h3>
                                    </div>
                                    <div className="text-center col-span-2">
                                        <h3 title={items.title_idea !== null ? items.title_idea : ""} className="text-sm break-all">
                                            {items.title_idea !== null ? (items.title_idea.length > 50 ? items.title_idea.substring(0, 50) + "..." : items.title_idea) : ""}
                                        </h3>
                                    </div>
                                    <div className="text-center">
                                        <h3
                                            id="status"
                                            className={`py-[3px] text-xs font-semibold uppercase text-center 
                                            ${items.status === "Experimentation" ? "text-heritageGreen" : ""} 
                                            ${items.status === "Validation" ? "text-naartjie" : ""} 
                                            ${items.status === "Generation" ? " text-freshGreen" : ""} 
                                            ${items.status === "Commercialisation" ? "text-blueSky" : ""} 
                                            ${items.status === "Declined" ? "text-red-700" : ""} 
                                            ${items.status === "Closed" ? "text-cerise" : ""}
                                            ${items.status === "On Hold" ? "text-orange-900" : ""} 
                                            `}
                                        >
                                            {items.status}
                                        </h3>
                                    </div>
                                    <div className="text-center">
                                        <p className="text-xs">
                                            {DateUtility.formatDate(items.created_at)}
                                        </p>
                                    </div>
                                    <div className="text-center">
                                        <div className="flex justify-center items-center space-x-2">
                                            <img
                                                src="/assets/global/img/visible.svg"
                                                className="h-3 w-4"
                                                alt="visible"
                                            />
                                            <p className="text-sm">{items.total_view}</p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <div className="flex justify-center items-center space-x-2">
                                            <img
                                                src="/assets/global/img/chat.svg"
                                                className="h-3 w-4"
                                                alt="chat"
                                            />
                                            <p className="text-sm">{items.total_commentary}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    )}
                </div>
            ) : (
                <div className='h-screen text-center'>
                    Loading...
                </div>
            )}
        </div>
    )
}

export default ListSubmitted