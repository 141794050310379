import React, { useState, useEffect } from "react";
import TermCondRepo from "../../../repositories/user-repo/TermCondRepo";
import GeneralUtility from "../../../utility/GeneralUtility";

const TermsnConds = () => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);

  const getContent = async () => {
    const res = await TermCondRepo.getTermsCondition();
    setLoading(true);
    if (res.status === 200) {
      setContent(res.data.data.value);
      setLoading(false);
    }
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getContent();
  }, []);

  return (
    <div className="my-10 pb-40 mx-10 md:mx-20 lg:mx-40">
      <div className="text-justify ">
        {!loading ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default TermsnConds;
