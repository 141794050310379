import Chart from "react-apexcharts";

export default function Column(params) {
    var column = {
        options: {
          colors : ['#8327e3', '#6ad9f1', '#50B848', '#E30909', '#FFF201', '#ED0680', '#F37021'],
          chart: {
                id: "basic-bar",
                toolbar: {
                    show: false
                },
                stacked: false,
            },
            grid:{
                show: true,  
            },
            legend: {
              position: params.legend
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                      position: 'top', 
                    },
                }
            },
            xaxis: {
                categories: params.labels
            },
            yaxis: {
              labels: {
                formatter: function(val) {
                  return val?.toFixed(0)
                }
              }
            },
            stroke: {
              width: [2, 2, 2, 2, 2, 2, 2],
              curve: 'smooth'
            },
            fill: {
              opacity: [1, 1, 1, 1, 1, 1, 1],      
            },
        },
        series: [{
            name: params.name1,
            data: params.current
          }, {
            name: params.name2,
            data: params.previous
          }, {
            name: params.name3,
            data: params.other
          // }, {
          //   name: params.name4,
          //   data: params.other2
          // },{
          //   name: params.name5,
          //   data: params.other3
          // },{
          //   name: params.name6,
          //   data: params.other4
          // },{
          //   name: params.name7,
          //   data: params.other5
          }]
    };

    var columnOverview = {
      options: {
        colors : ['#8327e3', '#6ad9f1', '#50B848', '#E30909', '#FFF201', '#ED0680', '#F37021'],
        chart: {
              id: "bar",
              toolbar: {
                  show: false,
              },
              stacked: false,
          },
          grid:{
              show: true,  
          },
          legend: {
            position: params.legend
          },
          plotOptions: {
              bar: {
                  dataLabels: {
                    position: 'top', // top, center, bottom
                  },
              }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val?.toFixed(0);
            },
            offsetY: -16,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          xaxis: {
              categories: params.labels
          },
          yaxis: {
            labels: {
              formatter: function(val) {
                return val?.toFixed(0)
              }
            }
          },
          stroke: {
            width: [2, 2, 2, 2, 2, 2, 2],
            curve: 'smooth'
          },
          fill: {
            opacity: [1, 1, 1, 1, 1, 1, 1],      
          },
      },
      series: [{
          name: params.name1,
          data: params.current
        }, {
          name: params.name2,
          data: params.previous
        // }, {
        //   name: params.name3,
        //   data: params.other
        // }, {
        //   name: params.name4,
        //   data: params.other2
        // },{
        //   name: params.name5,
        //   data: params.other3
        // },{
        //   name: params.name6,
        //   data: params.other4
        // },{
        //   name: params.name7,
        //   data: params.other5
        }]
  };


    return(
    <div className='column'>
        <Chart
           options={params.from === "Overview" ? columnOverview.options : column.options} 
           series={params.from === "Overview" ? columnOverview.series : column.series} 
           type="bar" 
           width={params.width === undefined ? "750" : params.width}
           height={params.height}
        />
    </div>
    )   
}
