import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RichText from "../../../../components/RichText";
import ArrowIco from "../../../../components/icons/ArrowIco";
import ConfirmPopup from "../../../../components/ConfirmPopup";
import ChallengeAdmRepo from "../../../../repositories/adm-repo/ChallengeAdmRepo";
import FilterChallengeAdmRepo from "../../../../repositories/adm-repo/FilterChallengeAdmRepo";
import ChallengeRepo from "../../../../repositories/user-repo/ChallengeRepo";
import Select from "react-select";
import RewardEditChallenge from "./RewardEditChallenge";
import AlertUtility from "../../../../utility/alert-utility";
import RouteAdm from "../../../../services/routeAdm";
import DateUtility from "../../../../utility/date-utility";
import Swal from "sweetalert2";
import UsersRepo from "../../../../repositories/admin-repo/UsersRepo";
import DragDropLC from "../../LaunchChallenge/components/DragDropLC";
import GeneralUtility from "../../../../utility/GeneralUtility";

const EditChallengeAdm = () => {
  let { id } = useParams();
  const [isIdeaChanged, setIsIdeaChanged] = useState(false);
  const [isChallengeInfo, setIsChallengeInfo] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [isEvaluators, setIsEvaluators] = useState(false);
  const [error, setError] = useState({});
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [listTypeChal, setlistTypeChal] = useState([]);
  const [listPrivacy, setListPrivacy] = useState([]);
  const [listTeam, setListTeam] = useState([]);
  const [detailChallenge, setDetailChallenge] = useState("");
  const [bannerImg, setBannerImg] = useState("");
  const [option, setOption] = useState([]);
  const [cardImg, setCardImg] = useState("");
  const [docs, setDocs] = useState([]);
  const icon = [
    { ico: "/assets/global/img/lightbulb.svg" },
    { ico: "/assets/global/img/check.png" },
    { ico: "/assets/global/img/diamond.png" },
  ];

  const [formValue, setFormValue] = useState({
    title: "",
    card_image: null,
    banner_image: null,
    overview: null,
    evaluation_day: "",
    evaluators: [],
    start_date: null,
    launch_date: null,
    resources: [],
    end_date: null,
    message_evaluator_title: "",
    message_evaluator_message: "",
    type_idea: null,
    status_privacy_id: null,
    category_id: null,
    id: null,
    questions: [],
    links: [],
    drafted: false,
    rewards: [],
    docs: [],
  });

  const getQuestionChall = async () => {
    const result = await ChallengeAdmRepo.getQuestion(id);
    let questionChallenge = result.data.data;
    return questionChallenge;
  };

  const rewards = [
    {
      reward: "200",
      index: 1,
      title: "Stage 1",
      description: "Submit a valid idea and receive R200 per idea.."
    },
    {
      reward: "1000",
      index: 2,
      title: "Stage 2",
      description: "Should the idea pass validation by showing potential to go into an experiment you could receive R 1,000."
    },
    {
      reward: "10000",
      index: 3,
      title: "Stage 3",
      description: "If the experiment is a success and demonstrates value as an implementable solution you could receive R 10,000."
    },
    {
      reward: "25000",
      index: 4,
      title: "Stage 4",
      description: "Successfully manage the implementation of your idea and receive up to R 50,000. If you decide not to manage the implementation you could still receive up to R 25,000."
    },

  ]

  const deleteChallenge = async (id) => {
    AlertUtility.loadingAlert();
    const result = await ChallengeAdmRepo.deleteChallengeAdmin({ id: id });
    if (result.status === 200) {
      Swal.close();
      AlertUtility.successAlert({
        title: "Your challenge was successfully deleted.",
      });
      window.location = RouteAdm.challenges;
    } else {
      Swal.close();
      AlertUtility.errorAlert({
        title: "There is some problem. Please try again.",
      });
    }
  };

  const getDetailChallenge = async () => {
    const result = await ChallengeRepo.getDetailChallenges({ id: id });
    let listChallenges = result;
    // eslint-disable-next-line
    let links = result.links;
    let listDocs = result.documents;
    setDocs(listDocs);
    setDetailChallenge(listChallenges);
    let questions = await getQuestionChall();
    setBannerImg(listChallenges.banner_image);
    setCardImg(listChallenges.card_image);
    setFormValue((prevState) => {
      return {
        ...prevState,
        id: id,
        title: listChallenges.title ? listChallenges.title : "",
        overview: listChallenges.description ? listChallenges.description : "",
        category_id: listChallenges.category ? listChallenges.category.id : "",
        end_date: listChallenges.endDate ? listChallenges.endDate : "",
        start_date: listChallenges.startDate ? listChallenges.startDate : "",
        launch_date: listChallenges.launchDate ? listChallenges.launchDate : "",
        status_privacy_id: listChallenges.statusprivacy ? listChallenges.statusprivacy.id : "",
        type_idea: listChallenges.typeIdea ? listChallenges.typeIdea.id : "",
        message_evaluator_title: listChallenges?.message_evaluator?.title ?? "",
        message_evaluator_message: listChallenges?.message_evaluator?.message ?? "",
        evaluators:
          listChallenges.managerchallange?.length > 0
            ? listChallenges.managerchallange?.map((v) => {
                return {
                  id: v?.id,
                  user: v?.id,
                  label: v?.fullname,
                  fullname: v?.fullname,
                  value: v?.id,
                };
              })
            : [],
        evaluation_day: listChallenges.evaluation_day ? listChallenges.evaluation_day : "" ,
        questions: questions ,
        links: listChallenges.links,
        rewards: listChallenges.rewards.length > 0 ? listChallenges.rewards : rewards,
      };
    });
  };

  const validateDate = (start_date, end_date) => {
    let startDate = new Date(start_date + " 23:59:00").getTime();
    let endDate = new Date(end_date).getTime();
    let d = new Date();
    let dateNow = new Date(
      DateUtility.changeFormatDate(d) + " 00:00:00"
    ).getTime();
    if (startDate <= dateNow) {
      return "less_than_now";
    }
    if (startDate > endDate) {
      return "start_more_than_end";
    }
    if (endDate < startDate) {
      return "end_less_than_start";
    }
  };

  const validateLaunchDate = (launch_date, start_date, end_date) => {
    let launchDate = new Date(launch_date + " 23:59:00").getTime();
    let startDate = new Date(start_date + " 23:59:00").getTime();
    let endDate = new Date(end_date).getTime();
    let d = new Date();
    let dateNow = new Date(
      DateUtility.changeFormatDate(d) + " 00:00:00"
    ).getTime();
    if (launchDate <= dateNow) {
      return "less_than_now";
    }
    if (launchDate > endDate) {
      return "more_than_end";
    }
    if (launchDate < startDate) {
      return "less_than_start";
    }
  };

  const validateEndDate = (start_date, end_date) => {
    let startDate = new Date(start_date + " 23:59:00").getTime();
    let endDate = new Date(end_date).getTime();
    let d = new Date();
    // eslint-disable-next-line
    let dateNow = new Date(
      DateUtility.changeFormatDate(d) + " 00:00:00"
    ).getTime();

    if (endDate < startDate) {
      return "end_less_than_start";
    }
  };

  const isFileSizeMoreThan2MB = (file) => {
    return file.size > 2000000;
  };

  const validate = () => {
    const errors = {};
    if (
      formValue.banner_image &&
      isFileSizeMoreThan2MB(formValue.banner_image)
    ) {
      errors.bannerImg = "Challenge Banner Image size, cannot more than 2MB";
    }

    if (formValue.card_image && isFileSizeMoreThan2MB(formValue.card_image)) {
      errors.cardImg = "Image size, cannot more than 2MB";
    }

    if (!formValue.title || formValue.title === "") {
      errors.title = "Title Reward is required!";
    }
    if (!formValue.start_date) {
      errors.start_date = "Start Date is required!";
    }
    if (!formValue.end_date) {
      errors.end_date = "End Date is required!";
    }

    if (!formValue.overview || formValue.overview === "") {
      errors.overview = "Overview is required!";
    }
    if (!formValue.questions) {
      errors.questions = "Questions is required!";
    }
    setError(errors);
    return errors;
  };

  const handleAddQuestion = () => {
    const values = [...formValue.questions];
    values.push({
      question: "",
    });
    onChangeState("questions", values);
  };

  const handleRemoveQuestion = (index) => {
    const values = [...formValue.questions];
    values.splice(index, 1);
    onChangeState("questions", values);
  };

  const handleInputChange = (index, event) => {
    const values = [...formValue.questions];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;

    onChangeState("questions", values);
  };

  const handleAddLinks = () => {
    const values = [...formValue.links];
    values.push({
      resourceName: "",
      source: "",
    });
    onChangeState("links", values);
  };

  const handleStartDate = (value) => {
    if (validateDate(value, formValue.end_date) === "less_than_now") {
      AlertUtility.errorAlert({
        title: "Start must be greater than now",
      });
    } else if (
      validateDate(value, formValue.end_date) === "start_more_than_end"
    ) {
      AlertUtility.errorAlert({
        title: "Start must be less than End Date",
      });
    } else {
      onChangeState("start_date", value);
      onChangeState("launch_date", value);
    }
  };

  const handleLaunchDate = (value) => {
    if (
      validateLaunchDate(value, formValue.start_date, formValue.end_date) ===
      "less_than_now"
    ) {
      AlertUtility.errorAlert({
        title: "Launch Date must be greater than now",
      });
    } else if (validateLaunchDate(value, value) === "less_than_start") {
      AlertUtility.errorAlert({
        title: "Launch Date must be greater than Start Date",
      });
    } else if (
      validateLaunchDate(value, formValue.start_date, formValue.end_date) ===
      "more_than_end"
    ) {
      AlertUtility.errorAlert({
        title: "Launch Date must be less than End Date",
      });
    } else {
      onChangeState("launch_date", value);
    }
  };

  const handleEndDate = (value) => {
    if (
      validateEndDate(formValue.start_date, value) === "end_less_than_start"
    ) {
      AlertUtility.errorAlert({
        title: "End Date must be greater than start date",
      });
    } else {
      onChangeState("end_date", value);
    }
  };

  const handleRemoveLinks = (index) => {
    const values = [...formValue.links];
    values.splice(index, 1);
    onChangeState("links", values);
  };

  const handleInputLinks = (index, event) => {
    const values = [...formValue.links];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;

    onChangeState("links", values);
  };

  const addEvaluators = (data) => {
    onChangeState(
      "evaluators",
      data.length > 0
        ? data.map((v) => {
            return {
              id: v?.value,
              user: v?.value,
              label: v?.label,
              fullname: v?.label,
              value: v?.value,
            };
          })
        : []
    );
  };

  const getOption = async () => {
    const result = await UsersRepo.getUsers();
    let listOpt = result.data.data;
    const opt = [];
    listOpt.forEach((element) => {
      opt.push({
        id: element.id,
        value: element.id,
        label: element.firstname + " " + element.lastname,
      });
    });
    setOption(opt);
  };

  const deleteEvaluators = (index) => {
    let data = [...formValue.evaluators];
    data.splice(index, 1);
    onChangeState("evaluators", data);
  };

  const onConfirm = () => {
    setShow(true);
  };

  const onConfirmDelete = () => {
    setShowDelete(true);
  };

  const onPublish = async (e) => {
    setShow(false);
    await submitEditChallenge();
  };

  const onDelete = async (e) => {
    setShowDelete(false);
    await deleteChallenge(id);
  };

  const getPrivacy = async () => {
    const result = await FilterChallengeAdmRepo.getPrivacy();
    let listPrivacy = result.data.data;
    setListPrivacy(listPrivacy);
  };

  const getTeam = async () => {
    const result = await FilterChallengeAdmRepo.getTypeIdea();
    let listTeam = result.data.data;
    setListTeam(listTeam);
  };

  const getTypeChallengeAdm = async () => {
    const result = await FilterChallengeAdmRepo.getFilterType();
    let listTypeCategory = result.data.data;
    setlistTypeChal(listTypeCategory);
  };

  const getBase64 = async (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  };

  const submitEditChallenge = async () => {
    const formData = new FormData();
    let err = validate();
    if (Object.keys(err).length < 1) {
      for (const [key, value] of Object.entries(formValue)) {
        switch (key) {
          case "id":
            formData.append("id", value);
            break;
          case "resources":
            if (docs && docs?.length > 0) {
              //filter documents, new documents
              const docUp = docs.filter((object) => {
                return object.id === null;
              });

              //filter deleted documents
              const docDel = docs.filter((object) => {
                return object.source === null;
              });

              docUp.forEach((value, index) => {
                formData.append(`documents[${index}].source`, value.source);
              });

              docDel.forEach((value, index) => {
                formData.append(`remove_file[${index}]`, value.id);
              });
            }
            break;

          case "rewards":
            if (value && value?.length > 0) {
              value?.map((reward, index) => {
                formData.append(`rewards[${index}].index`, index + 1);
                formData.append(`rewards[${index}].title`, reward.title);
                formData.append(`rewards[${index}].reward`, reward.reward);
                formData.append(
                  `rewards[${index}].description`,
                  reward.description
                );
              });
            }
            break;

          case "card_image":
            if (value) {
              formData.append("card_image", value);
            }
            break;

          case "banner_image":
            if (value) {
              formData.append("banner_image", value);
            }
            break;

          case "title":
            formData.append("title", value);
            break;

          case "category_id":
            formData.append("category.id", value);
            break;

          case "status_privacy_id":
            formData.append("statusprivacy.id", value);
            break;

          case "type_idea":
            formData.append("typeidea.id", value);
            break;

          case "evaluation_day":
            formData.append("evaluation_day", value);
            break;

          case "overview":
            formData.append("overview", value);
            break;

          case "start_date":
            formData.append("start_date", value);
            break;

          case "launch_date":
            formData.append("launch_date", value);
            break;

          case "evaluators":
            if (value?.length > 0) {
              value.map((v, index) => {
                formData.append(`managerchallange[${index}].user`, v?.value);
              });
            }
            break;

          case "end_date":
            formData.append("end_date", value);
            break;

          case "drafted":
            formData.append("drafted", value);
            break;

          case "message_evaluator_title":
            formData.append("evaluators.title", value);
            break;

          case "message_evaluator_message":
            formData.append("evaluators.message", value);
            break;

          case "questions":
            if (value?.length > 0) {
              value.map((v, index) => {
                formData.append(`questions[${index}].question`, v?.question);
                formData.append(`questions[${index}].index`, index + 1);
              });
            }
            break;

          case "links":
            if (value?.length > 0) {
              value.map((v, index) => {
                formData.append(
                  `links[${index}].resourceName`,
                  v?.resourceName
                );
                formData.append(`links[${index}].source`, v?.source);
              });
            }
            break;

          default:
            break;
        }
      }

      AlertUtility.loadingAlert();
      const result = await ChallengeAdmRepo.updateChallengeAdmin(formData);
      if (result.status === 200) {
        Swal.close();
        AlertUtility.successAlert({ title: "The changes has been saved." });
        window.location = RouteAdm.challenges;
      } else {
        Swal.close();
        AlertUtility.errorAlert({
          title: "There is some problem. Please try again.",
        });
      }
    } else {
      AlertUtility.errorAlert({
        title: JSON.stringify(err),
      });
    }
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getOption();
    getDetailChallenge();
    getTypeChallengeAdm();
    getPrivacy();
    getTeam();
  }, []);


  const onChangeState = (key, value) => {
    setFormValue((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const onChangeReward = (index, value) => {
    let data = formValue.rewards;
    data[index] = { ...data[index], ...value };

    onChangeState("rewards", data);
  };

  return (
    <div className="container mx-auto">
      <div className="m-6">
        <div>
          <div
            className="bg-freshGreen h-[55px] relative cursor-pointer flex justify-between items-center"
            onClick={() => setIsIdeaChanged(!isIdeaChanged)}
          >
            <h3 className=" m-5 font-normal text-white text-lg px-2 py-1">
              Images
            </h3>
            <div>
              <img
                src="/assets/global/img/downChevronWhite.png"
                className={`h-4 w-6 stroke-black mr-5 mt-2`}
                alt=""
              />
            </div>
          </div>
          {isIdeaChanged ? (
            <div className="flex flex-col border-b-2 border-l-2 border-r-2 border-[#DCDDDE] bg-white">
              <div className="m-5">
                <div className="grid gap-3 grid-cols-1 lg:grid-cols-2">
                  <div>
                    <div className="font-semibold text-[18px] mb-3">
                      Banner Image
                    </div>
                    <div
                      className="bannerimage relative bg-cover h-40 w-full "
                      style={{
                        backgroundImage: "url(" + bannerImg + ")",
                      }}
                    >
                      <div className="bg-cover h-full w-full space-y-4 text-right flex ">
                        <div className="float-right absolute right-0 mt-5 mr-5">
                          <label>
                            <img
                              src="/assets/global/img/edit.svg"
                              className="h-[18px] w-[18px]"
                              alt=""
                            />
                            <input
                              autoComplete="off"
                              type="file"
                              onChange={(e) => {
                                onChangeState(
                                  "banner_image",
                                  e.target.files[0]
                                );
                                getBase64(e.target?.files?.[0], (result) => {
                                  setBannerImg(result);
                                });
                              }}
                              className="hidden"
                              accept=".jpeg, .png, .jpg"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <p className="text-red-500 w-48 text-sm">
                      {error.bannerImg}
                    </p>
                  </div>
                  <div>
                    <div className="font-semibold text-[18px] mb-3">
                      Card Image
                    </div>
                    <div
                      className="bannerimage relative items-center text-center justify-center flex bg-cover h-40 w-full"
                      style={{
                        backgroundImage: "url(" + cardImg + ")",
                      }}
                    >
                      <div className="absolute top-0 right-0 mt-5 mr-5">
                        <label>
                          <img
                            src="/assets/global/img/edit.svg"
                            className="h-[18px] w-[18px]"
                            alt=""
                          />
                          <input
                            autoComplete="off"
                            type="file"
                            onChange={(e) => {
                              onChangeState("card_image", e.target.files[0]);
                              getBase64(e.target?.files?.[0], (result) => {
                                setCardImg(result);
                              });
                            }}
                            className="hidden"
                            accept=".jpeg, .png, .jpg"
                          />
                        </label>
                      </div>

                      <div className="items-center">
                        <label>
                          <img
                            src="/assets/global/img/gallery.png"
                            className="h-[18px] w-[18px]"
                            alt=""
                          />
                          <input
                            autoComplete="off"
                            type="file"
                            onChange={(e) => {
                              onChangeState("card_image", e.target.files[0]);
                              getBase64(e.target?.files?.[0], (result) => {
                                setCardImg(result);
                              });
                            }}
                            className="hidden"
                            accept=".jpeg, .png, .jpg"
                          />
                        </label>
                      </div>
                    </div>
                    <p className="text-red-500 w-48 text-sm">{error.cardImg}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="m-6">
        <div>
          <div
            className="bg-freshGreen h-[55px] w-full relative cursor-pointer flex justify-between items-center"
            onClick={() => setIsChallengeInfo(!isChallengeInfo)}
          >
            <h3 className=" m-5 font-normal text-white text-lg px-2 py-1">
              Challenge Information
            </h3>
            <div>
              <img
                src="/assets/global/img/downChevronWhite.png"
                className={`h-4 w-6 stroke-black mr-5 mt-2`}
                alt=""
              />
            </div>
          </div>
          {isChallengeInfo ? (
            <div className="flex flex-col border-b-2 border-l-2 border-r-2 border-[#DCDDDE] bg-white">
              <div className="m-5">
                <div className="mb-5">
                  <h3 className="text-textBlack flex font-semibold pt-3 text-md mb-2">
                    Challenge Title
                  </h3>
                  <div className="border-2 border-[#DCDDDE] w-full">
                    <input
                      autoComplete="off"
                      type="text"
                      className="text-sm font-semibold text-gray-700 p-2 w-full"
                      onChange={(event) => {
                        onChangeState("title", event.target.value);
                      }}
                      value={formValue.title}
                    />
                  </div>
                </div>

                <div className="mb-5">
                  <h3 className="text-textBlack flex font-semibold pt-3 text-md mb-2">
                    Challenge Overview
                  </h3>
                  <div>
                    <RichText
                      initialValue={detailChallenge?.description}
                      onChange={(content) =>
                        onChangeState(
                          "overview",
                          content === ""
                            ? detailChallenge?.description
                            : content
                        )
                      }
                    />
                  </div>
                </div>

                <div className="mb-5">
                  <h3 className="font-semibold text-black"> Links </h3>
                  <div className="links space-y-2">
                    {formValue.links.length > 0 && (
                      <>
                        {formValue.links.map((field, index) => (
                          <div
                            key={index}
                            className="flex space-x-3 items-center"
                          >
                            <div className=" basis-4/12" id="resourceName">
                              <input
                                autoComplete="off"
                                className="border-none shadow-md w-full"
                                type="text"
                                name="resourceName"
                                placeholder="Resources Name"
                                value={field.resourceName}
                                onChange={(event) =>
                                  handleInputLinks(index, event)
                                }
                              />
                            </div>
                            <div className="basis-7/12" id="source">
                              <input
                                autoComplete="off"
                                className="border-none shadow-md w-full"
                                type="text"
                                name="source"
                                placeholder="Paste URL"
                                value={field.source}
                                onChange={(event) =>
                                  handleInputLinks(index, event)
                                }
                              />
                            </div>
                            <div className="basis-1/12 pl-4">
                              <img
                                onClick={() => handleRemoveLinks(index)}
                                className="cursor-pointer basis-1/12"
                                src="/assets/global/img/bin.svg"
                                alt="trash"
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <button className="mt-3">
                    <img
                      onClick={() => handleAddLinks()}
                      src="/assets/global/img/squarePlus.png"
                      className="w-14 h-14"
                      alt="add"
                    />
                  </button>
                </div>

                <div className="mb-5 hidden">
                  <h3 className="text-textBlack flex font-semibold pt-3 text-md mb-2">
                    Incentive
                  </h3>
                  <div>
                    <RichText
                      initialValue={detailChallenge?.incentive}
                      onChange={(content) =>
                        onChangeState("incentive", content)
                      }
                    />
                  </div>
                </div>

                <div className="mb-5 hidden">
                  <h3 className="text-textBlack flex font-semibold pt-3 text-md mb-2">
                    Add Links
                  </h3>
                  <div className="shadow-md h-11 mb-2  w-full">
                    <p className="text-sm font-normal text-[#808285] p-3">
                      {" "}
                      Resource Name
                    </p>
                  </div>
                  <div className="shadow-md h-11  w-full">
                    <p className="text-sm font-normal text-[#808285] p-3">
                      {" "}
                      Paste URL
                    </p>
                  </div>
                </div>

                <div className="mb-5">
                  <div className="file">
                    <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                      Upload Resources
                    </h3>
                    <DragDropLC docs={docs} setDocs={setDocs} />
                  </div>
                </div>

                <div className="mb-5">
                  <h3 className="text-textBlack flex font-semibold pt-3 text-md mb-2">
                    How to get rewarded
                  </h3>
                  <div className="mx-auto mt-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                      {formValue.rewards &&
                        formValue.rewards.length > 0 &&
                        formValue.rewards.map((items, index) => (
                          <RewardEditChallenge
                            items={items}
                            index={index}
                            icon={icon.ico}
                            onEditReward={(index, item) =>
                              onChangeReward(index, item)
                            }
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* <ModalEditPointReward
        show={showPoint}
        setShow={setShowPoint}
        reward={point}
      /> */}
      {/* <ModalEditDescReward
        show={showDesc}
        setShow={setShowDesc}
        description={descReward}
      /> */}

      <div className="m-6">
        <div>
          <div
            className="bg-freshGreen h-[55px] w-full relative cursor-pointer flex justify-between items-center"
            onClick={() => setIsDetail(!isDetail)}
          >
            <h3 className=" m-5 font-normal text-white text-lg px-2 py-1">
              Detail
            </h3>
            <div>
              <img
                src="/assets/global/img/downChevronWhite.png"
                className={`h-4 w-6 stroke-black mr-5 mt-2`}
                alt=""
              />
            </div>
          </div>
          {isDetail ? (
            <div className="flex flex-col border-b-2 border-l-2 border-r-2 border-[#DCDDDE] bg-white">
              <div className="m-5">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <div>
                    <div id="category" className="space-y-3 h-24">
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                        Type
                      </h3>
                      <div>
                        <select
                          name="category"
                          className="w-full h-10 border-none shadow-md text-sm text-textGray"
                          onChange={(e) =>
                            onChangeState("category_id", e.target.value)
                          }
                          defaultValue="default"
                          value={formValue.category_id}
                        >
                          <option value="default" disabled>
                            -- Select Type --
                          </option>
                          {listTypeChal.map((items, index) => (
                            <option key={index} value={items.id}>
                              {items.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div id="startdate" className="space-y-3 h-24">
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                        Start Date
                      </h3>
                      <div>
                        <input
                          autoComplete="off"
                          onChange={(e) => {
                            handleStartDate(e.target.value);
                          }}
                          className="w-full h-10 border-none shadow-md text-sm text-textGray"
                          type="date"
                          value={formValue.start_date}
                        />
                        <p className="text-red-500 text-xs">
                          {error.start_date}
                        </p>
                      </div>
                    </div>
                    {/* Type Privacy */}
                    <div id="privacy" className="space-y-3 h-24 mb-3 pt-1.5">
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                        Privacy
                      </h3>
                      {listPrivacy.map((items, index) => (
                        <div key={index}>
                          <input
                            autoComplete="off"
                            onChange={(e) =>
                              onChangeState(
                                "status_privacy_id",
                                e.currentTarget.value
                              )
                            }
                            value={items.id}
                            id={items.id}
                            checked={formValue.status_privacy_id === items.id}
                            name="privacy"
                            type="radio"
                          />
                          <label
                            htmlFor={items.id}
                            className="radio-custom-label"
                          >
                            <p className="font-semibold text-textBlack text-sm capitalize">
                              {items.name}
                            </p>
                            <p className="text-textGray text-xs">
                              {items.name === "public"
                                ? "Any member can view this challenge"
                                : "A member must be invited to view this challenge"}
                            </p>
                          </label>
                        </div>
                      ))}
                      <p className="text-red-500 text-xs">{error.privacy}</p>
                    </div>
                  </div>
                  <div>
                    <div id="launchDate" className="space-y-3 h-24">
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                        Launch Date
                      </h3>
                      <div>
                        <input
                          autoComplete="off"
                          onChange={(e) => handleLaunchDate(e.target.value)}
                          className="w-full h-10 border-none shadow-md text-sm text-textGray"
                          type="date"
                          value={formValue.launch_date}
                        />
                        <p className="text-red-500 text-xs">
                          {error.launch_date}
                        </p>
                      </div>
                    </div>
                    <div id="endate" className="space-y-3 h-24">
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                        End Date
                      </h3>
                      <div>
                        <input
                          autoComplete="off"
                          onChange={(e) => handleEndDate(e.target.value)}
                          className="w-full h-10 border-none shadow-md text-sm text-textGray"
                          type="date"
                          value={formValue.end_date}
                        />
                        <p className="text-red-500 text-xs">{error.end_date}</p>
                      </div>
                    </div>
                    {/* typeidea */}
                    <div id="team" className="space-y-3 pb-3 pt-1.5">
                      <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                        Team
                      </h3>
                      {listTeam.map((items, index) => (
                        <div key={index}>
                          <input
                            autoComplete="off"
                            onChange={(e) =>
                              onChangeState("type_idea", e.currentTarget.value)
                            }
                            value={items.id}
                            id={items.id}
                            checked={formValue.type_idea === items.id}
                            name="Team"
                            type="radio"
                          />
                          <label
                            htmlFor={items.id}
                            className="radio-custom-label"
                          >
                            <p className="font-semibold text-textBlack text-sm capitalize">
                              {items.name === "personal"
                                ? "No Team"
                                : "Team Ideas"}
                            </p>
                            <p className="text-textGray text-xs">
                              {items.name === "personal"
                                ? "Do not allow team ideas"
                                : "Allow team ideas"}
                            </p>
                          </label>
                        </div>
                      ))}
                      <p className="text-red-500 text-xs">{error.typeidea}</p>
                    </div>
                  </div>
                </div>
                <div className="question space-y-4">
                  {formValue.questions.length === 0 ? (
                    <div className="w-full flex space-x-4 space-y-2 relative block">
                      <input
                        autoComplete="off"
                        className="w-full border-none shadow-md text-sm"
                        type="text"
                        name="question"
                        disabled
                        placeholder="Question for this challenge"
                      />
                      <span className="absolute inset-y-0 right-0 flex items-center pr-3 space-x-2">
                        <img
                          onClick={() => handleAddQuestion()}
                          src="/assets/global/img/duplicate.svg"
                          alt=""
                        />
                      </span>
                    </div>
                  ) : (
                    <div className="w-full space-y-2 relative block">
                      {formValue.questions.map((field, index) => (
                        <div
                          key={index}
                          className="w-full flex space-x-4 space-y-2 relative block"
                        >
                          <input
                            autoComplete="off"
                            className="w-full border-none shadow-md text-sm"
                            type="text"
                            name="question"
                            placeholder={`Question ${index + 1}`}
                            value={field.question}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                          />
                          <span className="absolute inset-y-0 right-0 flex items-center pr-3 space-x-2">
                            <img
                              onClick={() => handleAddQuestion()}
                              src="/assets/global/img/duplicate.svg"
                              alt=""
                            />
                            <img
                              onClick={() => handleRemoveQuestion(index)}
                              src="/assets/global/img/bin.svg"
                              className="cursor-pointer"
                              alt="delete"
                            />
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                  <p className="text-red-500 text-xs">{error.question}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* EVALUATOR */}
      <div className="m-6">
        <div>
          <div
            className="bg-freshGreen h-[55px] w-full relative cursor-pointer flex justify-between items-center"
            onClick={() => setIsEvaluators(!isEvaluators)}
          >
            <h3 className=" m-5 font-normal text-white text-lg px-2 py-1">
              Evaluators
            </h3>
            <div>
              <img
                src="/assets/global/img/downChevronWhite.png"
                className={`h-4 w-6 stroke-black mr-5 mt-2`}
                alt=""
              />
            </div>
          </div>
          {isEvaluators ? (
            <div className="flex flex-col border-b-2 border-l-2 border-r-2 border-[#DCDDDE] bg-white">
              <div className="m-5">
                <div className="mb-5">
                  <h3 className="text-textBlack flex font-semibold pt-3 text-md mb-2">
                    Select Evaluators
                  </h3>

                  <Select
                    className="border-none z-[3]"
                    options={option}
                    value={formValue.evaluators}
                    onChange={addEvaluators}
                    isMulti
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
                  {formValue.evaluators.map((items, index) => (
                    <div
                      key={index}
                      className="bg-gray-200 flex p-2 justify-between space-x-6 items-center shadow-sm"
                    >
                      <div className="flex space-x-2">
                        <img
                          src="/assets/global/img/avatarRound.png"
                          className="w-5 h-5"
                          alt="avatar"
                        />
                        <div className="text-gray-600 text-sm">
                          {items.fullname}
                        </div>
                      </div>
                      <button>
                        <img
                          onClick={(e) => deleteEvaluators(index)}
                          src="/assets/global/img/bin.svg"
                          className="w-3 h-4"
                          alt="dlt"
                        />
                      </button>
                    </div>
                  ))}
                </div>

                <div id="msg" className="space-y-3 mb-5">
                  <h3 className="text-textBlack flex font-semibold pt-3 mb-2 text-md">
                    Custom message for Evaluators
                  </h3>
                  <input
                    autoComplete="off"
                    type="text"
                    value={formValue.message_evaluator_title}
                    className="border-none shadow-md h-10 text-textGray w-full text-sm font-semibold text-gray-600 p-2 w-full"
                    placeholder="Subject title"
                    onChange={(e) =>
                      onChangeState("message_evaluator_title", e.target.value)
                    }
                  />
                  <RichText
                    initialValue={detailChallenge?.message_evaluator?.message}
                    onChange={(content) =>
                      onChangeState(
                        "message_evaluator_message",
                        content === ""
                          ? detailChallenge?.message_evaluator?.message
                          : content
                      )
                    }
                  />
                </div>

                <div id="ev-day" className="space-y-3">
                  <h3 className="text-textBlack flex font-semibold pt-3 mb-2 text-md">
                    How many days do Evaluators have to evaluate an idea?
                  </h3>
                  <input
                    autoComplete="off"
                    type="number"
                    className="border-none h-10 shadow-md w-full text-textGray"
                    placeholder="Number of days"
                    min={0}
                    onChange={(e) =>
                      onChangeState("evaluation_day", e.target.value)
                    }
                    value={formValue.evaluation_day}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="pt-4 mr-5 pb-5 text-right">
        <button
          className={`
          ${detailChallenge.status === "Active" ? "hidden" : ""} 
          inline-flex items-center text-sm font-medium text-center hover:text-freshGreen text-[#F00808] mr-3 cursor-pointer`}
          onClick={onConfirmDelete}
        >
          Permanently Delete
        </button>
        <button
          onClick={onConfirm}
          className="text-sm border boder-1 border-green-400 text-freshGreen hover:border hover:border-1 hover:border-green-400 text-center py-2 px-3 inline-flex items-center hover:bg-gradient-to-r hover:from-green-600 hover:to-freshGreen hover:text-white"
        >
          PUBLISH
          <ArrowIco />
        </button>
      </div>

      <ConfirmPopup
        show={show}
        setShow={setShow}
        onSave={onPublish}
        message="Are you sure you want to publish these changes?"
      />

      <ConfirmPopup
        show={showDelete}
        setShow={setShowDelete}
        onSave={onDelete}
        message="Are you sure to delete this changes ?"
      />
    </div>
  );
};

export default EditChallengeAdm;
