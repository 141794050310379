import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import RouteName from "../../../../../services/routename";
import DragDrop from "../../../../../components/DragDrop";
import PersonalRepo from "../../../../../repositories/user-repo/PersonalRepo";
import Breadcrumbs from "./Breadcrumbs";
import AlertUtility from "../../../../../utility/alert-utility";
import 'quill/dist/quill.snow.css'; 
import RichTextEditor from "../../../../../components/RichTextEditor";


const CreatePersonalIdea = () => {

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [links, setLinks] = useState([{ resourceName: "", source: "" }]);
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState([]);
  const navigate = useNavigate();

  /**
   * function to add row links
   */
  const handleAddLinks = () => {
    const values = [...links];
    values.push({
      resourceName: "",
      source: ""
    });
    setLinks(values);
  };

  const handleChangeDesc = (e) => {
    setDescription(e);
  }

  /**
   * function to remove row links
   * @param {*} index 
   */
  const handleRemoveLinks = (index) => {
    const values = [...links];
    values.splice(index, 1);
    setLinks(values);
  };

  /**
   * funtion to handle links value
   * @param {*} index 
   * @param {*} event 
   */
  const handleInputChange = (index, event) => {
    const values = [...links];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    setLinks(values);
  };

  /**
   * for validate form
   * @param {} description 
   * @param {*} title 
   * @param {*} links 
   * @returns 
   */
  const validate = (description, title, links) => {
    const errors = {}
    if (!title) {
      errors.title = "Title is required!"
    }
    if (!description) {
      errors.description = "Description is required!"
    }

    setError(errors);
    return errors;
  }


  /**
   * for handle submit form
   * @param {*} e 
   */
  const submitForm = async e => {
    e.preventDefault();
    let err = validate(description, title, links);
    if (Object.keys(err).length === 0) {
      let data = { title, links: links, description, documents };
      AlertUtility.loadingAlert();
      const res = await PersonalRepo.uploadPersonalIdea(data);
      if (res.status === 200) {
        Swal.close();
        AlertUtility.successAlert({ title: "Your idea has been saved." });
        navigate(RouteName.personalIdeas);
      } else {
        Swal.close();
        AlertUtility.errorAlert({ title: "There is some problem. Please try again." })
      }
    }
  };

  

  return (
    <div className="container mx-auto">
      <div className="m-6 bx_myidea_top">
        <div className="mx-auto">
          <Breadcrumbs />
        </div>
      </div>
      <div className="px-6 text-black text-xl font-bold w-full bx_myidea_top_title">
        <div className="tx_top_page_latest lg:hidden">
          My Ideas
        </div>
      </div>

      <div className="m-6 bg-baseGray p-6 space-y-4 bx_myidea_personal">
        <div className="space-y-2">
          <h3 className="font-semibold text-black"> Idea Title </h3>
          <input
            autoComplete="off"
            type="text"
            name="ideaTitle"
            className="border-none shadow-md w-full text-sm valAutoComp"
            placeholder="Enter a title with a maximum length of 100 characters"
            max={100}
            onChange={e => setTitle(e.target.value)}
          />
          <p className="m-6 text-red-500 w-48 text-sm">{error.title}</p>
        </div>
        {/* <div className="space-y-2">
          <h3 className="font-semibold text-black"> Description </h3>
          <RichText
            onChange={content => {
              setDescription(content);
            }}
          />
          <p className="text-red-500 w-48 text-sm">{error.description}</p>
        </div> */}

      <div>
      <h3 className="font-semibold text-black"> Description </h3>
      <RichTextEditor value={description} onChange={handleChangeDesc} />
      </div>

        {/* TO DO hidden this phase */}
        <div className="space-y-2 hidden">
          <h3 className="font-semibold text-black"> Add Links </h3>
          <div className="space-y-2">
            {links.length > 0 && (
              <>
                {links.map((field, index) => (
                  <div key={index} className='flex space-x-3 items-center'>
                    <div className=" basis-4/12" id="resourceName">
                      <input
                        autoComplete="off"
                        className="border-none shadow-md w-full valAutoComp"
                        type="text"
                        name="resourceName"
                        placeholder="Resources Name"
                        value={field.resourceName}
                        onChange={(event) =>
                          handleInputChange(index, event)
                        }
                      />
                    </div>
                    <div className="basis-7/12" id="source">
                      <input
                        autoComplete="off"
                        className="border-none shadow-md w-full valAutoComp"
                        type="text"
                        name="source"
                        placeholder="Paste URL"
                        value={field.source}
                        onChange={(event) =>
                          handleInputChange(index, event)
                        }
                      />
                    </div>
     
                    <div className="basis-1/12 pl-4">
                      <img
                        onClick={() => handleRemoveLinks(index)}
                        className="cursor-pointer basis-1/12"
                        src="/assets/global/img/bin.svg"
                        alt="trash"
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <button onClick={() => handleAddLinks()}>
            <img
              src="/assets/global/img/squarePlus.png"
              className="w-14 h-14"
              alt="add"
            />
          </button>
        </div>
      
        {/* TO DO hidden this phase */}
        <div className="space-y-2 hidden">
          <h3 className="font-semibold text-black"> Attachment </h3>
          <DragDrop docs={documents} setDocs={setDocuments} />
        </div>

        <button className="border border-1 border-green-400 bg-gradient-to-r from-green-500 to-freshGreen py-2 px-5 text-white text-xs inline-flex items-center hover:bg-none hover:text-green-400 uppercase" onClick={submitForm}>SAVE</button>

        {/* <BtnSubmit onClick={submitForm} title="SAVE" /> */}

      </div>
    </div>
  );
};

export default CreatePersonalIdea;
