import React, { useState, useEffect } from "react";
import ArrowIco from "../../../components/icons/ArrowIco";
import AllOverviewRepo from "../../../repositories/admin-repo/OverviewRepo";
import AllChallengesRepo from "../../../repositories/admin-repo/ChallengesRepo";
import Ideas from "./Ideas";
import Challenges from "./Challanges";
import AllIdeasRepo from "../../../repositories/admin-repo/IdeasRepo";
import Users from "./Users";
import Communication from "./Communication";
import "./Dashboard.css";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment/moment";
import ConfirmPopup from "../../../components/ConfirmPopup";
import AlertUtility from "../../../utility/alert-utility";
import Swal from "sweetalert2";
import TabOverview from "./TabOverview";
import Engagement from "./Engagement";
import GeneralUtility from "../../../utility/GeneralUtility";
import DateRange from "../../../components/input/DateRange";
import DateUtility from "../../../utility/date-utility";
import IsLoading from "../../../components/loading/IsLoading";

const ViewOverview = (props) => {
  let tempYear = [];
  let tempTotal = [];
  let tempIncrease = "";
  if (props.ideasData !== null) {
    props?.ideasData?.map((res, index) => {
      tempYear.push(res["year"]);
      tempTotal.push(res["total"]);
      tempIncrease = res["increases"];
    });
  }

  //ideas by stages
  let tempName = [];
  let tempValue = [];
  if (props.ideasByStages !== null) {
    props?.ideasByStages?.map((res, index) => {
      tempName.push(res["name"]);
      tempValue.push(res["total_submited"]);
    });
  }

  //challenge list
  let tempYearChallange = [];
  let tempTotalChallange = [];
  let tempIncreaseChallange = "";
  if (props.challange !== null) {
    props?.challange?.map((res, index) => {
      tempYearChallange.push(res["year"]);
      tempTotalChallange.push(res["total"]);
      tempIncreaseChallange = res["increases"];
    });
  }

  //statistic
  let tempStatisticMonth = [];
  let tempStatisticCurrent = [];
  let tempStatisticPrevious = [];
  let current_year = "";
  let previous_year = "";
  if (props.statisticList !== null) {
    current_year = props.statisticList.current_year;
    previous_year = props.statisticList.previous_year;

    if (props.statisticList.data !== null) {
      props.statisticList.data?.map((res, index) => {
        tempStatisticMonth.push(res["month"]);
        tempStatisticCurrent.push(res["total_current_year"]);
        tempStatisticPrevious.push(res["total_previous_year"]);
      });
    }
  }

  // users
  let tempUser = [];
  let tempPercent = [];
  if (props.users !== null) {
    props?.users?.map((res, index) => {
      tempUser.push(res["name"].charAt(0).toUpperCase() + res["name"].slice(1));
      tempPercent.push(res["total"]);
    });
  }

  //ideas static config
  const options = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: true,
        offsetX: 200,
        offsetY: 8,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          display: false,
        },
      },
      colors: ["#66bf40", "#F44336"],
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        type: "category",
        categories: tempYear.length ? tempYear : [props.sortByYear, 2020],
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        // tickAmount: 3,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      fill: {
        colors: ["#66bf40", "#F44336"],
      },
    },
    series: [
      {
        name: "Total Ideas",
        data: tempTotal.length ? tempTotal : [0, 0],
      },
    ],
  };

  //challange static config
  const option = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: true,
        offsetX: 200,
        offsetY: 8,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          display: false,
        },
      },
      colors: ["#66bf40", "#F44336"],
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        type: "category",
        categories: tempYearChallange.length ? tempYearChallange : [2023, 2020],
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        // tickAmount: 3,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      fill: {
        colors: ["#66bf40", "#F44336"],
      },
    },
    series: [
      {
        name: "Total Challenges",
        data: tempTotalChallange.length ? tempTotalChallange : [0, 0],
      },
    ],
  };

  return (
    <>
      <TabOverview
        props={props}
        options={options}
        option={option}
        tempIncrease={tempIncrease}
        tempIncreaseChallange={tempIncreaseChallange}
        tempPercent={tempPercent}
        tempStatisticCurrent={tempStatisticCurrent}
        tempName={tempName}
        tempStatisticMonth={tempStatisticMonth}
        tempStatisticPrevious={tempStatisticPrevious}
        tempValue={tempValue}
        tempUser={tempUser}
        current_year={current_year}
        previous_year={previous_year}
      />
    </>
  );
};

const Overview = () => {
  const currentYear = moment(new Date()).year();
  const [selectedMenu, setSelectedMenu] = useState("Overview");
  const [staticList, setStaticList] = useState([]);
  const [evaluationData, setEvaluationData] = useState([]);
  const [challangeData, setChallangeData] = useState([]);
  const [statisticData, setStatisticData] = useState([]);
  const [sortYear, setSortYear] = useState(false);
  const [sortYear2, setSortYear2] = useState(false);
  const [listYear, setListYear] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [listTopUser, setListTopUser] = useState([]);
  const [statisticChallenge, setStatisticChallenge] = useState(null);
  // eslint-disable-next-line
  const [challengeView, setchallengeView] = useState(null);
  const [generationIdeas, setGenerationIdeas] = useState(null);
  const [listUserIdeas, setListUserIdeas] = useState([]);
  const [statisticBrokenDown, setStatisticBrokenDown] = useState([]);
  const [challangeType, setChallangeType] = useState([]);
  const [sortByYear, setSortByYear] = useState(currentYear);
  const [sortByYear2, setSortByYear2] = useState(currentYear);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [download, setDownload] = useState(false);
  const [onShow, setOnShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [archetype, setArchetype] = useState([]);
  const [viewPage, setViewPage] = useState([]);
  const [userReturn, setUserReturn] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [totalCommunication, setTotalCommunication] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  let subMenus = ["Overview", "Ideas", "Challenges", "Engagement"];

  useEffect(() => {
    fetchDataInitial();
  }, [sortByYear]);

  const fetchDataInitial = async () => {
    setLoading(true);
    setStartDate("");
    setEndDate("");

    GeneralUtility.hitRefreshToken();
    try{

      let data = { years: [sortByYear, sortByYear - 1] };
      const responseIdeas = await AllOverviewRepo.getStaticList(data);
      if (responseIdeas.status === 200) {
        setStaticList(responseIdeas.data.data);
      }
  
      let dataEvaluation = { year: sortByYear };
      const responseEvaluation = await AllOverviewRepo.getStaticEvaluation(
        dataEvaluation
      );
      if (responseEvaluation.status === 200) {
        setEvaluationData(responseEvaluation.data.data);
      }
  
      let dataChallenge = { years: [sortByYear, sortByYear - 1] };
      const responseChallange = await AllOverviewRepo.getChallange(dataChallenge);
      if (responseChallange.status === 200) {
        setChallangeData(responseChallange.data.data);
      }
  
      let dataStatistic = { year: sortByYear };
      const responseStatistic = await AllOverviewRepo.getStatistic(dataStatistic);
      if (responseStatistic.status === 200) {
        // setStatisticData(responseStatistic.data.data.data);
        setStatisticData(responseStatistic.data.data);
      }
  
      const responseListYear = await AllOverviewRepo.getListYear();
      if (responseListYear.status === 200) {
        setListYear(responseListYear.data.data);
      }
  
      let dataUser = { year: sortByYear };
      const responseUser = await AllOverviewRepo.getUser(dataUser);
      if (responseUser.status === 200) {
        setListUser(responseUser.data.data);
      }
  
      let topUser = { year: sortByYear };
      const responseTopUser = await AllChallengesRepo.getTopUser(topUser);
      if (responseTopUser.status === 200) {
        setListTopUser(responseTopUser.data.data);
      }
  
      let engagementDate = { startDate, endDate };
      const responseUserArchetype = await AllOverviewRepo.getArchetype({
        startDate,
        endDate,
      });
      if (responseUserArchetype.status === 200) {
        setArchetype(responseUserArchetype.data.data);
      }
  
      const responseTotalViewPage = await AllOverviewRepo.getTotalViewPage(
        engagementDate
      );
      if (responseTotalViewPage.status === 200) {
        setViewPage(responseTotalViewPage.data.data);
      }
  
      const responseUserReturning = await AllOverviewRepo.getUserReturn(
        engagementDate
      );
      if (responseUserReturning.status === 200) {
        setUserReturn(responseUserReturning.data.data);
      }
  
      const responseTotUsers = await AllOverviewRepo.getTotUsers(engagementDate);
      if (responseTotUsers.status === 200) {
        setTotalUsers(responseTotUsers.data);
      }
  
      // engagemnet
      const responseCommunication = await AllOverviewRepo.getTotCommunication(
        engagementDate
      );
      if (responseCommunication.status === 200) {
        setTotalCommunication(responseCommunication.data);
      }
  
      let statisticChallenge = { year: sortByYear };
      const responseStatisticChallenge =
        await AllChallengesRepo.getStatisticChallenge(statisticChallenge);
      if (responseStatisticChallenge.status === 200) {
        setStatisticChallenge(responseStatisticChallenge?.data?.data);
      }
  
      let challengeView = { year: sortByYear };
      const responseChallengeView = await AllChallengesRepo.getAverageView(
        challengeView
      );
      if (responseChallengeView.status === 200) {
        setchallengeView(responseChallengeView.data.data);
      }
  
      let challengeType = { year: sortByYear };
      const responseChallengeType =
        await AllChallengesRepo.getStatisticChallengeType(challengeType);
      if (responseChallengeType.status === 200) {
        setChallangeType(responseChallengeType.data.data);
      }
  
      let ideasGeneration = { year: sortByYear };
      const responseIdeasGeneration = await AllIdeasRepo.getListGeneration(
        ideasGeneration
      );
      if (responseIdeasGeneration.status === 200) {
        setGenerationIdeas(responseIdeasGeneration.data.data);
      }
  
      let dataIdeas = { year: sortByYear };
      const responseTopUserIdeas = await AllIdeasRepo.getUser(dataIdeas);
      if (responseTopUserIdeas.status === 200) {
        setListUserIdeas(responseTopUserIdeas.data.data);
      }
  
      let dataIdeasStatisticStatusBroken = { year: sortByYear };
      const responseIdeasStatusBroken = await AllIdeasRepo.getStatusBrokenDown(
        dataIdeasStatisticStatusBroken
      );
      if (responseIdeasStatusBroken.status === 200) {
        setStatisticBrokenDown(responseIdeasStatusBroken.data.data);
      }
      setLoading(false)
    }catch(error){
      setLoading(false);
    }

  };

  const engagementData = async (data) => {
    let startD = DateUtility.dateFormatApi(data.startDate);
    let endD =
      data.endDate !== "" ? DateUtility.dateFormatApi(data.endDate) : "";

    GeneralUtility.hitRefreshToken();

    const responseUserArchetype = await AllOverviewRepo.getArchetype({
      startD,
      endD,
    });
    if (responseUserArchetype.status === 200) {
      setArchetype(responseUserArchetype.data.data);
    }

    const responseTotalViewPage = await AllOverviewRepo.getTotalViewPage({
      startD,
      endD,
    });
    if (responseTotalViewPage.status === 200) {
      setViewPage(responseTotalViewPage.data.data);
    }

    const responseUserReturning = await AllOverviewRepo.getUserReturn({
      startD,
      endD,
    });
    if (responseUserReturning.status === 200) {
      setUserReturn(responseUserReturning.data.data);
    }

    const responseTotUsers = await AllOverviewRepo.getTotUsers({
      startD,
      endD,
    });
    if (responseTotUsers.status === 200) {
      setTotalUsers(responseTotUsers.data);
    }

    const responseCommunication = await AllOverviewRepo.getTotCommunication({
      startD,
      endD,
    });
    if (responseCommunication.status === 200) {
      setTotalCommunication(responseCommunication.data);
    }
  };

  const renderTab = () => {
    let component = <div></div>;
    switch (selectedMenu) {
      case "Overview":
        component = (
          loading ?
           <IsLoading/> :
          
           <ViewOverview
           rootId={"OverviewPage"}
           ideasData={staticList}
           ideasByStages={evaluationData}
           challange={challangeData}
           statisticList={statisticData}
           users={listUser}
           sortYear={sortYear}
           sortByYear2={sortByYear2}
           sortYear2={sortYear2}
           setSortByYear2={setSortByYear2}
           setSortYear2={setSortYear2}
           listYear={listYear}
           sortByYear={sortByYear}
           setSortYear={setSortYear}
           onShow={onShow}
         />
        );
        break;
      case "Ideas":
        component = (
          loading ?
          <IsLoading/>
            :
            <Ideas
            rootId={"IdeasPage"}
            download={download}
            ideasByStages={evaluationData}
            ideasByGeneration={generationIdeas}
            ideasByUser={listUserIdeas}
            statusBroken={statisticBrokenDown}
            onShow={onShow}
          />

        );
        break;
      case "Challenges":
        component = (
         loading?
         <IsLoading/>
          :
          <Challenges
          rootId={"ChallengesPage"}
          statistic={statisticChallenge}
          listTopUser={listTopUser}
          average={challengeView}
          challangeType={challangeType}
          onShow={onShow}
        />
        );
        break;
      case "Users":
        component = loading ? <IsLoading/> : <Users rootId={"UsersPage"} />;
        break;
      case "Communication":
        component = loading ?<IsLoading/> :<Communication rootId={"CommunicationPage"} />;
        break;
      case "Engagement":
        component = (
          loading ? <IsLoading/>
          :
          <Engagement
            rootId={"EngagementPage"}
            totalUserName={
              totalUsers.length > 0
                ? [totalUsers[0].name, totalUsers[1].name, totalUsers[2].name]
                : []
            }
            totalUserData={
              totalUsers.length > 0
                ? [
                    totalUsers[0].total,
                    totalUsers[1].total,
                    totalUsers[2].total,
                  ]
                : []
            }
            newReturningUsersName={
              userReturn.length > 0
                ? [userReturn[0].name, userReturn[1].name]
                : []
            }
            newReturningUsersData={
              userReturn.length > 0
                ? [userReturn[0].total, userReturn[1].total]
                : []
            }
            usersByArchetypeName={
              archetype.length > 0
                ? [archetype[0].name, archetype[1].name, archetype[2].name]
                : []
            }
            usersByArchetypeValue={
              archetype.length > 0
                ? [archetype[0].total, archetype[1].total, archetype[2].total]
                : []
            }
            usersByArchetypeData={
              archetype.length > 0
                ? [
                    archetype[0].percentage,
                    archetype[1].percentage,
                    archetype[2].percentage,
                  ]
                : []
            }
            numberPageViewsName={
              viewPage.length > 0
                ? [
                    viewPage[0].name,
                    viewPage[1].name,
                    viewPage[2].name,
                    viewPage[3].name,
                  ]
                : []
            }
            numberPageViewsData={
              viewPage.length > 0
                ? [
                    viewPage[0].total,
                    viewPage[1].total,
                    viewPage[2].total,
                    viewPage[3].total,
                  ]
                : []
            }
            communicationName={
              totalCommunication !== null
                ? ["Users Comments", "Users Likes"]
                : []
            }
            communicationData={
              totalCommunication !== null
                ? [
                    totalCommunication.totalCommentary,
                    totalCommunication.totalLikeSubmitted,
                  ]
                : []
            }
            onShow={onShow}
          />
        );
        break;
      default:
        break;
    }
    return component;
  };

  const downloadPdf = async (downloadId) => {
    AlertUtility.loadingAlert();
    let listId = ["Overview", "Ideas", "Challenges", "Engagement"];
    let findId = listId.find((res) => res === downloadId);
    const input = document.getElementById(`${findId}Page`);
    var canvas = document.createElement("canvas");
    canvas.width = 3180;
    canvas.height = findId === "Overview" ? 3500 : 2800;
    var opts = {
      canvas: canvas,
      logging: true,
      useCORS: true,
      removeContainer: true,
      scale: 2,
    };
    html2canvas(input, opts).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const link = document.createElement("a");
      var imgWidth = 1080;
      var pageHeight = findId === "Overview" ? 1150 : 930;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var position = 50;
      var img = new Image();
      const pdf = new jsPDF("l", "pt", "a4");
      img.src =
        findId === "Overview"
          ? "/assets/global/img/ico_overview_green.png"
          : findId === "Ideas"
          ? "/assets/global/img/ico_ideas_green.png"
          : "/assets/global/img/ico_chellenges_green.png";

      pdf.addImage(img, "PNG", 30, 5, 110, 35);
      pdf.addImage(
        imgData,
        "PNG",
        findId === "Overview" ? -10 : -14,
        position,
        imgWidth,
        pageHeight
      );
      heightLeft -= pageHeight;

      while (heightLeft >= 0 && findId === "Overview") {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(
          imgData,
          "PNG",
          -14,
          position + 450,
          imgWidth,
          imgHeight + 60
        );
        heightLeft -= pageHeight;
      }

      while (heightLeft >= 0 && findId === "Challenges") {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(
          imgData,
          "PNG",
          -14,
          position + 290,
          imgWidth,
          imgHeight + 200
        );
        heightLeft -= pageHeight;
      }

      while (heightLeft >= 0 && findId === "Engagement") {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(
          imgData,
          "PNG",
          -16,
          position + 260,
          imgWidth,
          imgHeight + 100
        );
        heightLeft -= pageHeight;
      }

      pdf.save(`${findId} Page`);
      Swal.close();
    });
    setDownload(false);
  };

  const downloadExcel = async () => {
    AlertUtility.loadingAlert();
    let res = await AllOverviewRepo.exportUserExcel();
    if (res.status === 200) {
      const contentDisposition = res.headers["content-disposition"];
      const filenameMatch =
        contentDisposition && contentDisposition.match(/filename=([^;]+)/);
      const filename = filenameMatch
        ? filenameMatch[1]
        : "downloaded-file.xlsx";

      // Create a Blob URL for the blob
      const blobUrl = window.URL.createObjectURL(new Blob([res.data]));

      // Create a link element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;

      document.body.appendChild(link);

      Swal.close();
      link.click();

      document.body.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);
    } else {
      Swal.close();
      Swal.fire("Sorry...", "Fail to get file", "error");
    }
  };
  
  return (
    <div
      className={`container transition-opacity ${
        selectedMenu === "Overview" ? "xl:pr-32" : "xl:pr-32"
      } `}
    >
      <div
        className={`mx-16 mt-14 [&::-webkit-scrollbar]:hidden xxs:overflow-auto xxs:w-[330px] xs:w-[370px] sm:w-[490px] md:w-[670px] lg:w-[890px]  ${
          selectedMenu === "Overview" ? "xl:w-[1080px]" : "xl:w-[1080px]"
        } 2xl:w-[1250px] lg:overflow-hidden flex border-b-2 mr-16 border-gray-400`}
      >
        {subMenus.map((res, index) => {
          return (
            <div
              key={index}
              className={`mx-4 mt-8 flex-row border-b-2 font-bold transition-colors duration-300 ${
                res === selectedMenu ? "border-[#6cb556]" : "border-none"
              } ${
                res === selectedMenu ? "text-[#6cb556]" : "text-none"
              } enabled:hover:border-gray-400 cursor-pointer text-sm font-semibold`}
              onClick={() => setSelectedMenu(res)}
            >
              {res}
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 justify-between m-16 mt-7 gap-3">
        <div className="flex flex-row space-x-1">
          <div
            className={`flex flex-row h-10 w-[180px] text-xs md:text-sm cursor-pointer justify-center items-center bg-gradient-to-r from-[#44ad5c] via-[#66bf40] to-[#85c240] text-white`}
            onClick={() => {
              setShowConfirm(true);
              setOnShow(true);
            }}
          >
            Download PDF
            <div className="pl-6">
              <ArrowIco />
            </div>
          </div>
          <div
            className={`${
              selectedMenu === "Overview" ? "" : "hidden"
            } flex flex-row h-10 w-[230px] text-xs md:text-sm cursor-pointer py-2 px-2 bg-gradient-to-r from-[#44ad5c] via-[#66bf40] to-[#85c240] text-white justify-center items-center`}
            onClick={() => downloadExcel()}
          >
            Download Total User
            <div className="pl-6">
              <ArrowIco />
            </div>
          </div>
        </div>

        <div
          className={`${
            selectedMenu === "Engagement" ? "hidden" : ""
          } flex md:justify-self-end h-9`}
        >
          <div
            className={`items-center bg-white h-9 ${
              sortYear ? "2xl:h-auto" : "h-9"
            } w-44 py-2 border border-gray-400 text-[10px] z-10 bx_filter_sortby mr-8`}
          >
            <div
              className="flex items-center flex-row cursor-pointer h-[20px] justify-between w-fill font-Montserrat"
              onClick={() => setSortYear(!sortYear)}
            >
              <span className="uppercase px-2">
                Sort By Year : {sortByYear}
              </span>
              <img
                src="/assets/global/img/up-arrow.svg"
                className={`h-2 w-4 stroke-black ${
                  sortYear
                    ? "transition-width duration-300 easy rotate-0"
                    : "transition-width duration-300 easy rotate-180"
                }`}
                alt="logo"
              />
            </div>
            <div className="mt-1">
              {sortYear && (
                <div style={{ border: "0.5px solid #a3a3a3", borderTop: "none" }} className="bg-white mt-1">
                  {listYear &&
                    listYear.map((res) => {
                      return (
                        <div
                          style={{ paddingBottom: "3px" }}
                          key={res.year}
                          className="bg-white flex flex-row uppercase cursor-pointer hover:font-semibold items-center"
                          onClick={() => setSortByYear(res.year)}
                        >
                          <img
                            src={`${
                              sortByYear === res.year
                                ? "/assets/global/img/toggle - check.svg"
                                : "/assets/global/img/toggle.svg"
                            }`}
                            className={`h-4 w-6 stroke-black mt-1`}
                            alt="year"
                          />
                          <div>{res.year}</div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className={`${
            selectedMenu === "Engagement" ? "" : "hidden"
          } flex lg:justify-self-end h-9 space-x-5 lg:mt-[-10px] mr-8`}
        >
          <div>
            <div className="font-semibold text-xs mb-1">Start Date :</div>
            <DateRange
              value={startDate}
              onOpen={() => {}}
              onChange={(dateObject) => {
                let startDate = new Date(dateObject);
                if (startDate > endDate) {
                  setEndDate("");
                  engagementData({ startDate, endDate: "" });
                } else {
                  engagementData({ startDate, endDate });
                }
                setStartDate(startDate);
              }}
              inputClass="h-9 ml-[-1px] w-[150px] font-Montserrat text-xs border border-gray-400 bg-white placeholder:text-gray-500 cursor-pointer ml-[-11px]"
              placeholder="Start Date"
            />
          </div>

          <div>
            <div className="font-semibold text-xs mb-1">End Date :</div>
            <DateRange
              disabled={!startDate === true}
              value={endDate}
              minDate={startDate}
              onOpen={() => {}}
              onChange={(dateObject) => {
                let endDate = new Date(dateObject);
                setEndDate(endDate);
                engagementData({ startDate, endDate });
              }}
              inputClass="h-9 ml-[-1px] w-[150px] font-Montserrat text-xs border border-gray-400 bg-white placeholder:text-gray-500 cursor-pointer ml-[-11px]"
              placeholder="End Date"
            />
          </div>
        </div>
      </div>
      {renderTab()}
      <ConfirmPopup
        show={showConfirm}
        setShow={(value) => {
          setShowConfirm(value);
          setOnShow(false);
        }}
        onSave={() => {
          downloadPdf(selectedMenu);
          setShowConfirm(false);
          setOnShow(false);
        }}
        message="Are you sure want to download to pdf?"
      />
    </div>
  );
};

export default Overview;
