import React, { useState, useEffect } from "react";
import RichText from "../../../../components/RichText";
import BtnSubmit from "../../../../components/buttons/BtnSubmit";
import CategoryRepo from "../../../../repositories/admin-repo/CategoryRepo";
import FilterRepo from "../../../../repositories/user-repo/FilterRepo";
import AlertUtility from "../../../../utility/alert-utility";
import DragDropLC from "./DragDropLC";
import DateUtility from "../../../../utility/date-utility";
import GeneralUtility from "../../../../utility/GeneralUtility";
import UploadImage from "../../../../components/uploadImage/UploadImage";
import InputText from "../../../../components/input/InputText";
import RichTextEditor from "../../../../components/RichTextEditor";

const Info = ({ setPage, setInfo, error, info, start_date, setStartDate, type, once, setOnce, }) => {
  const [infoOnce, setInfoOnce] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("default");
  const [end_date, setEndDate] = useState("");
  const [overview, setOverview] = useState("");
  const [documents, setDocuments] = useState([]);
  const [card_image, setCardImage] = useState("");
  const [banner_image, setBannerImage] = useState("");
  const [listCategory, setListCategory] = useState([]);
  const [listPrivacy, setListPrivacy] = useState([]);
  const [listTeam, setListTeam] = useState([]);
  const [statusprivacy, setStatusprivacy] = useState(null);
  const [typeidea, setTypeIdea] = useState(null);
  const [links, setLinks] = useState([{ resourceName: "", source: "" }]);
  const [remove_banner, setRemoveBanner] = useState("");
  const [remove_card, setRemoveCard] = useState("");

  const [rewards, setRewards] = useState([
    {
      title: "Stage 1",
      reward: "200",
      description: "Submit a valid idea and receive R200 per idea.",
      index: "1",
    },
    {
      title: "Stage 2",
      reward: "1,000",
      description:
        "Should the idea pass validation by showing potential to go into an experiment you could receive R 1,000.",
      index: "2",
    },
    {
      title: "Stage 3",
      reward: "10,000",
      description:
        "If the experiment is a success and demonstrates value as an implementable solution you could receive R 10,000.",
      index: "3",
    },
    {
      title: "Stage 4",
      reward: "50,000",
      description:
        "Successfully manage the implementation of your idea and receive up to R 50,000. If you decide not to manage the implementation you could still receive up to R 25,000.",
      index: "4",
    },
  ]);

  const dataApi = () => {
    if (type === "2") {
      setTitle(info.title);
      setCategory(info.category !== null ? info.category : "default");
      setLinks(info.links);
      setRewards(
        info.rewards
          ? info.rewards
          : [
            {
              title: "Stage 1",
              reward: "200",
              description: "Submit a valid idea and receive R200 per idea..",
              index: "1",
            },
            {
              title: "Stage 2",
              reward: "1,000",
              description:
                "Should the idea pass validation by showing potential to go into an experiment you could receive R 1,000.",
              index: "2",
            },
            {
              title: "Stage 3",
              reward: "10,000",
              description:
                "If the experiment is a success and demonstrates value as an implementable solution you could receive R 1,0000.",
              index: "3",
            },
            {
              title: "Stage 4",
              reward: "50,000",
              description:
                "Successfully manage the implementation of your idea and receive up to R 50,000. If you decide not to manage the implementation you could still receive up to R 25,000.",
              index: "4",
            },
          ]
      );
      setEndDate(info.end_date);
      setDocuments(info.documents);
      setBannerImage(info.banner_image);
      setCardImage(info.card_image);
      setOverview(info.overview)
      setStatusprivacy(info.statusprivacy !== null ? info.statusprivacy : null);
      setTypeIdea(info.typeidea !== null ? info.typeidea : null);
    } else {
      setTitle(info.title ? info.title : "");
      setCategory(info.category ? info.category.id : "default");
      setLinks(info.links ? info.links : [{ resourceName: "", source: "" }]);
      setRewards(
        info.rewards
          ? info.rewards
          : [
            {
              title: "Stage 1",
              reward: "200",
              description: "Submit a valid idea and receive R200 per idea..",
              index: "1",
            },
            {
              title: "Stage 2",
              reward: "1,000",
              description:
                "Should the idea pass validation by showing potential to go into an experiment you could receive R 1,000.",
              index: "2",
            },
            {
              title: "Stage 3",
              reward: "10,000",
              description:
                "If the experiment is a success and demonstrates value as an implementable solution you could receive R 10,000.",
              index: "3",
            },
            {
              title: "Stage 4",
              reward: "50,000",
              description:
                "Successfully manage the implementation of your idea and receive up to R 50,000. If you decide not to manage the implementation you could still receive up to R 25,000.",
              index: "4",
            },
          ]
      );
      setOverview(info.overview)
      setEndDate(info.end_date ? info.end_date : "");
      setDocuments(info.documents ? info.documents : []);
      setBannerImage(info.banner_image ? info.banner_image : "");
      setCardImage(info.card_image ? info.card_image : "");
      setStatusprivacy(info.statusprivacy ? info.statusprivacy.id : null);
      setTypeIdea(info.typeidea ? info.typeidea.id : null);
    }
    setOnce(true);
  };

  /**
   * function to add row links
   */
  const handleAddLinks = () => {
    const values = [...links];
    values.push({
      resourceName: "",
      source: "",
    });

    setLinks(values);
  };

  /**
   * function to remove row links
   * @param {*} index
   */
  const handleRemoveLinks = (index) => {
    const values = [...links];
    values.splice(index, 1);

    setLinks(values);
  };

  /**
   * funtion to handle links value
   * @param {*} index
   * @param {*} event
   */

  const handleInputLinks = (index, event) => {
    const values = [...links];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;

    setLinks(values);
  };

  /**
   * handle changes input in
   * @param {*} index
   * @param {*} event
   */
  const handleInputChange = (index, event) => {
    const values = [...rewards];
    const updatedValue = event.target.name;
    if (updatedValue === "reward") {
      values[index][updatedValue] = GeneralUtility.removeNonNumeric(
        event.target.value
      );
    } else {
      values[index][updatedValue] = event.target.value;
    }

    setRewards(values);
  };

  /**
   * funtion to get dropdown data type challenge/category
   */
  const getCategory = async () => {
    const result = await CategoryRepo.getCategory();
    let listCategory = result;

    setListCategory(listCategory);
  };

  /**
   * funtion to get dropdown data list privacy
   */
  const getPrivacy = async () => {
    const result = await FilterRepo.getPrivacy();
    let listPrivacy = result.data.data;
    setListPrivacy(listPrivacy);
  };

  /**
   * get list Team, No team
   */
  const getTeam = async () => {
    const result = await FilterRepo.getTypeIdea();
    let listTeam = result.data.data;
    setListTeam(listTeam);
  };

  /**
   * funtion to handle selected privacy
   * @param {*} id
   */
  const changePrivacy = async (id) => {
    setStatusprivacy(id);
  };

  /**
   * handler selected team/ type idea no team, team
   * @param {*} id
   */
  const changeTeam = async (id) => {
    setTypeIdea(id);
  };

  /**
   * function to delete selected card/banner image
   */
  const delImage = (image, type) => {
    if (image) {
      if (type === "card") {
        if (image.name) {
          setCardImage("");
        } else {
          setRemoveCard(image);
          setCardImage("");
        }
      } else {
        if (image.name) {
          setBannerImage("");
        } else {
          setRemoveBanner(image);
          setBannerImage("");
        }
      }
    }
  };

  /**
   * function to handle and validate change in end date
   */
  const validateDate = () => {
    if (start_date !== "") {
      let startDate = new Date(start_date + " 23:59:00").getTime();
      let endDate = new Date(end_date).getTime();
      let d = new Date();
      let dateNow = new Date(
        DateUtility.changeFormatDate(d) + " 00:00:00"
      ).getTime();
      if (startDate <= dateNow) {
        AlertUtility.errorAlert({
          title: "Start date must be more than today!",
        });
        setStartDate(DateUtility.changeFormatDate(dateNow));
      }
      if (startDate > endDate) {
        AlertUtility.errorAlert({
          title: "End date must be later than start date!",
        });
        setEndDate("");
      }
    }
  };

  /**
   * function to handle save info form
   * @param {*} e
   */
  const handleSave = async (e) => {
    let priv = statusprivacy === null ? null : { id: statusprivacy };
    let data;
    if (type === "1") {
      let newCategory = category === "default" ? null : category;
      data = {
        banner_image,
        card_image,
        title,
        category: { id: newCategory },
        start_date,
        end_date,
        overview,
        documents,
        statusprivacy: priv,
        typeidea: { id: typeidea },
        links,
        rewards,
      };
    } else {
      let newOverview = overview ? overview : info.overview;
      let newCategory = category === "default" ? null : category;
      data = {
        id: info.id,
        banner_image,
        card_image,
        title,
        category: { id: newCategory },
        start_date,
        end_date,
        overview: newOverview,
        documents,
        statusprivacy: priv,
        typeidea: { id: typeidea },
        links,
        rewards,
        remove_banner,
        remove_card,
      };
    }
    setInfo(data);
    setPage("form");
  };

  useEffect(() => {
    if (infoOnce === false) {
      dataApi();
      getCategory();
      getPrivacy();
      getTeam();
      setInfoOnce(true);
    }

    validateDate();
  }, [end_date, start_date, error]);

  return (
    <>
      <div className="space-y-3">
        <div id="challenges" className="space-y-1">
          <h3 className="text-textBlack flex font-semibold pt-3 text-md pb-1">
            Challenge Image{" "}
            <label className="text-md text-heritageGreen font-bold">*</label>
          </h3>
          <div className="flex space-x-4">
            <div className="w-[600px] h-[150px]">
              <UploadImage
                title="Banner Image"
                width="full"
                label="banner image"
                getImg={(img) => {
                  setBannerImage(img);
                }}
                name="banner_image"
                onDelete={() => delImage(banner_image, "banner")}
                image={banner_image}
              />
              <p className="text-red-500 text-[10px]">{error.banner_image}</p>
            </div>

            <div className="w-[225px]">
              <UploadImage
                title="Card image"
                width="full"
                label="card image"
                getImg={(img) => {
                  setCardImage(img);
                }}
                name="card_image"
                onDelete={() => delImage(card_image, "card")}
                image={card_image}
              />
              <p className="text-red-500 text-[10px]">{error.card_image}</p>
            </div>
          </div>

          <div className="text-textGray text-xs">
            <p>
              {" "}
              <b> Banner image: 1000 x 250 pixels </b> and{" "}
              <b> Card image: 600 x 400 pixels </b>{" "}
            </p>
            <p>
              {" "}
              <b> Max file size: 5MB</b>{" "}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div id="category" className="space-y-3">
            <h3 className="text-textBlack flex font-semibold pt-3 text-md">
              Type
            </h3>
            <div>
              <select
                name="category"
                className="w-full h-10 border-none shadow-md text-sm text-textGray"
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                value={category}
              >
                <option value="default" disabled>
                  -- Select Type --
                </option>
                {listCategory.map((items, index) => (
                  <option key={index} value={items.id}>
                    {items.name}
                  </option>
                ))}
              </select>
              <p className="text-red-500 text-xs">{error.category}</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 pb-12">
          <div>
            <div id="startdate" className="space-y-3 h-24 pt-1.5">
              <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                Start Date
              </h3>
              <div>
                <InputText value={start_date} onChange={(e) => setStartDate(e.target.value)} type="date" />
                <p className="text-red-500 text-xs">{error.start_date}</p>
              </div>
            </div>
            <div id="privacy" className="space-y-2 h-24 mb-3 pt-1.5">
              <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                Privacy
              </h3>
              {listPrivacy.map((items, index) => (
                <div key={index}>
                  <input
                    autoComplete="off"
                    onChange={(e) => changePrivacy(e.target.value, items.id)}
                    defaultChecked={statusprivacy === items.id ? true : false}
                    value={items.id}
                    id={items.id}
                    name="privacy"
                    type="radio"
                  />
                  <label htmlFor={items.id} className="radio-custom-label">
                    <p className="font-semibold text-textBlack text-sm">
                      {items.name.charAt(0).toUpperCase() + items.name.slice(1)}
                    </p>
                    <p className="text-textGray text-xs">
                      {items.name === "public"
                        ? "Any member can view this challenge"
                        : "A member must be invited to view this challenge"}
                    </p>
                  </label>
                </div>
              ))}
              <p className="text-red-500 text-[10px]">{error.privacy}</p>
            </div>
          </div>

          <div>
            <div id="endate" className="space-y-3 h-24 pt-1.5">
              <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                End Date
              </h3>
              <div>
                <InputText value={end_date} onChange={(e) => setEndDate(e.target.value)} disabled={start_date ? false : true} type="date" />
                <p className="text-red-500 text-xs">{error.end_date}</p>
              </div>
            </div>
            <div id="team" className="space-y-2 pt-2">
              <h3 className="text-textBlack flex font-semibold pt-3 text-md">
                Team
              </h3>
              {listTeam.map((items, index) => (
                <div key={index}>
                  <input
                    autoComplete="off"
                    onChange={(e) => changeTeam(e.target.value, items.id)}
                    defaultChecked={typeidea === items.id ? true : false}
                    value={items.id}
                    id={items.id}
                    name="team"
                    type="radio"
                  />
                  <label htmlFor={items.id} className="radio-custom-label">
                    <p className="font-semibold text-textBlack text-sm">
                      {items.name === "personal" ? "No Team" : "Team Ideas"}
                    </p>
                    <p className="text-textGray text-xs">
                      {items.name === "personal"
                        ? "Do not allow team ideas"
                        : "Allow team ideas"}
                    </p>
                  </label>
                </div>
              ))}
              <p className="text-red-500 text-[10px]">{error.typeidea}</p>
            </div>
          </div>
        </div>
        <div className="bg-gray-300 w-full h-0.5"></div>
        <div>
          <div id="title" className="space-y-3">
            <h3 className="text-textBlack flex font-semibold pt-1 text-md">
              Title <label className="font-bold text-freshGreen">*</label>
            </h3>
            <InputText value={title} onChange={(e) => setTitle(e.target.value)} maxLength="100" type="text" placeholder="Enter a title with a maximum length of 100 characters" />
            <p className="text-red-500 text-xs">{error.title}</p>
          </div>
          <div id="overview" className="space-y-3">
            <h3 className="text-textBlack flex font-semibold pt-3 text-md">
              Challenge Overview <p className="font-bold text-freshGreen">*</p>
            </h3>
            <RichTextEditor
              value={overview}
              onChange={(content) => {
                setOverview(content);
              }}
              initialValue={info.overview}
            />
            <p className="text-red-500 text-[10px] py-0.5">{error.overview}</p>
          </div>

          {/* add links */}
          <div className="space-y-2">
            <h3 className="font-semibold text-black"> Add Links </h3>
            <div className="space-y-2">
              {links.length > 0 && (
                  links.map((field, index) => (
                    <div key={index} className="flex space-x-3 items-center">
                      <div className=" basis-4/12" id="resourceName">
                        <InputText type="text" names="resourceName" placeholder="Resources Name" value={field.resourceName} onChange={(event) => handleInputLinks(index, event)} />
                      </div>
                      <div className="basis-7/12" id="source">
                        <InputText type="text" names="source" placeholder="Paste URL" value={field.source} onChange={(event) => handleInputLinks(index, event)} />
                      </div>
                      <div className="basis-1/12 pl-4">
                        <img
                          onClick={() => handleRemoveLinks(index)}
                          className="cursor-pointer basis-1/12"
                          src="/assets/global/img/bin.svg"
                          alt="trash"
                        />
                      </div>
                    </div>
                  ))
              )}
            </div>
            <button onClick={() => handleAddLinks()}>
              <img
                src="/assets/global/img/squarePlus.png"
                className="w-14 h-14"
                alt="add"
              />
            </button>
          </div>

          <div className="file">
            <h3 className="text-textBlack flex font-semibold pt-3 text-md">
              Upload Resources
            </h3>
            <DragDropLC docs={documents} setDocs={setDocuments} />
          </div>

          <h3 className="text-textBlack flex font-semibold pt-3 text-md">
            Add Rewards
          </h3>
          <div className="mx-auto mt-3">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
              {rewards.map((items, index) => (
                <div
                  className="flex justify-center bg-white w-full"
                  key={index}
                >
                  <div className="shadow-md w-full">
                    <div
                      className={`${index === 0
                        ? "bg-gradient-to-r from-orange-600 to-pink-600"
                        : ""
                        }
                      ${index === 1
                          ? "bg-gradient-to-r from-futureGreen to-heritageGreen"
                          : ""
                        }
                      ${index === 2
                          ? "bg-gradient-to-r from-yellow-200 to-blueSky"
                          : ""
                        }
                      ${index === 3
                          ? "bg-gradient-to-r from-orange-300 to-yellow-300"
                          : ""
                        } h-24 w-full flex items-center bg-cover`}
                    >
                      <div
                        className="bg-cover h-full w-full flex items-center justify-between"
                        style={{
                          backgroundImage: `url("/assets/global/img/Pattern2.png")`,
                        }}
                      >
                        <div className="h-full w-full flex items-center justify-between p-3">
                          <div className="flex items-center">
                            <div className="w-7 h-7">
                              <img
                                src={`${index === 0
                                  ? "/assets/global/img/lightbulb.svg"
                                  : ""
                                  }
                                ${index === 1
                                    ? "/assets/global/img/check.png"
                                    : ""
                                  }
                                ${index === 2
                                    ? "/assets/global/img/science-white.png"
                                    : ""
                                  }
                                ${index === 3
                                    ? "/assets/global/img/diamond.png"
                                    : ""
                                  }`}
                                alt="image_ico"
                                className="mt-1"
                              />
                            </div>
                            <input
                              autoComplete="off"
                              className="bg-transparent font-semibold p-0 ml-2 mr-3 text-white text-md w-full border-t-transparent border-l-transparent border-r-transparent focus:fill-none border-b-2 border-b-white"
                              type="text"
                              name="title"
                              value={items.title}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                            />
                          </div>
                          <div className="text-white mr-3">
                            <div className="flex items-center">
                              <label className="text-md font-semibold">R</label>
                              <input
                                autoComplete="off"
                                className="bg-transparent p-0 w-20 text-white text-md border-none focus:fill-none"
                                type="text"
                                name="reward"
                                placeholder={items.reward}
                                value={GeneralUtility.addCommas(items.reward)}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                              />
                            </div>
                            <div className="text-sm border-t-2">REWARD</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <input
                        autoComplete="off"
                        type="hidden"
                        name="steps"
                        value={items.index}
                      />
                      <textarea
                        className="w-full h-20 border-none shadow-md text-[10px] text-center"
                        type="text"
                        name="description"
                        placeholder={items.description}
                        value={items.description}
                        onChange={(event) => handleInputChange(index, event)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="pt-4 text-right">
          <BtnSubmit onClick={handleSave} title="Save & Next" />
        </div>
      </div>
    </>
  );
};

export default Info;
