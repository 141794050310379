import React, { useEffect, useState } from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import StatisticDashRepo from "../../../../repositories/user-repo/StatisticDashRepo";
import Skeleton from "../../../../components/skeleton/Skeleton";

const IdeaDashboard = () => {
  const [staticDash, setStaticDash] = useState({});
  const [listEval, setListEval] = useState([]);
  const [loading, setLoading] = useState(true);

  /**
   * funtion to get statistic dashboard
   */
  const getStaticDash = async () => {
    setLoading(true);
    const result = await StatisticDashRepo.getStatistic();
    if (result.status === 200) {
      let listStatic = result.data.data;
      let listStaticEval = result.data.data.listEvaluation;
      setStaticDash(listStatic);
      setListEval(listStaticEval);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStaticDash();
  }, []);

  return (
    <div className="bg-baseGray m-4 md:m-6 bx_idea_dasboard">
      <div className="p-4 md:p-6 bx_sub_idea_dasboard">
        <div className="font-bold text-xl">Ideas Dashboard</div>
        <div className="font-semibold text-lg py-3">Personal Statistics</div>
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-0 gap-y-3 md:gap-y-3 lg:gap-2">
          <div className="shadow-md w-full bg-white">
            <div className="px-2 py-3">
              <div>
                <h3 className="font-semibold text-sm break-all h-12">
                  Total Ideas in Progress
                </h3>
              </div>
              {!loading ? 
              <div className="flex">
                <div>
                  <CircularProgressbarWithChildren
                    strokeWidth={6}
                    value={staticDash.totalIdeaSubmited}
                    maxValue={staticDash.totalIdeaSubmited}
                    className="h-12 w-12"
                    styles={buildStyles({ pathColor: "#50B848" })}
                  >
                    <img
                      style={{ width: 15, position: "center", marginTop: -2 }}
                      src="/assets/global/img/lightbulbGreen.png"
                      alt="lightbulb"
                    />
                  </CircularProgressbarWithChildren>
                </div>
                <div className="ml-2">
                  <h3 className="text-sm font-semibold text-freshGreen space-y-3">
                    {staticDash.totalIdeaSubmited} Ideas
                  </h3>
                  <p className="text-xs text-gray-500 font-normal break-all">
                    Submitted
                  </p>
                </div>
              </div>
              :
              <div>
                <Skeleton col="1" height="150" />
              </div>
            }
            </div>
          </div>
          <div className="w-full col-span-4 shadow-md p-3 bg-white">
            <div className="h-12">
              <h3 className="font-semibold text-sm">Breakdown per Stage</h3>
            </div>

            {!loading ? (
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-1">
                {listEval.map((items, index) => (
                  <div key={index} className="px-1 py-3 lg:py-0">
                    <div className="flex">
                      <div>
                        <CircularProgressbarWithChildren
                          strokeWidth={6}
                          value={items.totalSubmited}
                          maxValue={staticDash.totalIdeaSubmited}
                          className="h-12 w-12"
                          styles={buildStyles({
                            pathColor: `${
                              items.evaluationName === "Generation"
                                ? "#00C0E8"
                                : ""
                            } 
                          ${
                            items.evaluationName === "Experimentation"
                              ? "#F37021"
                              : ""
                          } 
                          ${
                            items.evaluationName === "Commercialisation"
                              ? "#ED0080"
                              : ""
                          }
                          ${
                            items.evaluationName === "Validation"
                              ? "#E5D900"
                              : ""
                          }
                          `,
                          })}
                        >
                          <img
                            style={{
                              width: 15,
                              position: "center",
                              marginTop: -2,
                            }}
                            src={`${
                              items.evaluationName === "Generation"
                                ? "/assets/global/img/lightbulbBlue.png"
                                : ""
                            } 
                              ${
                                items.evaluationName === "Experimentation"
                                  ? "/assets/global/img/science.png"
                                  : ""
                              } 
                              ${
                                items.evaluationName === "Commercialisation"
                                  ? "/assets/global/img/diamondPink.png"
                                  : ""
                              }
                          ${
                            items.evaluationName === "Validation"
                              ? "/assets/global/img/checkYellow.png"
                              : ""
                          }
                          `}
                            alt="lightbulb"
                          />
                        </CircularProgressbarWithChildren>
                      </div>
                      <div className="ml-2">
                        <h3
                          className={`${
                            items.evaluationName === "Generation"
                              ? "text-blueSky"
                              : ""
                          } 
                        ${
                          items.evaluationName === "Experimentation"
                            ? " text-naartjie"
                            : ""
                        }
                        ${
                          items.evaluationName === "Commercialisation"
                            ? "text-cerise"
                            : ""
                        }
                        ${
                          items.evaluationName === "Validation"
                            ? " text-yellowTag"
                            : ""
                        }
                        text-sm font-semibold space-y-3`}
                        >
                          {items.totalSubmited} Ideas
                        </h3>
                        <p className="text-xs lg:w-26 text-gray-500 font-normal break-all">
                          In {items.evaluationName}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <Skeleton col="4" height="150" />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* NOT YET - HIDDEN */}
      <div className="p-6 hidden">
        <div className="font-semibold text-lg py-3">Team Statistics</div>
        <div className="grid grid-cols-1 lg:grid-cols-5 bg-white">
          <div className="shadow-md w-full grid-cols-1">
            <div className="p-3">
              <div>
                <h3 className="font-bold mb-3 text-sm">
                  Total Ideas in Evaluation
                </h3>
              </div>
              <div className="flex">
                <div>
                  <CircularProgressbarWithChildren
                    strokeWidth={6}
                    value={66}
                    className="h-14 w-14"
                    styles={buildStyles({ pathColor: "#50B848" })}
                  >
                    <img
                      style={{ width: 15, position: "center", marginTop: -2 }}
                      src="/assets/global/img/lightbulbGreen.png"
                      alt="lightbulb"
                    />
                  </CircularProgressbarWithChildren>
                </div>
                <div className="ml-2">
                  <h3 className="text-md font-bold text-freshGreen space-y-3">
                    {staticDash.totalIdeaSubmited} Ideas
                  </h3>
                  <p className="text-xs text-gray-500 font-normal">
                    Submitted this year
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full col-span-4 shadow-md p-3 bg-white">
            <div>
              <h3 className="font-bold text-sm">Breakdown per Stage</h3>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-4 gap-1 ">
              <div className="p-3">
                <div className="flex">
                  <div>
                    <CircularProgressbarWithChildren
                      strokeWidth={6}
                      value={66}
                      className="h-14 w-14"
                      styles={buildStyles({ pathColor: "#00C0E8" })}
                    >
                      <img
                        style={{
                          width: 15,
                          position: "center",
                          marginTop: -2,
                        }}
                        src="/assets/global/img/lightbulbBlue.png"
                        alt="lightbulb"
                      />
                    </CircularProgressbarWithChildren>
                  </div>
                  <div className="ml-2">
                    <h3 className="text-md font-bold text-blueSky space-y-3">
                      0 Ideas
                    </h3>
                    <p className="text-xs text-gray-500 font-normal">
                      In Generation
                    </p>
                  </div>
                </div>
              </div>

              <div className="p-3">
                <div className="flex">
                  <div>
                    <CircularProgressbarWithChildren
                      strokeWidth={6}
                      value={66}
                      className="h-14 w-14"
                      styles={buildStyles({ pathColor: "#E5D900" })}
                    >
                      <img
                        style={{
                          width: 15,
                          position: "center",
                          marginTop: -2,
                        }}
                        src="/assets/global/img/checkYellow.png"
                        alt="lightbulb"
                      />
                    </CircularProgressbarWithChildren>
                  </div>
                  <div className="ml-2">
                    <h3 className="text-md font-bold text-yellowTag space-y-3">
                      0 Ideas
                    </h3>
                    <p className="text-xs text-gray-500 font-normal">
                      In Validation
                    </p>
                  </div>
                </div>
              </div>

              <div className="p-3">
                <div className="flex">
                  <div>
                    <CircularProgressbarWithChildren
                      strokeWidth={6}
                      value={66}
                      className="h-14 w-14"
                      styles={buildStyles({ pathColor: "#F37021" })}
                    >
                      <img
                        style={{
                          width: 15,
                          position: "center",
                          marginTop: -2,
                        }}
                        src="/assets/global/img/science.png"
                        alt="science"
                      />
                    </CircularProgressbarWithChildren>
                  </div>
                  <div className="ml-2">
                    <h3 className="text-md font-bold text-orange space-y-3">
                      0 Ideas
                    </h3>
                    <p className="text-xs text-gray-500 font-normal">
                      In Experimentation
                    </p>
                  </div>
                </div>
              </div>

              <div className="p-3">
                <div className="flex">
                  <div>
                    <CircularProgressbarWithChildren
                      strokeWidth={6}
                      value={66}
                      className="h-14 w-14"
                      styles={buildStyles({ pathColor: "#ED0080" })}
                    >
                      <img
                        style={{
                          width: 15,
                          position: "center",
                          marginTop: -2,
                        }}
                        src="/assets/global/img/diamondPink.png"
                        alt="science"
                      />
                    </CircularProgressbarWithChildren>
                  </div>
                  <div className="ml-2">
                    <h3 className="text-md font-bold text-cerise space-y-3">
                      0 Ideas
                    </h3>
                    <p className="text-xs text-gray-500 font-normal">
                      In Commercialisation
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* NOT YET - HIDDEN */}
    </div>
  );
};

export default IdeaDashboard;
