// ROUTE USER

const login = "/"
const register = "/register"
const userHome = "/home"
const userLatestChallenges = "/latest-challenges"
const userArchivedChallenges = "/archived-challenges"
const userMyIdeas = "/myideas"
const userMyTeams = "/myteams"
const personalIdeas = "/myideas/personal-ideas"
const teamIdeas = "/myideas/team-ideas"
const createPersonal = "/myideas/create-personal-ideas"
const createTeam = "/myideas/create-team-ideas"
const editPersonal = "/myideas/edit-personal-ideas"
const editTeam = "/myideas/edit-team-ideas"
const submitIdea = "/myideas/submit-ideas"
const submitPersonal = "/myideas/personal-ideas/submit";
const submitTeam = "/myideas/team-ideas/submit";
const createIdea = "/latest-challenges/create-ideas";
const createSelectIdea = "/latest-challenges/submit-select";
const sumbitedIdea = "/latest-challenges/submited-ideas";
const detailIdea = "/latest-challenges/detail-ideas";
const searchPage = "/search-page";
const authUser = "/authUser";
const profile = "/profile";
const termsandcond = "/terms-and-conditions"
const sumbitedArcIdea = "/archived-challenges/submited-ideas";
const detailArcIdea = "/archived-challenges/detail-ideas";
const detailViewIdea = "/myideas/detail-ideas";


const RouteName = {
    login,
    register,
    userHome,
    userLatestChallenges,
    userArchivedChallenges,
    userMyIdeas,
    userMyTeams,
    personalIdeas,
    teamIdeas,
    createPersonal,
    createTeam,
    editPersonal,
    editTeam,
    submitIdea,
    createIdea,
    createSelectIdea,
    sumbitedIdea,
    detailIdea,
    submitPersonal,
    submitTeam,
    searchPage,
    authUser,
    profile,
    termsandcond,
    sumbitedArcIdea,
    detailArcIdea,
    detailViewIdea
};

export default RouteName
