// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes isLoading_spin__0pyig {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.isLoading_spinner__Sy1dm {
    border: 4px solid rgba(0, 0, 0, 0.1); 
    border-top: 4px solid #009677; 
    border-radius: 50%; 
    width: 43px; 
    height: 43px; 
    animation: isLoading_spin__0pyig 1.3s linear infinite; 
    margin: auto; 
    display: block;
    z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/components/loading/isLoading.module.css"],"names":[],"mappings":"AAAA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC;;AAEA;IACI,oCAAoC;IACpC,6BAA6B;IAC7B,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,qDAAoC;IACpC,YAAY;IACZ,cAAc;IACd,WAAW;AACf","sourcesContent":["@keyframes spin {\r\n    0% { transform: rotate(0deg); }\r\n    100% { transform: rotate(360deg); }\r\n}\r\n\r\n.spinner {\r\n    border: 4px solid rgba(0, 0, 0, 0.1); \r\n    border-top: 4px solid #009677; \r\n    border-radius: 50%; \r\n    width: 43px; \r\n    height: 43px; \r\n    animation: spin 1.3s linear infinite; \r\n    margin: auto; \r\n    display: block;\r\n    z-index: 99;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `isLoading_spinner__Sy1dm`,
	"spin": `isLoading_spin__0pyig`
};
export default ___CSS_LOADER_EXPORT___;
