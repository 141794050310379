import React from "react";
import Swal from "sweetalert2";
import RouteName from "../../../../../services/routename";
import { useNavigate } from "react-router-dom";
import PersonalRepo from "../../../../../repositories/user-repo/PersonalRepo";
import ArrowIco from "../../../../../components/icons/ArrowIco";
import BtnDropdown from "../../../../../components/buttons/BtnDropdown";
import AlertUtility from "../../../../../utility/alert-utility";
import Cookies from "js-cookie";
import EncryptDecrypt from "../../../../../utility/encrypt-decrypt";

const Personal = ({ list, listView }) => {
  const navigate = useNavigate();

  // for go to detail to edit ideas page
  const goDetail = async (id) => {
    navigate(`${RouteName.editPersonal}/${id}`);
  };

  //for go to detail ideas submitted
  const goView = async (params) => {
    Cookies.set("slugChallenge", EncryptDecrypt.enc(params.slug), { secure: true });
    Cookies.set("idChallenge", EncryptDecrypt.enc(""), { secure: true  });
    navigate(RouteName.detailViewIdea + "/" + params.id);
  };

  /**
   * function to delete idea
   * @param {*} id
   */
  const deleteIdea = async (param) => {
    AlertUtility.loadingAlert();
    const result = await PersonalRepo.deletePersonalIdea({ id: param.id });
    if (result.status === 200) {
      Swal.close();
      AlertUtility.successAlert({
        title: "Your idea was successfully deleted.",
      });
      window.location = RouteName.personalIdeas;
    } else {
      Swal.close();
      AlertUtility.errorAlert({
        title: "There is some problem. Please try again.",
      });
    }
  };

  /**
   * function to duplicate idea
   * @param {*} id
   */
  const duplicateIdea = async (id) => {
    AlertUtility.loadingAlert();
    const result = await PersonalRepo.duplicatePersonalIdea({ id: id });
    if (result.status === 200) {
      Swal.close();
      AlertUtility.successAlert({
        title: "Your idea was successfully duplicated.",
      });
      window.location = RouteName.personalIdeas;
    } else {
      Swal.close();
      AlertUtility.errorAlert({
        title: "There is some problem. Please try again.",
      });
    }
  };

  return (
    <div>
      <div className={` ${listView ? "mx-auto" : "hidden"}`}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
          {list.map((items) => (
            <div className="flex justify-center " key={items.id}>
              <div className=" bg-white border border-gray-200 shadow-md w-full relative pb-8 h-[230px] bx_myidea_list">
                <div className={`${items.delete ? "hidden" : ""}`}>
                  <div
                    className={`
                  ${
                    items.evaluation === "Experimentation"
                      ? "bg-heritageGreen"
                      : ""
                  } 
                  ${items.evaluation === "Validation" ? "bg-naartjie" : ""} 
                  ${items.evaluation === "Generation" ? " bg-freshGreen" : ""} 
                  ${
                    items.evaluation === "Commercialisation"
                      ? " bg-blueSky"
                      : ""
                  } 
                  ${items.evaluation === "Declined" ? " bg-red-700" : ""} 
                  ${items.evaluation === "Closed" ? "  bg-cerise" : ""}
                  ${
                    items.evaluation === "On Hold" ? "  bg-orange-900" : ""
                  } absolute right-0 w-44 text-white text-center py-[3px]`}
                  >
                    <p className="text-white text-xs uppercase">
                      {items.evaluation === null ? "" : items.evaluation}
                    </p>
                  </div>
                </div>
                <div className="px-5 pt-5 flex justify-between mt-3">
                  <div className="h-20">
                    <h3
                      title={items.title}
                      className="mb-3 text-gray-700 font-medium"
                    >
                      {items.title.length > 40
                        ? items.title.substring(0, 40) + "..."
                        : items.title}
                    </h3>
                  </div>
                  {items.delete ? (
                    <div className="px-2 w-10">
                      <BtnDropdown
                        isDelete={items.delete}
                        duplicate={(e) => duplicateIdea(items.id)}
                        del={(e) =>
                          deleteIdea({ id: items.id, delete: items.delete })
                        }
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>

                <div className="px-5 pt-3.5">
                  {items.drafted ? (
                    <p className="uppercase text-gray-400 text-xs font-semibold">
                      Draft
                    </p>
                  ) : (
                    <p
                      className={`${
                        items.challenge === null ? "hidden" : ""
                      } uppercase text-gray-400 text-xs font-semibold`}
                    >
                      submitted to{" "}
                      {items.challenge === null
                        ? ""
                        : items.challenge.length > 100
                        ? items.challenge.substring(0, 100)
                        : items.challenge}
                    </p>
                  )}
                </div>

                <div className="flex space-x-3 px-5 mt-10 absolute bottom-3 justify-between w-full">
                  <div className="flex items-center space-x-2 rounded-md text-gray-400">
                    <img
                      src="/assets/global/img/attachment.svg"
                      className="h-3 w-2"
                      alt="attachment"
                    />
                    <p>{items.count_document}</p>
                  </div>
                  {items.delete ? (
                    <div
                      title={items.title}
                      onClick={() => goDetail(items.id)}
                      className={`inline-flex items-center px-3 py-2 text-sm font-medium text-center text-freshGreen hover:text-gray-500 cursor-pointer`}
                    >
                      Edit
                      <ArrowIco />
                    </div>
                  ) : (
                    <div
                      onClick={() =>
                        goView({
                          id: items.submittedId,
                          slug: items.slugChallenge,
                        })
                      }
                      title={items.title}
                      className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-freshGreen hover:text-gray-500 cursor-pointer"
                    >
                      View
                      <ArrowIco />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={` ${listView ? "hidden" : "mx-auto space-y-3"}`}>

        <div className="shadow-sm text-sm p-3 flex  mx-auto">
       
          
          <div className="flex gap-4">

          <div className="flex space-x-4 w-10">
            
          </div>

          <div className="font-medium w-80">
            <p className="uppercase text-gray-600 text-md  font-semibold">IDEA </p>
          </div>

          <div className=" w-44 lg:w-60 ml-2">
            <p className="uppercase text-gray-600 text-md font-semibold">Challenge</p>
          </div>
        
          </div>
        
          
        </div>

        {list.map((items) => (
          <div
            key={items.id}
            className="bg-white shadow-sm text-sm p-3 flex justify-between items-center"
          >
            <div className="flex space-x-4">
              {items.delete ? (
                <div className="w-10">
                  <BtnDropdown
                    isDelete={items.delete}
                    duplicate={(e) => duplicateIdea(items.id)}
                    del={(e) =>
                      deleteIdea({ id: items.id, delete: items.delete })
                    }
                  />
                </div>
              ) : (
                <div className="w-10"></div>
              )}

              <div className="font-medium w-44 lg:w-60" title={items.title}>
                {items.title ? items.title : ""}
              </div>
            </div>

            <div className="hidden lg:block w-44 lg:w-[270px]">
              {items.drafted ? (
                <p className="uppercase text-gray-400 text-xs font-semibold">
                  Draft
                </p>
              ) : (
                <p
                  className={`${
                    items.delete ? "hidden" : ""
                  } uppercase text-gray-400 text-xs font-semibold`}
                >
                  submitted to {items.challenge === null ? "" : items.challenge}
                </p>
              )}
            </div>

            <div className="hidden lg:block">
              {!items.delete ? (
                <div
                  className={` ${
                    items.evaluation === "Experimentation"
                      ? "bg-heritageGreen"
                      : ""
                  }
                ${items.evaluation === "Validation" ? "bg-naartjie" : ""} 
                 ${items.evaluation === "Generation" ? " bg-freshGreen" : ""} 
                  ${
                    items.evaluation === "Commercialisation"
                      ? " bg-blueSky"
                      : ""
                  } 
                  ${items.evaluation === "Declined" ? " bg-red-700" : ""} 
                  ${items.evaluation === "Closed" ? "  bg-cerise" : ""}
                  ${
                    items.evaluation === "On Hold" ? "  bg-orange-900" : ""
                  } w-44 text-center py-[3px]`}
                >
                  <p className="text-white text-xs uppercase">
                    {items.evaluation === null ? "" : items.evaluation}
                  </p>
                </div>
              ) : (
                <div className="w-44"></div>
              )}
            </div>

            <div className="space-x-7 flex text-sm justify-between">
              <div className="flex items-center space-x-2 text-gray-400">
                <img
                  src="/assets/global/img/attachment.svg"
                  className="h-3 w-2"
                  alt="attachment"
                />
                <p>{items.count_document}</p>
              </div>
              {items.delete ? (
                <div
                  title={items.title}
                  onClick={() => goDetail(items.id)}
                  className={`inline-flex items-center w-16 lg:w-20 px-3 py-2 text-sm font-medium text-center text-freshGreen hover:text-gray-500 cursor-pointer`}
                >
                  Edit
                  <ArrowIco />
                </div>
              ) : (
                <div
                  onClick={() =>
                    goView({ id: items.submittedId, slug: items.slugChallenge })
                  }
                >
                  <div
                    title={items.title}
                    className="inline-flex items-center w-16 lg:w-20 px-3 py-2 text-sm font-medium text-center text-freshGreen hover:text-gray-500 cursor-pointer"
                  >
                    View
                    <ArrowIco />
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Personal;
