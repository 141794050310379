import React from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import RouteName from "../../../../services/routename";
import PersonalRepo from "../../../../repositories/user-repo/PersonalRepo";
import ArrowIco from "../../../../components/icons/ArrowIco";
import BtnDropdown from "../../../../components/buttons/BtnDropdown";
import AlertUtility from "../../../../utility/alert-utility";
import Cookies from "js-cookie";
import EncryptDecrypt from "../../../../utility/encrypt-decrypt";

const PersonalIdeaList = ({ personalList, loading, getPersonalIdeas }) => {
  const deleteIdea = async (param) => {
    AlertUtility.loadingAlert();
    const result = await PersonalRepo.deletePersonalIdea({ id: param.id });
    if (result.status === 200) {
      Swal.close();
      AlertUtility.successAlert({
        title: "Your idea was successfully deleted.",
      });
    } else {
      Swal.close();
      AlertUtility.errorAlert({
        title: "There is some problem. Please try again.",
      });
    }
    getPersonalIdeas({ search: "" });
  };

  const duplicateIdea = async (id) => {
    AlertUtility.loadingAlert();
    const result = await PersonalRepo.duplicatePersonalIdea({ id: id });
    if (result.status === 200) {
      Swal.close();
      AlertUtility.successAlert({
        title: "Your idea was successfully duplicated.",
      });
    } else {
      Swal.close();
      AlertUtility.errorAlert({
        title: "There is some problem. Please try again.",
      });
    }
    getPersonalIdeas({ search: "" });
  };

  const detailView = async (param) => {
    Cookies.set("slugChallenge", EncryptDecrypt.enc(param.slug), { secure: true });
    Cookies.set("idChallenge", EncryptDecrypt.enc(""), { secure: true  });
  };

  if (loading) {
    return (
      <div className="bg-baseGray m-6 p-6 tx_loading">
        <p>Loading ...</p>
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
        <>
          {personalList.map((List) => (
            <div className="flex justify-center " key={List.id}>
              <div className=" bg-white border border-gray-200 shadow-md w-full relative pb-8 h-[230px] bx_myidea_list">
                <div className={`${List.delete ? "hidden" : ""}`}>
                  <div
                    className={`
                  ${
                    List.evaluation === "Experimentation"
                      ? "bg-heritageGreen"
                      : ""
                  } 
                  ${List.evaluation === "Validation" ? "bg-naartjie" : ""} 
                  ${List.evaluation === "Generation" ? " bg-freshGreen" : ""} 
                  ${
                    List.evaluation === "Commercialisation" ? " bg-blueSky" : ""
                  } 
                  ${List.evaluation === "Declined" ? " bg-red-700" : ""} 
                  ${List.evaluation === "Closed" ? "  bg-cerise" : ""}
                  ${
                    List.evaluation === "On Hold" ? "  bg-orange-900" : ""
                  } absolute right-0 w-44 text-center py-[3px]`}
                  >
                    <p className="text-white text-xs uppercase">
                      {List.evaluation === null ? "" : List.evaluation}
                    </p>
                  </div>
                </div>
                <div className="px-5 pt-5 flex justify-between mt-3">
                  <div className="h-20">
                    <h3
                      title={List.title}
                      className="mb-3 font-medium text-gray-700"
                    >
                      {List.title.length > 40
                        ? List.title.substring(0, 40) + "..."
                        : List.title}
                    </h3>
                  </div>
                  {List.delete ? (
                    <div className="px-2 w-10">
                      <BtnDropdown
                        isDelete={List.delete}
                        duplicate={(e) => duplicateIdea(List.id)}
                        del={(e) =>
                          deleteIdea({ id: List.id, delete: List.delete })
                        }
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>

                <div className="px-5 pt-3.5">
                  {List.drafted ? (
                    <p className="uppercase text-gray-400 text-xs font-semibold">
                      Draft
                    </p>
                  ) : (
                    <p
                      className={`${
                        List.delete ? "hidden" : ""
                      } uppercase text-gray-400 text-xs font-semibold`}
                    >
                      submitted to{" "}
                      {List.challenge === null
                        ? ""
                        : List.challenge.length > 100
                        ? List.challenge.substring(0, 100) + "..."
                        : List.challenge}
                    </p>
                  )}
                </div>

                <div className="flex space-x-3 px-5 mt-10 absolute bottom-3 justify-between w-full">
                  <div className="flex items-center space-x-2 rounded-md text-gray-400">
                    <img
                      src="/assets/global/img/attachment.svg"
                      className="h-3 w-2"
                      alt="attachment"
                    />
                    <p>{List.count_document}</p>
                  </div>
                  {List.delete ? (
                    <Link to={RouteName.editPersonal + "/" + List.id}>
                      <div
                        title={List.title}
                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-freshGreen hover:text-gray-500 cursor-pointer"
                      >
                        Edit
                        <ArrowIco />
                      </div>
                    </Link>
                  ) : (
                    <Link
                      to={RouteName.detailViewIdea + "/" + List.submittedId}
                    >
                      <div
                        onClick={() => detailView({ id:List.submittedId, slug: List.slugChallenge })}
                        title={List.title}
                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-freshGreen hover:text-gray-500 cursor-pointer"
                      > View
                        <ArrowIco />
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      </div>
      {/* {personalList > 0 ?
        :
        <div className="bg-baseGray text-center m-6 tx_loading">
          <p>No data available</p>
        </div>
      } */}
    </>
  );
};

export default PersonalIdeaList;
