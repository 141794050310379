import CallApi from "../../services/request-helper";

const API_STATIC = process.env.REACT_APP_STATDASHBOARD;

/**
 * get statistic idea dashboard
 * @param {*} data 
 * @returns 
 */
const getStatistic = async (data) => {
    const url = API_STATIC;
    const response = await CallApi({url, method: "GET"});
    return response;
  };

const StatisticDashRepo = {
    getStatistic,
}

export default StatisticDashRepo;