import React from "react";
import GeneralUtility from "../../../../utility/GeneralUtility";

const RewardsHome = ({ reward, loading }) => {
  return (
    <div className="bg-baseGray p-6 mt-0 bx_home_reward">
      {!loading ? (
        <div>
          <div>
            <h2 className="text-sm lg:text-xl text-center pt-3 font-semibold ht_home_title">
              {reward.titleReward !== null ? reward.titleReward : ""}
            </h2>
            <p className="text-sm text-center pt-3 ht_home_title">
              {reward.subTitleReward !== null ? reward.subTitleReward : ""}
            </p>
          </div>
          <div className="mx-auto mt-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {reward.reward.map((items, index) => (
                <div
                  className="flex justify-center bg-white w-full"
                  key={index}
                >
                  <div className="shadow-md w-full">
                    <div
                      className={`${
                        index === 0
                          ? "bg-gradient-to-r from-orange-600 to-pink-600 bx_stage_1"
                          : ""
                      }
                      ${
                        index === 1
                          ? "bg-gradient-to-r from-futureGreen to-heritageGreen bx_stage_2"
                          : ""
                      }
                      ${
                        index === 2
                          ? "bg-gradient-to-r from-yellow-200 to-blueSky bx_stage_3"
                          : ""
                      }
                      ${
                        index === 3
                          ? "bg-gradient-to-r from-orange-200 to-yellow-200 bx_stage_4"
                          : ""
                      } h-24 w-full flex items-center bg-cover bx_home_reward_banner`}
                    >
                      <div
                        className="bg-cover h-full w-full flex items-center justify-between"
                        style={{
                          backgroundImage: `url("/assets/global/img/Pattern2.png")`,
                        }}
                      >
                        <div className="h-full w-full flex items-center justify-between p-3 font-Montserrat">
                          <div className="flex items-start">
                            <div className="h-6">
                              <img
                                src={`${
                                  index === 0
                                    ? "/assets/global/img/lightbulb.svg"
                                    : ""
                                }
                                ${
                                  index === 1
                                    ? "/assets/global/img/check.png"
                                    : ""
                                }
                                ${
                                  index === 2
                                    ? "/assets/global/img/science-white.png"
                                    : ""
                                }
                                ${
                                  index === 3
                                    ? "/assets/global/img/diamond.png"
                                    : ""
                                }`}
                                alt="image_card"
                                className={`${index === 0 ? "img_stage_1" : ""}
                              ${index === 1 ? "img_stage_2" : ""}
                              ${index === 2 ? "img_stage_3" : ""}
                              ${index === 3 ? "img_stage_4" : ""}`}
                              />
                            </div>
                            <div className="font-semibold text-white text-lg px-3 font-Montserrat tracking-wide">
                              {items.title}
                            </div>
                          </div>
                          <div className="text-white mr-4">
                            <p className="font-medium text-lg font-Montserrat tracking-wide leading-tight">
                              R{GeneralUtility.addCommas(items.reward)}
                            </p>
                            <p className="text-sm font-light font-Montserrat tracking-wide leading-tight">
                              REWARD
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-5 text-center">
                      <p className="mb-3 text-xs font-normal text-gray-700 font-Montserrat tx_home_reward_desc">
                        {items.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center h-28">
          <div role="status" className="max-w-sm animate-pulse mx-auto">
            <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-6 mt-3"></div>
            <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default RewardsHome;
