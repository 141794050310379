import Chart from "react-apexcharts";

export default function ColumnArea(params) {
    var column = {
        options: {
            colors : ['#b84644', '#00C0E8', '#50B848', '#E30909', '#FFF201', '#ED0680', '#F37021'],
            chart: {
                id: "basic-bar",
                toolbar: {
                    show: false
                },
                stacked: true,
            },
            grid:{
                show: true,  
            },
              plotOptions: {
                bar: {
                    // borderRadius: 14,
                    dataLabels: {
                      position: 'top', // top, center, bottom
                    },
                }
            },
            xaxis: {
                categories: params.labels
            },
            stroke: {
              width: [2,2,4],
              curve: 'smooth'
            },
            fill: {
              opacity: [0.7, 0.3, 1],      
            },
        },
        series: [{
            name: params.name1,
            type: "area",
            data: params.current
          }, {
            name: params.name2,
            type: "area",
            data: params.previous
          }, {
            name: params.name3,
            data: params.other
          }, {
            name: params.name4,
            data: params.other2
          },{
            name: params.name5,
            data: params.other3
          },{
            name: params.name6,
            data: params.other4
          },{
            name: params.name7,
            data: params.other5
          }]
    };


    return(
    <div className='column'>
        <Chart
           options={column.options} 
           series={column.series} 
           type="bar" 
           width={params.width}
           height={params.height}
        />
    </div>
    )   
}
