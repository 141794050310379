import React, { useState } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import RouteName from "../../../services/routename";
import Cookies from "js-cookie";
import ConfirmPopup from "../../ConfirmPopup";
import GeneralUtility from "../../../utility/GeneralUtility";
import EncryptDecrypt from "../../../utility/encrypt-decrypt";

const NavbarSm = () => {
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);
  const Menus = [
    { title: "Home", src: "home", route: RouteName.userHome },
    {
      title: "Latest Challenges",
      src: "launch",
      route: RouteName.userLatestChallenges,
    },
    {
      title: "Archived Challenges",
      src: "archive",
      route: RouteName.userArchivedChallenges,
    },
    { title: "My Ideas", src: "lightbulb", route: RouteName.userMyIdeas },
    { title: "Coming Soon", src: "team", route: RouteName.userMyTeams },
  ];
  let enc_firstName = Cookies.get("firstname");
  let firstName = enc_firstName ? EncryptDecrypt.dec(enc_firstName) : null;
  let enc_lastName = Cookies.get("lastname");
  let lastName = enc_lastName ? EncryptDecrypt.dec(enc_lastName) : null;

  const [searchNav, setSearchNav] = useState("");
  const navigate = useNavigate();
  let location = useLocation();
  let title = "";
  let username = `${firstName} ${lastName}`;
  if (location.pathname.includes("home")) {
    title = `Hi ${username}, Welcome back!`;
  }
  if (location.pathname.includes("latest-challenge")) {
    title = "Latest Challenge";
  }
  if (location.pathname.includes("archived-challenges")) {
    title = "Archived Challenge";
  }
  if (location.pathname.includes("myideas")) {
    title = "My Ideas";
  }
  if (location.pathname.includes("myteams")) {
    title = "My Teams";
  }
  if (location.pathname.includes("brainstorming")) {
    title = "Brainstorming";
  }
  if (location.pathname.includes("leaderBoard")) {
    title = "Leaderboard";
  }
  if (location.pathname.includes("resources")) {
    title = "Resources";
  }
  if (location.pathname.includes("polls")) {
    title = "Polls";
  }
  if (location.pathname.includes("how-it-works")) {
    title = "How It Works";
  }

  const handleSearch = (e) => {
    setSearchNav(e.target.value);
  };

  const handleSubmit = () => {
    navigate(RouteName.searchPage, { state: { searchText: searchNav } });
    navigate(0);
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      // Get input value
      handleSubmit();
    }
  };

  const handleLogout = async () => {
    GeneralUtility.logout();
  };

  return (
    <>
      <div className="inline-flex items-center">
        <div>
          <img
            onClick={() => setOpen(!open)}
            src="/assets/global/img/menu.svg"
            className="h-5 w-5 cursor-pointer"
            alt="menu"
          />
        </div>
        <div className="mx-8 ml-5 w-28 text-black"></div>
        <div className="absolute right-0 mr-8 inline-flex space-x-4 items-center bx_top_navbar">
          <div className="relative">
            <input
              autoComplete="off"
              type="text"
              className="border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-freshGreen focus:border-freshGreen block w-24 pr-10 py-0.5 px-2 txt_navbar_search"
              placeholder="Search for a challenge"
              onChange={handleSearch}
              onKeyDown={(e) => {
                handleKey(e);
              }}
            />
            <button
              onClick={handleSubmit}
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          {/* <div className=" cursor-pointer">
                        <img
                            src="/assets/global/img/chatIco.svg"
                            className="h-5 mt-1"
                            alt="chat"
                        />
                    </div>
                    <div className=" cursor-pointer">
                        <img
                            src="/assets/global/img/notification.svg"
                            className="h-5 mt-1"
                            alt="notification"
                        />
                    </div> */}
          <div className="cursor-pointer">
            <img
              src="/assets/global/img/Logo.png"
              className="w-14 h-10"
              alt="logo"
            />
          </div>
        </div>
      </div>

      {/* SHOW NAVIGATION */}
      <div
        id="mobile-navigation"
        className={`${
          open ? "hidden " : ""
        } absolute top-0 right-0 bottom-0 left-0 backdrop-blur-sm`}
      >
        <div className="bg-heritageGreen w-screen h-screen">
          <ul className="absolute top-10 left-0 bottom-0 py-4 transition-all ul_mob_menu">
            {Menus.map((Menu, index) => (
              <NavLink
                key={index}
                to={Menu.route}
                onClick={() => setOpen(!open)}
                className="font-normal w-screen my-1 text-white div-2 cursor-pointer flex"
              >
                <div className="text-sm flex items-center gap-x-3 ml-4 li_mob_menu">
                  <div className="h-5 w-5">
                    <img
                      src={`/assets/global/img/${Menu.src}.svg`}
                      alt={`${Menu.title}`}
                    />
                  </div>
                  <span>{Menu.title}</span>
                </div>
              </NavLink>
            ))}
            <div
              onClick={() => setShow(true)}
              className="font-normal w-screen my-1 text-white div-2 cursor-pointer flex"
            >
              <div className="text-sm flex items-center gap-x-3 ml-4 li_mob_menu">
                <div className="h-5 w-5">
                  <img src={`/assets/global/img/logout.svg`} alt="logout" />
                </div>
                <span className="ml-2 text-xs">Logout</span>
              </div>
            </div>
          </ul>
        </div>
        <div className="justify-between flex">
          <button
            onClick={() => setOpen(!open)}
            className="text-white font-bold"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 absolute top-5 left-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="h-12 w-16 absolute top-1 right-5">
            <img
              src="/assets/global/img/LogoWhite.png"
              className="h-12"
              alt="logo"
            />
          </div>
        </div>
      </div>
      <ConfirmPopup
        show={show}
        setShow={setShow}
        onSave={handleLogout}
        message="Are you sure you want to log out?"
      />
    </>
  );
};

export default NavbarSm;
