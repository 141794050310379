import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import ChallengeRepo from "../../../../repositories/user-repo/ChallengeRepo";
import EvaluationDetail from "./components/EvaluationDetail";
import OverviewDetail from "./components/OverviewDetail";
import RewardDetail from "./components/RewardDetail";
import Breadcrumbs from "./components/Breadcrumbs";
import Banner from "../../../../components/Banner";
import GeneralUtility from "../../../../utility/GeneralUtility";
import LinkReference from "./components/LinkReference";

const DetailChallenge = () => {
  let [id, setId] = useState("");
  let slug = useParams();
  const [reward, setReward] = useState([]);
  const [detail, setDetail] = useState({});
  const [manager, setManager] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(true);

  /**
   * funtion to get data detail challenge
   */
  const getDetailChallenge = useCallback(async () => { setLoading(true);
    const result = await ChallengeRepo.getDetailBySlug({ slug: slug.id });
    if (result !== null) {
      let listChallenges = result;
      let listManager = result.managerchallange;
      let listEvaluations = result.evaluations;
      let listRewards = result.rewards;
      let link = result.links;
      setLinks(link)
      setId(listChallenges.id);
      setDetail(listChallenges);
      setManager(listManager);
      setEvaluations(listEvaluations);
      setReward(listRewards);
      setLoading(false);
    }
  }, [ slug.id ]);

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getDetailChallenge();
  }, [getDetailChallenge]);
  return (
    <div className="container mx-auto bx_detail_challenges">
        <div className="m-6">
          <Breadcrumbs title={detail.title} />
        </div>
        <div>
          <Banner detail={{ banner: detail.banner_image, title: detail.title }} loading={loading} />
        </div>
        <div className="m-6 space-y-4 bx_sub_detail_challenges">
          <EvaluationDetail evaluations={evaluations} loading={loading} />

          <div id="overview" className="w-full space-y-2">
            <OverviewDetail
              detail={detail}
              id={id}
              slug={slug}
              manager={manager}
              detailChallenge={getDetailChallenge}
              loading={loading}
            />
          </div>
          <LinkReference links={links} title={detail.title}/>

          <RewardDetail reward={reward} loading={loading} />

        </div>
      </div>
  );
};

export default DetailChallenge;
