import Swal from "sweetalert2";

const successAlert = ({ title }) => {
    Swal.fire({
        icon: 'success',
        text: title,
        showConfirmButton: false,
        timer: 2000
    })
}

const errorAlert = ({ title }) => {
    Swal.fire({
        icon: 'error',
        text: title,
        showConfirmButton: true,
    })
}

const loadingAlert = () => {
    Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
        },
    })
}

const warningAlert = ({time}) => {
    Swal.fire({
        title: '<strong>Sorry</strong>',
        icon: 'warning',
        html: 'Server is under maintenance. Please try again later!',
        showConfirmButton: true,
        timer: time
    })
}

const toastAlert = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const showToast = () => {
    toastAlert.fire({
        icon: 'success',
        title: 'Signed in successfully'
    })
}

const AlertUtility = {
    successAlert,
    errorAlert,
    loadingAlert,
    showToast,
    warningAlert
}

export default AlertUtility;