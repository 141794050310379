import React from 'react'

//Button Back
const BtnBack = ({ onClick, title }) => {
    return (
        <div>
            <button
                onClick={onClick}
                className="border border-1 border-green-400 text-center text-xs p-3 py-2 inline-flex items-center text-freshGreen hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white uppercase"
            >
                <svg
                    aria-hidden="true"
                    className="w-4 h-4 mr-2 -ml-1 rotate-180"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    ></path>
                </svg>
                {title}
            </button>
        </div>
    );
}

export default BtnBack
