import React from "react";
import Skeleton from "../../../../components/skeleton/Skeleton";

const NewsHome = ({ detail, loading }) => {
  const openExternalLink = () => {
    window.open("https://www.ominnovoice.co.za/?oldmutualinnovation", "_blank");
  };

  return (
    <>
      {!loading ? (
        <div className="relative">
          <img
            className="overflow-hidden max-h-64 w-full bg-gray-200"
            src={detail.newsBanner}
            alt="banner-explore"
          />
          <div className="text-white space-y-1 md:space-y-4 absolute top-0 flex flex-col items-center justify-center h-full w-full">
            <h2 className="font-semibold text-center pt-1 text-sm md:text-base">
              {detail.newsInfo ? detail.newsInfo :""}
            </h2>
            <div className="text-center font-semibold">
              <button
                onClick={openExternalLink}
                className=" bg-white text-freshGreen text-sm md:text-base hover:text-white py-1 px-2 md:py-1.5 md:px-4 hover:bg-gradient-to-tr from-green-600 to-freshGreen"
              >
                EXPLORE
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="c-image-container">
          <Skeleton col={1} height={300} />
        </div>
      )}
    </>
  );
};

export default NewsHome;
