import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import RouteAdm from "../../../services/routeAdm";
import ConfirmPopup from "../../ConfirmPopup";
import GeneralUtility from "../../../utility/GeneralUtility";
import Cookies from "js-cookie";

const NavbarSm = () => {
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);

  const Menus = [
    { title: "Dashboard", src: "dashboard", route: RouteAdm.dashboard },
    { title: "Challenges", src: "launch", route: RouteAdm.challenges },
    { title: "Ideas", src: "lightbulb", route: RouteAdm.ideas },
    { title: "Communication", src: "email", route: RouteAdm.communication },
    { title: "Pages", src: "edit", route: RouteAdm.admPages },
  ];

  const removeCookies = () =>{
    Cookies.remove("gridView");
    Cookies.remove("ideasYear");
    Cookies.remove("ideasPage");
  };

  const handleLogout = async () => {
    GeneralUtility.logout();
  };

  return (
    <>
      <div className="inline-flex items-center">
        <div>
          <img
            onClick={() => setOpen(!open)}
            src="/assets/global/img/menu.svg"
            className="h-6 w-6 cursor-pointer"
            alt="menu"
          />
        </div>
        <div className="mx-8 ml-5 w-28 text-black">
        </div>
        <div className="absolute right-0 mr-8 inline-flex space-x-4 items-center">
          <div className="cursor-pointer hidden">
            <img
              src="/assets/global/img/chatIco.svg"
              className="h-5 mt-1"
              alt="chat"
            />
          </div>
          <div className=" cursor-pointer hidden">
            <img
              src="/assets/global/img/notification.svg"
              className="h-5 mt-1"
              alt="notification"
            />
          </div>
          <div className="cursor-pointer">
            <img
              src="/assets/global/img/Logo.png"
              className="w-14 h-10"
              alt="logo"
            />
          </div>
        </div>
      </div>

      {/* SHOW NAVIGATION */}
      <div
        id="mobile-navigation"
        className={`${
          open ? "hidden " : ""
        } fixed top-0 right-0 bottom-0 left-0 backdrop-blur-sm z-10`}
      >
        <div className="bg-heritageGreen w-screen h-screen">
          <ul className="absolute top-10 left-0 bottom-0 py-4 transition-all">
            {Menus.map((Menu, index) => (
              <NavLink
                key={index}
                to={Menu.route}
                onClick={() => { setOpen(!open); removeCookies(); }}
                className="font-normal w-screen my-1 text-white p-2 cursor-pointer flex"
              >
                <div className="text-sm flex items-center gap-x-3 ml-4">
                  <div className="h-5 w-5">
                    <img
                      src={`/assets/global/img/${Menu.src}.svg`}
                      alt={`${Menu.title}`}
                    />
                  </div>
                  <span>{Menu.title}</span>
                </div>
              </NavLink>
            ))}
            <div
              onClick={() => setShow(true)}
              className="font-normal w-screen my-1 text-white p-2 cursor-pointer flex"
            >
              <div className="text-sm flex items-center gap-x-3 ml-4">
                <div className="h-5 w-5">
                  <img src={`/assets/global/img/logout.svg`} alt="logout" />
                </div>
                <span>Logout</span>
              </div>
            </div>
          </ul>
        </div>
        <div className="justify-between flex">
          <button
            onClick={() => setOpen(!open)}
            className="text-white font-bold"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 absolute top-5 left-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="h-12 w-16 absolute top-1 right-5">
            <img
              src="/assets/global/img/LogoWhite.png"
              className="h-12"
              alt="logo"
            />
          </div>
        </div>
      </div>
      <ConfirmPopup
        show={show}
        setShow={setShow}
        onSave={handleLogout}
        message="Are you sure you want to log out?"
      />
    </>
  );
};

export default NavbarSm;
