import React, { useEffect, useState } from "react";
import ButtonPublish from "./ButtonPublish";
const ButtonPreview = ({ showButtonPublish, returnToEditMode,saveTriggers,publishTriggers }) => {
  return (
    <>
      <div className="w-full content-cancel ml-5 ">
        <div className="w-40 content-cancel-1 flex justify-start items-start ml-4">
          {showButtonPublish ? <ButtonPublish saveTrigger={()=> saveTriggers("save")} publishTrigger={()=> publishTriggers("publish")}/> : null}
        </div>
        <div className="ml-2">
          <button
            className="h-10 w-20 mb-4 bg-baseGray border border-freshGreen border-solid border-2 text-freshGreen text-sm md:text-base  hover:text-white hover:bg-gradient-to-tr from-green-600 to-freshGreen" id="cancel-return"
            onClick={() => returnToEditMode()}
          >
            CANCEL
          </button>
        </div>
      </div>
    </>
  );
};
export default ButtonPreview;
