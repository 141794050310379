import React from 'react'

const BtnWhite = ({ onClick, message }) => {
    return (
        <>
            <button
                onClick={onClick}
                className="border border-1 border-green-400 text-center px-5 py-2 inline-flex items-center text-freshGreen hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white btn_modal_global"
            >
                {message}
            </button>
        </>
    )
}

export default BtnWhite