import Modal from "react-modal";
import RichTextTerms from "./RichTextTerms";
import BtnGreen from "../../../../../components/buttons/BtnGreen";
import RichTextEditor from "../../../../../components/RichTextEditor";
import EditorTerms from "./EditorTerms";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "75%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: { zIndex: 1000 },
};

Modal.setAppElement("#root");
const ModalTerms = (props) => {
  if (!props.isOpen) return null;

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      style={customStyles}
    >
      <h1 className="mb-5 text-4xl font-medium	">Edit Terms & Conditions</h1>
      <EditorTerms
        value={props.initialTerms}
        onChange={props.setTerms}
      />
      <div className="mt-5 flex justify-between">
        <BtnGreen onClick={props.saveTerms} message="SAVE" />
        <button
          className="px-2 border border-black border-solid border-2"
          onClick={props.onClose}
        >
          CANCEL
        </button>
      </div>
    </Modal>
  );
};

export default ModalTerms;
