import CallApi from "../../services/request-helper";

const API_LISTYEAR = process.env.REACT_APP_LISTYEAR;
const API_EVALUATION = process.env.REACT_APP_EVALUATION;

const getFilterListYears = async () => {
  const url = API_LISTYEAR;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getEvaluation = async () => {
    const url = API_EVALUATION;
    const response = await CallApi({ url, method: "GET" });
    return response;
  };

const FilterIdeaRepo = {
  getFilterListYears,
  getEvaluation,
};

export default FilterIdeaRepo;
