import React from "react";

const ButtonDelete = ({ onClick }) => {
  return (
    <>
      <div
        className="mr-2 listedit-rewards pt-2 w-7 h-7"
        title="Delete"
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="15"
          viewBox="0 0 10 12.857"
        >
          <path
            id="bin"
            d="M5.714,14.429a1.433,1.433,0,0,0,1.429,1.429h5.714a1.433,1.433,0,0,0,1.429-1.429V5.857H5.714ZM7.143,7.286h5.714v7.143H7.143ZM12.5,3.714,11.786,3H8.214L7.5,3.714H5V5.143H15V3.714Z"
            transform="translate(-5 -3)"
            fill="#fff"
          />
        </svg>
      </div>
    </>
  );
};

export default ButtonDelete;
