import React, { useState } from "react";
import Chart from "react-apexcharts";
import Donut from "../../../components/chart/Donut";
import "./Dashboard.css";

const Ideas = (params) => {

  //ideas by stages
  let tempName = [];
  let tempValue = [];
  if (params.ideasByStages !== null) {
    params?.ideasByStages?.map((res) => {
      tempName.push(res["name"]);
      tempValue.push(res["total_submited"]);
    });
  }

  //ideas by generation
  let tempGenerationUser = [];
  let tempValueUser = [];
  if (params.ideasByGeneration !== null) {
    params?.ideasByGeneration?.map((res) => {
      tempGenerationUser.push(res["name"]);
      tempValueUser.push(res["total"]);
    });
  }

  const toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  //ideas by status broken down
  let tempStatusBroken = [];
  let tempValueStatusBroken = [];
  if (params.statusBroken !== null) {
    params?.statusBroken?.map((res, index) => {
      tempStatusBroken.push(
        toTitleCase(
          res["name"] === "In evaluation" ? "In Progress" : res["name"]
        )
      );
      tempValueStatusBroken.push(res["total"]);
    });
  }

  //ideas by number options
  var options = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
        fontFamily: "Montserrat",
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 8,
          strokeColor: "black",
          fillColors: undefined,
          radius: 2,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          display: false,
          distributed: true,
        },
      },
      colors: ["#545ee5", "#ef2f5b"],
      xaxis: {
        show: true,
        type: "category",
        categories: tempGenerationUser,
        tooltip: {
          enabled: false,
        },
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      fill: {
        colors: ["#545ee5", "#ef2f5b"],
      },
    },
    series: [
      {
        name: "",
        data: tempValueUser,
      },
    ],
  };

  //ideas by status broken down
  var optionsStatus = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          display: false,
          distributed: true,
        },
      },
      colors: ["#8327e3", "#e20a0a", "#f37021"],
      xaxis: {
        show: true,
        type: "category",
        categories: tempStatusBroken,
        tooltip: {
          enabled: false,
        },
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true,
      },
      fill: {
        colors: ["#8327e3", "#e20a0a", "#f37021"],
      },
    },
    series: [
      {
        name: "",
        data: tempValueStatusBroken,
      },
    ],
  };

  var getInitials = function (name) {
    var parts = name.split(" ");
    var initials = "";
    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        if (i < 2) {
          initials += parts[i][0];
        }
      }
    }
    return initials;
  };

  return (
    <>
      {!params.onShow ? (
        // responsive
        <div className="container pb-8 pr-20" id={params.rootId}>
          <div className="flex flex-col xl:flex-row space-x-4">
            <div className="flex flex-col ml-16 xxs:w-[370px] sm:w-[370px] md:w-[24em] lg:w-[28em] xl:w-[400px]">
              <div className="mt-[-20px] h-auto w-full bg-white drop-shadow-lg border rgba(0,0,0,.2)">
                <div className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                Submitted Ideas vs Ideas In Validation
                </div>
                <div className="mixed-chart">
                  {params?.ideasByGeneration?.length > 0 ? (
                    <div className="flex flex-col">
                      <Chart
                        options={options.options}
                        series={options.series}
                        type="bar"
                        width="300"
                        height="150"
                      />
                      <div className={`flex flex-row bx_main_ideas_status`}>
                        {tempGenerationUser?.map((arr, index) => (
                          <div
                            className={` ${
                              index === 0
                                ? "border-[#545ee5]"
                                : "border-[#ef2f5b]"
                            }    text-xs font-Montserrat bx_adm_dashboard_ideas_status`}
                            key={index}
                          >
                            <span
                              className={`${
                                index === 0
                                  ? "ico_dashboard_ideas_status_1"
                                  : "ico_dashboard_ideas_status_2"
                              }`}
                            >
                              {arr}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="h-[250px] w-auto font-Montserrat flex justify-center pt-24">
                      No Data Available
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-[20px] h-auto w-full bg-white drop-shadow-lg border rgba(0,0,0,.2)">
                <div className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                  Ideas by Stages
                </div>
                {params?.ideasByStages?.length > 0 ? (
                  <Donut
                    height="300"
                    width="400"
                    labels={tempName}
                    value={tempValue}
                    onShow={params.onShow}
                  />
                ) : (
                  <div className="h-[250px] w-auto font-Montserrat flex justify-center pt-24">
                    No Data Available
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col 2xl:w-[780px] md:w-[600px] xxs:w-[370px]">
              <div className="border rgba(0,0,0,.2) w-full mt-5 xl:mt-[-20px] ml-[50px] xl:ml-0">
                <h1 className="text-sm font-semibold pb-4 pt-5">
                  Top Creators of Ideas
                </h1>
                {params?.ideasByUser &&
                  params?.ideasByUser?.map((res, indx) => {
                    return (
                      <div
                        className="flex flex-row items-center h-[60px] border-2 drop-shadow-lg"
                        key={indx}
                      >
                        <span className="2xl:w-[50px] xxs:w-[40px] text-xs md:text-sm ml-4 text-left font-Montserrat-semibold">
                          {indx + 1}
                        </span>
                        <div
                          className={`2xl:w-[4em] xxs:w-[3em] h-10 rounded-full mt-[-10px] flex flex-col justify-center mr-4`}
                        >
                          <button className="text-sm flex items-center rounded-full bg-heritageGreen text-white font-bold justify-center h-9 w-9">
                            {getInitials(
                              `${res.firstname} ${
                                res.lastname ? res.lastname : ""
                              }`
                            )}
                          </button>
                        </div>
                        <span className="2xl:w-[320px] md:w-[180px] xxs:w-[120px] text-xs md:text-sm text-left font-Montserrat-semibold">
                          {res.firstname} {res.lastname}
                        </span>
                        <div className="flex md:flex-row flex-col ml-5 md:ml-0">
                          <span className="2xl:w-[200px] md:w-[150px] xxs:w-[300px] text-left text-xs md:text-sm font-Montserrat-semibold">
                            {res.total_submited} Ideas submitted
                          </span>
                          <span className="2xl:w-[200px] md:w-[150px] xxs:w-[300px] text-left text-xs md:text-sm font-Montserrat-semibold">
                            {res.ideas_progressed} Ideas progressed
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="h-auto mt-7 w-full bg-white drop-shadow-md border rgba(0,0,0,.2) ml-[50px] xl:ml-0">
                <div className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                  Status Breakdown
                </div>
                <div className="mixed-chart">
                  {params?.statusBroken?.length > 0 ? (
                    <Chart
                      options={optionsStatus.options}
                      series={optionsStatus.series}
                      type="bar"
                      width="300"
                      height="150"
                    />
                  ) : (
                    <div className="h-[250px] w-auto font-Montserrat flex justify-center pt-24">
                      No Data Available
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="pb-8 pr-20 w-[1280px]" id={params.rootId}>
          <div className="flex flex-row">
            <div className="flex flex-col">
              <div className="ml-7 h-auto w-[400px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
                <div className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                Submitted Ideas vs Ideas In Validation
                </div>
                <div className="mixed-chart">
                  {params?.ideasByGeneration?.length > 0 ? (
                    <div className="flex flex-col">
                      <Chart
                        options={options.options}
                        series={options.series}
                        type="bar"
                        width="290"
                        height="140"
                      />
                      <div className={`flex flex-row bx_main_ideas_status`}>
                        {tempGenerationUser?.map((arr, index) => (
                          <div
                            className={` ${
                              index === 0
                                ? "border-[#545ee5]"
                                : "border-[#ef2f5b]"
                            } text-xs font-Montserrat bx_adm_dashboard_ideas_status`}
                            key={index}
                          >
                            <span
                              className={`${
                                index === 0
                                  ? "ico_dashboard_ideas_status_1"
                                  : "ico_dashboard_ideas_status_2"
                              }`}
                            >
                              {arr}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="h-[250px] w-auto font-Montserrat flex justify-center pt-24">
                      No Data Available
                    </div>
                  )}
                </div>
              </div>
              <div className="ml-7 mt-[20px] h-auto w-[400px] bg-white drop-shadow-lg border rgba(0,0,0,.2)">
                <div className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                  Ideas by Stages
                </div>
                {params?.ideasByStages?.length > 0 ? (
                  <Donut
                    height="300"
                    width="400"
                    labels={tempName}
                    value={tempValue}
                    onShow={params.onShow}
                  />
                ) : (
                  <div className="h-[250px] w-auto font-Montserrat flex justify-center pt-24">
                    No Data Available
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col ml-4">
              <div className="border rgba(0,0,0,.2)">
                <h1 className="font-Montserrat text-sm font-semibold pb-4 pt-5">
                  Top Creators of Ideas
                </h1>
                {params?.ideasByUser &&
                  params?.ideasByUser?.map((res, indx) => {
                    return (
                      <div
                        className="flex flex-col w-[700px] mx-1 my-1 h-[60px] border-2 justify-between pt-4 drop-shadow-lg"
                        key={indx}
                      >
                        <div className={`flex flex-row`}>
                          <span className="w-[50px] text-base xxs:text-sm ml-4 text-left font-Montserrat-semibold">
                            {indx + 1}
                          </span>
                          <div
                            className={`w-[4em] justify-center mr-4`}
                          >
                            <button className="text-sm flex pb-1 items-center rounded-full h-9 w-9 bg-heritageGreen text-white font-bold justify-center">
                              {getInitials(`${res.firstname} ${res.lastname}`)}
                            </button>
                          </div>
                          <span className="w-[280px]  text-sm text-left font-Montserrat-semibold">
                            {res.firstname} {res.lastname}
                          </span>
                          <div className="mt-0 flex-none flex-row space-x-1">
                            <span className="w-[200px] text-left text-sm font-Montserrat-semibold">
                              {res.total_submited} Ideas submitted
                            </span>
                            <span className="w-[200px] text-left text-sm font-Montserrat-semibold">
                              {res.ideas_progressed} Ideas progressed
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="h-auto ml-1 w-[700px] bg-white drop-shadow-md border rgba(0,0,0,.2)">
                <div className="flex text-sm mt-5 ml-5 font-Montserrat font-semibold">
                  Status Breakdown
                </div>
                <div className="mixed-chart">
                  {params?.statusBroken?.length > 0 ? (
                    <Chart
                      options={optionsStatus.options}
                      series={optionsStatus.series}
                      type="bar"
                      width="300"
                      height="150"
                    />
                  ) : (
                    <div className="h-[250px] w-auto font-Montserrat flex justify-center pt-24">
                      No Data Available
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Ideas;
