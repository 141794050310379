import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RouteName from "../../../services/routename";
import Cookies from "js-cookie";
import GeneralUtility from "../../../utility/GeneralUtility";
import ConfirmPopup from "../../ConfirmPopup";
import EncryptDecrypt from "../../../utility/encrypt-decrypt";

const Navbar = () => {
  const [searchNav, setSearchNav] = useState("");
  let enc_firstName = Cookies.get("firstname");
  let firstName = enc_firstName ? EncryptDecrypt.dec(enc_firstName) : null;
  let enc_lastName = Cookies.get("lastname");
  let lastName = enc_lastName ? EncryptDecrypt.dec(enc_lastName) : null;
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const profileView = () => {
    navigate(RouteName.profile);
  };
  let location = useLocation();
  let title = "";
  let username = `${firstName} ${lastName}`;

  if (location.pathname.includes("home")) {
    title = `<b> Hi ${username},</b> <p>Welcome Back!</p>`
  }
  if (location.pathname.includes("latest-challenge")) {
    title = "<b>Latest Challenges</b>"
  }
  if (location.pathname.includes("archived-challenges")) {
    title = "<b>Archived Challenges</b>"
  }
  if (location.pathname.includes("myideas")) {
    title = "<b>My Ideas</b>"
  }
  if (location.pathname.includes("myteams")) {
    title = "<b>Coming Soon</b>"
  }
  if (location.pathname.includes("brainstorming")) {
    title = "<b>Brainstorming</b>"
  }
  if (location.pathname.includes("leaderBoard")) {
    title = "<b>Leaderboard</b>"
  }
  if (location.pathname.includes("resources")) {
    title = "<b>Resources</b>"
  }
  if (location.pathname.includes("polls")) {
    title = "<b>Polls</b>"
  }
  if (location.pathname.includes("how-it-works")) {
    title = "<b>How It Works</b>"
  }

  /**
   * handle search
   * @param {*} e 
   */
  const handleSearch = e => {
    setSearchNav(e.target.value);
  };

  /**
   * handle submit search
   */
  const handleSubmit = () => {
    navigate(RouteName.searchPage, { state: { searchText: searchNav } });
    navigate(0);
  };

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      // 👇 Get input value
      handleSubmit()
    }
  }

  const handleLogout = async () => {
    GeneralUtility.logout();
  }

  return (
    <>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,700&display=swap" rel="stylesheet" />
      <div className="inline-flex items-center font-Montserrat">
        <div className="mx-5 lg:mr-28">
          <img src="/assets/global/img/Logo.png" className="h-12" alt="logo" />
        </div>
        <div className="mx-8 ml-5 w-44 text-black font-Montserrat text-xs">
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className="mt-2 absolute right-0 mr-8 inline-flex space-x-6 items-center bx_top_navbar">
          <div className="relative">
            <input
              autoComplete="off"
              type="text"
              className="border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 py-0.5 px-2 "
              placeholder="Search for a challenge"
              onChange={handleSearch}
              onKeyDown={(e) => {
                handleKey(e);
              }
              }
            />
            <button
              onClick={handleSubmit}
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          {/* hidden */}
          <div className="cursor-pointer hidden">
            <img
              src="/assets/global/img/chatIco.svg"
              className="h-5 mt-1"
              alt="chat"
            />
          </div>
          {/* hidden */}
          <div className="cursor-pointer hidden">
            <img
              src="/assets/global/img/notification.svg"
              className="h-5 mt-1"
              alt="notification"
            />
          </div>
          <div className="dropdown inline-block relative">
            <button className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-bold justify-center">
              {GeneralUtility.getUserInitials()}
            </button>

            <ul className="dropdown-menu absolute hidden border border-gray-100 bg-white text-gray-700 pt-1 -inset-x-20 px-1 w-36">
              {/* hidden */}
              <div onClick={() => profileView()} className=" inline-flex space-x-1 items-center">
                <div className="w-5 h-5">
                  <img
                    src="/assets/global/img/ico_profile.jpg"
                    className="h-5 w-5"
                    alt="avatar"
                  />
                </div>
                <p className="hover:bg-gray-100 py-1 px-4 block whitespace-no-wrap text-sm cursor-pointer">
                  My Profile
                </p>
              </div>
              {/* hidden */}
              <div className="inline-flex space-x-1 items-center hidden">
                <div className="w-5 h-5">
                  <img
                    src="/assets/global/img/ico_lock.jpg"
                    className="w-5 h-5"
                    alt="avatar"
                  />
                </div>
                <p className="hover:bg-gray-100 py-1 px-4 block whitespace-no-wrap text-sm cursor-pointer">
                  Password
                </p>
              </div>
              {/* <hr /> */}
              <div onClick={e => setShow(true)} className="inline-flex space-x-1 items-center">
                <div className="w-5 h-5">
                  <img
                    src="/assets/global/img/ico_logout.jpg"
                    className="w-5 h-5"
                    alt="avatar"
                  />
                </div>
                <p className="hover:bg-gray-100 py-1 px-4 block whitespace-no-wrap text-sm cursor-pointer">
                  Logout
                </p>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <ConfirmPopup show={show} setShow={setShow} onSave={handleLogout} message="Are you sure you want to log out?" />
    </>
  );
};

export default Navbar;
