import React from 'react';
import GeneralUtility from '../../../../../utility/GeneralUtility';
import DateUtility from '../../../../../utility/date-utility';

const ListSubmittedSm = ({ listSubmitted, detailSubmitted, loading }) => {
    return (
        <div className='bg-baseGray p-5 md:hidden lg:hidden'>
            {!loading ? (
                <div>
                    <h3 className="font-semibold text-xl p-3 text-center">Submitted Ideas</h3>
                    {listSubmitted.length === 0 ? (
                        <div className="text-center font-semibold text-md p-6">
                            No Submitted Ideas
                        </div>
                    ) : (
                        <div className="space-y-3">
                            {listSubmitted.map((items, index) => (
                                <div key={index} onClick={() => detailSubmitted(items.id)} className="bg-white shadow-md p-2 space-y-2">
                                    <div className="inline-flex items-center space-x-3">
                                        <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-bold justify-center">
                                            {GeneralUtility.getInitials(items.fullname)}
                                        </div>
                                        <p className="text-xs font-semibold break-all">{items.fullname}</p>
                                    </div>
                                    <div className="text-xs">
                                        {items.title_idea}
                                    </div>
                                    <div 
                                     id="status"
                                     className={`text-white py-[3px] text-xs uppercase text-center 
                                     ${items.status === "Generation" ? "bg-heritageGreen" : ""} 
                                       ${items.status === "Validation" ? "bg-naartjie" : ""} 
                                       ${items.status === "Experimentation" ? " bg-freshGreen" : ""} 
                                       ${items.status === "Commercialisation" ? " bg-blueSky" : ""} 
                                       ${items.status === "Declined" ? " bg-red-700" : ""} 
                                       ${items.status === "Closed" ? "  bg-cerise" : ""}
                                       ${items.status === "On Hold" ? "  bg-orange-900" : ""} `}
                                       >
                                        {items.status}
                                    </div>
                                    <div>
                                        <p className="text-xs">
                                            {DateUtility.formatDate(items.created_at)}
                                        </p>
                                    </div>
                                    <div className="inline-flex space-x-2">
                                        <div className="flex items-center space-x-1.5">
                                            <img
                                                src="/assets/global/img/visible.svg"
                                                className="h-3 w-4"
                                                alt="visible"
                                            />
                                            <p className="text-xs">{items.total_view}</p>
                                        </div>
                                        <div>
                                            <div className="flex items-center space-x-1.5">
                                                <img
                                                    src="/assets/global/img/chat.svg"
                                                    className="h-3 w-4"
                                                    alt="chat"
                                                />
                                                <p className="text-xs">{items.total_commentary}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) :
                (
                    <div className='h-screen text-center'>
                        Loading...
                    </div>
                )
            }
        </div>
    )
}

export default ListSubmittedSm