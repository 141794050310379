import React, { useEffect, useState } from 'react'
import Communication from "../LaunchChallenge/components/Communication"
import Evaluators from "../LaunchChallenge/components/Evaluators"
import Form from "../LaunchChallenge/components/Form"
import Info from "../LaunchChallenge/components/Info"
import InviteUsers from "../LaunchChallenge/components/InviteUsers"
import Launch from "../LaunchChallenge/components/Launch"
import LaunchRepo from '../../../repositories/admin-repo/LaunchRepo'
import GeneralUtility from '../../../utility/GeneralUtility'

const LaunchChallenge = () => {

  const [page, setPage] = useState("info");
  const [info, setInfo] = useState({});
  const [form, setForm] = useState({});
  const [evl, setEvl] = useState({});
  const [error, setError] = useState({});
  const [start_date, setStartDate] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [once, setOnce] = useState(false);
  const [tab, setTab] = useState([]);

  const changeStat = (route, status) => {
    let arr = [
      { title: "Challenge Info", route: "info", status: error.info ? false : true },
      { title: "Form", route: "form", status: error.question ? false : true },
      { title: "Communication", route: "evaluators", status: error.evaluator ? false : true },
      // { title: "Communication", route: "communication" },
      // { title: "Invite Users", route: "invite" },
      { title: "Launch", route: "launch", status: true }
    ]
    setTab(arr)
  }

  /**
   * function to get detail drafted challenge
   */
  const getDrafted = async () => {
      setLoading(true);
      const result = await LaunchRepo.getDraftChallenge();
      if (result !== null) {
        let category = result.category ? result.category.id : null;
        let statusprivacy = result.statusprivacy ? result.statusprivacy.id : null;
        let typeidea = result.typeIdea ? result.typeIdea.id : null;
        setInfo({
          id: result.id, banner_image: result.banner_image,
          card_image: result.card_image, title: result.title,
          category: category, end_date: result.endDate,
          overview: result.description, incentive: result.incentive,
          statusprivacy: statusprivacy, typeidea: typeidea,
          links: result.links, rewards: result.rewards,
          documents: result.documents
        })
        if (result.message_evaluator !== null) {
          let evalMessage = result.message_evaluator.message;
          let evalTitle = result.message_evaluator.title;
          setEvl({ managerchallange: result.managerchallange, evaluation_day: result.evaluation_day, evlMsg: evalMessage, evlTitle: evalTitle })
        } else if (result.message_evaluator === null) {
          setEvl({ managerchallange: result.managerchallange, evaluation_day: result.evaluation_day })
        }

        setForm({ questions: result.questions })
        setStartDate(result.startDate)
        setType("2") //edit
      } else {
        setType("1"); //create
      }
      setLoading(false);
  }

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    if (once === false) {
      getDrafted();
    }
    changeStat();
  }, [error]);

  return (
    <div className='container mx-auto hidden md:block lg:block'>
      <div className='m-6 bg-baseGray p-6'>
        <div className='border-b-2 border-gray-400 w-full'>
          <ul className="inline-flex items-center space-x-3">
            {tab.map((items, index) => (
              <div key={index}
                className="cursor-pointer">
                <div className={`${page === items.route ? "text-futureGreen border-b-2 bx_active_tab_launch" : " border-none"} text-sm font-semibold px-1 flex gap-x-1`}>
                  {items.title}
                  <div className={`${items.status ? "hidden" : "mr-1"} bg-red-500 h-2 w-2 rounded-full`}></div>
                </div>
              </div>
            ))}
          </ul>
        </div>

        <div className='pt-5'>
          <div className={`${page === "info" ? "block" : "hidden"}`}>
            {!loading ?
              (<Info setPage={setPage} info={info} setInfo={setInfo} error={error} start_date={start_date} setStartDate={setStartDate} type={type} once={once} setOnce={setOnce} />)
              :
              (<div className='h-screen'> </div>)
            }
          </div>
          <div className={`${page === "form" ? "block" : "hidden"}`}>
            {!loading ? (
              <Form setPage={setPage} form={form} setForm={setForm} error={error} type={type} />
            ) : (<div className='h-screen'></div>)
            }
          </div>
          <div className={`${page === "evaluators" ? "block" : "hidden"}`}>
            {!loading ? (
              <Evaluators setPage={setPage} setEvl={setEvl} error={error} evl={evl} type={type} />
            ) : (
              <div className='h-screen'></div>
            )}
          </div>
          {/* not yet */}
          <div className={`${page === "communication" ? "block" : "hidden"}`}>
            <Communication setPage={setPage} />
          </div>
          {/* not yet */}
          <div className={`${page === "invite" ? "block" : "hidden"}`}>
            <InviteUsers setPage={setPage} />
          </div>
          <div className={`${page === "launch" ? "block" : "hidden"}`}>
            <Launch info={info} form={form} evl={evl} error={error} setError={setError} start_date={start_date} setStartDate={setStartDate} setPage={setPage} type={type} />
          </div>
        </div>
      </div>
    </div >
  )
}

export default LaunchChallenge
