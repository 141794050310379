import CallApi from "../../services/request-helper";

const API_TYPEIDEA = process.env.REACT_APP_TYPEIDEA;
const API_PRIVACY = process.env.REACT_APP_PRIVACY;
const API_STATUS = process.env.REACT_APP_STATUS;
const API_EVALUATION = process.env.REACT_APP_EVALUATION;
const API_TRANSACTION = process.env.REACT_APP_TRANSACTION;

const getTypeIdea = async () => {
  const url = API_TYPEIDEA;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getPrivacy = async () => {
  const url = API_PRIVACY;
  const response = await CallApi({ url, method: "GET" });
  return response;
}

const getStatus = async () => {
  const url = API_STATUS;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getEvaluation = async () => {
  const url = API_EVALUATION;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getTransaction = async () => {
  const url = API_TRANSACTION;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const FilterRepo = {
  getTypeIdea,
  getPrivacy,
  getStatus,
  getEvaluation,
  getTransaction,
};

export default FilterRepo;
