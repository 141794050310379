import CallApi from "../../services/request-helper";

const API_QUESTION = process.env.REACT_APP_QUESTION;

const getQuestion = async (id) => {
    const url = API_QUESTION +'/'+ id;
    const response = await CallApi({url, method: "GET"});
    return response;
}

const QuestionRepo = {
    getQuestion,
  }
  
  export default QuestionRepo;