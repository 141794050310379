import React from 'react'

const InputText = ({ defaultValue, onChange, placeholder, maxLength, value, type, disabled, names, style }) => {
    return (
        <>
            <input
                autoComplete="off"
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                className={`${style === "border-style" ? "border border-gray-300" : "border-none shadow-md"} text-gray-700 text-sm w-full outline-none`}
                maxLength={maxLength}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                name={names}
            />
        </>
    )
}

export default InputText