import React from "react";
import Skeleton from "../../../../../components/skeleton/Skeleton";

const EvaluationDetail = ({ evaluations, loading }) => {
  return (
    <div className="evaluationProc bg-baseGray">
      <h3 className="text-black font-semibold bx_list_challenges_cont p-3 md:text-lg lg:text-lg">
        Evaluation Process
      </h3>
      {!loading ? (
        <div className="evaluationProc w-[21em] 3xs:w-[21em] sm:w-[37em] md:w-[45em] lg:w-full overflow-x-auto">
          <div
            id="evaluationProc"
            className=" w-[50em] md:w-full lg:w-full p-4 space-y-2"
          >
            <div className="flex space-x-8">
              {evaluations.map((items, index) => (
                <div key={index} className="space-y-3 basis-1/4 bx_list_stage">
                  <h4 className="text-freshGreen uppercase font-semibold">
                    Step {index + 1}
                  </h4>
                  <div className="flex items-start space-x-2">
                    <div className="h-5 w-5">
                      <img
                        src={`
                    ${
                      items.name === "Generation"
                        ? "/assets/global/img/lightbulbGreen.svg"
                        : ""
                    }
                    ${
                      items.name === "Validation"
                        ? "/assets/global/img/validateGreen.svg"
                        : ""
                    }
                    ${
                      items.name === "Experimentation"
                        ? "/assets/global/img/scienceGreen.svg"
                        : ""
                    }
                    ${
                      items.name === "Commercialisation"
                        ? "/assets/global/img/launchGreen.svg"
                        : ""
                    }
                    `}
                        alt="icon_name"
                      />
                    </div>
                    <p className="text-heritageGreen font-semibold inline-block">
                      {items.name}
                    </p>
                  </div>
                  <div className="w-full h-0.5 bg-freshGreen"></div>
                  <div className="flex space-x-2">
                    <p className="text-freshGreen text-md">
                      {" "}
                      {items.total_idea}{" "}
                    </p>
                    <p className="text-black text-md font-semibold ">
                      {items.info}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Skeleton col="1" height="200" />
        </div>
      )}
    </div>
  );
};

export default EvaluationDetail;
