import React from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { useRef, useState, useEffect } from 'react';

const Comments = ({ onChange, editorKey }) => {
  const editorRef = useRef(null);
  const [description, setDescription] = useState("");

  useEffect(() => {
    onChange(description);
  }, [description]);

  return (
    <div className="z-0">
      <Editor
        key={editorKey}
        // apiKey="rx4no1r1mwnsqiwgk8n4ruah0znkp4pn0n3qy6updcoi09yt"
        apiKey="lt4xr20og1e36xj9296qxlxrk7cwjn9b8v6522k7bdxm6v3o"
        outputFormat="text"
        initialValue=""
        onEditorChange={newText => setDescription(newText)}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          placeholder: "Add a comment...",
          plugins: ["emoticons", "image", "preview", "fullscreen", "media"],
          toolbar: "insert emoticons",
          menubar: false,
          toolbar_location: "bottom",
          statusbar: false,
          height: 150,
          width: "100%",
          entity_encoding: "numeric"
        }}

      />
    </div>
  );
}

export default Comments
