import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import RouteAdm from "../../../services/routeAdm";
import ConfirmPopup from "../../ConfirmPopup";
import GeneralUtility from "../../../utility/GeneralUtility";
import "./Sidebar.css";
import Cookies from "js-cookie";

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);

  const Menus = [
    { title: "Dashboard", src: "dashboard", route: RouteAdm.dashboard },
    { title: "Challenges", src: "launch", route: RouteAdm.challenges },
    { title: "Ideas", src: "lightbulb", route: RouteAdm.ideas },
    { title: "Communication", src: "email", route: RouteAdm.communication },
    { title: "Pages", src: "edit", route: RouteAdm.admPages },
  ];

  const removeCookies = () =>{
    Cookies.remove("gridView");
    Cookies.remove("ideasYear");
    Cookies.remove("ideasPage");
  }

  const handleLogout = async () => {
    GeneralUtility.logout();
  };

  return (
    <div className="flex">
      <div
        className={` ${
          open ? " w-60" : "w-20"
        } bg-heritageGreen h-screen py-5 pt-8 relative duration-300`}
      >
        <img
          alt=""
          src="/assets/global/img/collapse.png"
          className={`absolute cursor-pointer -right-3 top-5 w-7 ${
            !open && "rotate-180"
          }`}
          onClick={() => setOpen(!open)}
        />
        <ul className="pt-6">
          <NavLink
          onClick={removeCookies}
            to={RouteAdm.launchChallenge}
            className="font-normal my-1 ml-4 text-white p-2 cursor-pointer bg-futureGreen flex"
          >
            <div className={`text-sm flex items-center gap-x-1`}>
              <div className="h-5 w-5">
                <img
                  src="/assets/global/img/create.svg"
                  alt="launchChallenge"
                />
              </div>
              <span
                className={`${!open && "hidden"} origin-left duration-200 ml-2`}
              >
                Launch Challenge
              </span>
            </div>
          </NavLink>
          {Menus.map((Menu, index) => (
            <NavLink
              key={index}
              to={Menu.title === "Challenges" ? Menu.route + '?tab=active&page=0&year='+ new Date().getFullYear() + "&type=all": Menu.route}
              onClick={removeCookies}
              className="font-normal my-1 ml-4 text-white p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex"
            >
              <p className="text-sm flex items-center gap-x-1">
                <div className="h-5 w-5">
                  <img
                    className={`${index === 2 ? "w-[21px] h-[21px]" : ""}`}
                    src={`/assets/global/img/${Menu.src}.svg`}
                    alt={`${Menu.title}`}
                  />
                </div>
                <span
                  className={`${
                    !open && "hidden"
                  } origin-left duration-200 ml-2`}
                >
                  {Menu.title}
                </span>
              </p>
            </NavLink>
          ))}
          <div
            onClick={(e) => setShow(true)}
            className="font-normal my-1 ml-4 text-white p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex"
          >
            <div className="text-sm flex items-center gap-x-1">
              <div className="h-5 w-5">
                <img src={`/assets/global/img/logout.svg`} alt="logout" />
              </div>
              <span
                className={`${!open && "hidden"} origin-left duration-200 ml-2`}
              >
                Log Out
              </span>
            </div>
          </div>
        </ul>
      </div>
      <ConfirmPopup
        show={show}
        setShow={setShow}
        onSave={handleLogout}
        message="Are you sure you want to log out?"
      />
    </div>
  );
};

export default Sidebar;
