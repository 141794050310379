import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RouteName from "../../../../../services/routename";
import { Modal } from "flowbite-react";
import FilterRepo from "../../../../../repositories/user-repo/FilterRepo";
import ChallengeRepo from "../../../../../repositories/user-repo/ChallengeRepo";
import Cookies from "js-cookie";
import EncryptDecrypt from "../../../../../utility/encrypt-decrypt";
import DropdownFilter from "../../../../../components/DropdownFilter";
import Paginate from "../../../../../components/paginate/Paginate";

const ModalPersonal = ({ show, setShow, id }) => {
  Cookies.set("idIdea", EncryptDecrypt.enc(id), { secure: true });
  const [listStatus, setListStatus] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [limit] = useState(8);
  const [page, setPage] = useState([]);
  const [checked, setChecked] = useState(false);
  const [selectedStat, setSelectedStat] = useState({});

  /**
   * get filter status
   */
  const getFilterStatus = async () => {
    const result = await FilterRepo.getStatus();
    let listStatus = result.data.data;
    listStatus.push({ id: 'all', name: 'all' })
    //removing up coming and closed filter
    let newArr = listStatus.filter(items => items.name !== "up coming" && items.name !== "closed");
    setListStatus(newArr);
  };

  /**
   * get active and new challenge
   * @param {*} pageNumber 
   */
  const getActChallenge = async pageNumber => {
    const result = await ChallengeRepo.getActChallenges({
      offset: pageNumber,
      limit: limit,
      status: selectedStat.name,
    });
    let listChallenges = result !== null ? result.data : [];
    let listPage = result !== null ? result : [];
    setChallenges(listChallenges);
    setPage(listPage);
  }

  /**
   * get id selected challenge
   * @param {*} id 
   */
  const getIdChallenge = async slug => {
    setSelectedId(slug);
    setChecked(true);
  };

  // Change Page
  const paginate = event => {
    getActChallenge(event.selected);
  };

  useEffect(() => {
    getFilterStatus();
    getActChallenge(0);
  }, [selectedStat]);

  return (
    <div>
      <Modal className="bx_modalpop_global" show={show} popup={true} size="4xl" onClose={() => setShow(!show)}>
        <Modal.Header />
        <Modal.Body>
          <div className='bx_modalpop_global_min'>
            <div className="text-center text-heritageGreen text-2xl mb-4 ht_modal_title">
              Select Challenges
            </div>
            <div className="flex justify-end bx_modal_filter">
              <DropdownFilter title="sort by :"  selected={selectedStat} setSelected={setSelectedStat} resList={listStatus} />
            </div>
            <div className="bg-baseGray h-[28rem] overflow-y-auto mt-2 py-6">
              <div className="mx-auto px-3 md:px-6 bx_modal_listchallenges">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-3">
                  {challenges.map((items, index) => (
                    <div
                      onClick={e => getIdChallenge(items.slug)}
                      key={index}
                      className={`${selectedId === items.slug ? "shadow-md shadow-freshGreen " : ""} bg-white border border-gray-200 shadow-md relative pb-8 `}
                    >
                      <div
                        className="w-full h-[200px] bg-cover bg-center bg-no-repeat mb-4 md:h-[200px]"
                        style={{
                          backgroundImage:
                            "url(" + items.card_image + ")",
                        }}
                      >
                        <div className="float-right">
                          <p
                            id="status"
                            className="bg-white w-32 py-[3px] text-center text-xs font-semibold text-freshGreen uppercase tracking-[0.5px]"
                          >
                            {items.status}
                          </p>
                        </div>
                      </div>
                      <div className="p-3 space-y-3">
                        <h5
                          id="title"
                          className="mb-2 text-sm font-bold tracking-tight text-gray-900"
                          title={items.title}
                        >
                          { items.title ? (items.title.length > 40 ? items.title.substring(0, 40) + "..." : items.title ) : ''}
                        </h5>
                        <div id="content">
                          <p className="mb-3 text-xs text-gray-700">
                            {items.desc === null ? "" : (
                              <div dangerouslySetInnerHTML={{
                                __html: items.desc.length > 100
                                  ? items.desc.substring(0, 100) + "..."
                                  : items.desc
                              }} />
                            )}
                          </p>
                        </div>
                        <div className="flex space-x-2 mt-10 absolute bottom-3 ">
                          <div className="flex items-center space-x-1 rounded-md text-gray-500">
                            <img
                              src="/assets/global/img/avatar.png"
                              className="h-2 w-2"
                              alt="avatar"
                            />
                            <p className="text-sm">{items.total_submited}</p>
                          </div>
                          <div className="flex items-center space-x-1 rounded-md text-gray-500">
                            <img
                              src="/assets/global/img/visible.png"
                              className="h-2 w-3"
                              alt="view"
                            />
                            <p className="text-sm">{items.total_view}</p>
                          </div>
                          <div className="flex items-center space-x-1 rounded-md text-gray-500">
                            <img
                              src="/assets/global/img/lightbulbGray.png"
                              className="h-3 w-2.5"
                              alt="idea"
                            />
                            <p className="text-sm">{items.total_submited}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="py-3">
                  <Paginate paginate={paginate} page={page} />
                </div>
              </div>
            </div>

            <div className="text-center mt-4">
              <div className="flex justify-center">
                {checked === true ? (
                  <Link to={RouteName.submitPersonal + "/" + selectedId}>
                    {/* <BtnSubmit onClick={() => setShow(!show)} title="SUBMIT IDEA" /> */}
                    <button className="border border-1 border-green-400 text-center text-xs p-3 py-2 inline-flex items-center text-freshGreen hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white uppercase" onClick={() => setShow(!show)}>SUBMIT IDEA</button>

                  </Link>
                ) : (
                  <button className="border border-1 border-green-400 text-center text-xs p-3 py-2 inline-flex items-center text-freshGreen hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white uppercase">SUBMIT IDEA</button>
                  )
                }
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalPersonal;
