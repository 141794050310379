import React from "react";
import Chart from "react-apexcharts";

const ChartBarReturning = ({
  categories,
  colors,
  data,
  width,
  height,
  optWidth,
  optLegend,
}) => {
  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    legend: {
      show: optLegend,
      horizontalAlign: "left",
      offsetX: -10,
      width: optWidth,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        display: false,
      },
    },
    colors: colors,
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      type: "category",
      categories: categories,
    },
    yaxis: {
      show: false,
      labels: {
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      colors: colors,
    },
    tooltip: {
      enabled: true,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        const category = w.config.xaxis.categories[dataPointIndex];
        const tooltipText = category === "New" ?
          "" :
          category === "Returning" ?
          "Users that return at least once." :
          "No additional information.";
          
   
        return `
          <div style="padding: 10px; background: #fff; border: 1px solid #ccc;">
            <strong>${category}</strong><br />
            ${tooltipText}
          </div>
        `;
      }
    }
  };

  const series = [
    {
      name: "User Data",
      data: data,
    }
  ];

  return (
    <>
      <div className="mixed-chart">
        <Chart
          options={options}
          series={series}
          type="bar"
          width={width}
          height={height}
        />
      </div>
      {!optLegend ? (
        <div className="grid grid-flow-row gap-2 mt-[-15px] p-3">
          {colors.map((res, index) => (
            <div
              className="text-[12px] flex flex-row capitalize justify-between items-center"
              key={res}
            >
              <div className="flex flex-row justify-center align-middle items-center">
                <div className={`bg-[${res}] h-3 w-3 mr-3 ml-2`}> </div>
                {categories[index]}
              </div>
              <div className="ml-1">{data[index]}</div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default ChartBarReturning;
