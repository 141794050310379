import React from 'react';
import "./Paginate.css";
import ReactPaginate from 'react-paginate';

const Paginate = ({ paginate, page, defPage }) => {
    
    return (
        <ReactPaginate
            nextLabel=">"
            onPageChange={paginate}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={page.totalPage}
            previousLabel="<"
            pageClassName="text-xs pt-1 bg-white shadow-md rounded-sm font-semibold border border-gray-200 hover:bg-gradient-to-r from-futureGreen to-heritageGreen hover:text-white border-none"
            pageLinkClassName="page-link px-3 py-2"
            previousClassName="bg-white shadow-md rounded-sm px-3 font-semibold"
            previousLinkClassName="page-link"
            nextClassName="bg-white shadow-md rounded-sm px-3 font-semibold"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="text-xs pt-1 bg-white shadow-md rounded-sm font-semibold border border-gray-200 hover:bg-gradient-to-r from-futureGreen to-heritageGreen hover:text-white border-none"
            breakLinkClassName="page-link"
            containerClassName="paginate"
            activeClassName="bg-gradient-to-r from-futureGreen to-heritageGreen text-white border-none"
            renderOnZeroPageCount={null}
            className=''
            forcePage={ defPage ? parseInt(defPage) : 0}
        />
    )
}

export default Paginate