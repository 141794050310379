import React from "react";
import { useEffect, useState, useRef } from "react";
//Button Green
const ButtonEdit = ({ onClick, isEdit }) => {
  return (
    <>
      <div
        className={
          isEdit
            ? "mr-2 listedit-rewards-on pt-2 w-7 h-7"
            : "mr-2 listedit-rewards pt-2 w-7 h-7"
        }
        title="Edit"
        onClick={onClick}
      >
        <svg
          id="edit_-_icon"
          data-name="edit - icon"
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="15"
          viewBox="0 0 12.857 12.857"
        >
          <path
            id="Path_16"
            data-name="Path 16"
            d="M3,16.77H5.677l7.9-7.9L10.9,6.192,3,14.092Zm1.429-2.086L10.9,8.213l.657.657L5.085,15.342H4.427Z"
            transform="translate(-2.999 -3.913)"
            fill="#fff"
          />
          <path
            id="Path_17"
            data-name="Path 17"
            d="M17.443,3.208a.711.711,0,0,0-1.007,0L15.129,4.515l2.679,2.679,1.307-1.307a.711.711,0,0,0,0-1.007Z"
            transform="translate(-6.466 -2.999)"
            fill="#fff"
          />
        </svg>
      </div>
    </>
  );
};

export default ButtonEdit;
