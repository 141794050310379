import React from "react";
import { useEffect, useState, useRef } from "react";
import Skeleton from "../../../../components/skeleton/Skeleton";
import "../css/edit_page.css";
import ButtonGroupEdit from "./Button/ButtonGroupEdit";
import ButtonGroupEditNews from "./Button/ButtonGroupEditNews";
import AlertUtility from "../../../../utility/alert-utility";

const NewsHome = ({
  loading,
  newsInfo,
  bannerInfo,
  defaultNews,
  setBannerInfo,
  setNewsInfo,
  showModePreview,
  setPrevData,
  fileToBase64,
  img,
  setImg,
  setEditStatus,
  editStatus,
}) => {
  const refNewsInfo = React.createRef();
  const [isEdit, setIsEdit] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const uploadImageRef = useRef(null);
  const openExternalLink = () => {
    window.open("https://www.ominnovoice.co.za/?oldmutualinnovation", "_blank");
  };
  const handleEdit = () => {
    const addStatus = [...editStatus];
    addStatus.push(1);
    setEditStatus(addStatus);
    setIsEdit(true);
    refNewsInfo.current.setAttribute("contenteditable", true);
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
    refNewsInfo.current.setAttribute("contenteditable", false);
    const addStatus = [...editStatus];
    addStatus.pop(1);
    setEditStatus(addStatus);
  };

  const handleOnBlurNewsInfo = (event) => {
    if (event.target.getAttribute("data-content-changed") === "true") {
      newsInfo= event.target.innerText;
      setNewsInfo(newsInfo);
      setPrevData();
    }
    event.target.setAttribute("data-content-changed", "false");
  };
  const handleOnInputNewsInfo = (event) => {
    event.target.setAttribute("data-content-changed", "true");
  };

  /**
   * function to handle delete
   *
   */
  const handleDelete = () => {
    setImg(defaultNews.data.bannerInfo);
    setNewsInfo(defaultNews.data.newsInfo);
    setBannerInfo(defaultNews.data.bannerInfo);
  };

  /**
   * function to handle change
   * @param {e}
   */
  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size < 5000000) {
      if (file) {
        try {
          const base64 = await fileToBase64(file);
          setImg(base64);
        } catch (error) {
          console.error("Error converting file to base64:", error);
        }
      }
      setIsUpload(true);
      setBannerInfo(file);
    } else {
      AlertUtility.errorAlert({ title: "File more than 5 Mb" });
    }
  };
  const setPrevImage = () => {
    if (isUpload) {
      setPrevData();
      setIsUpload(false);
    }
  };

  const hitImage = async () => {
    setImg(img ? img : bannerInfo === null ? "" : bannerInfo);
  };
  const handleUploadButton = () => {
    uploadImageRef.current.click();
  };

  useEffect(() => {
    setPrevImage();
    hitImage();
  });

  return (
    <>
      {!loading ? (
        <>
          <div className="relative m-5 tooltip">
          <span className="tooltiptext" style={{top:"10%", left:"41%"}}>Image ratio : 1400 x 300 pixels. Max file : 5 MB</span>
            {showModePreview ? (
              ""
            ) : (
              <div className="flex z-[6] absolute -top-3 left-6">
                {isEdit ? (
                  <ButtonGroupEditNews
                    onClickEdit={handleCancelEdit}
                    onClickUpload={handleUploadButton}
                  />
                ) : (
                  <ButtonGroupEdit
                    onClickDelete={handleDelete}
                    onClickEdit={handleEdit}
                  />
                )}
              </div>
            )}

            <img
              className="overflow-hidden max-h-64 w-full bg-gray-200"
              src={img}
              alt="banner-explore"
            />
            <div className="text-white space-y-1 md:space-y-4 absolute top-0 flex flex-col items-center justify-center h-full w-full">
              <h2
                ref={refNewsInfo}
                className="font-semibold text-center pt-1 text-sm md:text-base break-words"
                onInput={handleOnInputNewsInfo}
                onBlur={handleOnBlurNewsInfo}
                data-content-changed="false"
              >
                {newsInfo}
              </h2>
              <div className="text-center font-semibold">
                <button
                  onClick={openExternalLink}
                  className=" bg-white text-freshGreen text-sm md:text-base hover:text-white py-1 px-2 md:py-1.5 md:px-4 hover:bg-gradient-to-tr from-green-600 to-freshGreen"
                >
                  EXPLORE
                </button>
              </div>
            </div>
            <input
              style={{ display: "none" }}
              ref={uploadImageRef}
              autoComplete="off"
              type="file"
              className="hidden"
              accept=".jpeg, .png, .jpg"
              onChange={(e) => handleUpload(e)}
            />
          </div>
        </>
      ) : (
        <div className="c-image-container">
          <Skeleton col={1} height={300} />
        </div>
      )}
    </>
  );
};

export default NewsHome;
