import React from "react";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import ArrowIco from "../../../../../components/icons/ArrowIco";
import RouteName from "../../../../../services/routename";
import "../../css/edit_page.css";
import ButtonGroupEdit from "../Button/ButtonGroupEdit";
import InsightCardLarge from "./InsightCardLarge";
import InsightCardSmall from "./InsightCardSmall";

// const InsightHome = ({ insights, insights2 }) => {
const InsightHome = ({
  listInsight,
  titleInsight,
  setInsight,
  showModePreview,
  setTitleInsight,
  defaultContent,
  setPrevData,
  setEditStatus,
  editStatus,
}) => {
  const refInsightTitle = React.createRef();
  const [isEdit, setIsEdit] = useState(false);
  const handleEditTitle = () => {
    const addStatus = [...editStatus];
    addStatus.push(1);
    setEditStatus(addStatus);
    setIsEdit(true);
    refInsightTitle.current.setAttribute("contenteditable", true);
  };

  const handleCancelEditTitle = () => {
    setIsEdit(false);
    refInsightTitle.current.setAttribute("contenteditable", false);
    const addStatus = [...editStatus];
    addStatus.pop(1);
    setEditStatus(addStatus);
  };

  const handleDeleteTitle = () => {
    setTitleInsight(defaultContent.data.titleInsight);
  };
  const handleOnBlurTitleInsight = (event) => {
    if (event.target.getAttribute("data-content-changed") === "true") {
      titleInsight= event.target.innerText;
      setTitleInsight(titleInsight);
      setPrevData();
    }
    event.target.setAttribute("data-content-changed", "false");
  };
  const handleOnInputTitleInsight = (event) => {
    event.target.setAttribute("data-content-changed", "true");
  };

  const handleInputChange = (index, event) => {
    const values = [...listInsight];
    const updatedValue = event.target.name;
    const inputValue = event.target.value;
    if (updatedValue === "source") {
      const baseUrl = "https://www.youtube.com/embed/";
      values[index][updatedValue] = baseUrl + inputValue;
    } else {
      values[index][updatedValue] = inputValue;
    }
    console.log(""+index+" "+values[index][updatedValue]);
    setInsight(values);
    setPrevData();
  };

  const updateChangeValue = (index, name, value) => {
    const values = [...listInsight];
    const updatedValue = name;
    values[index][updatedValue] = value;
    console.log(""+index+""+name+""+value);
    setInsight(values);
    setPrevData();
  };
  const handleDeleteCard = (index) => {
    console.log(defaultContent);
    const values = [...listInsight];
    values[index] = defaultContent.data.insights[index];

    setInsight(values);
  };

  return (
    <>
      <div className="relative bg-baseGray space-y-3 p-6 font-Montserrat bx_home_insight ml-5 mr-5">
        {showModePreview ? (
          ""
        ) : (
          <div className="flex z-[6] absolute -top-3 left-6">
            <ButtonGroupEdit
              onClickDelete={handleDeleteTitle}
              onClickEdit={isEdit ? handleCancelEditTitle : handleEditTitle}
              isEdit={isEdit}
            />
          </div>
        )}
        <h2
          className="text-xl font-semibold text-center pt-3 pb-7 ht_home_title break-words"
          ref={refInsightTitle}
          onInput={handleOnInputTitleInsight}
          onBlur={handleOnBlurTitleInsight}
          data-content-changed="false"
        >
          {titleInsight}
        </h2>
        {listInsight !== null ? (
          <>
            <div className="relative grid grid-cols-1 lg:grid-cols-2 gap-6">
              <InsightCardLarge
                insights2={listInsight[0]}
                onChange={handleInputChange}
                updateChangeValue={updateChangeValue}
                setPrevData={setPrevData}
                handleDeleteCard={handleDeleteCard}
                showModePreview={showModePreview}
                setEditStatus={setEditStatus}
                editStatus={editStatus}
              />

              <div className="relative space-y-1 lg:space-y-1">
                {listInsight.map((items, index) =>
                  index !== 0 ? (
                    <InsightCardSmall
                      items={items}
                      onChange={handleInputChange}
                      index={index}
                      updateChangeValue={updateChangeValue}
                      setPrevData={setPrevData}
                      handleDeleteCard={handleDeleteCard}
                      showModePreview={showModePreview}
                      setEditStatus={setEditStatus}
                      editStatus={editStatus}
                    />
                  ) : (
                    ""
                  )
                ) ?? ""}
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="py-4"></div>

        {/* hidden */}
        <div className="text-center mt-4 py-9 hidden">
          <Link to={RouteName.userResources}>
            <button className="border border-1 uppercase text-sm border-green-400 text-center p-3 inline-flex items-center text-freshGreen hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white">
              VIEW RESOURCES
              <ArrowIco />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default InsightHome;
