import CallApi from "../../services/request-helper";

const API_EDIT_CONTENT = process.env.REACT_APP_EDIT_CONTENT;
const API_EDIT_TERMS = process.env.REACT_APP_EDIT_TERMS;
const API_GET_CONTENT_DRAFT = process.env.REACT_APP_GET_CONTENT_DRAFT;
const API_GET_CONTENT_DEFAULT = process.env.REACT_APP_GET_CONTENT_DEFAULT;

const editPublishPage = async (data) => {
  const url = API_EDIT_CONTENT;
  const response = await CallApi({ url, method: "POST", data, type: "file" });
  return response;
};

const editTerms = async (data) => {
  const url = API_EDIT_TERMS;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const getContentDraft = async (data) => {
  const url = API_GET_CONTENT_DRAFT;
  const response = await CallApi({ url, method: "POST", data });
  const dat = response !== undefined ? response.data : null;
  return dat;
};

const getContentDefault = async () => {
  const url = API_GET_CONTENT_DEFAULT;
  const response = await CallApi({ url, method: "GET" });
  const dat = response !== undefined ? response.data : null;
  return dat;
};

const EditRepo = {
  editPublishPage,
  getContentDraft,
  getContentDefault,
  editTerms,
};

export default EditRepo;
