// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 18px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 12px;
    height: 12px;
    border: 1px solid #ddd;
    border-radius: 0;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '\\1F5F9';
    width: 12px;
    height: 12px;
    font-size: 15px;
    position: absolute;
    top: 2px;
    left: -0.5px;
    border-radius: 0;
    color: #50B848;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}`, "",{"version":3,"sources":["webpack://./src/pages/admin-page/LaunchChallenge/LaunchChallenge.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,aAAa;AACjB;AACA;;;IAGI,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;AACzB;AACA;;IAEI,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;;IAEI,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,YAAY;IACZ,gBAAgB;IAChB,cAAc;IAEd,yBAAyB;AAC7B;AACA;IACI,UAAU;IAEV,mBAAmB;AACvB;AACA;IACI,UAAU;IAEV,mBAAmB;AACvB","sourcesContent":["[type=\"radio\"]:checked,\r\n[type=\"radio\"]:not(:checked) {\r\n    position: absolute;\r\n    left: -9999px;\r\n}\r\n[type=\"radio\"]:checked + label,\r\n[type=\"radio\"]:not(:checked) + label\r\n{\r\n    position: relative;\r\n    padding-left: 18px;\r\n    cursor: pointer;\r\n    line-height: 20px;\r\n    display: inline-block;\r\n}\r\n[type=\"radio\"]:checked + label:before,\r\n[type=\"radio\"]:not(:checked) + label:before {\r\n    content: '';\r\n    position: absolute;\r\n    left: 0;\r\n    top: 6px;\r\n    width: 12px;\r\n    height: 12px;\r\n    border: 1px solid #ddd;\r\n    border-radius: 0;\r\n    background: #fff;\r\n}\r\n[type=\"radio\"]:checked + label:after,\r\n[type=\"radio\"]:not(:checked) + label:after {\r\n    content: '\\1F5F9';\r\n    width: 12px;\r\n    height: 12px;\r\n    font-size: 15px;\r\n    position: absolute;\r\n    top: 2px;\r\n    left: -0.5px;\r\n    border-radius: 0;\r\n    color: #50B848;\r\n    -webkit-transition: all 0.2s ease;\r\n    transition: all 0.2s ease;\r\n}\r\n[type=\"radio\"]:not(:checked) + label:after {\r\n    opacity: 0;\r\n    -webkit-transform: scale(0);\r\n    transform: scale(0);\r\n}\r\n[type=\"radio\"]:checked + label:after {\r\n    opacity: 1;\r\n    -webkit-transform: scale(1);\r\n    transform: scale(1);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
