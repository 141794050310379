import React from 'react'
import { Link,useLocation } from 'react-router-dom'
import RightIco from '../../../../../components/icons/RightIco'
import RouteAdm from '../../../../../services/routeAdm'

const Breadcrumbs = ({ title }) => {
  let location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const urlview = queryParams.get('view') || 0; 
  const urlTab = queryParams.get('tab') || 0; 
  const urlpage = parseInt(queryParams.get('page')) || 0; 
  const urlyear = parseInt(queryParams.get('year')) || 2024; 
  const urlEval  = queryParams.get('evaluation') || "all"; 
  const urlStatus  = queryParams.get('status') || "all";
  const base  = queryParams.get('base') || "ideas"; 
  const urlChallenge = queryParams.get('challengeId')

  return (
    <div>
      <nav className="flex mb-3 mt-4 px-6 text-[9px] md:text-[10px] pb-1 md:pb-0" aria-label="Breadcrumb">
        <div className="inline-flex items-center space-x-1 md:space-x-3">
          <div className="inline-flex items-center">
            <Link
              to={RouteAdm.dashboard}
              className="inline-flex items-center font-medium text-gray-500 hover:text-gray-900"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
            </Link>
          </div>
          <div className="flex items-center">
            <Link
              to={base === "challenges" ? RouteAdm.submittedChallengeAdm + "/" + urlChallenge +"?"+"tab=" + urlTab +"&page="+ urlpage+ "&status=" + urlStatus 
                : RouteAdm.ideas +"?"+"view=" + urlview +"&page=" + urlpage + "&year=" + urlyear + "&evaluation="+urlEval}
              className="md:ml-1 flex items-center font-medium text-gray-500 hover:text-gray-900 md:ml-2"
            >
              <RightIco />
              Ideas
            </Link>
          </div>
          <div aria-current="page">
            <div className="flex items-center ">
              <RightIco />
              <span className="ml-1 font-medium text-gray-700 md:ml-2 hidden md:block lg:block">
                {title ? (title.length > 80 ? title.substring(0, 80) + '...' : title) : title}
              </span>
              <span className="ml-1 font-medium text-gray-700 md:ml-2 md:hidden lg:hidden">
                {title ? (title.length > 60 ? title.substring(0, 60) + '...' : title) : title}
              </span>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Breadcrumbs
