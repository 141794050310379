import React, { useState } from 'react'
import RichText from '../../../../components/RichText'
import BtnSubmit from '../../../../components/buttons/BtnSubmit';

const InviteUsers = ({ setPage, setInvite }) => {
  const [message, setMessage] = useState("");
  const handleSave = async () => {
    setPage("launch");
  }

  return (
    <>
      <div className='space-y-4'>
        <div className='space-y-3'>
          <h3 className='text-textBlack flex font-semibold pt-3 text-md'>Notifications</h3>
          <div className='flex items-start space-x-3'>
            <div>
              <input autoComplete="off" type="checkbox" className="w-3.5 h-3.5 text-freshGreen bg-gray-100 border-gray-300 focus:ring-freshGreen focus:ring-2" />
            </div>
            <div>
              <p className='font-semibold text-textBlack text-sm'>All Users</p>
              <p className='text-textGray text-xs'>All members will receive an email notification of the launch of this challenge</p>
            </div>
          </div>
        </div>
        <div className='space-y-3'>
          <div className='flex items-start space-x-3'>
            <div>
              <input autoComplete="off" type="checkbox" className="w-3.5 h-3.5 text-freshGreen bg-gray-100 border-gray-300 focus:ring-freshGreen focus:ring-2" />
            </div>
            <div>
              <p className='font-semibold text-textBlack text-sm'>Select Users / Teams</p>
              <p className='text-textGray text-xs'>Selected members and/or teams will get an email notification of the launch of this challenge</p>
            </div>
          </div>
        </div>
        <div className='space-y-3'>
          <h3 className='text-textBlack flex font-semibold pt-3 text-md'>Select Users</h3>
          <select className='border-none shadow-md h-10 text-textGray w-full' id="">
            <option value="">option 1</option>
            <option value="">option 2</option>
            <option value="">option 3</option>
          </select>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5'>
            <div className="bg-gray-200 flex p-2 justify-between space-x-6 items-center shadow-sm">
              <div className="flex space-x-2">
                <img
                  src="/assets/global/img/avatarRound.png"
                  className="w-5 h-5"
                  alt=""
                />
                <div className="text-gray-600 text-sm">Evaluator Name</div>
              </div>
              <button>
                <img
                  src="/assets/global/img/bin.svg"
                  className="w-3 h-4"
                  alt=""
                />
              </button>
            </div>
            <div className="bg-gray-200 flex p-2 justify-between space-x-6 items-center shadow-sm">
              <div className="flex space-x-2">
                <img
                  src="/assets/global/img/avatarRound.png"
                  className="w-5 h-5"
                  alt=""
                />
                <div className="text-gray-600 text-sm">Username</div>
              </div>
              <button>
                <img
                  src="/assets/global/img/bin.svg"
                  className="w-3 h-4"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
        <div className='space-y-3'>
          <h3 className='text-textBlack flex font-semibold pt-3 text-md'>Select Teams</h3>
          <select className='border-none shadow-md h-10 text-textGray w-full' id="">
            <option value="">option 1</option>
            <option value="">option 2</option>
            <option value="">option 3</option>
          </select>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5'>
            <div className="bg-gray-200 flex p-2 justify-between space-x-6 items-center shadow-sm">
              <div className="flex space-x-2">
                <img
                  src="/assets/global/img/avatarRound.png"
                  className="w-5 h-5"
                  alt=""
                />
                <div className="text-gray-600 text-sm">Username</div>
              </div>
              <button>
                <img
                  src="/assets/global/img/bin.svg"
                  className="w-3 h-4"
                  alt=""
                />
              </button>
            </div>
            <div className="bg-gray-200 flex p-2 justify-between space-x-6 items-center shadow-sm">
              <div className="flex space-x-2">
                <img
                  src="/assets/global/img/avatarRound.png"
                  className="w-5 h-5"
                  alt=""
                />
                <div className="text-gray-600 text-sm">Username</div>
              </div>
              <button>
                <img
                  src="/assets/global/img/bin.svg"
                  className="w-3 h-4"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
        <div id='msg' className='space-y-3'>
          <h3 className='text-textBlack flex font-semibold pt-3 text-md'>Custom message for Evaluators</h3>
          <RichText onChange={(content) => setMessage(content)} />
        </div>
        <div className='pt-4 text-right'>
          <BtnSubmit onClick={handleSave} title="Save & Next" />
        </div>
      </div>
    </>
  )
}

export default InviteUsers
