import CallApi from "../../services/request-helper";

const API_SUBMITCREATE = process.env.REACT_APP_CREATEBYSUBMITTED;
const API_SUBMITBYSELECT = process.env.REACT_APP_CREATEBYSELECT;
const API_GETDETAIL = process.env.REACT_APP_DETAILDRAFTIDEA;

/**
 * function to submit idea by create in challenge
 * @param {*} data 
 * @returns 
 */
const createSubmitted = async (dat) => {
  let data = new FormData();
  data.append("myideas.title", dat.myideas.title)
  data.append("myideas.description", dat.myideas.description)
  if (dat.myideas.documents !== []) {
    dat.myideas.documents.forEach((value, index) => {
      data.append(`myideas.documents[${index}].source`, value.file);
    });
  }
  if (dat.myideas.links !== []) {
    let link = 0;
    dat.myideas.links.forEach((value) => {
      if (value.resourceName !== "" && value.source !== "") {
        data.append(`myideas.links[${link}].resourceName`, value.resourceName);
        data.append(`myideas.links[${link}].source`, value.source);
        link = link + 1;
      }
    });
  };
  if (dat.transaction.banner_image) {
    data.append("transaction.banner_image", dat.transaction.banner_image);
  }
  if (dat.transaction.card_image) {
    data.append("transaction.card_image", dat.transaction.card_image);
  }
  data.append("transaction.challange.id", dat.transaction.id);
  dat.transaction.answers.forEach((value, index) => {
    data.append(`transaction.answers[${index}].question.id`, value.question.id);
  });
  dat.transaction.answers.forEach((value, index) => {
    data.append(`transaction.answers[${index}].answer`, value.answer);
  });
  data.append("myideas.drafted", dat.myideas.drafted);
  // if (dat.remove_banner) {
  //     data.append('remove_banner', dat.remove_banner)
  // }
  // if (dat.remove_card) {
  //     data.append('remove_card', dat.remove_card)
  // }
  const url = API_SUBMITCREATE;
  const response = await CallApi({ url, method: "POST", data, type: "file" });
  return response;
};


/**
 * create submit idea with selected idea
 * @param {*} dat 
 * @returns 
 */
const createSubmittedSelect = async (dat) => {
  let data = new FormData();
  if (dat.banner_image) {
    if (dat.banner_image.name) {
      data.append("banner_image", dat.banner_image);
    }
  }
  if (dat.card_image) {
    if (dat.card_image.name) {
      data.append("card_image", dat.card_image);
    }
  }
  data.append("idea.id", dat.idea.id);
  data.append("challange.id", dat.challange.id);
  data.append("drafted", dat.drafted);
  dat.answers.forEach((value, index) => {
    data.append(`answers[${index}].question.id`, value.question.id);
  });
  dat.answers.forEach((value, index) => {
    data.append(`answers[${index}].answer`, value.answer);
  });
  if (dat.id) {
    data.append("id", dat.id)
  }
  //   if (dat.remove_banner) {
  //     data.append('remove_banner', dat.remove_banner)
  // }
  // if (dat.remove_card) {
  //     data.append('remove_card', dat.remove_card)
  // }
  const url = API_SUBMITBYSELECT;
  const response = await CallApi({ url, method: "POST", data, type: "file" });
  return response;
};


const getDetailDrafted = async (data) => {
  const url = API_GETDETAIL;
  const response = await CallApi({ url, method: "POST", data });
  return response;
}

const AllSubmitRepo = {
  createSubmitted,
  createSubmittedSelect,
  getDetailDrafted
};

export default AllSubmitRepo;