import React, { useEffect, useState } from 'react'
import BtnSubmit from '../../../../components/buttons/BtnSubmit';
import UsersRepo from '../../../../repositories/admin-repo/UsersRepo';
// import NotificationRepo from '../../../../repositories/admin-repo/NotificationRepo';
import Select from 'react-select'
import BtnBack from '../../../../components/buttons/BtnBack';
import InputText from '../../../../components/input/InputText';
import RichTextEditor from '../../../../components/RichTextEditor';

const Evaluators = ({ setPage, setEvl, error, evl, type }) => {
  const [option, setOption] = useState([]);
  const [evaluation_day, setEvaluationDay] = useState("");
  const [evlTitle, setEvltitle] = useState(evl.evlTitle ? evl.evlTitle : "");
  const [evlMsg, setEvlmsg] = useState("");
  const [selected, setSelected] = useState([]);

  // function to get list user
  const getOption = async () => {
    const result = await UsersRepo.getUsers();
    let listOpt = result.data.data;
    const opt = [];
    listOpt.forEach(element => {
      opt.push({
        id: element.id,
        value: element.id,
        label: element.firstname + ' ' + element.lastname
      })
    });
    setOption(opt);
  };


  const listManagerchallenge = () => {
    if (type === "2") {
      setSelected(
        evl.managerchallange?.length > 0
          ? evl.managerchallange?.map((v) => {
            return {
              id: v?.id,
              label: v?.fullname,
              value: v?.id,
              fullname: v?.fullname,
            };
          }) : []);
      setEvaluationDay(evl.evaluation_day);
    } else {
      setSelected(
        evl.managerchallange ? evl.managerchallange?.map((v) => {
          return {
            id: v?.id,
            label: v?.label,
            value: v?.id,
            fullname: v?.label,
          };
        }) : []);
      setEvaluationDay(evl.evaluation_day ? evl.evaluation_day : "");
    }
  }

  /**
   * 
   * @param {*} event 
   */
  const handleChange = (event) => {
    let arr = [];
    event.forEach(e => {
      arr.push({ id: e.id, fullname: e.label, label: e.label, value: e.id })
    });
    setSelected(arr);
  }

  /**
   * handle save evaluators
   * @param {*} e 
   */
  const handleSave = async e => {
    //delete name before push
    let newEv = selected.map(({ fullname, ...id }) => id);
    let data = { evlTitle, evlMsg, managerchallange: newEv, evaluation_day };
    setEvl(data);
    setPage("launch");
  }

  useEffect(() => {
    getOption();
    listManagerchallenge();
    setEvlmsg(evl.evlMsg)
  }, []);

  return (
    <>
      <div className='space-y-4'>
        <div className='space-y-3'>
          <h3 className='text-textBlack flex font-semibold pt-3 text-md'>Select Evaluators</h3>
          <Select
            className='border-none z-[3]'
            options={option}
            value={selected}
            onChange={handleChange}
            isMulti
          />
          <p className='text-sm text-red-500'>{error.managerchallange}</p>

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5'>
            {selected.map((items, index) => (
              <div div key={index} className="bg-gray-200 flex p-2 justify-between space-x-6 items-center shadow-sm" >
                <div className="flex space-x-2">
                  <img
                    src="/assets/global/img/avatarRound.png"
                    className="w-5 h-5"
                    alt="avatar"
                  />
                  <div className="text-gray-600 text-sm">{items.fullname}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div id='msg' className='space-y-3'>
          <h3 className='text-textBlack flex font-semibold pt-3 text-md'>Custom message for Evaluators</h3>
          {/* <input
            autoComplete="off"
            defaultValue={evlTitle}
            type="text"
            onChange={e => setEvltitle(e.target.value)}
            className='border-none shadow-md h-10 text-textGray w-full'
            placeholder="Subject Title"
          /> */}
          <InputText defaultValue={evlTitle} onChange={e => setEvltitle(e.target.value)} placeholder="Subject Title" type="text" />
          <RichTextEditor
            value={evlMsg}
            onChange={(content) => setEvlmsg(content)}
            placeholder="Input message for evaluators"
            //initialValue={evl.evlMsg ? evl.evlMsg : ""}
          />
        </div>

        <div id='ev-day' className='space-y-3'>
          <h3 className='text-textBlack flex font-semibold pt-3 text-md'>How many days do Evaluators have to evaluate an idea?</h3>
          <input
            autoComplete="off"
            type="number"
            className='border-none h-10 shadow-md w-full text-textGray'
            placeholder='Number of days'
            defaultValue={evaluation_day}
            min={0}
            onChange={(e) => setEvaluationDay(e.target.value)}
          />
          <p className='text-sm text-red-500'>{error.evaluation_day}</p>
        </div>

        <div className='pt-4 flex flex-row-reverse gap-x-2'>
          <BtnSubmit onClick={handleSave} title="Save & Next" />
          <BtnBack onClick={() => setPage("form")} title="Back" />
        </div>
      </div>
    </>
  )
}

export default Evaluators
