import CallApi from "../../services/request-helper";

const API_LISTYEAR = process.env.REACT_APP_LISTYEAR;
const API_LISTCHALL = process.env.REACT_APP_TYPECHALLENGE;
const API_USER = process.env.REACT_APP_USER;
const API_TYPEIDEA = process.env.REACT_APP_TYPEIDEA;
const API_PRIVACY = process.env.REACT_APP_PRIVACY;


const getFilterListYears = async () => {
  const url = API_LISTYEAR;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getFilterType = async () => {
    const url = API_LISTCHALL;
    const response = await CallApi({ url, method: "GET" });
    return response;
  };

const getUser = async () => {
  const url = API_USER;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getTypeIdea = async () => {
  const url = API_TYPEIDEA;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getPrivacy = async () => {
  const url = API_PRIVACY;
  const response = await CallApi({ url, method: "GET" });
  return response;
}
  

const FilterChallengeAdmRepo = {
  getFilterListYears,
  getFilterType,
  getUser,
  getTypeIdea,
  getPrivacy,
};

export default FilterChallengeAdmRepo;
