import React, { useEffect, useState } from "react";
import LeaderboardRepo from "../../../../repositories/adm-repo/LeaderboardRepo";
import GeneralUtility from "../../../../utility/GeneralUtility";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

const TotalPoints = ({ onShow, year }) => {
  const [dataPoints, setDataPoints] = useState({});
  const [percentTotal, setPercentTot] = useState([0]);
  const [dataArr, setDataArr] = useState([]);

  const getPoints = async () => {
    const res = await LeaderboardRepo.getTotalPoints({ year });
    let totPoint = res.data;
    let listPoint = res.data.data;
    if (res.status === 200) {
      setDataPoints(totPoint);
      setDataArr(listPoint);
      setPercentTot([totPoint.totalPrecentage]);
    }
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getPoints();
  }, [year]);

  return (
    <>
      {!onShow ? (
        <div className="lg:bg-white xxs:bg-none w-[300px] xs:w-[450px] sm:w-[600px] md:w-[700px] lg:w-full drop-shadow-lg py-4 px-4 xxs:px-0 font-Montserrat font-semibold">
          <h1 className="ml-4 mb-5">Points by Action</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3">
            <div className="flex flex-col justify-center space-y-2">
              <div className="text-center text-sm">Total Points</div>
              <div className="flex justify-center">
                <CircularProgressbarWithChildren
                  strokeWidth={5}
                  value={percentTotal}
                  maxValue={100}
                  className="h-20 w-20"
                  styles={buildStyles({ pathColor: "#50B848" })}
                >
                  <img
                    style={{ width: 20, position: "sticky" }}
                    src="/assets/global/img/diamond - icon.svg"
                    alt="lightbulb"
                  />
                </CircularProgressbarWithChildren>
              </div>
              <div>
                <div className="text-sm text-center font-semibold text-[#50B848]">
                  {dataPoints.totalPoint} Points
                </div>
                <div className="text-xs text-center font-Montserrat">
                  Generated this Year
                </div>
              </div>
            </div>

            {dataArr?.map((items, index) => (
              <div>
                <div
                  key={items.pointName}
                  className="flex flex-col justify-center space-y-2"
                >
                  <div className="text-center text-sm">{items.pointName}</div>
                  <div className="flex justify-center">
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      value={items.precentage}
                      maxValue={100}
                      className="h-20 w-20"
                      styles={buildStyles({
                        pathColor: `${index === 0 ? "#00C0E8" : ""}
                        ${index === 1 ? "#E5D900" : ""}
                        ${index === 2 ? "#F37021" : ""}
                        ${index === 3 ? "#ED0000" : ""}`,
                      })}
                    >
                      <img
                        style={{ width: 20 }}
                        src={`${
                          index === 0
                            ? "/assets/global/img/lightbulb - icon.svg"
                            : ""
                        }
                        ${
                          index === 1
                            ? "/assets/global/img/like - icon.svg"
                            : ""
                        }
                        ${index === 2 ? "/assets/global/img/chatIcon.svg" : ""}
                        ${
                          index === 3
                            ? "/assets/global/img/check - icon.svg"
                            : ""
                        }
                        `}
                        alt={items.pointName}
                      />
                    </CircularProgressbarWithChildren>
                  </div>
                  <div>
                    <div
                      className={`text-sm text-center font-semibold 
                    ${index === 0 ? "text-[#00C0E8]" : ""}
                    ${index === 1 ? "text-[#E5D900]" : ""}
                    ${index === 2 ? "text-[#F37021]" : ""}
                    ${index === 3 ? "text-[#ED0000]" : ""}
                    `}
                    >
                      {items.point} Points
                    </div>
                    <div className="text-xs text-center font-Montserrat">
                      Generated this Year
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="bg-white w-[1180px] h-[200px] drop-shadow-lg py-2 px-4 font-Montserrat font-semibold">
          <h1 className="ml-4 text-sm">Points by Action</h1>
          <div className="grid grid-cols-5">
            <div className="flex flex-col justify-center space-y-2">
              <div className="text-center text-xs">Total Points</div>
              <div className="flex justify-center">
                <CircularProgressbarWithChildren
                  strokeWidth={5}
                  value={percentTotal}
                  maxValue={100}
                  className="h-14 w-14"
                  styles={buildStyles({ pathColor: "#50B848" })}
                >
                  <img
                    style={{ width: 20, position: "sticky" }}
                    src="/assets/global/img/diamond - icon.svg"
                    alt="lightbulb"
                  />
                </CircularProgressbarWithChildren>
              </div>
              <div>
                <div className="text-xs text-center font-semibold text-[#50B848]">
                  {dataPoints.totalPoint} Points
                </div>
                <div className="text-xs text-center font-Montserrat">
                  Generated this Year
                </div>
              </div>
            </div>

            {dataArr?.map((items, index) => (
              <div>
                <div
                  key={items.pointName}
                  className="flex flex-col justify-center space-y-2"
                >
                  <div className="text-center text-xs">{items.pointName}</div>
                  <div className="flex justify-center">
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      value={items.precentage}
                      maxValue={100}
                      className="h-14 w-14"
                      styles={buildStyles({
                        pathColor: `${index === 0 ? "#00C0E8" : ""}
                        ${index === 1 ? "#E5D900" : ""}
                        ${index === 2 ? "#F37021" : ""}
                        ${index === 3 ? "#ED0000" : ""}`,
                      })}
                    >
                      <img
                        style={{ width: 20 }}
                        src={`${
                          index === 0
                            ? "/assets/global/img/lightbulb - icon.svg"
                            : ""
                        }
                        ${
                          index === 1
                            ? "/assets/global/img/like - icon.svg"
                            : ""
                        }
                        ${index === 2 ? "/assets/global/img/chatIcon.svg" : ""}
                        ${
                          index === 3
                            ? "/assets/global/img/check - icon.svg"
                            : ""
                        }
                        `}
                        alt={items.pointName}
                      />
                    </CircularProgressbarWithChildren>
                  </div>
                  <div>
                    <div
                      className={`text-xs text-center font-semibold 
                    ${index === 0 ? "text-[#00C0E8]" : ""}
                    ${index === 1 ? "text-[#E5D900]" : ""}
                    ${index === 2 ? "text-[#F37021]" : ""}
                    ${index === 3 ? "text-[#ED0000]" : ""}
                    `}
                    >
                      {items.point} Points
                    </div>
                    <div className="text-xs text-center font-Montserrat">
                      Generated this Year
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default TotalPoints;
