import React, { useState, useEffect, useCallback } from "react";
import Paginate from "../../../components/paginate/Paginate";
import ChallengeRepo from "../../../repositories/user-repo/ChallengeRepo";
import RouteName from "../../../services/routename";
import Skeleton from "../../../components/skeleton/Skeleton";
import Card from "../../../components/card/Card";
import GeneralUtility from "../../../utility/GeneralUtility";
import Cookies from "js-cookie";


const ArchivedChallenges = () => {
  let [limit] = useState(12);
  const [challenges, setChallenges] = useState([]);
  const [page, setPage] = useState([]);
  const [loading, setLoading] = useState(false);
  
  let pGet = Cookies.get("archivedChallengesPage") !== undefined ? Cookies.get("archivedChallengesPage") : "0";

  const getAllChallenge = useCallback(
    async (pageNumber) => {
      setLoading(true);
      const result = await ChallengeRepo.getChallenges({
        offset: pageNumber,
        limit: limit,
        status: "Closed",
      });
      let listChallenges = result !== null ? result.data : [];
      let listPage = result !== null ? result : [];
      setChallenges(listChallenges);
      setPage(listPage);
      setLoading(false);
    },
    [limit]
  );

  // Change Page
  const paginate = (event) => {
    Cookies.set("archivedChallengesPage", event.selected);
    getAllChallenge(event.selected);
  };

  const handleCountView = async (id) => {
    await ChallengeRepo.postCountView({ challange: { id: id } });
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    GeneralUtility.countViewPage("Archived_Challenges");
    getAllChallenge(pGet);
  }, [getAllChallenge,pGet]);

  return (
    <div className="container mx-auto">
        <div className="bg-baseGray m-4 md:m-7 p-4 md:p-6">
          <h2 className="text-center font-semibold text-xl p-4 md:p-5">
            Archived Challenges
          </h2>
          {!loading ? (
            <div>
              <div className="mx-auto mt-3 md:px-2 lg:px-6">
                {challenges.length === 0 ? (
                  <div className="text-center h-screen">No data available</div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
                    {challenges.map((items) => (
                      <div key={items.id}>
                        <Card
                          linkTo={RouteName.userArchivedChallenges + "/" + encodeURIComponent(items.slug)}
                          id={items.id}
                          card_image={items.card_image}
                          status={items.status}
                          title={items.title}
                          desc={items.desc}
                          total_contributor={items.total_contributor}
                          total_submited={items.total_submited}
                          total_view={items.total_view}
                          onClick={e => handleCountView(items.id)}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="p-3 mx-auto mt-4 md:mt-1"></div>
            </div>
          ) : (
            <div className="mx-auto mt-8 md:px-6">
              <Skeleton col={4} height={350} />
              <div className="p-3 mx-auto mt-4 md:mt-1"></div>
            </div>
          )}
          <div className="ml-2">
            <Paginate paginate={paginate} page={page} defPage={pGet} />
          </div>
        </div>
      </div>
  );
};

export default ArchivedChallenges;
