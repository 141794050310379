import React, { useState } from "react";
import GeneralUtility from "../../utility/GeneralUtility";

const DropdownCard = ({ onShow, res, indx,orderNumber }) => {
  const [expand, setExpand] = useState(false);
  const [index, setIndex] = useState(null);
  return (
    <>
      {!onShow ? (
        <div
          className={`flex flex-col w-[300px] xs:w-[450px] sm:w-[600px] md:w-[700px] lg:w-full font-semibold px-4 my-2 py-2 justify-between items-center shadow-md ${
            expand ? "" : "h[60px]"
          } transition-width duration-300 easy`}
        >
          <div
            className={`flex ml-2 md:ml-0 md:flex-row xs:w-[450px] sm:w-[600px] md:w-[700px] lg:w-full md:items-center justify-between text-xs md:text-sm md:space-x-1`}
          >
            <div className="flex flex-row basis-1/12 items-center">
              <div className="mr-6">{orderNumber(indx)}</div>
              <div className="rounded-full text-white font-bold text-xs md:text-md h-9 w-9 md:h-11 md:w-11 flex items-center bg-slate-500 justify-center">
                {GeneralUtility.getInitials(
                  `${res.firstName} ${res.lastName ? res.lastName : ""}`
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row text-xs basis-9/12 md:items-center md:space-x-2">
              <div className="w-[200px]  text-left break-words">
                {res.firstName} {res.lastName}
              </div>
              <div className="w-[200px]  text-left break-words">
                {res.email}
              </div>
              <div className=" w-[300px]  ">
                {res.departemen}
              </div>
              <div className=" w-[110px] text-left  text-[#51b848]">
                {GeneralUtility.addCommas(res.totalPoint)} Points
              </div>
            </div>
            <div
              className="flex  cursor-pointer w-[150px] items-center"
              onClick={() => [setExpand(!expand), setIndex(indx)]}
            >
              <span className="mr-2 text-left text-[#51b848] hidden w-[200px] md:block items-center text-[11px] md:text-[9px]">
                View Points Breakdown
              </span>
              <img
                src="/assets/global/img/up-arrow-color.svg"
                className={`h-3 w-4 fill-[#51b848] ${
                  expand && index === indx
                    ? "transition-width duration-300 easy rotate-180"
                    : "transition-width duration-300 easy rotate-0"
                }`}
                alt="arrow"
              />
            </div>
          </div>
          <div
            className={`${
              expand && index === indx
                ? "border-b-2 mx-6 transition-opacity ease-in duration-700 opacity-100"
                : "border-b-0"
            }`}
          />
          {expand && index === indx && (
            <div>
              <hr className="my-4" />
              <div
                className={`${
                  expand && index === indx
                    ? "grid grid-cols-2 lg:grid-cols-5 justify-between w-full mb-[20px] transition-opacity ease-in duration-700 opacity-100 text-xs"
                    : "none"
                }`}
              >
                <div className="text-center">
                  <div className="h-10">Total Points</div>
                  <div className="text-[#51b848] font-semibold">
                    <img
                      src="/assets/global/img/diamond - icon.svg"
                      className="h-[30px] w-[30px] mt-4 mx-16 mb-4"
                      alt="Total Points"
                    />
                    {res.totalPoint} Points
                  </div>
                </div>
                {res.points.map((items, index) => (
                  <div className="text-center">
                    <div className="h-10">{items.pointName}</div>
                    <div
                      className={`${index === 0 ? "text-[#00c0e8]" : " "}
                    ${index === 1 ? "text-[#e5d900]" : " "}
                    ${index === 2 ? "text-[#f37021]" : " "}
                    ${index === 3 ? "text-[#ed0000]" : " "} font-semibold
                    `}
                    >
                      <img
                        src={`${
                          index === 0
                            ? "/assets/global/img/lightbulb - icon.svg"
                            : " "
                        }
                        ${
                          index === 1
                            ? "/assets/global/img/like - icon.svg"
                            : " "
                        }
                        ${index === 2 ? "/assets/global/img/chatIcon.svg" : " "}
                        ${
                          index === 3
                            ? "/assets/global/img/check - icon.svg"
                            : " "
                        }
                        `}
                        className="h-[30px] w-[30px] mt-4 mx-16 mb-4"
                        alt="Ideas Submitted"
                      />
                      {items.point} Points
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col font-semibold px-4 my-1 py-1 justify-between items-center shadow-md w-[1080]">
          <div className="flex flex-row w-full items-center justify-between">
            <div className="flex mt-0 flex-row items-center">
              <div className="mr-6 text-xs">{indx + 1}</div>
              <div className="rounded-full text-white font-bold text-xs h-8 w-8 flex bg-slate-500 justify-center">
                {GeneralUtility.getInitials(
                  `${res.firstName} ${res.lastName ? res.lastName : ""}`
                )}
              </div>
              <div className="mt-0 flex-row inline-flex pl-3 items-center text-xs">
                <div className="w-[250px] text-left break-words">
                  {res.firstName} {res.lastName}
                </div>
                <div className="w-[280px] text-left break-words">
                  {res.email}
                </div>
                <div className="w-[250px] text-left break-words">
                  {res.departemen}
                </div>
                <div className="w-[150px] break-words text-[#51b848] text-center">
                  {GeneralUtility.addCommas(res.totalPoint)} Points
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DropdownCard;
