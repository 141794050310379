import Chart from "react-apexcharts";

export default function Donut(params) {
  let donut = {
    options: {
      labels: params.labels,
      colors:
        params.colors === undefined
          ? [
              "#00c0e8",
              "#8cc63f",
              "#f00a09",
              "#fff201",
              "#8327e3",
              "#f37021",
              "#0027e8",
              "#33ffbd",
              "#bc36cc"
            ]
          : params.colors,
      legend: {
        show: false,
        formatter: function (val, opts) {
          return [val, " - ", opts.w.globals.series[opts.seriesIndex]];
        },
        position: "bottom",
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 8,
          strokeColor: "black",
          fillColors: undefined,
          radius: 2,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
        itemMargin: {
          horizontal: 6,
          vertical: 4,
        },
      },
      plotOptions: {
        pie: {
          customScale: 0.7,
          donut: {
            size: "35%",
          },
        },
      },
      dataLabels: {
        enabled: true,
        expandOnClick: true,
        dropShadow: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: params.value,
  };

  return (
    <div className="donut">
      <div className="flex flex-col">
        <div className="h-[250px] mt-[-40px]">
          <Chart
            options={donut.options}
            series={donut.series}
            type="donut"
            width={params.width === undefined ? "300" : params.width}
            height={params.height}
          />
        </div>
        <div
          className={`grid grid-flow-row ${
            params?.typeLabel === "row" ? "grid-cols-1" : "grid-cols-2"
          }  gap-2 p-3`}
        >
          <div className="col-span-1 space-y-1">
            {params?.labels.map((res, index) => {
              if (index < 4) {
                return (
                  <div
                    className="text-[11px] h-3 flex flex-row capitalize items-center"
                    key={res}
                  >
                    <div
                      className={`${
                        index === 0
                          ? "bg-[#00c0e8]"
                          : index === 1
                          ? "bg-[#8ad02e]"
                          : index === 2
                          ? "bg-[#f00a09]"
                          : index === 3
                          ? "bg-[#fff201]"
                          : index === 4
                          ? "bg-[#8327e3]"
                          : index === 5
                          ? "bg-[#ff9553]"
                          : index === 6
                          ? "bg-[#0027e8]"
                          : "bg-[#23ffe9]"
                      } h-3 w-3 mr-3 ml-2]`}
                    ></div>
                    <div className={`${params.onShow ? "mt-[-10px] items-center overflow-hidden text-ellipsis whitespace-nowrap" : "items-center mt-[-2px]"} h-3 w-[9rem] overflow-hidden text-ellipsis whitespace-nowrap`}>{res}</div>
                    <div className={`${params.onShow ? "mt-[-10px] items-center " : "items-center mt-[-2px]"} h-3 ml-1 mt-[-6px] `}>
                      {params.type === "persentage"
                        ? params?.value[index].toFixed(2) + " %"
                        : params?.value[index]}
                    </div>
                  </div>
                );
              }
            })}
          </div>

          <div className="col-span-1 space-y-1">
            {params?.labels.map((res, index) => {
              if (index > 3) {
                return (
                  <div
                    className="text-[11px] flex flex-row capitalize items-center"
                    key={res}
                  >
                    <div
                      className={`${
                        index === 0
                          ? "bg-[#00c0e8]"
                          : index === 1
                          ? "bg-[#8ad02e]"
                          : index === 2
                          ? "bg-[#f00a09]"
                          : index === 3
                          ? "bg-[#fff201]"
                          : index === 4
                          ? "bg-[#8327e3]"
                          : index === 5
                          ? "bg-[#ff9553]"
                          : index === 6
                          ? "bg-[#0027e8]"
                          : "bg-[#23ffe9]"
                      } h-3 w-3 mr-3 ml-2`}
                    ></div>
                    <div className={`${params.onShow ? "mt-[-10px] overflow-hidden text-ellipsis whitespace-nowrap items-center" : "mt-[-2px]"} h-3 w-[9rem]  text-ellipsis whitespace-nowrap items-center`}>{res}</div>
                    <div className={`${params.onShow ? "mt-[-10px] overflow-hidden text-ellipsis whitespace-nowrap items-center" : "mt-[-2px]"} h-3 ml-1 mt-[-6px] `}>
                      {params.type === "persentage"
                        ? params?.value[index].toFixed(2) + " %"
                        : params?.value[index]}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
