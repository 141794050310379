import ArrowIco from "../../../../../components/icons/ArrowIco";
import ButtonGroupEdit from "../Button/ButtonGroupEdit";
import { useEffect, useState, useRef } from "react";
import InputText from "../../../../../components/input/InputText";
import React from "react";
const InsightCardLarge = ({
  insights2,
  showModePreview,
  handleDeleteCard,
  onChange,
  updateChangeValue,
  setPrevData,
  setEditStatus,
  editStatus,
}) => {
  const refTitle = React.createRef();
  const refContent = React.createRef();

  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = () => {
    // const addStatus = [...editStatus];
    // addStatus.push(1);
    // setEditStatus(addStatus);
    setIsEdit(true);
  };

  const handleCancelEdit = () => {
    setIsEdit(false);

    // const addStatus = [...editStatus];
    // addStatus.pop(1);
    // setEditStatus(addStatus);
  };

  const handleOnChangeTitle = (event) => {
    updateChangeValue(0, "title", event.target.value);
  };

  const handleOnChangeContent = (event) => {
    updateChangeValue(0, "content", event.target.value);
  };

  const handleDelete = () => {
    handleDeleteCard(0);
  };

  return (
    <>
      {showModePreview ? (
        ""
      ) : (
        <div className="flex z-[6] absolute -top-3 left-6">
          <ButtonGroupEdit
            onClickDelete={handleDelete}
            onClickEdit={isEdit ? handleCancelEdit : handleEdit}
            isEdit={isEdit}
            index={0}
          />
        </div>
      )}
      <div>
        <div
          key={insights2.id}
          className="bg-white h-[450px] border border-gray-200 shadow-md bx_home_list_insight"
        >
          <iframe
            width="100%"
            height="300"
            src={insights2.source}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
          ></iframe>
          <div className="px-5 pt-3">
            <div>
              {isEdit ? (
                <input
                  title={insights2.title}
                  className="mb-2 text-md lg:text-md font-bold tracking-tight text-gray-900 ht_list_insight w-full"
                  onChange={handleOnChangeTitle}
                  defaultValue={insights2.title}
                  onBlur={handleOnChangeTitle}
                  maxLength={80}
                  value={insights2.title}
                />
              ) : (
                <h5
                  className="mb-2 text-md lg:text-md font-bold tracking-tight text-gray-900 ht_list_insight"
                  title={insights2.title}
                  id="titleInsight"
                >
                  {insights2.title.length > 80
                    ? insights2.title.substring(0, 80) + "..."
                    : insights2.title}
                </h5>
              )}
            </div>
            {isEdit ? (
              <div>
                <textarea
                  className="mb-2 font-normal text-gray-700 text-xs w-full break-words max-h-24"
                  onChange={handleOnChangeContent}
                  maxLength={200}
                  value={insights2.content}
                >
                  {insights2.content}
                </textarea>
              </div>
            ) : (
              <p
                className="mb-2 font-normal text-gray-700 text-xs max-w-max break-words"
                id="contentInsight"
              >
                {insights2.content.length > 200
                  ? insights2.content.substring(0, 200) + "..."
                  : insights2.content}
              </p>
            )}
          </div>
        </div>
        {isEdit ? (
          <>
            <div className="w-full flex flex-row">
              <div className="bg-heritageGreen text-xs pl-1 pr-1 text-white ">
                URL Youtube
              </div>

              <div className="text-sm flex items-center bg-[#fff] text-gray-500 shadow-sm">
                https://www.youtube.com/embed/
              </div>
              <InputText
                placeholder="URL YOUTUBE"
                defaultValue={insights2.source.split("/embed/").pop()}
                onChange={(event) => onChange(0, event)}
                value={insights2.source.split("/embed/").pop()}
                names="source"
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default InsightCardLarge;
