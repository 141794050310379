import React, { useEffect, } from 'react'
import LoginRepo from '../../../repositories/user-repo/LoginRepo';
import Cookies from 'js-cookie';
import RouteAdm from '../../../services/routeAdm';
import RouteName from '../../../services/routename';
import EncryptDecrypt from '../../../utility/encrypt-decrypt';
import AlertUtility from '../../../utility/alert-utility';
import CookieNotification from '../../../components/cookieNotification/CookieNotification';

const Login = () => {

  const cekSession = async () => {
    let data = {
      sessionId: "",
      email: ""
    }
    try {
      let res = await LoginRepo.getSession(data);

      if (res.data.data.data === null || res.data.data.active === false) {
        window.location = res.data.data.redirectUrl
      } else {
        window.location = RouteName.login
      }
    } catch (error) {
      AlertUtility.warningAlert({ time: 3500 })
    }

  }

  const loginMicrosoft = () => {
    cekSession();
  }

  const checkSessionID = async (e) => {

    const ROLE_USER = process.env.REACT_APP_ROLE_USER;
    const ROLE_ADMIN = process.env.REACT_APP_ROLE_ADMIN;

    const encaccessToken = Cookies.get("token");
    const accessToken = encaccessToken ? EncryptDecrypt.dec(encaccessToken) : null;

    if (accessToken) {
      const encrole = Cookies.get("role");
      const role = encrole ? EncryptDecrypt.dec(encrole) : null;

      if (role === ROLE_ADMIN) {
        window.location = RouteAdm.dashboard;
      } else if (role === ROLE_USER) {
        window.location = RouteName.userHome;
      }

    } else {
      // cek sessionID
      // cekSession()
    }

  }

  useEffect(() => {
    checkSessionID();
  }, []);


  return (
    <>
      <CookieNotification />
      <div className="flex bg-white drop-shadow-md h-16 px-4 py-2 md:block lg:block z-[2]">
        <div className="inline-flex items-center">
          <div className="mx-5 lg:mr-28">
            <img src="/assets/global/img/Logo.png" className="h-12" alt="logo" />
          </div>
        </div>
      </div>

      {/* WEB */}
      <div className='hidden lg:block md:block'>
        <div className='flex flex-row h-screen'>
          <div className='basis-6/12'>
            <img className='basis-6/12 h-screen w-full' src="/assets/global/img/login_lamp.jpg" alt="image" />
          </div>
          <div className='lg:basis-7/12 md:basis-7/12'>
            <div className='items-center space-y-6 p-20'>
              <div>
                <h2 className='text-2xl font-bold'>SIGN IN</h2>
                <h5 className='text-md text-[#A7A9AC] font-semibold'>Welcome to Innovoice</h5>
                <p className='text-xs mt-10'>For any support issues please contact  <b>PFinnovation@oldmutual.com</b></p>
              </div>
              <button onClick={loginMicrosoft} className='w-full bg-gradient-to-r from-heritageGreen to-yellow-200 px-3 py-2 text-white font-semibold'>
                SIGN IN
              </button>

            </div>
          </div>
        </div>
      </div>


      {/* MOBILE */}
      <div className='block lg:hidden md:hidden'>
        <div className=' h-screen'>


          <div className='items-center space-y-6 p-20'>
            <div>
              <h2 className='text-2xl font-bold'>SIGN IN</h2>
              <h5 className='text-md text-[#A7A9AC] font-semibold'>Welcome to Innovoice</h5>
              <p className='text-xs mt-10'>For any support issues please contact  <b>PFinnovation@oldmutual.com</b></p>
            </div>
            <button onClick={loginMicrosoft} className='w-full bg-gradient-to-r from-heritageGreen to-yellow-200 px-3 py-2 text-white font-semibold'>
              SIGN IN
            </button>

          </div>
          <div
            className="bannerimage bg-cover h-full w-full  bannerimageloginCustom bg-gradient-to-b from-yellow-200 to-blueSky"

          >
            <div
              className="bg-cover h-full w-full space-y-4 text-center items-center justify-center flex"
              style={{
                backgroundImage: `url("/assets/global/img/Pattern.png")`,
              }}
            ></div>
          </div>

        </div>

      </div>

    </>
  )
}

export default Login
