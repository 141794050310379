import CallApi from "../../services/request-helper";

const API_CHALLENGES = process.env.REACT_APP_CHALLENGES;
const API_DETAIL = process.env.REACT_APP_DETAILCHALLENGE;
const API_DETAIL_SLUG = process.env.REACT_APP_CHALLENGE_SLUG;
const API_COUNTVIEW = process.env.REACT_APP_VIEWCHALLENGE;
const API_ACTIVE = process.env.REACT_APP_ACTIVE_CHALLENGE;

// get challenges with body request
const getChallenges = async ({
  offset,
  limit,
  privacy,
  type_idea,
  status,
  search,
  search_home,
}) => {
  const data = {};
  data.offset = offset || 0;
  data.limit = limit || 10;
  if (privacy === "all") {
    // TO DO document why this block is empty
  
  } else if (privacy) {
    data.privacy = privacy;
  }
  if (type_idea === "all") {
    // TO DO document why this block is empty
  
  } else if (type_idea) {
    data.type_idea = type_idea;
  }
  if (status === "all") {
    // TO DO document why this block is empty
  
  } else if (status) {
    data.status = status;
  }
  if (search) {
    data.search = search;
  }
  if (search_home) {
    data.search_home = search_home;
  }
  const url = API_CHALLENGES;
  const response = await CallApi({ url, method: "POST", data });
  const dat = response !== undefined ? response.data : null;
  const dat2 = dat !== null ? dat.data : null;
  return dat2;
};

/**
 * post to get active and new challenge only
 * @param {*} param0 
 * @returns 
 */
const getActChallenges = async ({
  offset,
  limit,
  status
}) => {
  const data = {};
  data.offset = offset || 0;
  data.limit = limit || 10;
  if (status === "all") {
    // TO DO document why this block is empty
  
  } else if (status) {
    data.status = status;
  }

  const url = API_ACTIVE;
  const response = await CallApi({ url, method: "POST", data });
  const dat = response !== undefined ? response.data : null;
  const dat2 = dat.data !== undefined ? dat.data : null;
  return dat2;
}

/**
 * get Detail Challenges by id challenge
 * @param {*} param0 
 * @returns 
 */
const getDetailChallenges = async ({ id }) => {
  const url = API_DETAIL;
  let data = { id: id };
  const response = await CallApi({ url, method: "POST", data });
  const dat = response !== undefined ? response.data : null;
  const dat2 = dat.data !== undefined ? dat.data : null;
  return dat2;
};


/**
 * get Detail Challenges by slug
 * @param {*} param0 
 */
const getDetailBySlug = async ({ slug }) => {
  let data = { slug: slug };
  const url = API_DETAIL_SLUG;
  const response = await CallApi({ url, method: "POST", data });
  const dat = response.status === 200 ? response.data : null;
  const dat2 = dat !== null ? dat.data : null;
  return dat2;
}

/**
 * funtion for count view
 * @param {*} data 
 * @returns 
 */
const postCountView = async data => {
  const url = API_COUNTVIEW;
  const response = await CallApi({ url, method: "POST", data });
  return response;
}

const ChallengeRepo = {
  getChallenges,
  getDetailChallenges,
  postCountView,
  getDetailBySlug,
  getActChallenges
};

export default ChallengeRepo;
