import CallApi from "../../services/request-helper";

const API_CHALLENGES = process.env.REACT_APP_ADMCHALLENGE;
const API_UPDATECHALL = process.env.REACT_APP_UPDATECHALLENGE;
const API_QUESTIONS = process.env.REACT_APP_QUESTIONCHALLENGE;
const API_DELETECHALL = process.env.REACT_APP_DELETECHALLENGE;

const getChallengesAdm = async ({
  offset,
  limit,
  status,
  search,
  year,
  calendar,
  category,
}) => {
    const data = {};
  data.offset = offset ? offset : 0;
  data.limit = limit ? limit : 10;
  if (status) {
    data.status = status;
  }
  data.search = search;

  if (calendar) data.calendar = calendar;

  data.year = year;
  
  if (category === "all") {
  } else if (category) {
    data.category = category;
  }
  
  const url = API_CHALLENGES;
  const response = await CallApi({ url, method: "POST", data });
  const response2 = response !== undefined ? response : null;
  return response2;
};

const getQuestion = async (id, data) => {
  const url = API_QUESTIONS + "/" + id;
  const response = await CallApi({ url, method: "GET", data });
  return response;
}

const updateChallengeAdmin = async (data) => {
  const url = API_UPDATECHALL;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const deleteChallengeAdmin = async (data) => {
  const url = API_DELETECHALL;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const ChallengeAdmRepo = {
  getChallengesAdm,
  updateChallengeAdmin,
  getQuestion,
  deleteChallengeAdmin,
};

export default ChallengeAdmRepo;
