import CallApi from "../../services/request-helper";

const API_LIKE_IDEA = process.env.REACT_APP_LIKESUBMITTED;
const API_DISLIKE_IDEA = process.env.REACT_APP_DISLIKESUBMITTED;
const API_UNLIKE_IDEA = process.env.REACT_APP_UNLIKEIDEA;
const API_CHECKED_IDEA = process.env.REACT_APP_STATUSLIKEIDEA;

/**
 * Post Like Idea
 * @param {*} data 
 * @returns 
 */
const postLikeIdea = async (data) => {
    const url = API_LIKE_IDEA;
    const response = await CallApi({ url, method: "POST", data });
    return response;
};

/**
 * Post Dislike Idea
 * @param {*} data 
 * @returns 
 */
const postDislikeIdea = async data => {
    const url = API_DISLIKE_IDEA;
    const response = await CallApi({ url, method: "POST", data });
    return response;
};

/**
 * Post Unlike Idea
 * @param {*} data 
 * @returns 
 */
const postUnlikeIdea = async data => {
    const url = API_UNLIKE_IDEA;
    const response = await CallApi({ url, method: "POST", data });
    return response;
};

/**
 * To checked status liked Idea
 * @param {*} id 
 * @returns 
 */
const getStatusIdea = async (id) => {
    const url = API_CHECKED_IDEA + "/" + id;
    const response = await CallApi({ url, method: "GET" });
    const data = typeof response != "undefined" ? response.data : null;
    const data2 = typeof data != "undefined" && data != null ? data.data : {}
    return data2;
};

const LikeIdeaRepo = {
    postLikeIdea,
    postDislikeIdea,
    postUnlikeIdea,
    getStatusIdea
  };
  
  export default LikeIdeaRepo;
  