import Cookies from "js-cookie";
import EncryptDecrypt from "./encrypt-decrypt";
import FilterChallengeAdmRepo from "../repositories/adm-repo/FilterChallengeAdmRepo";
import axios from "axios";
import LoginRepo from "../repositories/user-repo/LoginRepo";
import CountPageRepo from "../repositories/user-repo/CountPageRepo";
import AlertUtility from "./alert-utility";
import RouteName from "../services/routename";

const getToken = async () => {
  let token = Cookies.get("token");
  let decToken = token ? EncryptDecrypt.dec(token) : null;
  return decToken;
};

const getAccessToken = async () => {
  let user_accessToken = Cookies.get("user_accessToken");
  let dec_user_accessToken = user_accessToken
    ? EncryptDecrypt.dec(user_accessToken)
    : null;
  return dec_user_accessToken;
};

const getRefreshToken = () => {
  let refresh_token = Cookies.get("user_refresh_token") ?? null;
  let dec_refresh_token = refresh_token
    ? EncryptDecrypt.dec(refresh_token)
    : null;
  return dec_refresh_token;
};

const getRole = () => {
  let role = Cookies.get("role");
  let dec_role = role ? EncryptDecrypt.dec(role) : "";
  return dec_role;
};

const getEmail = () => {
  let email = Cookies.get("user_email") ?? "";
  let dec_email = email ? EncryptDecrypt.dec(email) : "";
  return dec_email;
};

const getSessionId = () => {
  let sessionId = Cookies.get("user_session_id") ?? "";
  let dec_sessionId = sessionId ? EncryptDecrypt.dec(sessionId) : "";
  return dec_sessionId;
};

const getFirstName = () => {
  let firstName =
    Cookies.get("firstname") !== undefined ? Cookies.get("firstname") : "";
  let dec_firstName = firstName ? EncryptDecrypt.dec(firstName) : "";
  return dec_firstName;
};

const getLastName = () => {
  let lastName =
    Cookies.get("lastname") !== undefined ? Cookies.get("lastname") : "";
  let dec_lastName = lastName ? EncryptDecrypt.dec(lastName) : "";
  return dec_lastName;
};

// Hit Api to Get New Token
const getNewToken = async (headers) => {
  const userEmail = GeneralUtility.getEmail();
  const sessionId = GeneralUtility.getSessionId();
  const API_REFRESH_TOKEN = process.env.REACT_APP_GET_REFRESH_TOKEN;

  const hit1 = await axios({
    url: API_REFRESH_TOKEN,
    method: "POST",
    data: { email: userEmail, "sessionId": sessionId },
    headers,
  }).catch((err) => err.response);
  if ((hit1.data.status === "OK") & (hit1.data.data !== null)) {
    let new_accessToken = hit1.data.data.access_token;
    let new_refreshToken = hit1.data.data.refresh_token;
    Cookies.set("user_accessToken", EncryptDecrypt.enc(new_accessToken), {
      secure: true,
    });
    Cookies.set("user_refresh_token", EncryptDecrypt.enc(new_refreshToken), {
      secure: true,
    });
    window.location.reload();
  }
};

// Checking token expired, then hit Api to Get New Token
const hitRefreshToken = async () => {
  const result = await FilterChallengeAdmRepo.getFilterListYears();
  if (result !== undefined) {
    if (result.status === 401) {
      getNewToken();
    }
  } else {
    AlertUtility.warningAlert({time:5500})
  }
};

const logout = async () => {
  let userEmail = getEmail();
  await LoginRepo.getLogoutSSO({ email: userEmail });
  window.location =
    "https://login.microsoftonline.com/00691924-e082-4301-a3dc-1732afd14289/oauth2/v2.0/logout";
  let removing = [
    Cookies.remove("token"),
    Cookies.remove("role"),
    Cookies.remove("firstname"),
    Cookies.remove("lastname"),
    Cookies.remove("user_accessToken"),
    Cookies.remove("user_refresh_token"),
    Cookies.remove("user_session_id"),
    Cookies.remove("user_email"),
    Cookies.remove("idChallenge"),
    Cookies.remove("idIdea"),
    Cookies.remove("gridView"),
    Cookies.remove("ideasYear"),
    Cookies.remove("ideasPage"),
  ];
  return removing;
};

/**
 * get initial username
 * @param {*} username
 */
const getInitials = (username) => {
  if (username) {
    if (username.includes(" ")) {
      let values = username.split(" ");
      let f_name = values[0].substring(0, 1);
      let l_name = values[1]
        ? username.substr(username.indexOf(" ") + 1).substring(0, 1)
        : "";
      return f_name.toUpperCase() + l_name.toUpperCase();
    } else {
      return username.substring(0, 1).toUpperCase();
    }
  }
};

const getUserInitials = () => {
  let first = getFirstName() !== "" ? getFirstName().substring(0, 1) : "";
  let last = getLastName() !== "" ? getLastName().substring(0, 1) : "";
  return first + last;
};

//change int to string with comas (add separator)
const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

// prevent alphabet from input
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

// change string with commas to integer
const decimalToInt = (num) => {
  if (num) {
    return parseFloat(num.replace(/,/g, ""));
  }
};

const countViewPage = async (typePage) => {
  await CountPageRepo.countView(typePage);
};


const isInCreaseIndicator = (value) => {
  if(value[0]?.year > value[1]?.year){
      if(value[0].total < value[1].total){
        return 'rotate-180'
      }  else if(value[0].total === value[1].total){
          return 'rotate-90'
      }
  } else{
    return 'rotate-90'
  }
}

const isInCreaseIndicatorBoolean = (value) => {
  if(value[0]?.year > value[1]?.year){
      if(value[0].total < value[1].total){
        return true
      }  else if(value[0].total === value[1].total){
          return false
      }
  } else{
    return false
  }
}

function getUrlBeforeParam(url) {
  const regex = /^(.*)\/[a-fA-F0-9\-]{36}$/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

function redirectPage(url){
  if(url === RouteName.sumbitedIdea){
    return RouteName.sumbitedIdea
  } else if(url === RouteName.sumbitedArcIdea){
    return RouteName.sumbitedArcIdea
  } else{
    return ""
  }
}

const GeneralUtility = {
  getToken,
  getRole,
  getFirstName,
  getLastName,
  getInitials,
  getUserInitials,
  addCommas,
  removeNonNumeric,
  decimalToInt,
  logout,
  getAccessToken,
  getRefreshToken,
  getEmail,
  getSessionId,
  getNewToken,
  hitRefreshToken,
  countViewPage,
  isInCreaseIndicator,
  isInCreaseIndicatorBoolean,
  getUrlBeforeParam,
  redirectPage
};

export default GeneralUtility;
