import React, { useState } from 'react'
import ConfirmPopup from '../ConfirmPopup'

const BtnDropdown = ({ duplicate, del, isDelete }) => {
    const [showDupl, setShowDupl] = useState(false);
    const [showDel, setShowDel] = useState(false);

    return (
        <>
            <div className="dropdown inline-block relative">
                <button className={`text-sm inline-flex items-center`}>
                    <img
                        src="/assets/global/img/options.svg"
                        className="w-5 h-1"
                        alt="opt"
                    />
                </button>
                <div className={`${isDelete ? "" : "hidden"} `}>
                    <ul className={`dropdown-menu absolute hidden border border-gray-100 bg-white text-gray-700 pt-1 -inset-x-14 bx_duplicate_idea`}>
                        {/* <p
                        onClick={() => setShowDupl(true)}
                    >
                        <p className="hover:bg-gray-100 py-1 px-4 block whitespace-no-wrap text-xs cursor-pointer">
                            Duplicate Idea
                        </p>
                    </p> */}
                        <div
                            onClick={() => setShowDel(true)}
                        >
                            <p className="hover:bg-gray-100 py-1 block whitespace-no-wrap text-xs cursor-pointer text-red-700">
                                Delete Idea
                            </p>
                        </div>
                    </ul>
                </div>
            </div>

            <ConfirmPopup show={showDupl} setShow={setShowDupl} onSave={duplicate} message="Are you sure want to duplicate this idea ? " />
            <ConfirmPopup show={showDel} setShow={setShowDel} onSave={del} message="Are you sure want to delete this idea ? " />
        </>
    )
}

export default BtnDropdown
