import React from 'react'
import { Modal } from 'flowbite-react'
import BtnWhite from './buttons/BtnWhite'

const ConfirmPopup = ({ show, setShow, onSave, message, alert }) => {
    return (
        <>
            <Modal
                show={show}
                size="xl"
                popup={true}
                onClose={() => setShow(!show)}
                className="bx_modalpop_global"
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center font-Montserrat">
                        <h3 className="mb-5 text-md md:text-lg font-normal text-gray-500 tracking-[0.5px]">
                            {message}
                        </h3>
                        <p className='text-red-500'>{alert}</p>
                        <div className='space-x-5 p-5'>
                            <BtnWhite onClick={(e) => { onSave(e) }} message="YES" />
                            <BtnWhite onClick={() => setShow(!show)} message="NO" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConfirmPopup