import React, {useState, useEffect} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import RouteName from "../../../../../services/routename";
import RichText from "../../../../../components/RichText";
import ChallengeRepo from "../../../../../repositories/user-repo/ChallengeRepo";
import QuestionRepo from "../../../../../repositories/user-repo/QuestionRepo";
import PersonalRepo from "../../../../../repositories/user-repo/PersonalRepo";
import AllSubmitRepo from "../../../../../repositories/user-repo/AllSubmitRepo";
import EncryptDecrypt from "../../../../../utility/encrypt-decrypt";
import ConfirmPopup from "../../../../../components/ConfirmPopup";
import AlertUtility from "../../../../../utility/alert-utility";
import Swal from "sweetalert2";
import Breadcrumb from "./Breadcrumb";
import BtnGreen from "../../../../../components/buttons/BtnGreen";
import GeneralUtility from "../../../../../utility/GeneralUtility";
import RichTextEditor from "../../../../../components/RichTextEditor";

const SubmitPersonal = () => {
  let slug = useParams();
  let getIdIdea = Cookies.get("idIdea");
  const [page, setPage] = useState("submit");
  let idIdea = EncryptDecrypt.dec(getIdIdea);
  const [show, setShow] = useState(false);
  const [showDraft, setShowDraft] = useState(false);
  const [detailChallenge, setDetailChallenge] = useState({});

  const [card_image, setCardImage] = useState("");
  const [banner_image, setBannerImage] = useState("");
  const [question, setQuestion] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const [detailDrafted, setDetailDrafted] = useState([]);

  /**
   * function to handle file card
   * @param {*} e
   */
  const handleCardUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size < 5000000) {
      setCardImage(file);
    } else {
      AlertUtility.errorAlert({ title: "FILE MORE THAN 5 MB." });
    }
  };

  /**
   * function to handle file banner
   * @param {*} e
   */
  const handleBannerUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size < 5000000) {
      setBannerImage(file);
    } else {
      AlertUtility.errorAlert({ title: "FILE MORE THAN 5 MB." });
    }
  };

  /**
   * function to delete selected card image
   */
  const delCard = () => {
    setCardImage("");
  };

  /**
   * function to delete selected banner image
   */
  const delBanner = () => {
    setBannerImage("");
  };

  /**
   * get Challenge
   */
  const getChallenge = async () => {
    const result = await ChallengeRepo.getDetailBySlug({ slug: slug.id });
    let listChallenges = result;
    setDetailChallenge({
      id: listChallenges.id,
      title: listChallenges.title,
      description: listChallenges.description,
    });
    getDetailDrafted({ id: listChallenges.id });
  };

  /**
   * to get detail drafted
   * @param {*} idChallenge
   */
  const getDetailDrafted = async (idChallenge) => {
    let data = { challenge: idChallenge.id, idea: idIdea };
    let result = await AllSubmitRepo.getDetailDrafted(data);
    let dataDrafted = result.data.data;
    if (dataDrafted !== null) {
      setCardImage(dataDrafted.cardImage);
      setBannerImage(dataDrafted.bannerImage);
      setDetailDrafted(dataDrafted);
      getQuestionDraft(idChallenge.id, dataDrafted.answers);
    } else if (dataDrafted === null) {
      getIdea();
      getQuestion(idChallenge.id);
    }
  };

  /**
   * function for get detail Idea
   */
  const getIdea = async () => {
    const result = await PersonalRepo.editPersonalIdea(idIdea);
    let listIdeas = result.data.data;
    setDetailDrafted({
      idIdea: listIdeas.id,
      descriptionIdea: listIdeas.description,
      ideaTitle: listIdeas.title,
    });
  };

  const getQuestionDraft = async (id, answer) => {
    const result = await QuestionRepo.getQuestion(id);
    let listQuestion = result.data.data;

    const joinedArray = listQuestion.map((obj1) => {
      const obj2 = answer.find((obj2) => obj2.questionId === obj1.id);
      return { ...obj1, ...obj2 };
    });

    const newQuestion = joinedArray.map((question) => {
      return {
        id: question.questionId,
        question: question.question,
        answer: question.answer,
      };
    });
    setQuestion(newQuestion);

    const newAnswer = answer.map((item) => {
      return {
        question: { id: item.questionId },
        answer: item.answer,
      };
    });
    setAnswers(newAnswer);
  };

  /**
   * function for get question
   */
  const getQuestion = async (id) => {
    try {
      const result = await QuestionRepo.getQuestion(id);
      let listQuestion = result.data.data;
  
      // Menambah properti answer ke setiap objek pertanyaan
      listQuestion = listQuestion.map(question => ({
        ...question,
        answer: "" // Inisialisasi nilai answer, bisa juga null atau nilai default lainnya
      }));
  
      // Menetapkan data pertanyaan yang sudah dimodifikasi ke state question
      setQuestion(listQuestion);
  
      // Membuat objek untuk answers
      const q = listQuestion.map(element => ({
        answer: "", // Inisialisasi nilai answer, bisa juga null atau nilai default lainnya
        question: { id: element.id }
      }));
  
      // Menetapkan data answers ke state answers
      setAnswers(q);
    } catch (error) {
      console.error("Error fetching questions:", error);
      // Handle error fetching data
    }
  };
  /**
   * function for add to update the question
   * @param {*} id
   * @param {*} content
   */
  const handleQ = (id, content) => {
    const myNextList = [...answers];
  
    const artwork = myNextList.find((a) => a.question.id === id);
    artwork.answer = content;
  
    setAnswers(myNextList);
  
    const updatedQuestions = question.map(q =>
      q.id === id ? { ...q, answer: content } : q
    );
    setQuestion(updatedQuestions);
  };

  /**
   * validate empty field
   * @param {*} answers
   * @returns
   */
  const validate = (answers) => {
    const errors = {};
    for (let index = 0; index < answers.length; index++) {
      if (!answers[index].answer) {
        errors.answer = "Questions must be answered.";
      }
    }
    setError(errors);
    return errors;
  };

  /**
   * handle save as draft
   * @param {*} e
   */
  const onDraft = async (e) => {
    e.preventDefault();
    setShowDraft(false);
    if (detailDrafted === null) {
      let submittedData = {
        card_image,
        banner_image,
        challange: { id: detailChallenge.id },
        idea: { id: idIdea },
        answers,
        drafted: 1,
      };
      AlertUtility.loadingAlert();
      const res = await AllSubmitRepo.createSubmittedSelect(submittedData);
      if (res.status === 200) {
        Swal.close();
        AlertUtility.successAlert({ title: "Your idea was saved as a draft" });
        navigate(`${RouteName.userLatestChallenges + "/" + slug.id}`);
      } else {
        Swal.close();
        AlertUtility.errorAlert({ title: res.data.message });
      }
    } else {
      let submittedData = {
        id: detailDrafted.id,
        card_image,
        banner_image,
        challange: { id: detailChallenge.id },
        idea: { id: idIdea },
        answers,
        drafted: 1,
      };
      AlertUtility.loadingAlert();
      const res = await AllSubmitRepo.createSubmittedSelect(submittedData);
      if (res.status === 200) {
        Swal.close();
        AlertUtility.successAlert({ title: "Your idea was saved as a draft" });
        navigate(`${RouteName.userLatestChallenges + "/" + slug.id}`);
      } else {
        Swal.close();
        AlertUtility.errorAlert({ title: res.data.message });
      }
    }
  };

  /**
   * handle publish form
   * @param {*} e
   */
  const onPublish = async (e) => {
    e.preventDefault();
    setShow(false);
    let err = validate(answers);
    if (Object.keys(err).length === 0) {
      if (detailDrafted === null) {
        let submittedData = {
          card_image,
          banner_image,
          challange: { id: detailChallenge.id },
          idea: { id: idIdea },
          answers,
          drafted: 0,
        };
        AlertUtility.loadingAlert();
        const res = await AllSubmitRepo.createSubmittedSelect(submittedData);
        if (res.status === 200) {
          Swal.close();
          AlertUtility.successAlert({
            title: "Your idea was successfully submitted",
          });
          navigate(`${RouteName.userLatestChallenges + "/" + slug.id}`);
        } else {
          Swal.close();
          AlertUtility.errorAlert({ title: res.data.message });
        }
      } else {
        let submittedData = {
          id: detailDrafted.id,
          card_image,
          banner_image,
          challange: { id: detailChallenge.id },
          idea: { id: idIdea },
          answers,
          drafted: 0,
        };
        AlertUtility.loadingAlert();
        const res = await AllSubmitRepo.createSubmittedSelect(submittedData);
        if (res.status === 200) {
          Swal.close();
          AlertUtility.successAlert({
            title: "Your idea was successfully submitted",
          });
          navigate(`${RouteName.userLatestChallenges + "/" + slug.id}`);
        } else {
          Swal.close();
          AlertUtility.errorAlert({ title: res.data.message });
        }
      }
    }
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getChallenge();
  }, []);

  const tab = [
    { title: "Submit Idea", route: "submit" },
    { title: "Overview", route: "overview" },
    // { title: "My Idea", route: "idea" },
  ];

  return (
    <>
      <div className="container mx-auto">
        <Breadcrumb detailIdea={detailDrafted.ideaTitle} idIdea={idIdea} />

        <div className="mx-6 mb-6 bg-white pb-6 space-y-4 bx_form_submit_idea">
          <h3 className="text-lg font-bold text-gray-500 md:hidden lg:hidden">
            My Ideas
          </h3>
          <h3 className="text-md md:text-lg font-bold mb-4 ">
            Add Idea To {detailChallenge.title}
          </h3>

          <div className="border-b-2 border-gray-400 w-full lg:hidden bx_tabs_createidea">
            <ul className="inline-flex items-center space-x-3 ul_tab_submit_idea">
              {tab.map((items, index) => (
                <div
                  key={index}
                  className="cursor-pointer"
                  onClick={() => setPage(items.route)}
                >
                  <div
                    className={`${
                      page === items.route
                        ? "text-futureGreen border-b-2 border-futureGreen"
                        : "text-textGray border-none"
                    } text-sm font-semibold px-1`}
                  >
                    {items.title}
                  </div>
                </div>
              ))}
            </ul>
          </div>

          <div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-0 lg:gap-3 ">
              <div
                className={`${
                  page === "submit" ? "" : "lg:block hidden"
                } bg-baseGray w-full p-6 space-y-5 col-span-3 bx_tab_submit_idea`}
              >
                <div id="ideaimages" className="space-y-3">
                  <div className="flex font-semibold">Idea Images</div>
                  <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 space-x-0 lg:space-x-4">
                    <div>
                      <label className="text-freshGreen justify-center font-semibold items-center flex space-x-2 text-xs py-2 bg-gray-300 hover:bg-gray-200 h-20 px-20 cursor-pointer">
                        <div className="text-center">
                          <div className="flex items-center space-x-2">
                            <img
                              src="/assets/global/img/camera.svg"
                              alt="cam"
                            />
                            <p className="uppercase">Banner image</p>
                          </div>
                          <p className="text-black text-[9px] font-normal tracking-tight">
                            Choose File
                          </p>
                        </div>
                        <input
                          autoComplete="off"
                          type="file"
                          className="hidden valAutoComp"
                          name="banner_image"
                          accept=".jpeg, .png, .jpg"
                          onChange={(e) => handleBannerUpload(e)}
                        />
                      </label>
                      {banner_image ? (
                        <div className="inline-flex items-center border border-freshGreen mt-1 space-x-4 py-1 pl-1 pr-2 break-all">
                          <p className="text-black text-xs">
                            {banner_image.name
                              ? banner_image.name.length > 14
                                ? banner_image.name.substring(0, 14) + "..."
                                : banner_image.name
                              : banner_image.substring(66, 76) + "..." + ".jpg"}
                          </p>
                          <img
                            className="w-2 h-2 cursor-pointer"
                            onClick={() => delBanner(banner_image)}
                            src="/assets/global/img/close.svg"
                            alt="close"
                          />
                        </div>
                      ) : null}

                      <p className="text-red-500 text-sm">
                        {error.banner_image}
                      </p>
                    </div>
                    <div>
                      <label className="text-freshGreen justify-center font-semibold items-center flex space-x-2 text-xs py-2 px-4 bg-gray-300 hover:bg-gray-200 h-20 w-26 text-center cursor-pointer">
                        <div className="text-center">
                          <div className="flex items-center space-x-2">
                            <img
                              src="/assets/global/img/camera.svg"
                              alt="cam"
                            />
                            <p className="uppercase">Card image</p>
                          </div>
                          <p className="text-black text-[9px] font-normal tracking-tight">
                            Choose File
                          </p>
                        </div>
                        <input
                          autoComplete="off"
                          type="file"
                          className="hidden valAutoComp"
                          name="card_image"
                          accept=".jpeg, .png, .jpg"
                          onChange={(e) => handleCardUpload(e)}
                        />
                      </label>
                      {card_image ? (
                        <div className="inline-flex items-center border border-freshGreen mt-1 space-x-2 py-1 pl-1 pr-2 break-all">
                          <p className="text-black text-xs">
                            {card_image.name
                              ? card_image.name.length > 14
                                ? card_image.name.substring(0, 14) + "..."
                                : card_image.name
                              : card_image.substring(66, 76) + "..." + ".jpg"}
                          </p>
                          <img
                            className="w-2 h-2 cursor-pointer"
                            onClick={() => delCard(card_image)}
                            src="/assets/global/img/close.svg"
                            alt="close"
                          />
                        </div>
                      ) : null}
                      <p className="text-red-500 text-sm">{error.card_image}</p>
                    </div>
                  </div>
                  <div className="text-textGray text-xs">
                    <p>
                      {" "}
                      <b> Banner image: 1000 x 250 pixels </b> and{" "}
                      <b> Card image: 600 x 400 pixels </b>{" "}
                    </p>
                    <p>
                      {" "}
                      <b> Max file size: 5MB</b>{" "}
                    </p>
                  </div>
                </div>

                <div id="ideaTitle" className="space-y-3">
                  <div className="font-semibold">
                    Idea Title{" "}
                    <label className="text-freshGreen text-md">*</label>
                  </div>
                  <input
                    autoComplete="off"
                    className="border-none shadow-sm w-full text-sm txt_idea_detail valAutoComp"
                    type="text"
                    value={detailDrafted.ideaTitle}
                    disabled
                  />
                </div>

                {question.map((items, index) => (
            
                  <div key={index} className="space-y-3">
                    <div className="font-semibold">
                      {items.question}
                      <label className="text-freshGreen text-md"> *</label>
                    </div>
                    <RichTextEditor
                      value={items.answer}
                      onChange={(content) => {
                        handleQ(items.id, content);
                      }}
                    />
                  </div>
                ))}
                <p className="text-red-500 text-sm">{error.answer}</p>

                {/* <div id="ideaTitle" className="space-y-3">
                <div className="flex font-semibold">Add Team Members</div>
                <select
                  className="w-full border-none shadow-sm text-gray-500"
                  name=""
                  id=""
                >
                  <option value="">Team members name</option>
                </select>
                <input
                  className="border-none shadow-sm w-full text-sm"
                  placeholder="Enter a personalised message to invite team members."
                  type="text"
                  name=""
                  id=""
                />
              </div> */}

                {/* 
              <div id="ideaTitle" className="space-y-3">
                <div className="flex font-semibold">Upload Files</div>
                <DragDrop
                  onChange={content => {
                    setDocuments(content);
                  }}
                />
              </div> 
              */}
              </div>

              <div className="space-y-5 hidden lg:block">
                <div className="bg-baseGray p-5 space-y-5 bx_tab_submit_idea">
                  <div className="text-heritageGreen text-lg font-semibold">
                    {detailChallenge.title}
                  </div>
                  <hr />
                  <div className="text-xs">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: detailChallenge.description,
                      }}
                    />
                  </div>
                </div>
                {/* <div className="bg-baseGray p-5 space-y-5 bx_tab_submit_idea">
                  <div className="text-heritageGreen text-lg font-semibold">
                    {detailDrafted.ideaTitle}
                  </div>
                  <hr />
                  <div className="text-xs">
                    <div dangerouslySetInnerHTML={{ __html: detailDrafted.descriptionIdea }} />
                  </div>
                </div> */}
              </div>

              <div>
                <div
                  className={`${
                    page === "overview" ? "lg:hidden" : "hidden lg:hidden"
                  } bg-baseGray p-5 space-y-5 bx_tab_submit_idea`}
                >
                  <div className="text-heritageGreen text-lg font-semibold">
                    {detailChallenge.title}
                  </div>
                  <div className="text-xs">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: detailChallenge.description,
                      }}
                    />
                  </div>
                </div>
                {/* <div className={`${page === "idea" ? "block" : "hidden"} bg-baseGray p-5 space-y-5 bx_tab_submit_idea`}>
                  <div className="text-heritageGreen text-lg font-semibold">
                    {detailDrafted.ideaTitle}
                  </div>
                  <div className="text-xs">
                    <div dangerouslySetInnerHTML={{ __html: detailDrafted.descriptionIdea }} />
                  </div>
                </div> */}
              </div>
            </div>

            <div
              className={`${
                page === "submit" ? "" : "lg:block hidden"
              } flex space-x-3 mt-3 text-sm bx_submit_ideas`}
            >
              <BtnGreen onClick={(e) => setShow(true)} message="publish" />
              <BtnGreen
                onClick={(e) => setShowDraft(true)}
                message="save as draft"
              />
              <button className="hover:font-bold text-xs">
                <Link to={RouteName.editPersonal + "/" + idIdea}>Discard</Link>
              </button>
            </div>
          </div>
        </div>

        <ConfirmPopup
          show={show}
          setShow={setShow}
          onSave={onPublish}
          message="Are you sure you want to publish this idea?"
        />
        <ConfirmPopup
          show={showDraft}
          setShow={setShowDraft}
          onSave={onDraft}
          message="The idea will be saved as a draft"
        />
      </div>
    </>
  );
};

export default SubmitPersonal;
