import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RewardsHome from "./components/RewardsHome";
import NewsHome from "./components/NewsHome";
import InsightHome from "./components/InsightHome";
import RouteName from "../../../services/routename";
import Banner from "../../../components/Banner";
import "./style_home.css";
import GeneralUtility from "../../../utility/GeneralUtility";
import HomeRepo from "../../../repositories/user-repo/HomeRepo";
import ChallengeRepo from "../../../repositories/user-repo/ChallengeRepo";
// import Challenges from "../LatestChallenges/components/Challenges";
// import BtnSubmit from "../../../components/buttons/BtnSubmit";

const UserHome = () => {
  const [banner, setBanner] = useState(null);
  const [reward, setReward] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newsBanner, setNewsBanner] = useState(null);
  const [insight, setInsight] = useState([]);
  const [insight0, setInsight0] = useState([]);
  const [insightTitle, setInsightTitle] = useState("");
  const [challangeLoad, setChallengeLoad] = useState(true);
  const [challenges, setChallenges] = useState([]);

  const getHomeContent = async () => {
    setLoading(true);
    let resContent = await HomeRepo.getContent();
    if (resContent !== null) {
      if (resContent.status === 200) {
        let content = resContent.data.data;
        setBanner(content.banner);
        setReward({
          titleReward: content.titleReward,
          subTitleReward: content.subTitleReward,
          reward: content.rewards,
        });
        setNewsBanner({
          newsInfo: content.newsInfo,
          newsBanner: content.bannerInfo,
        });
        setInsightTitle(content.titleInsight);
        setInsight0(content.insights[0]);
        let insight1 = content.insights;
        insight1.splice(0, 1);
        setInsight(insight1);
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      // do nothing
    }
  };

  const getChallenges = async () => {
    setChallengeLoad(true);
    const result = await ChallengeRepo.getChallenges({
      limit: 4,
    });
    if (result !== null) {
      let listChallenges = result.data;
      setChallenges(listChallenges);
      setChallengeLoad(false);
    }
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    GeneralUtility.countViewPage("Home_Page");
    getHomeContent();
    getChallenges();
  }, []);

  return (
    <div className="container mx-auto space-y-5">
      <div>
        <Banner type="homepage" detail={{ banner: banner }} loading={loading} />
      </div>
      {/* <div className="bg-baseGray p-6 mt-0 bx_home_reward">
        <h2 className="text-sm lg:text-xl text-center p-3 font-semibold ht_home_title">
          Get Rewarded With our Latest Challenges
        </h2>
        <Challenges challenges={challenges} loading={challangeLoad} />
        <div className="text-center mt-4 py-9">
          <Link to={RouteName.userLatestChallenges}>
            <BtnSubmit title="Latest Challenges" />
          </Link>
        </div>
      </div> */}
      <div>
        <RewardsHome reward={reward} loading={loading} />
      </div>
      <div>
        <NewsHome detail={newsBanner} loading={loading} />
      </div>
      <div>
        <InsightHome insight0={insight0} insight1={insight} loading={loading} titleInsight={insightTitle} />
      </div>

      <div className="text-center text-xs pt-1 pb-10">
        <Link to={RouteName.termsandcond}>Terms and Conditions</Link>
      </div>
    </div>
  );
};

export default UserHome;
