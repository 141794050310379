// ROUTE ADMIN
const launchChallenge = "/admin/launch-challenge";
const dashboard = "/admin/dashboard";
const challenges = "/admin/challenges";
const ideas = "/admin/ideas";
const communication = "/admin/communication";
const admPages = "/admin/pages";
const admEditPages = "/admin/pages/edit-page";
const detailViewIdeas = "/admin/ideas/detail-view-ideas";
const detailChallengesAdm = "/admin/challenges/detail-challenges";
const callendar = "/admin/challenges/calendar";
const submittedChallengeAdm = "/admin/challenges/submitted-idea";
const editChallenge = "/admin/challenges/edit-challenge";

const RouteAdm = {
  launchChallenge,
  detailChallengesAdm,
  callendar,
  dashboard,
  challenges,
  ideas,
  communication,
  admPages,
  admEditPages,
  detailViewIdeas,
  submittedChallengeAdm,
  editChallenge,
};

export default RouteAdm;
