import React from 'react'

const NotFoundPage = () => {
    return (
        <h1 className='pt-10 space-y-4'>
            <p className='font-bold text-2xl text-center'> PAGE </p>
            <p className='font-bold text-3xl text-center'> NOT FOUND</p>
        </h1>
    )
}

export default NotFoundPage