import React, { useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";

const RichTextTermsReadonly = ({ value }) => {
  return (
    <>
      <Editor
        // apiKey="rx4no1r1mwnsqiwgk8n4ruah0znkp4pn0n3qy6updcoi09yt"
        apiKey="lt4xr20og1e36xj9296qxlxrk7cwjn9b8v6522k7bdxm6v3o"
        outputFormat="text"
        initialValue={value}
        init={{
          content_style: "body { margin: 0; }",
          plugins: [
            "advlist",
            "autolink",
            " autoresize",
            "lists",
            "link",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount",
          ],
          editable_root: false,
          statusbar: false,
          menubar: false,
          paste_data_images: false,
          toolbar: false,
          readonly: 1,
        }}
      />
    </>
  );
};

export default RichTextTermsReadonly;
