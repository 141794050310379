import React from "react";
import { Link } from "react-router-dom";
import RouteAdm from "../../../../../services/routeAdm";
import GeneralUtility from "../../../../../utility/GeneralUtility";
import Skeleton from "../../../../../components/skeleton/Skeleton";

const DetailContentChallenges = ({
  detail,
  manager,
  formValue,
  loading,
  cp,
  ct,
  cy
}) => {
  return (
    <div className="flex flex-col-reverse md:flex-row gap-0 gap-y-3 md:gap-3 m-6 pb-8">
      <div className="space-y-4 md:basis-3/4">
        {!loading ? (
          <div className="bg-baseGray pb-6 h-full">
            <div>
              <h3 className="font-semibold text-lg p-3">Challenge Overview</h3>
              <div id="desc" className="text-sm p-3">
                <div dangerouslySetInnerHTML={{ __html: detail.description }} />
              </div>

              <div className="p-3 hidden">
                <h3 className="font-semibold text-black"> Links </h3>
                <div className="links space-y-2">
                  {formValue.links.length > 0 && (
                    <>
                      {formValue.links.map((field, index) => (
                        <div
                          key={index}
                          className="flex space-x-3 items-center"
                        >
                          <div className=" basis-4/12" id="resourceName">
                            <input
                              className="border-none shadow-md w-full"
                              type="text"
                              name="resourceName"
                              placeholder="Resources Name"
                              value={field.resourceName}
                              disabled
                            />
                          </div>
                          <div className="basis-7/12" id="source">
                            <input
                              className="border-none shadow-md w-full"
                              type="text"
                              name="source"
                              placeholder="Paste URL"
                              value={field.source}
                              disabled
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>

            <Link to={RouteAdm.submittedChallengeAdm + "/" + detail.id  +"?"+"tab=0" +"&page=0&status=all"}>
              <button className="text-center px-3 py-2 inline-flex items-center text-freshGreen text-sm">
                View Submitted Ideas
              </button>
            </Link>
          </div>
        ) : (
          <div>
            <Skeleton col="1" height="250" />
          </div>
        )}
      </div>

      {!loading ? (
        <div className="bg-baseGray p-3 space-y-4 md:basis-1/4">
          <h3 className="font-semibold text-lg">Challenge Breakdown</h3>
          <div className="space-y-3">
            <div className="space-y-3">
              {manager.map((item, index) => (
                <div key={index} className="flex space-x-3 items-center">
                  <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                    {GeneralUtility.getInitials(item.fullname)}
                  </div>
                  <p className="text-sm font-semibold">{item.fullname}</p>
                </div>
              ))}
            </div>

            <div id="line" className="bg-gray-200 w-full h-0.5"></div>

            <div className="space-y-3">
              <div className="flex space-x-3 items-center">
                <img
                  src="/assets/global/img/avatar.png"
                  className="w-4 h-4"
                  alt=""
                />
                <p className="text-sm text-gray-700">
                  {" "}
                  {detail.total_contributor} Contributors
                </p>
              </div>
              <div className="flex space-x-3 items-center">
                <img
                  src="/assets/global/img/visible.png"
                  className="w-5 h-4"
                  alt=""
                />
                <p className="text-sm text-gray-700">
                  {" "}
                  {detail.total_view} Views
                </p>
              </div>
              <div className="flex space-x-3 items-center">
                <img
                  src="/assets/global/img/lightbulbGray.png"
                  className="w-4 h-5"
                  alt=""
                />
                <p className="text-sm text-gray-700">
                  {" "}
                  {detail.total_submited} Ideas
                </p>
              </div>
              <div className="flex space-x-3 items-center">
                <img
                  src="/assets/global/img/like.svg"
                  className="w-4 h-4"
                  alt=""
                />
                <p className="text-sm text-gray-700">
                  {" "}
                  {detail.total_like} Likes
                </p>
              </div>
              <div className="flex space-x-3 items-center">
                <img
                  src="/assets/global/img/dislike.svg"
                  className="w-4 h-4"
                  alt=""
                />
                <p className="text-sm text-gray-700">
                  {" "}
                  {detail.total_dislike} Dislikes
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Skeleton col="1" height="250" />
        </div>
      )}
    </div>
  );
};

export default DetailContentChallenges;
