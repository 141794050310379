import React, { useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";

const RichTextTerms = (props) => {
  const editorRef = useRef(null);
  const [description, setDescription] = useState(props.initialValue);

  // useEffect(() => {
  //   props.setTerms(description);
  // }, [description]);

  return (
    <div className="z-0">
      <Editor
        // apiKey="rx4no1r1mwnsqiwgk8n4ruah0znkp4pn0n3qy6updcoi09yt"
        apiKey="lt4xr20og1e36xj9296qxlxrk7cwjn9b8v6522k7bdxm6v3o"
        outputFormat="text"
        initialValue={props.initialValue}
        onEditorChange={(newText) => props.setTerms(newText)}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          height: 300,
          menubar: "file edit view insert format tools",
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount",
          ],
          statusbar: false,
          menubar: false,
          toolbar: "bold italic underline strikethrough | bullist numlist",
          toolbar_location: "bottom",
          paste_data_images: false,
        }}
      />
    </div>
  );
};

export default RichTextTerms;
