import CallApi from "../../services/request-helper";

const API_DETAILIDEA = process.env.REACT_APP_DETAILIDEA;
const API_COMMENTIDEA = process.env.REACT_APP_LISTCOMMENT;
const API_STATUSCHANGE = process.env.REACT_APP_STATUSCHANGEIDEA;
const API_MESSIDEA = process.env.REACT_APP_MESSAGESTAGE;

const getDetailIdeas = async (id) => {
  const url = API_DETAILIDEA;
  let data = { id: id }
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const getMessageStage = async (stage) => {
  const url = API_MESSIDEA + "/" + stage;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getStatus = async () => {
  const url = API_STATUSCHANGE;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getCommentary = async ({
  offset,
  limit,
  id,
}) => {
  const data = {};
  data.offset = offset ? offset : 0;
  data.limit = limit ? limit : 10;
  data.id = id;
  const url = API_COMMENTIDEA;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const DetailIdeasRepo = {
  getDetailIdeas,
  getCommentary,
  getStatus,
  getMessageStage,
};

export default DetailIdeasRepo;