import React, { useState, useEffect } from "react";
import RouteName from "../../../../../services/routename";
import { Link } from "react-router-dom";
import LikeChallengeRepo from "../../../../../repositories/user-repo/LikeChallengeRepo";
import GeneralUtility from "../../../../../utility/GeneralUtility";
import Skeleton from "../../../../../components/skeleton/Skeleton";

function OverviewDetail({ detail, id, manager, detailChallenge, loading }) {
  const [like, setLike] = useState({ status: "", id: "" });

  /**
   * funtion to get status like challenge
   */
  const getStatusLike = async () => {
    const resultLike = await LikeChallengeRepo.getStatusChallenge(id);
    if (resultLike !== null) {
      setLike({ status: resultLike.status, id: resultLike.id });
    } else {
      setLike(null);
    }
  };

  /**
   * function to post like
   * @param {*} id
   */
  const postLike = async (id) => {
    setLike({ status: true, id: id });
    const result = await LikeChallengeRepo.postLikeChallenge({
      challange: { id: id },
    });
    if (result.status === 200) {
      getStatusLike();
      detailChallenge();
    }
  };

  /**
   * function to post dislike
   * @param {*} id
   */
  const postDisLike = async (id) => {
    setLike({ status: false, id: id });
    const result = await LikeChallengeRepo.postDislikeChallenge({
      challange: { id: id },
    });
    if (result.status === 200) {
      getStatusLike();
      detailChallenge();
    }
  };

  /**
   * post to unLike or unDislike idea
   * @param {*} id
   */
  const unLike = async (id, status) => {
    if (!!status) {
      setLike(null);
    } else {
      setLike({ status: false, id: id });
    }
    const result = await LikeChallengeRepo.postUnlikeChallenge({ id: id });
    if (result.status === 200) {
      getStatusLike();
      detailChallenge();
    }
  };

  useEffect(() => {
    if (!loading) {
      getStatusLike();
    }
  }, [detail]);

  return (
    <>
      {!loading ? (
        <div className="block md:hidden lg:hidden bg-baseGray p-3 space-y-4">
          <h3 className="font-semibold text-md md:text-lg">
            Challenge Managers
          </h3>
          <div className="space-y-3">
            <div className="grid grid-cols-2 gap-3">
              {manager.map((item, index) => (
                <div key={index} className="flex space-x-3 items-center">
                  <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                    {GeneralUtility.getInitials(item.fullname)}
                  </div>
                  <p className="text-xs md:text-sm font-semibold">
                    {item.fullname}
                  </p>
                </div>
              ))}
            </div>
            <hr />
            <div className="grid grid-cols-2 gap-3">
              <div className="space-y-2">
                <div className="flex space-x-3 items-center">
                  <div className="w-7 justify-center self-center flex">
                    <img
                      src="/assets/global/img/avatar.png"
                      className="w-4 h-4"
                      alt="avatar"
                    />
                  </div>
                  <p className="text-xs md:text-sm text-gray-700">
                    {detail.total_contributor} Contributors
                  </p>
                </div>
                <div className="flex space-x-3 items-center">
                  <div className="w-7 justify-center self-center flex">
                    <img
                      src="/assets/global/img/visible.svg"
                      className="w-[18px] h-4"
                      alt="visible"
                    />
                  </div>
                  <p className="text-xs md:text-sm text-gray-700">
                    {detail.total_view} Views
                  </p>
                </div>
                <div className="flex space-x-3 items-center">
                  <div className="w-7 justify-center self-center flex">
                    <img
                      src="/assets/global/img/lightbulbGray.png"
                      className="w-4 h-5"
                      alt="idea"
                    />
                  </div>
                  <p className="text-xs md:text-sm text-gray-700">
                    {detail.total_submited} Ideas
                  </p>
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex space-x-3 items-center">
                  <div className="w-7 justify-center self-center flex">
                    <img
                      src="/assets/global/img/like.svg"
                      className="w-4 h-4"
                      alt="like"
                    />
                  </div>
                  <p className="text-xs md:text-sm text-gray-700">
                    {detail.total_like} Likes
                  </p>
                </div>
                <div className="flex space-x-3 items-center">
                  <div className="w-7 justify-center self-center flex">
                    <img
                      src="/assets/global/img/dislike.svg"
                      className="w-4 h-4"
                      alt="dislike"
                    />
                  </div>
                  <p className="text-xs md:text-sm text-gray-700">
                    {detail.total_dislike} Dislikes
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="block md:hidden lg:hidden bg-baseGray p-3 space-y-4">
          <Skeleton col="1" height="200" />
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-0 gap-y-3 md:gap-3 lg:gap-3">
        <div className="bg-baseGray col-span-3 p-3 space-y-4">
          <h3 className="font-semibold text-md md:text-lg">
            Challenge Overview
          </h3>
          <div id="desc" className="text-sm">
            <div dangerouslySetInnerHTML={{ __html: detail.description }} />
          </div>
          <div className="flex justify-between">
            <div className="flex space-x-3 hidden md:block lg:block">
              <Link
                to={!loading && RouteName.sumbitedArcIdea + "/" + detail.id +"?" + "?isview=0&evaluation=&page=0"}
              >
                <button className="border uppercase border-freshGreen text-sm text-freshGreen py-1.5 px-2.5 inline-flex items-center hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white btn_overview_addidea">
                  <span className="text-sm font-semibold">
                    View Submitted Ideas
                  </span>
                </button>
              </Link>
            </div>
            <div className="space-x-3 bx_overview_like">
              {like === null ? (
                <>
                  <button onClick={(e) => postDisLike(id)}>
                    <img
                      src="/assets/global/img/dislike1.svg"
                      className="w-8 h-8"
                      alt="dislike"
                    />
                  </button>
                  <button onClick={(e) => postLike(id)}>
                    <img
                      src="/assets/global/img/like1.svg"
                      className="w-8 h-8"
                      alt="like"
                    />
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      if (like.status === false) {
                        unLike(like.id, like.status);
                      } else {
                        postDisLike(id);
                      }
                    }}
                  >
                    {like.status === false ? (
                      <img
                        src="/assets/global/img/dislike2.svg"
                        className="w-8 h-8"
                        alt=""
                      />
                    ) : (
                      <img
                        src="/assets/global/img/dislike1.svg"
                        className="w-8 h-8"
                        alt=""
                      />
                    )}
                  </button>
                  <button
                    onClick={() => {
                      if (like.status === true) {
                        unLike(like.id, like.status);
                      } else {
                        postLike(id);
                      }
                    }}
                  >
                    {like.status === true ? (
                      <img
                        src="/assets/global/img/like2.svg"
                        className="w-8 h-8"
                        alt=""
                      />
                    ) : (
                      <img
                        src="/assets/global/img/like1.svg"
                        className="w-8 h-8"
                        alt=""
                      />
                    )}
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="inline-flex w-full justify-center space-x-3 md:hidden lg:hidden">
            <Link to={!loading && RouteName.sumbitedArcIdea + "/" + detail.id + "?" + "isview=0&evaluation=&page=0"}>
              <button className="border border-freshGreen text-sm text-freshGreen py-1 px-2 inline-flex items-center hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white btn_overview_addidea">
                <span className="mr-1 text-sm font-semibold">
                  View Submitted Ideas
                </span>
              </button>
            </Link>
          </div>
        </div>
        <div className="hidden md:block lg:block bg-baseGray p-3 space-y-4">
          <h3 className="font-semibold text-md md:text-lg">
            Challenge Managers
          </h3>
          <div className="space-y-3">
            <div className="space-y-3">
              {manager.map((item, index) => (
                <div key={index} className="flex space-x-3 items-center">
                  <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                    {GeneralUtility.getInitials(item.fullname)}
                  </div>
                  <p className="text-xs md:text-sm font-medium">
                    {item.fullname}
                  </p>
                </div>
              ))}
            </div>

            <div id="line" className="bg-gray-200 w-full h-0.5"></div>

            <div className="space-y-2">
              <div className="flex space-x-3 items-center">
                <div className="w-7 justify-center self-center flex">
                  <img
                    src="/assets/global/img/avatar.png"
                    className="w-4 h-4"
                    alt="avatar"
                  />
                </div>
                <p className="text-xs md:text-sm text-gray-700">
                  {detail.total_contributor} Contributors
                </p>
              </div>
              <div className="flex space-x-3 items-center">
                <div className="w-7 justify-center self-center flex">
                  <img
                    src="/assets/global/img/visible.svg"
                    className="w-[18px] h-4"
                    alt="visible"
                  />
                </div>
                <p className="text-xs md:text-sm text-gray-700">
                  {detail.total_view} Views
                </p>
              </div>
              <div className="flex space-x-3 items-center">
                <div className="w-7 justify-center self-center flex">
                  <img
                    src="/assets/global/img/lightbulbGray.png"
                    className="w-4 h-5"
                    alt=""
                  />
                </div>
                <p className="text-xs md:text-sm text-gray-700">
                  {detail.total_submited} Ideas
                </p>
              </div>
              <div className="flex space-x-3 items-center">
                <div className="w-7 justify-center self-center flex">
                  <img
                    src="/assets/global/img/like.svg"
                    className="w-4 h-4"
                    alt="like"
                  />
                </div>
                <p className="text-xs md:text-sm text-gray-700">
                  {detail.total_like} Likes
                </p>
              </div>
              <div className="flex space-x-3 items-center">
                <div className="w-7 justify-center self-center flex">
                  <img
                    src="/assets/global/img/dislike.svg"
                    className="w-4 h-4"
                    alt="like"
                  />
                </div>
                <p className="text-xs md:text-sm text-gray-700">
                  {detail.total_dislike} Dislikes
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OverviewDetail;
