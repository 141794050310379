import React from 'react'
import Chart from "react-apexcharts";
import Donut from '../../../components/chart/Donut';

const Users = () => {

    const title = [{name: "Pending Invited Users", value: 5}, {name: "Deactivated Users", value: 50}, {name:"Active Users", value: 526}]

    var optionRadial = {
        options: {
            chart: {
                id: "basic-bar",
                toolbar: {
                    show: false
                },
            },
            grid: {
                show: false,
            },
            plotOptions: {
                radialBar: {
                  startAngle: -90,
                  endAngle: 90,
                  offsetX: 35,
                  hollow: {
                    size: '50%',
                  },
                  dataLabels: {
                    name: {
                        fontSize: '22px',
                        show: false
                    },    
                    value: {
                        offsetY: -10,
                        color: "black",
                        fontSize: "15px",
                    },
                    total: {
                        show: true,
                        label: 'Total',
                        formatter: function (w) {
                          return `${581}`
                        }
                    }
                  },
                },
               
            },
            colors: ["#14a8e7"],
            fill: {
                type: "gradient",
                gradient: {
                  shade: "dark",
                  type: "vertical",
                  gradientToColors: ["#6e3fe3"],
                  stops: [0, 100]
                }
              },
            // stroke: {
            //     lineCap: "round",
            // },
            labels: [''],
        },
        series: [526, 50, 5]
    };

    var options = {
        options: {
            chart: {
                id: "basic-bar",
                toolbar: {
                    show: false
                },
            },
            legend:{
                fontFamily: 'Montserrat',
                offsetX: -580,
                width: '100%'
            },
            grid: {
                show: false,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    distributed: true,
                    display: false
                    
                }
            },
            colors: ['#66bf40', '#F44336','#6649e4'],
            xaxis: {
                labels: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                type: 'category',
                categories: ["New Users", "Returning Users"],
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                labels: {
                    show: false,
                    style: {
                        color: undefined,
                        fontSize: '12px',
                        fontFamily: 'Montserrat',
                        fontWeight: 200,
                        cssClass: 'apexcharts-yaxis-title',
                    },
            
                },
            },
            fill: {
                colors: ['#66bf40', '#F44336','#6649e4']
            }
        },
        series: [
            {
                name: "",
                data: [1000,500]
            }
        ]
    };

    var option = {
        options: {
            chart: {
                id: "basic-bar",
                toolbar: {
                    show: false
                },
            },
            legend:{
                show: false,
                fontFamily: 'Montserrat',
                offsetX: -580,
                width: '100%'
            },
            grid: {
                show: false,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    distributed: true,
                    display: false
                    
                }
            },
            colors: ['#66bf40', '#F44336','#6649e4'],
            xaxis: {
                labels: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                type: 'category',
                categories: ["Home", "Challenges","Achived Challenges","My Ideas","My Teams","Brainstorming","Leader Board","Resource"],
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                labels: {
                    show: true,
                    style: {
                        color: undefined,
                        fontSize: '12px',
                        fontFamily: 'Montserrat',
                        fontWeight: 200,
                        cssClass: 'apexcharts-yaxis-title',
                    },
            
                },
            },
            fill: {
                colors: ['#8327E3','#00C0E8','#50B848','#E30909','#FFF200','#ED0080','#F37021']
            }
        },
        series: [
            {
                name: "",
                data: [1000,500, 400, 300, 200, 100, 50, 45, 35]
            }
        ]
    };

  return (
      <div className="container mx-auto">
          <div className="flex flex-row mt-[-20px]">
              <div className="ml-16 bg-white w-[30%] h-[320px] pb-10 drop-shadow-lg shadow-xl">
                  <div className='flex flex-col'>
                      <div className='flex text-sm mt-5 ml-5 font-Montserrat'>
                          Total Users
                      </div>
                      <div className='mixed-chart'>
                          <Chart
                              options={optionRadial.options}
                              series={optionRadial.series}
                              type="radialBar"
                              height="280"
                              width={"320"}
                          />
                      </div>
                      {title.map((res, index) => {
                          return (
                              <div className='mt-[-45px] ml-4 flex justify-between font-Montserrat space-y-4'>
                                  {res.name}
                                  <div className='mr-4'>
                                      {res.value}
                                  </div>
                              </div>
                          )
                      })}
                  </div>
              </div>
              <div className="ml-6 bg-white w-[60%] h-[320px] pb-10 drop-shadow-lg shadow-xl">
                  <div className='flex text-sm mt-5 ml-5 font-Montserrat'>
                      New Users Vs Returning Users
                  </div>
                  <div className='mixed-chart'>
                      <Chart
                          options={options.options}
                          series={options.series}
                          type="bar"
                          width="850"
                          height="150"
                      />
                  </div>
              </div>
          </div>
          <div className='flex flex-row pb-10'>
              <div className="ml-16 bg-white w-[60%] mt-10 h-[520px] pb-10 drop-shadow-lg shadow-xl">
              <div className='flex text-sm mt-5 ml-5 font-Montserrat'>
                      Number Of Page Views
                  </div>
                  <div className='mixed-chart'>
                      <Chart
                          options={option.options}
                          series={option.series}
                          type="bar"
                          width="850"
                          height="400"
                      />
                  </div>
              </div>
          <div className="ml-6 bg-white w-[30%] mt-10 h-[320px] pb-10 drop-shadow-lg shadow-xl">
          <div className='flex text-sm mt-5 ml-5 font-Montserrat'>
                    Users By Archetype
                  </div>
                  <Donut
                      height="300"
                      width="400"
                      colors={["#50B848","#8327E3","#FFF200"]}
                      labels={["Creators", "Contributors","Lurkers"]}
                      value={[28, 57,15]}
                  />
          </div>
          </div>
      </div>
  )
}

export default Users