import axios from "axios";
import GeneralUtility from "../utility/GeneralUtility";
import AlertUtility from "../utility/alert-utility";

export default async function CallApiBlob({ url, method, data, type }) {
  // get token:
  const token = await GeneralUtility.getToken();
  const role = await GeneralUtility.getRole();
  const accessToken = await GeneralUtility.getAccessToken();
  let headers = {};
  if (type) {
    // if the data upload with form data
    headers = {
      role: `${role}`,
      Authorization: `Bearer ${token}`,
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      MsGraphToken: `${accessToken}`,
    };
  } else {
    headers = {
      role: `${role}`,
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      MsGraphToken: `${accessToken}`,
    };
  }

  try {
    const response = await axios({
      url,
      method,
      data,
      headers,
      responseType: "blob",
    }).catch((error) => error.response);
    return response;
  } catch (error) {
    AlertUtility.warningAlert({ time: 3000 });
  }
}
