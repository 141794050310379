import React, { useEffect, useState } from "react";
import ModalViewIdeas from "./ModalViewIdeas";
import ArrowIco from "../../../../../components/icons/ArrowIco";
import ConfirmPopup from "../../../../../components/ConfirmPopup";
import DetailIdeasRepo from "../../../../../repositories/adm-repo/DetailIdeasRepo";
import GeneralUtility from "../../../../../utility/GeneralUtility";
import Skeleton from "../../../../../components/skeleton/Skeleton";

const ContentDetailIdeas = ({ answers, manager, detail, loading }) => {
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isIdeaChanged, setIsIdeaChanged] = useState(false);
  const [statusChange, setStatusChange] = useState([]);

  const getStatusChangeIdea = async () => {
    const result = await DetailIdeasRepo.getStatus();
    let listStatus = result.data.data;
    setStatusChange(listStatus);
  };

  const [formValue, setFormValue] = useState({
    ideaType: null,
    message: "",
    ideaName: "",
  });

  const onChangeState = (key, value) => {
    setFormValue((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    getStatusChangeIdea();
    onChangeState("ideaName", detail.status_transaction);
  }, [detail]);

  const onChangeStage = (items) => {
    onChangeState("ideaType", items.id);
    onChangeState("ideaName", items.name);
  };

  return (
    <>
      <div className="flex flex-col-reverse md:flex-row gap-y-3 md:gap-3 m-6 font-Montserrat">

        <div className="space-y-4 basis-3/4">
          {!loading ? (
            <div className="bg-baseGray p-4 md:p-5 space-y-2">
              <h3 className="text-lg font-semibold text-gray-700">
                Idea Description
              </h3>
              <div className="text-sm">
                <div dangerouslySetInnerHTML={{ __html: detail.description }} />
              </div>
            </div>
          ) : (
            <div>
              <Skeleton col="1" height="250" />
            </div>
          )}
          {answers?.map((items) => (
            <div key={items.id} className="bg-baseGray p-4 md:p-5 space-y-2 ">
              <h3 className="text-md font-semibold text-gray-600 font-Montserrat">
                {items.question.question}
              </h3>
              <div className="text-sm font-Montserrat">
                <div dangerouslySetInnerHTML={{ __html: items.answer }} />
              </div>
            </div>
          ))}
        </div>

        <div className="bg-baseGray p-3 space-y-4 md:basis-1/4">
          <h3 className="font-semibold text-md font-Montserrat">
            Show Current Stage
          </h3>
          {!loading ? (
            <div className="space-y-3">
              <div className="space-y-3">
                <div>
                  <div
                    className="bg-white border border-gray-400 relative cursor-pointer flex justify-between items-center"
                    onClick={() => setIsIdeaChanged(!isIdeaChanged)}
                  >
                    <h3 className="font-semibold text-sm px-2 py-1 font-Montserrat">
                      {formValue.ideaName}
                    </h3>
                    <div>
                      <img
                        src="/assets/global/img/down-arrow.png"
                        className={`h-4 w-6 stroke-black mr-1`}
                        alt=""
                      />
                    </div>
                  </div>
                  {isIdeaChanged ? (
                    <div className="flex flex-col border-b-1 border-gray-600 bg-white">
                      {statusChange && statusChange.length > 0
                        ? statusChange.map((items, index) => {
                            return (
                              <div
                                key={index}
                                className="font-Montserrat transition-opacity ease-in font-semibold text-sm duration-700 opacity-100 flex flex-row cursor-pointer"
                                onClick={() => {
                                  onChangeStage(items);
                                }}
                              >
                                <img
                                  src={`${
                                    items.id === formValue.ideaType
                                      ? "/assets/global/img/toggle - check.svg"
                                      : "/assets/global/img/toggle.svg"
                                  }`}
                                  className={`h-3 w-5 stroke-black mt-1`}
                                  alt=""
                                />
                                {items.name}
                              </div>
                            );
                          })
                        : "Data Not Available"}
                    </div>
                  ) : null}
                </div>
              </div>

              <div id="line" className="bg-gray-200 w-full h-0.5"></div>

              <h3 className="font-semibold text-sm font-Montserrat">
                Created by:
              </h3>
              <div className="space-y-3">
                <div className="flex space-x-3 items-center">
                  <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                    {GeneralUtility.getInitials(detail.createdBy)}
                  </div>
                  <p className="text-sm text-gray-700 font-Montserrat">
                    {detail.createdBy}
                  </p>
                </div>
              </div>
              <div id="line" className="bg-gray-200 w-full h-0.5"></div>

              <h3 className="font-semibold text-sm font-Montserrat">
                Challenge Managers:
              </h3>
              <div className="space-y-3">
                {manager.map((items, index) => (
                  <div key={index} className="flex space-x-3 items-center">
                    <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                      {GeneralUtility.getInitials(items.fullname)}
                    </div>
                    <p className="text-sm text-gray-700 font-Montserrat">
                      {items.fullname}
                    </p>
                  </div>
                ))}
              </div>

              <div id="line" className="bg-gray-200 w-full h-0.5"></div>
              <div className="text-right mt-4 py-4">
                <button
                  disabled={formValue.ideaType !== null ? false : true}
                  onClick={() => {
                    if (formValue.ideaType !== null) {
                      setShow(!show);
                    }
                  }}
                  className={`text-sm border border-1 font-Montserrat border-green-400 text-center py-2 px-3 inline-flex items-center text-freshGreen ${
                    formValue.ideaType !== null
                      ? "hover:bg-gradient-to-r from-green-600 to-freshGreen hover:text-white"
                      : ""
                  }`}
                >
                  UPDATE
                  <ArrowIco />
                </button>
              </div>
              <ModalViewIdeas
                show={show}
                setShow={setShow}
                onChange={onChangeState}
                idIdeas={detail.id}
                formValue={formValue}
              />
              <ConfirmPopup
                show={showConfirm}
                setShow={setShowConfirm}
                message="Are you sure you want to change Idea status ?"
              />
            </div>
          ) : (
            <div className="space-y-3">
              <Skeleton col="1" height="250" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ContentDetailIdeas;
