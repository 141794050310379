import React, { useState, useEffect, useCallback } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import TotalIdeas from "../Ideas/components/TotalIdeas";
import RouteAdm from "../../../services/routeAdm";
import CardIdeasRepo from "../../../repositories/adm-repo/CardIdeasRepo";
import FilterIdeaRepo from "../../../repositories/adm-repo/FilterIdeaRepo";
import TotalIdeasRepo from "../../../repositories/adm-repo/TotalIdeasRepo";
import Paginate from "../../../components/paginate/Paginate";
import GeneralUtility from "../../../utility/GeneralUtility";
import Skeleton from "../../../components/skeleton/Skeleton";
import Cookies from "js-cookie";
import IsLoading from "../../../components/loading/IsLoading";

const Ideas = () => {
  const navigate = useNavigate();

  let location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  
  const paramValue = queryParams.get('view');
  const urlview = paramValue === 'false' ? false : true;

  const urlpage = parseInt(queryParams.get('page')) || 0; 
  const urlyear = parseInt(queryParams.get('year')) || 2024; 
  const urlEval  = queryParams.get('evaluation') || "all"; 
  

  const [limit] = useState(12);
  const [page, setPage] = useState(parseInt(urlpage));
  const [selectedPage, setSelectedPage] = useState(urlpage);
  const [year, setYear] = useState(urlyear);
  const [evaluation, setEvaluation] = useState(urlEval);
  const [sortYear, setSortYear] = useState(false);
  const [sortIdea, setSortIdea] = useState(false);
  const [filListYear, setFilListYear] = useState([]);
  const [listCardIdea, setListCardIdea] = useState([]);
  const [listEvaluation, setListEvaluation] = useState([]);
  const [listTotalIdeas, setListTotalIdeas] = useState([]);
  const [loadTotalIdeas, setLoadTotalIdeas] = useState(true);
  const [loadListIdea, setLoadListIdea] = useState(true);
  const [gridView, setGridView] = useState(urlview);

  let filterTimeout;

  const detailViewIdeas = (e) => {
    navigate(RouteAdm.detailViewIdeas + "/" + e +"?"+"view=" + urlview +"&page=" + urlpage + "&year=" + urlyear + "&evaluation="+urlEval + "&base=ideas");
    Cookies.set("gridView", gridView);
    Cookies.set("ideasYear", year);
    Cookies.set("ideasPage", selectedPage);
  };

  const getCardIdeas = useCallback(
    async (pageNumber, search) => {
            setLoadListIdea(true);
      try{

          const result = await CardIdeasRepo.getCardIdeas({
            offset: pageNumber,
            limit: limit,
            evaluation: evaluation,
            year: year,
            search: search,
          });

        let listCardIdea = result.data.data;
        let listPage = result.data.data;

        setListCardIdea(listCardIdea);
        setPage(listPage);
        setLoadListIdea(false);

      }catch(error){
        setLoadListIdea(false)
      }
    },
    [evaluation, limit, year]
  );

  const getTotalIdeas = useCallback(async () => {
    setLoadTotalIdeas(true);
    const result = await TotalIdeasRepo.getTotalIdeas({ year: year });
    if (result.status === 200) {
      let listTotalIdeas = result.data.data;
      setListTotalIdeas(listTotalIdeas);
      setLoadTotalIdeas(false);
    }
  }, [year]);

  const getFilterYear = async () => {
    const result = await FilterIdeaRepo.getFilterListYears();
    let listYears = result.data.data;
    setFilListYear(listYears);
  };

  const getFilterEval = async () => {
    const result = await FilterIdeaRepo.getEvaluation();
    let listEval = result.data.data;
    setListEvaluation(listEval);
  };

  // Change Page
  const paginate = (event) => {
    navigate(RouteAdm.ideas+"?"+"view=" + urlview +"&page=" + event.selected + "&year=" + urlyear + "&evaluation="+ urlEval)
    getCardIdeas(event.selected);
    setSelectedPage(event.selected);
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getCardIdeas(urlpage);
    getTotalIdeas();
    getFilterEval();
    getFilterYear();
  }, [year, evaluation, getCardIdeas, getTotalIdeas]);

  const handleSetEvaluation = (val) =>{
    navigate(RouteAdm.ideas+"?"+"view=" + urlview +"&page=0"  + "&year=" + urlyear + "&evaluation="+val.id)
    setEvaluation(val.id)
    setSelectedPage(0)
  }
  console.log(gridView);
      
  return (
    <div className="container mx-auto">
      <div className="m-6">
        <div className="justify-between flex flex-col lg:flex lg:flex-row space-y-2">
          <div className="relative w-60">
            <input
              autoComplete="off"
              type="text"
              className="border border-gray-300 text-gray-800 text-[12px] rounded-sm focus:ring-freshGreen focus:border-freshGreen block w-full pr-10 p-2"
              placeholder="What are you looking for? ..."
              onChange={(e) => {
                clearTimeout(filterTimeout);
                filterTimeout = setTimeout(() => {
                  getCardIdeas(0, e.target.value);
                }, 500);
              }}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div className="flex items-center lg:space-x-3 md:space-x-3 sm:space-x-20 justify-between">
            <div className="flex space-x-2">
              {/* grid */}
              <button
                className={` ${
                  gridView
                    ? "bg-freshGreen text-baseGray"
                    : "bg-gray-300 text-freshGreen"
                } w-8 h-8`}
                onClick={() => {
                  setGridView(true)
                  navigate(RouteAdm.ideas+"?"+"view=" + true +"&page=" + urlpage + "&year=" + urlyear + "&evaluation="+ urlEval)

                }}
              >
                <img
                  src="/assets/global/img/grid.svg"
                  className="ml-1.5"
                  alt="grid"
                />
              </button>
              {/* list */}
              <button
                className={` ${
                  gridView
                    ? "bg-gray-300 text-freshGreen"
                    : "bg-freshGreen text-baseGray"
                } w-8 h-8`}
                onClick={() =>  {
                  navigate(RouteAdm.ideas+"?"+"view=" + false +"&page=" + urlpage + "&year=" + urlyear + "&evaluation="+ urlEval)
                  setGridView(false)
                } }
              >
                <img
                  src="/assets/global/img/list.svg"
                  className="ml-1.5"
                  alt=""
                />
              </button>
            </div>
            <div className="flex justify-self-end ">
              <div className="flex space-x-2">
                <div
                  className={`items-center bg-baseGray h-[35px] w-32 lg:w-44 md:w-44 py-2 border text-[10px] z-10 bx_filter_sortby transition-opacity ease-in duration-300 opacity-100`}
                >
                  <div
                    className="flex flex-row cursor-pointer justify-between w-fill font-Montserrat"
                    onClick={() => {
                      navigate(RouteAdm.ideas+"?"+"view=" + urlview +"&page=" + urlpage + "&year=" + urlyear + "&evaluation=all")
                      setSortIdea(!sortIdea)
                    } }
                  >
                    <span className="text-[10px] mt-0.5 ml-3 uppercase">
                      SHOW :{" "}
                      {listEvaluation && evaluation !== "all"
                        ? listEvaluation
                            .find((ev) => ev.id === evaluation)
                            ?.name?.substring(0, 14) + ".."
                        : "ALL IDEAS"}
                    </span>
                    <img
                      src="/assets/global/img/up-arrow.svg"
                      className={`h-3 w-2 stroke-black mr-2 mt-0.5 uppercase ${
                        sortIdea
                          ? "transition-width duration-300 easy rotate-180"
                          : "transition-width duration-300 easy rotate-0"
                      }`}
                      alt=""
                      onClick={() => setSortIdea(!sortIdea)}
                    />
                  </div>
                  <div className="z-50">
                    {sortIdea && (
                      <div
                        className="bg-white shadow-md lg:w-[175px] md:w-[175px] w-[128px] transition-opacity ease-in duration-700 opacity-100 flex flex-row text-[10px] uppercase"
                        onClick={() => setEvaluation("all")}
                      >
                        <img
                          src={`${
                            evaluation === "all"
                              ? "/assets/global/img/toggle - check.svg"
                              : "/assets/global/img/toggle.svg"
                          }`}
                          className={`h-4 w-3 stroke-black mt-0.5 ml-3 mr-1`}
                          alt=""
                          onClick={() => setSortIdea(!sortIdea)}
                        />
                        <div
                          className="mt-0.5 hover:font-semibold"
                          onClick={() => setSortIdea(!sortIdea)}
                        >
                          {" "}
                          ALL IDEAS
                        </div>
                      </div>
                    )}
                    {sortIdea &&
                      listEvaluation &&
                      listEvaluation
                        .sort((a, b) => a.step_index - b.step_index)
                        .map((items, index) => {
                          return (
                            <div
                              key={index}
                              className=" bg-white shadow-md lg:w-[175px] md:w-[175px] w-[128px] transition-opacity ease-in duration-700 opacity-100 flex flex-row text-[10px] uppercase"
                              onClick={() => handleSetEvaluation(items)}
                            >
                              <img
                                src={`${
                                  items.id === evaluation
                                    ? "/assets/global/img/toggle - check.svg"
                                    : "/assets/global/img/toggle.svg"
                                }`}
                                className={`h-4 w-3 stroke-black mt-1 ml-3 mr-1 uppercase`}
                                alt=""
                                onClick={() => setSortIdea(!sortIdea)}
                              />
                              <div
                                className="mt-1 hover:font-semibold"
                                onClick={() => setSortIdea(!sortIdea)}
                              >
                                {items.name}
                              </div>
                            </div>
                          );
                        })}
                  </div>
                </div>

                <div
                  className={`items-center bg-baseGray h-[35px] w-32 lg:w-44 md:44 py-2 border text-[10px] z-10 bx_filter_sortby transition-opacity ease-in duration-300 opacity-100`}
                >
                  <div
                    className="flex flex-row cursor-pointer justify-between w-fill font-Montserrat"
                    onClick={() => setSortYear(!sortYear)}
                  >
                    <span className="text-[10px] mt-0.5  ml-3 uppercase">
                      SORT BY : {year}{" "}
                    </span>
                    <img
                      src="/assets/global/img/up-arrow.svg"
                      className={`h-3 w-2 stroke-black mt-0.5 mr-3 uppercase ${
                        sortYear
                          ? "transition-width duration-300 easy rotate-180"
                          : "transition-width duration-300 easy rotate-0"
                      }`}
                      alt="check-year"
                      onClick={() => setSortYear(!sortYear)}
                    />
                  </div>
                  <div className="z-50">
                    {sortYear &&
                      filListYear &&
                      filListYear.map((items, index) => {
                        return (
                          <div key={items.year}
                            className="bg-white border-1 lg:w-[175px] md:w-[175px] w-[128px] shadow-md transition-opacity ease-in duration-700 opacity-100 flex flex-row text-[10px] uppercase"
                            onClick={() => { 
                              Cookies.remove("ideasPage");
                              setSelectedPage(0)
                              setYear(items.year);
                              navigate(RouteAdm.ideas+"?"+"view=" + urlview +"&page=" + urlpage + "&year=" + items.year + "&evaluation="+urlEval)
                          }}
                          >
                            <img
                              src={`${
                                items.year === year
                                  ? "/assets/global/img/toggle - check.svg"
                                  : "/assets/global/img/toggle.svg"
                              }`}
                              className={`h-4 w-3 stroke-black ml-3 mr-1 uppercase`}
                              alt=""
                              onClick={() => setSortYear(!sortYear)}
                            />
                            <div
                              className=" mb-1 hover:font-semibold"
                              onClick={() => setSortYear(!sortYear)}
                            >
                              {items.year}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-6">
        <TotalIdeas listTotalIdeas={listTotalIdeas} loading={loadTotalIdeas} />
      </div>

      <div className="m-6">
        {/* GRID VIEW*/}
        <div className={` ${gridView ? "mx-auto" : "hidden"}`}>
          <div className="bg-baseGray p-5">
            <h3 className="text-black font-semibold font-Montserrat text-md mb-3">
              Submitted Ideas
            </h3>
            {loadListIdea ?  <Skeleton col={4} height={300} /> : !loadListIdea ? (
              <div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                  {listCardIdea?.data?.length > 0 ? (
                    listCardIdea?.data?.map((items, index) => (
                      <div
                        key={items.id}
                        className="bg-white border border-gray-200 shadow-md relative pb-8 cursor-pointer"
                        onClick={() => detailViewIdeas(items.id)}
                      >
                        <div className="w-full h-[200px] mb-4 md:h-[200px] bx_img_list_challenges flex overflow-hidden justify-center">
                          <img
                            src={items.card_image}
                            alt={items.title_idea}
                            className="w-full h-[200px] mb-4 md:h-[200px] bx_img_list_challenges items-center min-w-[300px] object-cover"
                          />
                          <div className="absolute top-0 right-0">
                            <p
                              id="status"
                              className={`bg-white w-44 py-[3px] text-center text-[12px] tracking-[0.5px] font-semibold font-Montserrat uppercase ${
                                items.status === "Generation"
                                  ? "text-heritageGreen"
                                  : ""
                              } ${
                                items.status === "Validation"
                                  ? "text-naartjie"
                                  : ""
                              } ${
                                items.status === "Experimentation"
                                  ? " text-freshGreen"
                                  : ""
                              } ${
                                items.status === "Commercialisation"
                                  ? " text-blueSky"
                                  : ""
                              } ${
                                items.status === "Closed" ? "  text-cerise" : ""
                              } ${
                                items.status === "Declined"
                                  ? "  text-red-700"
                                  : ""
                              } ${
                                items.status === "On Hold"
                                  ? "  text-orange-900"
                                  : ""
                              } `}
                            >
                              {items.status}
                            </p>
                          </div>
                        </div>
                        <div className="p-3 space-y-5">
                          <div className="flex space-x-2 items-center">
                            <div className="h-8 w-8">
                              <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                                {GeneralUtility.getInitials(items.fullname)}
                              </div>
                            </div>
                            <div>
                              <p className="text-sm font-bold text-gray-700">
                                {items.fullname}
                              </p>
                              <p className="text-sm text-gray-700">
                                {items.created_at.substring(0, 10)}
                              </p>
                            </div>
                          </div>
                          <div id="content">
                            <p className="mb-3 h-12 text-md font-semibold text-gray-700">
                              {items?.title_idea?.length > 45
                                ? items?.title_idea?.substring(0, 45) + "..."
                                : items?.title_idea}
                            </p>
                          </div>
                          <div className="space-x-2 flex text-sm justify-between bottom-3">
                            <div className="flex items-center space-x-2 text-gray-400">
                              <div className="flex items-center space-x-2">
                                <img
                                  src="/assets/global/img/visible.svg"
                                  className="h-3 w-4"
                                  alt=""
                                />
                                <p className="text-sm">{items.total_view}</p>
                              </div>
                              <div className="flex items-center space-x-2">
                                <img
                                  src="/assets/global/img/chat.svg"
                                  className="h-2.5 w-4"
                                  alt=""
                                />
                                <p className="text-sm">
                                  {items.total_commentary}
                                </p>
                              </div>
                            </div>
                            <div className="flex items-center space-x-2 text-gray-400">
                              <div className="flex items-center space-x-2">
                                <img
                                  src="/assets/global/img/like.svg"
                                  className="h-3 w-3"
                                  alt=""
                                />
                                <p>{items.total_like}</p>
                              </div>
                              <div className="flex items-center space-x-2">
                                <img
                                  src="/assets/global/img/dislike.svg"
                                  className="h-3 w-3"
                                  alt=""
                                />
                                <p>{items.total_dislike}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">No Data Available</div>
                  )}
                </div>
              </div>
            ) : (
              <div className="mx-auto mt-8 md:px-6">
                <Skeleton col={4} height={350} />
                <div className="p-3 mx-auto mt-4 md:mt-1"></div>
              </div>
            )}
          </div>
        </div>

        {/* LIST VIEW*/}
        <div className={` ${gridView ? "hidden" : "mx-auto"}`}>
          <div className="bg-baseGray p-5 hidden lg:block md:block">
            <h3 className="text-black font-semibold mb-3">Submitted Ideas</h3>
            <div className="grid grid-cols-1 lg:grid-cols-7 gap-2 font-semibold py-3 border bg-white border-b-gray-200">
              <div className="text-center text-black font-semibold text-[12px] ">
                PROFILE
              </div>
              <div className="text-[12px]">MEMBER NAME</div>
              <div className="text-[12px]">IDEA TITLE</div>
              <div className="text-[12px]">STATUS</div>
              <div className="text-[12px]">DATE</div>
              <div className="text-[12px]">VIEWS</div>
              <div className="text-[12px]">COMMENTS</div>
            </div>
            {loadListIdea ?  <IsLoading/>:  listCardIdea?.data?.length > 0 ? (
              listCardIdea?.data?.map((items, index) => (
                <div
                  key={index}
                  onClick={() => detailViewIdeas(items.id)}
                  className="cursor-pointer grid grid-cols-1 lg:grid-cols-7 gap-2 items-center py-3 border border-b-gray-200 bg-white hover:bg-gray-100"
                >
                  <div className="flex justify-center">
                    <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                      {GeneralUtility.getInitials(items.fullname)}
                    </div>
                  </div>
                  <div className="text-overflow break-words text-[12px]">
                    {items.fullname}
                  </div>
                  <div className="text-[12px] w-full">{items.title_idea}</div>
                  <div>
                    <p
                      id="status"
                      className={`text-white py-[3px] text-[10px] uppercase text-center font-semibold
                        ${
                          items.status === "Generation"
                            ? "bg-heritageGreen"
                            : ""
                        } 
                          ${items.status === "Validation" ? "bg-naartjie" : ""} 
                          ${
                            items.status === "Experimentation"
                              ? " bg-freshGreen"
                              : ""
                          } 
                          ${
                            items.status === "Commercialisation"
                              ? " bg-blueSky"
                              : ""
                          } 
                          ${items.status === "Declined" ? " bg-red-700" : ""} 
                          ${items.status === "Closed" ? "  bg-cerise" : ""}
                          ${
                            items.status === "On Hold" ? "  bg-orange-900" : ""
                          } `}
                    >
                      {items.status}
                    </p>
                  </div>
                  <div className="text-[12px]">
                    {items.created_at.substring(0, 10)}
                  </div>

                  <div>
                    <div className="flex items-center ml-1 space-x-2">
                      <img
                        src="/assets/global/img/visible.svg"
                        className="h-3 w-4"
                        alt=""
                      />
                      <p className="text-[12px] ">{items.total_view}</p>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center ml-4 space-x-2">
                      <img
                        src="/assets/global/img/chat.svg"
                        className="h-3 w-4"
                        alt=""
                      />
                      <p className="text-[12px]">{items.total_commentary}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">No Data Available</div>
            )}
          </div>
          <div className="bg-baseGray p-5 lg:hidden md:hidden ">
            <h3 className="text-black font-semibold text-xl p-3">
              Submitted Ideas
            </h3>

            {listCardIdea?.data?.length > 0 ? (
              listCardIdea?.data?.map((items, index) => (
                <div
                  key={index}
                  onClick={() => detailViewIdeas(items.id)}
                  className="cursor-pointer grid grid-cols-1 lg:grid-cols-9 gap-2 items-center py-3 border border-b-gray-200 bg-white hover:bg-gray-100"
                >
                  <div className="bg-white p-3 space-y-4 ">
                    <div className="space-y-3">
                      <div className="flex space-x-3 items-center">
                        <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                          {GeneralUtility.getInitials(items.fullname)}
                        </div>
                        <p className="mb-3 text-md font-semibold text-gray-700">
                          {items.fullname}
                        </p>
                      </div>
                    </div>
                    <p className="mb-3 text-md font-semibold text-gray-700">
                      {items.title_idea}
                    </p>
                    <div className="space-y-3">
                      <div className="flex space-x-3 items-center">
                        <p
                          id="status"
                          className={`text-white w-full py-[3px] text-sm uppercase text-center 
                            ${
                              items.status === "Generation"
                                ? "bg-heritageGreen"
                                : ""
                            } ${
                            items.status === "Validation" ? "bg-naartjie" : ""
                          } ${
                            items.status === "Experimentation"
                              ? " bg-freshGreen"
                              : ""
                          } ${
                            items.status === "Commercialisation"
                              ? " bg-blueSky"
                              : ""
                          } ${
                            items.status === "Declined" ? " bg-red-700" : ""
                          } ${items.status === "Closed" ? "  bg-cerise" : ""} `}
                        >
                          {items.status}
                        </p>
                      </div>
                    </div>
                    <div className="space-y-3">
                      <div className="flex space-x-3 items-left">
                        <div className="">
                          {items.created_at.substring(0, 10)}
                        </div>
                      </div>
                    </div>
                    <div className="space-y-3">
                      <div className="flex space-x-3 items-left">
                        <div className="flex items-center space-x-2">
                          <img
                            src="/assets/global/img/visible.svg"
                            className="h-3 w-4"
                            alt=""
                          />
                          <p className="text-sm">{items.total_view}</p>
                        </div>
                        <div className="flex items-center space-x-2">
                          <img
                            src="/assets/global/img/chat.svg"
                            className="h-3 w-4"
                            alt=""
                          />
                          <p className="text-sm">{items.total_commentary}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">No Data Available</div>
            )}
          </div>
        </div>
      </div>

      <div className="m-6">
      <div className="mx-auto">
              <Paginate paginate={paginate} page={page} defPage={selectedPage}/>
            </div>
      </div>
    </div>
  );
};

export default Ideas;
