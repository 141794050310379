import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import SubmittedIdeaRepo from "../../../../repositories/user-repo/SubmittedIdeaRepo";
import ChallengeRepo from "../../../../repositories/user-repo/ChallengeRepo";
import Cookies from "js-cookie";
import EncryptDecrypt from "../../../../utility/encrypt-decrypt";
import RouteAdm from "../../../../services/routeAdm";
import Paginate from "../../../../components/paginate/Paginate";
import GeneralUtility from "../../../../utility/GeneralUtility";
import FilterIdeaRepo from "../../../../repositories/adm-repo/FilterIdeaRepo";
import Breadcrumbs from "./components/Breadcrumbs";
import Banner from "../../../../components/Banner";
import Skeleton from "../../../../components/skeleton/Skeleton";
import IsLoading from "../../../../components/loading/IsLoading";

const SubmittedChallengeAdm = () => {

  let { id } = useParams();
  Cookies.set("idChallenge", EncryptDecrypt.enc(id), { secure: true });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isTab = queryParams.get('tab'); 
  const isStatus = queryParams.get('status') || "all"; 
  const isPage = queryParams.get("page");

  const pageNumber = isPage && !isNaN(Number(isPage)) ? Number(isPage) : 0;

  const [limit] = useState(12);
  const [page, setPage] = useState([]);
  const [listView, setlistView] = useState(isTab === "0"? true : false);
  const navigate = useNavigate();
  const [listSubmitted, setListSubmitted] = useState([]);
  const [evaluation, setEvaluation] = useState(isStatus);
  const [loadSubmitted,setLoadSubmitted] = useState(false)
  const [sortIdea, setSortIdea] = useState(false);
  const [listEvaluation, setListEvaluation] = useState([]);
  const [banner, setBanner] = useState([]);
  // eslint-disable-next-line
  const [transaction, setTransaction] = useState(null);
  const [loadBanner, setLoadBanner] = useState(true);

  const detailViewIdeas = (e) => {
    navigate(RouteAdm.detailViewIdeas  + "/" + e +"?"+"tab=" + isTab +"&page=" + pageNumber +"&status=" + evaluation + "&base=challenges&challengeId="  + id);
  };

  /**
   * get data challenge
   */
  const getDetailChallenge = useCallback(async () => {
    setLoadBanner(true);
    const result = await ChallengeRepo.getDetailChallenges({ id: id });
    let listChallenges = result;
    setBanner(listChallenges);
    setLoadBanner(false);
  }, [id]);

  /**
   * get all submitted data
   */
  const getSubmittedList = useCallback(
    async (pageNumber) => {
      setLoadSubmitted(true)
      console.log('loading....');
      try{
        const result = await SubmittedIdeaRepo.getSubmittedIdea({
          offset: pageNumber,
          limit: limit,
          challange: id,
          status: transaction,
          evaluation: evaluation === "all" ? null : evaluation,
        });
        let listSubmitted = result !== null ? result.data : [];
        let listPage = result !== null ? result : [];
        setListSubmitted(listSubmitted);
        setPage(listPage);
        setLoadSubmitted(false)
      }catch(error){
        setLoadSubmitted(false)
      }
    },
    [evaluation, id, limit, transaction,setLoadSubmitted]
  );


  const getFilterEval = async () => {
    const result = await FilterIdeaRepo.getEvaluation();
    let listEval = result.data.data;
    setListEvaluation(listEval);
  };

  const paginate = (event) => {
    getSubmittedList(event.selected);
  navigate(RouteAdm.submittedChallengeAdm + "/" + id  +"?"+"view=" + listView +"&page="+ event.selected +"&status=" + evaluation)
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getSubmittedList(pageNumber);
    getDetailChallenge();
    getFilterEval();
  }, [transaction, evaluation, getSubmittedList, getDetailChallenge]);

  return (
    <>
      <div className="container mx-auto">
        <div className="m-6 space-y-4">
          <Breadcrumbs challenge={{ title: banner.title, id: banner.id }} />
        </div>
        <div>
          <Banner
            detail={{ banner: banner.banner_image, title: banner.title }}
            loading={loadBanner}
          />
        </div>
        <div className="m-6 space-y-4">
          <div className="justify-end flex flex-col lg:flex lg:flex-row space-y-2">
            <div className="flex items-center space-x-3 justify-end">
              <div className="flex space-x-2">
                <button
                  className={` ${
                    listView
                      ? "bg-freshGreen text-baseGray"
                      : "bg-gray-300 text-freshGreen"
                  } w-8 h-8`}
                  onClick={() => {
                    navigate(RouteAdm.submittedChallengeAdm + "/" + id  +"?"+"tab=" + 0 +"&page="+ isPage +"&status=" + evaluation)
                    setlistView(true)
                  } }
                >
                  <img
                    src="/assets/global/img/grid.svg"
                    className="ml-1.5"
                    alt=""
                  />
                </button>
                <button
                  className={` ${
                    listView
                      ? "bg-gray-300 text-freshGreen"
                      : "bg-freshGreen text-baseGray"
                  } w-8 h-8`}
                  onClick={() => {
                    navigate(RouteAdm.submittedChallengeAdm + "/" + id  +"?"+"tab=" + 1 +"&page=" + isPage +"&status=" + evaluation)
                    setlistView(false)
                  }}
                >
                  <img
                    src="/assets/global/img/list.svg"
                    className="ml-1.5"
                    alt=""
                  />
                </button>
              </div>
              <div className="flex lg:justify-self-end">
                <div className="flex space-x-2">
                  <div
                    className={`items-center bg-white h-[35px] w-32 lg:w-44 md:w-44 py-2 border text-[10px] z-10 bx_filter_sortby transition-opacity ease-in duration-300 opacity-100`}
                  >
                    <div
                      className="flex flex-row cursor-pointer justify-between w-fill font-Montserrat"
                      onClick={() => setSortIdea(!sortIdea)}
                    >
                      <span className="text-[10px] mt-0.5 ml-3 uppercase">
                        SHOW :{" "}
                        {listEvaluation && evaluation !== "all"
                          ? listEvaluation
                              .find((ev) => ev.id === evaluation)
                              ?.name?.substring(0, 14) + ".."
                          : "ALL IDEAS"}
                      </span>
                      <img
                        src="/assets/global/img/up-arrow.svg"
                        className={`h-3 w-2 stroke-black mr-2 mt-0.5 uppercase ${
                          sortIdea
                            ? "transition-width duration-300 easy rotate-180"
                            : "transition-width duration-300 easy rotate-0"
                        }`}
                        alt=""
                        onClick={() => setSortIdea(!sortIdea)}
                      />
                    </div>
                    <div className="z-50">
                      {sortIdea && (
                        <div
                          className="bg-white shadow-md lg:w-[175px] md:w-[175px] w-[128px] transition-opacity ease-in duration-700 opacity-100 flex flex-row text-[10px] uppercase"
                          onClick={() => {
                            navigate(RouteAdm.submittedChallengeAdm + "/" + id  +"?"+"tab=" + listView +"&page=0"+"&status=all")
                            setEvaluation("all")
                           // getSubmittedList(0);
                          } }
                        >
                          <img
                            src={`${
                              evaluation === "all"
                                ? "/assets/global/img/toggle - check.svg"
                                : "/assets/global/img/toggle.svg"
                            }`}
                            className={`h-4 w-3 stroke-black mt-0.5 ml-3 mr-1`}
                            alt=""
                            onClick={() => setSortIdea(!sortIdea)}
                          />
                          <div
                            className="mt-0.5 hover:font-semibold"
                            onClick={() => setSortIdea(!sortIdea)}
                          >
                            {" "}
                            ALL IDEAS
                          </div>
                        </div>
                      )}
                      {sortIdea &&
                        listEvaluation &&
                        listEvaluation
                          .sort((a, b) => a.step_index - b.step_index)
                          .map((items, index) => {
                            return (
                              <div
                                className=" bg-white shadow-md lg:w-[175px] md:w-[175px] w-[128px] transition-opacity ease-in duration-700 opacity-100 flex flex-row text-[10px] uppercase"
                                onClick={() => {
                                  navigate(RouteAdm.submittedChallengeAdm + "/" + id  +"?"+"tab=" + listView +"&page=0" +"&status=" + items.id)
                                  setEvaluation(items.id)
                                 // getSubmittedList(0);
                                } }
                              >
                                <img
                                  src={`${
                                    items.id === evaluation
                                      ? "/assets/global/img/toggle - check.svg"
                                      : "/assets/global/img/toggle.svg"
                                  }`}
                                  className={`h-4 w-3 stroke-black mt-1 ml-3 mr-1 uppercase`}
                                  alt=""
                                  onClick={() => setSortIdea(!sortIdea)}
                                />
                                <div
                                  className="mt-1 hover:font-semibold"
                                  onClick={() => setSortIdea(!sortIdea)}
                                >
                                  {items.name}
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={` ${listView ? "mx-auto" : "hidden"}`}>
            <div className="bg-baseGray p-5">
              <h3 className="font-semibold text-xl p-3 text-center">
                Submitted Ideas
              </h3>
              {!loadSubmitted ? (
                <>
                  {!loadSubmitted && listSubmitted.length === 0 ? (
                    <div className="text-center font-semibold text-md p-6">
                      No Submitted Ideas
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
                      {listSubmitted.map((items) => (
                        <div
                          key={items.id}
                          onClick={() => detailViewIdeas(items.id)}
                          className="bg-white border border-gray-200 shadow-md relative pb-4  cursor-pointer"
                        >
                          <div
                            className="w-full h-[200px] bg-cover bg-center bg-no-repeat mb-4 md:h-[200px]"
                            style={{
                              backgroundImage: "url(" + items.card_image + ")",
                            }}
                          >
                            <div className="float-right">
                              <p
                                id="status"
                                className={`bg-white w-44 py-[3px] text-center text-[12px] tracking-[0.5px] font-semibold font-Montserrat uppercase ${
                                  items.status === "Generation"
                                    ? "text-heritageGreen"
                                    : ""
                                } ${
                                  items.status === "Validation"
                                    ? "text-naartjie"
                                    : ""
                                } ${
                                  items.status === "Experimentation"
                                    ? " text-freshGreen"
                                    : ""
                                } ${
                                  items.status === "Commercialisation"
                                    ? " text-blueSky"
                                    : ""
                                } ${
                                  items.status === "Closed"
                                    ? "  text-cerise"
                                    : ""
                                } ${
                                  items.status === "Declined"
                                    ? "  text-red-700"
                                    : ""
                                } ${
                                  items.status === "On Hold"
                                    ? "  text-orange-900"
                                    : ""
                                } `}
                              >
                                {items.status}
                              </p>
                            </div>
                          </div>
                          <div className="p-3 space-y-5">
                            <div className="flex space-x-2 items-center">
                              <div>
                                <div className="h-8 w-8">
                                  <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                                    {GeneralUtility.getInitials(items.fullname)}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <p className="text-sm font-bold text-gray-700">
                                  {items.fullname}
                                </p>
                                <p className="text-sm text-gray-700">
                                  {items.created_at.substring(0, 10)}
                                </p>
                              </div>
                            </div>
                            <div id="content">
                              <p className="mb-3 h-12 text-md font-semibold text-gray-700">
                                {items?.title_idea?.length > 45
                                  ? items?.title_idea?.substring(0, 45) + "..."
                                  : items?.title_idea}
                              </p>
                            </div>
                            <div className="space-x-2 flex text-sm justify-between bottom-3">
                              <div className="flex items-center space-x-2 text-gray-400">
                                <div className="flex items-center space-x-2">
                                  <img
                                    src="/assets/global/img/visible.svg"
                                    className="h-3 w-4"
                                    alt=""
                                  />
                                  <p className="text-sm">{items.total_view}</p>
                                </div>
                                <div className="flex items-center space-x-2">
                                  <img
                                    src="/assets/global/img/chat.svg"
                                    className="h-2.5 w-4"
                                    alt=""
                                  />
                                  <p className="text-sm">
                                    {items.total_commentary}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center space-x-2 text-gray-400">
                                <div className="flex items-center space-x-2">
                                  <img
                                    src="/assets/global/img/like.svg"
                                    className="h-3 w-3"
                                    alt=""
                                  />
                                  <p>{items.total_like}</p>
                                </div>
                                <div className="flex items-center space-x-2">
                                  <img
                                    src="/assets/global/img/dislike.svg"
                                    className="h-3 w-3"
                                    alt=""
                                  />
                                  <p>{items.total_dislike}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div>
                    <Skeleton col="4" height="250" />
                  </div>
                </>
              )}
            </div>
            <div className="pb-5 mt-5">
              <Paginate paginate={paginate} page={page} defPage={isPage} />
            </div>
          </div>

          {/* LIST */}
          <div className={` ${listView ? "hidden" : "mx-auto"}`}>
            <div className="bg-baseGray p-5 hidden lg:block md:block">
              <h3 className="text-black font-semibold mb-3">Submitted Ideas</h3>
              <div className="grid grid-cols-1 lg:grid-cols-7 gap-2 font-semibold py-3 border bg-white border-b-gray-200">
                <div className="text-center text-black font-semibold text-[12px] ">
                  PROFILE
                </div>
                <div className="text-[12px]">MEMBER NAME</div>
                <div className="text-[12px]">IDEA TITLE</div>
                <div className="text-[12px]">STATUS</div>
                <div className="text-[12px]">DATE</div>
                <div className="text-[12px]">VIEWS</div>
                <div className="text-[12px]">COMMENTS</div>
              </div>
              { loadSubmitted ?
              <IsLoading/>
                :
              listSubmitted.length === 0 ? (
                <div className="text-center font-semibold text-md p-6">
                  No Submitted Ideas
                </div>
              ) : (
                <div>
                  {listSubmitted.map((items, index) => (
                    <div
                      key={index}
                      onClick={() => detailViewIdeas(items.id)}
                      className="cursor-pointer grid grid-cols-1 lg:grid-cols-7 gap-2 items-center py-3 border border-b-gray-200 bg-white hover:bg-gray-100"
                    >
                      <div className="flex justify-center">
                        <div className="h-8 w-8">
                          <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                            {GeneralUtility.getInitials(items.fullname)}
                          </div>
                        </div>
                      </div>
                      <div className="text-overflow break-words text-[12px]">
                        {items.fullname}
                      </div>
                      <div className="text-[12px] w-full">
                        {items.title_idea}
                      </div>

                      <div className="flex space-x-3 items-center">
                        <p
                          id="status"
                          className={`text-white w-full py-[3px] text-[10px] font-semibold uppercase text-center ${
                            items.status === "Generation"
                              ? "bg-heritageGreen"
                              : ""
                          } ${
                            items.status === "Validation" ? "bg-naartjie" : ""
                          } ${
                            items.status === "Experimentation"
                              ? " bg-freshGreen"
                              : ""
                          } ${
                            items.status === "Commercialisation"
                              ? " bg-blueSky"
                              : ""
                          } ${
                            items.status === "Declined" ? " bg-red-700" : ""
                          } ${
                            items.status === "On Hold" ? " bg-orange-900" : ""
                          } ${items.status === "Closed" ? "  bg-cerise" : ""} `}
                        >
                          {items.status}
                        </p>
                      </div>
                      <div className="text-[12px]">
                        {items.created_at.substring(0, 10)}
                      </div>

                      <div>
                        <div className="flex items-center space-x-2">
                          <img
                            src="/assets/global/img/visible.svg"
                            className="h-3 w-4"
                            alt=""
                          />
                          <p className="text-[12px]">{items.total_view}</p>
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center ml-4 space-x-2">
                          <img
                            src="/assets/global/img/chat.svg"
                            className="h-3 w-4"
                            alt=""
                          />
                          <p className="text-[12px]">
                            {items.total_commentary}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="bg-baseGray p-5 lg:hidden md:hidden ">
              <h3 className="font-semibold text-xl p-3 text-center">
                Submitted Ideas
              </h3>
              {listSubmitted.length === 0 ? (
                <div className="text-center font-semibold text-md p-6">
                  No Submitted Ideas
                </div>
              ) : (
                <div>
                  {listSubmitted.map((items, index) => (
                    <div
                      key={index}
                      onClick={() => detailViewIdeas(items.id)}
                      className="cursor-pointer grid grid-cols-1 lg:grid-cols-9 gap-2 items-center py-3 border border-b-gray-200 bg-white hover:bg-gray-100"
                    >
                      <div className="bg-white p-3 space-y-4 ">
                        <div className="space-y-3">
                          <div className="flex space-x-3 items-center">
                            <div className="h-8 w-8">
                              <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-semibold justify-center">
                                {GeneralUtility.getInitials(items.fullname)}
                              </div>
                            </div>
                            <p className="mb-3 text-md font-semibold text-gray-700">
                              {items.fullname}
                            </p>
                          </div>
                        </div>
                        <p className="mb-3 text-md font-semibold text-gray-700">
                          {items.title_idea}
                        </p>
                        <div className="space-y-3 ">
                          <div className="flex space-x-3 items-center">
                            <p
                              id="status"
                              className={`text-white w-full py-[3px] text-[10px] font-semibold uppercase text-center ${
                                items.status === "Generation"
                                  ? "bg-heritageGreen"
                                  : ""
                              } ${
                                items.status === "Validation"
                                  ? "bg-naartjie"
                                  : ""
                              } ${
                                items.status === "Experimentation"
                                  ? " bg-freshGreen"
                                  : ""
                              } ${
                                items.status === "Commercialisation"
                                  ? " bg-blueSky"
                                  : ""
                              } ${
                                items.status === "Declined" ? " bg-red-700" : ""
                              } ${
                                items.status === "On Hold"
                                  ? " bg-orange-900"
                                  : ""
                              } ${
                                items.status === "Closed" ? "  bg-cerise" : ""
                              } `}
                            >
                              {items.status}
                            </p>
                          </div>
                        </div>
                        <div className="space-y-3">
                          <div className="flex space-x-3 items-left">
                            <div className="">
                              {items.created_at.substring(0, 10)}
                            </div>
                          </div>
                        </div>
                        <div className="space-y-3">
                          <div className="flex space-x-3 items-left">
                            <div className="flex items-center space-x-2">
                              <img
                                src="/assets/global/img/visible.svg"
                                className="h-3 w-4"
                                alt=""
                              />
                              <p className="text-sm">{items.total_view}</p>
                            </div>
                            <div className="flex items-center space-x-2">
                              <img
                                src="/assets/global/img/chat.svg"
                                className="h-3 w-4"
                                alt=""
                              />
                              <p className="text-sm">
                                {items.total_commentary}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="pb-5 mt-5">
              <Paginate paginate={paginate} page={page} defPage={isPage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmittedChallengeAdm;
