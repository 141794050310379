import React from "react";
import ButtonEdit from "./ButtonEdit";
import ButtonDelete from "./ButtonDelete";

const ButtonGroupEdit = ({ onClickEdit, onClickDelete, isEdit = false }) => {
  return (
    <>
      <div className="h-7 w-30 controlhover-rewards">
        <ButtonEdit onClick={onClickEdit} isEdit={isEdit} />
        {isEdit ? "" : <ButtonDelete onClick={onClickDelete} />}
      </div>
    </>
  );
};

export default ButtonGroupEdit;
