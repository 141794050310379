const MONTH_NUMBER = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const formatDate = (date) => {
  const event = new Date(date);
  const day = event.getDate();
  const month = getMonth(event.getMonth());
  const year = event.getFullYear();
  return day + " " + month + " " + year;
};

const formatHour = (date) => {
  const event = new Date(date);
  const hour = event.getHours();
  const minute = event.getMinutes();
  return hour + ":" + minute;
};

const changeFormatDate = (date) => {
  const event = new Date(date);
  const day = event.getDate();
  const month = event.getMonth() + 1;
  const year = event.getFullYear();
  return (
    year +
    "-" +
    (month < 10 ? "0" + month : month) +
    "-" +
    (day < 10 ? "0" + day : day)
  );
};

const getMonth = (month) => {
  if (month === 0) {
    return "Jan";
  } else if (month === 1) {
    return "Feb";
  } else if (month === 2) {
    return "Mar";
  } else if (month === 3) {
    return "Apr";
  } else if (month === 4) {
    return "May";
  } else if (month === 5) {
    return "Jun";
  } else if (month === 6) {
    return "Jul";
  } else if (month === 7) {
    return "Aug";
  } else if (month === 8) {
    return "Sep";
  } else if (month === 9) {
    return "Oct";
  } else if (month === 10) {
    return "Nov";
  } else if (month === 11) {
    return "Des";
  }
};

const dateFormatApi = (value) => {
  const date = new Date(value);
  let newDate =
    date.getFullYear() +
    "-" +
    MONTH_NUMBER[date.getMonth()] +
    "-" +
    (date.getDate() < 10 ? "0" : "") +
    date.getDate();
  return newDate;
};

const DateUtility = {
  formatDate,
  formatHour,
  changeFormatDate,
  dateFormatApi,
};

export default DateUtility;
