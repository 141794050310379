import React from "react";
import GeneralUtility from "../../../../../utility/GeneralUtility";
import Skeleton from "../../../../../components/skeleton/Skeleton";

const RewardDetail = ({ reward, loading }) => {
  return (
    <div className="pb-5 bg-baseGray">
      <div className="howToGetRewarded">
        <div className=" p-3 mb-6">
          <div className="text-md md:text-lg lg:text-lg font-semibold pt-3 text-center md:text-left lg:text-left">
            How To Get Rewarded
          </div>
          {!loading ? (
            <div className="container mx-auto mt-8">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {reward.map((items, index) => (
                  <div
                    className="flex justify-center bg-white w-full"
                    key={index}
                  >
                    <div className="shadow-md w-full">
                      <div
                        className={`${
                          index === 0
                            ? "bg-gradient-to-r from-orange-600 to-pink-600 bx_stage_1"
                            : ""
                        }
                      ${
                        index === 1
                          ? "bg-gradient-to-r from-futureGreen to-heritageGreen bx_stage_2"
                          : ""
                      }
                      ${
                        index === 2
                          ? "bg-gradient-to-r from-yellow-200 to-blueSky bx_stage_3"
                          : ""
                      }
                      ${
                        index === 3
                          ? "bg-gradient-to-r from-orange-200 to-yellow-200 bx_stage_4"
                          : ""
                      } h-24 w-full flex items-center bg-cover`}
                      >
                        <div
                          className="bg-cover h-full w-full flex items-center justify-between"
                          style={{
                            backgroundImage: `url("/assets/global/img/Pattern2.png")`,
                          }}
                        >
                          <div className="h-full w-full flex items-center justify-between p-3">
                            <div className="flex items-start">
                              <div className="w-6 h-6">
                                <img
                                  src={`${
                                    index === 0
                                      ? "/assets/global/img/lightbulb.svg"
                                      : ""
                                  }
                                ${
                                  index === 1
                                    ? "/assets/global/img/check.png"
                                    : ""
                                }
                                ${
                                  index === 2
                                    ? "/assets/global/img/science-white.png"
                                    : ""
                                }
                                ${
                                  index === 3
                                    ? "/assets/global/img/diamond.png"
                                    : ""
                                }`}
                                  alt="image_card"
                                  className={`${
                                    index === 0 ? "img_stage_1" : ""
                                  }
                                ${index === 1 ? "img_stage_2" : ""}
                                ${index === 2 ? "img_stage_3" : ""}
                                ${index === 3 ? "img_stage_4" : ""}`}
                                />
                              </div>
                              <div className="font-semibold text-white text-lg md:text-xl px-3 font-Montserrat tracking-wide">
                                {items.title}
                              </div>
                            </div>
                            <div className="text-white mr-4">
                              <p className="font-medium text-lg md:text-xl tracking-wide leading-tight">
                                R{GeneralUtility.addCommas(items.reward)}
                              </p>
                              <p className="text-sm tracking-wide leading-tight">
                                REWARD
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-5 text-center">
                        <p className="font-normal text-gray-700 text-xs">
                          {items.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="container mx-auto mt-8">
              <Skeleton col="1" height="200" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RewardDetail;
