import React, { useEffect } from 'react';

const FrameBuster = () => {
  useEffect(() => {
    if (window.top !== window.self) {
      window.top.location = window.self.location;
    }
  }, []);

  return null; // Render nothing, as this is just a utility component
};

export default FrameBuster;
