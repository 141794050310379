import React from "react";
import { Link,useNavigate } from "react-router-dom";
import ArrowIco from "../../../../components/icons/ArrowIco";
import RouteAdm from "../../../../services/routeAdm";
import Skeleton from "../../../../components/skeleton/Skeleton";
import Cookies from "js-cookie";

const StatusCTA = {
  active: "Edit",
  new: "Edit",
};

const All = ({ listChallenge, loading,tab,page,year }) => {

  const navigate = useNavigate();
  const handleTo = (id) => {
      navigate(RouteAdm.detailChallengesAdm + "/" +id+"?tab="+tab +"&page="+ page + "&year="+ year)
  }

  return (
    <div>
      {!loading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
          {listChallenge?.data?.length > 0
            ? listChallenge?.data?.map((items) => (
                <div
                  key={items.id}
                  className="bg-white border border-gray-200 shadow-md relative pb-8"
                >
                  <div onClick={() =>handleTo(items.id)}>
                    <div className="w-full h-[200px] mb-4 md:h-[200px] bx_img_list_challenges relative flex overflow-hidden justify-center">
                      <img
                        src={items.card_image}
                        alt={items.title}
                        className="w-full h-[200px] mb-4 md:h-[200px] bx_img_list_challenges items-center min-w-[300px]"
                      />
                      <div className="absolute top-0 right-0">
                        <p
                          id="status"
                          className="bg-white w-32 py-[3px] text-center font-semibold text-freshGreen uppercase text-xs tracking-[0.5px]"
                        >
                          {items.status}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="p-3 space-y-3">
                    <Link to={RouteAdm.detailChallengesAdm + "/" + items.id}>
                      <h5
                        id="title"
                        className="mb-2 text-lg font-bold tracking-tight text-gray-900"
                      >
                        {items.title.length > 35
                          ? items.title.substring(0, 35) + "..."
                          : items.title}
                      </h5>
                    </Link>

                    <div id="content">
                      <div className="mb-3 text-sm text-gray-700">
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              items.desc && items.desc.length > 200
                                ? items.desc.substring(0, 200) + "..."
                                : items.desc,
                          }}
                        />
                      </div>
                    </div>

                    <Link
                      to={
                        items.status === "active" || items.status === "new"
                        ? `${RouteAdm.editChallenge}/${items.id}`
                        : `${RouteAdm.detailChallengesAdm}/${items.id}`
                    }
                    >
                      <div
                        className="inline-flex items-center text-sm mb-5 font-medium text-center hover:text-freshGreen cursor-pointer"
                      >
                        {/* {StatusCTA[items.status] ?? "Read More"}  */}
                        {items.status === "active" || items.status === "new" ? "Edit" : "Read More"}
                        <ArrowIco />
                      </div>
                    </Link>
                    <div className="flex space-x-3 mt-10 absolute bottom-3 ">
                      <div className="flex items-center space-x-2 rounded-md text-gray-400">
                        <img
                          src="/assets/global/img/avatar.png"
                          className="h-3 w-3"
                          alt="avatar"
                        />
                        <p>{items.total_contributor}</p>
                      </div>
                      <div className="flex items-center space-x-2 rounded-md text-gray-400">
                        <img
                          src="/assets/global/img/visible.png"
                          className="h-3 w-5"
                          alt="visible"
                        />
                        <p>{items.total_view}</p>
                      </div>
                      <div className="flex items-center space-x-2 rounded-md text-gray-400">
                        <img
                          src="/assets/global/img/lightbulbGray.png"
                          className="h-4 w-3"
                          alt="lightbulbGray"
                        />
                        <p>{items.total_submited}</p>
                      </div>

                      <div className="flex space-x-3 hidden">
                        <div className=" justify-self-end space-x-1 text-[9px] text-gray-400">
                          <p>Start Date : {items.start_date}</p>
                          <p>End Date : {items.end_date}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : "No Data Available"}
        </div>
      ) : (
        <div className="mx-auto mt-8 md:px-6">
          <Skeleton col={4} height={350} />
          <div className="p-3 mx-auto mt-4 md:mt-1"></div>
        </div>
      )}
    </div>
  );
};

export default All;
