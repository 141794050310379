import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import DragDrop from "../../../../components/DragDrop";
import RichText from "../../../../components/RichText";
import Breadcrumbs from "./components/Breadcrumbs";
import Overview from "./components/Overview";
import RouteName from "../../../../services/routename";
import ChallengeRepo from "../../../../repositories/user-repo/ChallengeRepo";
import QuestionRepo from "../../../../repositories/user-repo/QuestionRepo";
import AllSubmitRepo from "../../../../repositories/user-repo/AllSubmitRepo";
import ConfirmPopup from "../../../../components/ConfirmPopup";
import AlertUtility from "../../../../utility/alert-utility";
import Swal from "sweetalert2";
import BtnGreen from "../../../../components/buttons/BtnGreen";
import GeneralUtility from "../../../../utility/GeneralUtility";
import RichTextQuill from "../../../../components/RichTextQuill";
import RichTextEditor from "../../../../components/RichTextEditor";

const CreateChallenge = () => {
  let slug = useParams();
  const [show, setShow] = useState(false);
  const [showDraft, setShowDraft] = useState(false);
  const [page, setPage] = useState("submit");
  const [documents, setDocuments] = useState([]);
  const [challenge, setChallenge] = useState({});
  const [question, setQuestion] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [answers, setAnswers] = useState([]);
  const [links, setLinks] = useState([{ resourceName: "", source: "" }]);
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const [card_image, setCard_image] = useState("");
  const [banner_image, setBanner_image] = useState("");

  /**
   * function to handle file
   * @param {*} e
   */
  const handleCardUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size < 5000000) {
      setCard_image(file);
    } else {
      AlertUtility.errorAlert({ title: "FILE MORE THAN 5 MB." });
    }
  };

  /**
   * function to handle convert file
   * @param {*} e
   */
  const handleBannerUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size < 5000000) {
      setBanner_image(file);
    } else {
      AlertUtility.errorAlert({ title: "FILE MORE THAN 5 MB." });
    }
  };

  /**
   * function to delete selected card image
   */
  const delCard = () => {
    setCard_image("");
  };

  /**
   * function to delete selected banner image
   */
  const delBanner = () => {
    setBanner_image("");
  };

  const getDetailChallenge = useCallback(async () => {
    const result = await ChallengeRepo.getDetailBySlug({ slug: slug.id });
    let listChallenges = result;
    setChallenge({
      id: listChallenges.id,
      title: listChallenges.title,
      description: listChallenges.description,
    });
    getQuestion(listChallenges.id);
  }, [slug.id]);

  // Looping field question
  const getQuestion = async (id) => {
    const result = await QuestionRepo.getQuestion(id);
    let listQuestion = result.data.data;
    setQuestion(listQuestion);
    let q = listQuestion.map(myFunc);

    function myFunc(element) {
      return { answer: "", question: { id: element.id } };
    }
    setAnswers(q);
  };

  /**
   * function for add to update the question
   * @param {*} id
   * @param {*} content
   */
  const handleQ = (id, content) => {
    const myNextList = [...answers];
    // for find data id question = id that added
    const artwork = myNextList.find((a) => a.question.id === id);
    artwork.answer = content;
    setAnswers(myNextList);
  };

  /**
   * function to add row links
   */
  const handleAddLinks = () => {
    const values = [...links];
    values.push({
      resourceName: "",
      source: "",
    });
    setLinks(values);
  };

  /**
   * function to remove row links
   * @param {*} index
   */
  const handleRemoveLinks = (index) => {
    const values = [...links];
    values.splice(index, 1);
    setLinks(values);
  };

  /**
   * funtion to handle links value
   * @param {*} index
   * @param {*} event
   */
  const handleInputChange = (index, event) => {
    const values = [...links];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;

    setLinks(values);
  };

  const validate = (description, title, answers) => {
    const errors = {};
    if (!title) {
      errors.title = "Title is required!";
    }
    if (!description) {
      errors.description = "Description is required!";
    }
    for (let index = 0; index < answers.length; index++) {
      if (!answers[index].answer) {
        errors.answer = "Questions must be answered.";
      }
    }

    setError(errors);
    return errors;
  };

  /**
   * handle save as draft
   * @param {*} e
   */
  const onDraft = async (e) => {
    e.preventDefault();
    setShowDraft(false);
    let err = validate(description, title, answers);
    if (Object.keys(err).length === 0) {
      let myideas = { title, description, links, documents, drafted: 1 };
      let transaction = {
        banner_image,
        card_image,
        id: challenge.id,
        answers,
        drafted: true,
      };
      let data = { myideas: myideas, transaction: transaction };
      AlertUtility.loadingAlert();
      const res = await AllSubmitRepo.createSubmitted(data);
      if (res.status === 200) {
        Swal.close();
        AlertUtility.successAlert({ title: "Your idea was saved as a draft" });
        navigate(`${RouteName.userLatestChallenges + "/" + slug.id}`);
      } else {
        Swal.close();
        AlertUtility.errorAlert({ title: res.data.message });
      }
    }
  };

  /**
   * handle on publish form
   * @param {*} e
   */
  const onPublish = async (e) => {
    e.preventDefault();
    setShow(false);
    let err = validate(description, title, answers);
    if (Object.keys(err).length === 0) {
      let myideas = { title, description, links, documents, drafted: 0 };
      let transaction = {
        banner_image,
        card_image,
        id: challenge.id,
        answers,
        drafted: false,
      };
      let data = { myideas: myideas, transaction: transaction };
      AlertUtility.loadingAlert();
      const res = await AllSubmitRepo.createSubmitted(data);
      if (res.status === 200) {
        Swal.close();
        AlertUtility.successAlert({
          title: "Your idea was successfully submitted",
        });
        navigate(`${RouteName.userLatestChallenges + "/" + slug.id}`);
      } else {
        Swal.close();
        AlertUtility.errorAlert({ title: res.data.message });
      }
    }
  };

  const tab = [
    { title: "Submit Idea", route: "submit" },
    { title: "Overview", route: "overview" },
  ];

  const handleChangeDesc = (e) => {
    setDescription(e);
  }


  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getDetailChallenge();
  }, [getDetailChallenge]);

  return (
    <div className="container mx-auto">
      <Breadcrumbs challenge={challenge.title} id={slug.id} />
      <div className="m-6 bg-white space-y-4">
        <h3 className="text-lg font-bold text-gray-500  lg:hidden">
          Latest Challenges
        </h3>
        <h3 className="text-lg font-bold">Add Idea To {challenge.title}</h3>

        <div className="border-b-2 border-gray-400 w-full lg:hidden">
          <ul className="inline-flex items-center space-x-3">
            {tab.map((items, index) => (
              <div
                key={index}
                className="cursor-pointer"
                onClick={() => setPage(items.route)}
              >
                <div
                  className={`${
                    page === items.route
                      ? "text-futureGreen border-b-2 border-futureGreen"
                      : "text-textGray border-none"
                  } text-sm font-semibold px-1`}
                >
                  {items.title}
                </div>
              </div>
            ))}
          </ul>
        </div>

        <div className="space-x-5">
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-y-3 md:gap-3 lg:gap-3">
            <div
              className={`${
                page === "submit" ? "" : "lg:block hidden"
              } bg-baseGray w-full p-6 space-y-5 col-span-3`}
            >
              <div id="ideaimages" className="space-y-3">
                <div className="flex font-semibold">Idea Images</div>
                <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 space-x-0 lg:space-x-4">
                  <div>
                    <label className="text-freshGreen font-semibold justify-center items-center flex space-x-2 text-xs py-2 bg-gray-300 hover:bg-gray-200 h-20 px-20 cursor-pointer">
                      <div className="justify-center text-center">
                        <div className="flex items-center space-x-2">
                          <img src="/assets/global/img/camera.svg" alt="cam" />
                          <p className="uppercase">Banner image</p>
                        </div>
                        <p className="text-black text-[9px] font-normal tracking-tight">
                          Choose File
                        </p>
                      </div>

                      <input
                        autoComplete="off"
                        type="file"
                        className="hidden valAutoComp"
                        name="banner_image"
                        accept=".jpeg, .png, .jpg"
                        onChange={(e) => handleBannerUpload(e)}
                      />
                    </label>
                    <div
                      className={`${
                        banner_image ? "" : "hidden"
                      }  inline-flex items-center border border-freshGreen mt-1 space-x-4 py-1 pl-1 pr-2 break-all`}
                    >
                      <p className="text-black text-xs">
                        {banner_image.name
                          ? banner_image.name.length > 14
                            ? banner_image.name.substring(0, 14) + "..."
                            : banner_image.name
                          : banner_image.name}
                      </p>
                      <img
                        className="w-2 h-2 cursor-pointer"
                        onClick={() => delBanner()}
                        src="/assets/global/img/close.svg"
                        alt="close"
                      />
                    </div>
                    <p className="text-red-500 text-sm">{error.banner_image}</p>
                  </div>
                  <div>
                    <label className="text-freshGreen justify-center font-semibold items-center flex space-x-2 text-xs py-2 px-4 bg-gray-300 hover:bg-gray-200 h-20 w-26 text-center cursor-pointer">
                      <div className="text-center">
                        <div className="flex items-center space-x-2">
                          <img src="/assets/global/img/camera.svg" alt="cam" />
                          <p className="uppercase">Card image</p>
                        </div>
                        <p className="text-black text-[9px] font-normal tracking-tight">
                          Choose File
                        </p>
                      </div>
                      <input
                        autoComplete="off"
                        type="file"
                        className="hidden "
                        name="card_image"
                        accept=".jpeg, .png, .jpg"
                        onChange={(e) => handleCardUpload(e)}
                      />
                    </label>
                    <div
                      className={`${
                        card_image ? "" : "hidden"
                      } inline-flex items-center border border-freshGreen mt-1 space-x-2 py-1 pl-1 pr-2 break-all`}
                    >
                      <p className="text-black text-xs">
                        {card_image.name
                          ? card_image.name.length > 14
                            ? card_image.name.substring(0, 14) + "..."
                            : card_image.name
                          : card_image.name}
                      </p>
                      <img
                        className="w-2 h-2 cursor-pointer"
                        onClick={() => delCard()}
                        src="/assets/global/img/close.svg"
                        alt="close"
                      />
                    </div>
                    <p className="text-red-500 text-sm">{error.card_image}</p>
                  </div>
                </div>

                <div className="text-textGray text-xs">
                  <p>
                    {" "}
                    <b> Banner image: 1000 x 250 pixels </b> and{" "}
                    <b> Card image: 600 x 400 pixels </b>{" "}
                  </p>
                  <p>
                    {" "}
                    <b> Max file size: 5MB</b>{" "}
                  </p>
                </div>
              </div>

              <div id="ideaTitle" className="space-y-3">
                <div className="font-semibold">
                  Idea Title{" "}
                  <label className="text-freshGreen text-md"> *</label>
                </div>
                <input
                  autoComplete="off"
                  className="border-none shadow-sm w-full text-sm"
                  placeholder="Enter a title with a maximum length of 100 characters"
                  max={100}
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                />
                <p className="text-red-500 text-sm">{error.title}</p>
              </div>

              <div id="description" className="space-y-3">
                <div className="font-semibold">
                  Description
                  <label className="text-freshGreen text-md"> *</label>
                </div>
                <RichTextEditor value={description} onChange={handleChangeDesc} />
{/* 
                <RichText
                  onChange={(content) => {
                    setDescription(content);
                  }}
                /> */}
                <p className="text-red-500 text-sm">{error.description}</p>
              </div>

              <div className="space-y-2 hidden">
                <h3 className="font-semibold text-black"> Add Links </h3>
                <div className="space-y-2">
                  {links.length > 0 && (
                    <>
                      {links.map((field, index) => (
                        <div
                          key={index}
                          className="flex space-x-3 items-center"
                        >
                          <div className=" basis-4/12" id="resourceName">
                            <input
                              autoComplete="off"
                              className="border-none shadow-md w-full"
                              type="text"
                              name="resourceName"
                              placeholder="Resources Name"
                              value={field.resourceName}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                            />
                          </div>
                          <div className=" basis-7/12" id="source">
                            <input
                              autoComplete="off"
                              className="border-none shadow-md w-full"
                              type="text"
                              name="source"
                              placeholder="Paste URL"
                              value={field.source}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                            />
                          </div>
                          <div className="basis-1/12 pl-4">
                            <img
                              onClick={() => handleRemoveLinks(index)}
                              className="cursor-pointer basis-1/12"
                              src="/assets/global/img/bin.svg"
                              alt="trash"
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div className="flex space-x-3">
                  <p className="text-red-500 basis-4/12 text-sm">
                    {error.resourceName}
                  </p>
                  <p className="text-red-500 basis-7/12 text-sm">
                    {error.source}
                  </p>
                </div>
                <button onClick={() => handleAddLinks()}>
                  <img
                    src="/assets/global/img/squarePlus.png"
                    className="w-14 h-14"
                    alt="add"
                  />
                </button>
              </div>

              {question.map((items, index) => (
                <div key={index} id="ideaTitle" className="space-y-3">
                  <div className="font-semibold">
                    {items.question}
                    <label className="text-freshGreen text-md"> *</label>
                  </div>
                  <RichTextEditor
                    value={items.value}
                    onChange={(content) => {
                      handleQ(items.id, content);
                    }}
                  />
                </div>
              ))}
              <p className="text-red-500 text-sm">{error.answer}</p>

              {/* <div className="space-y-3">
                <div className="flex font-semibold">Add Team Members</div>
                <select
                  className="w-full border-none shadow-sm text-gray-500"
                  name=""
                  id=""
                >
                  <option value="">Team members name</option>
                </select>
                <input
                  className="border-none shadow-sm w-full text-sm"
                  placeholder="Enter a personalised message to invite team members."
                  type="text"
                  name=""
                  id=""
                />
              </div> */}

              <div id="ideaTitle" className="space-y-3 hidden">
                <div className="flex font-semibold">Upload Files</div>
                <DragDrop docs={documents} setDocs={setDocuments} />
              </div>
            </div>

            <div className="hidden md:block lg:block">
              <Overview
                titleChallenge={challenge.title}
                descChallenge={challenge.description}
              />
            </div>
            <div
              className={`${
                page === "overview" ? "lg:hidden" : "hidden lg:hidden"
              } md:hidden lg:hidden`}
            >
              <Overview
                titleChallenge={challenge.title}
                descChallenge={challenge.description}
              />
            </div>
          </div>
        </div>

        <div
          className={`${
            page === "submit" ? "" : "lg:block hidden"
          } flex space-x-3 mt-3 text-sm bx_submit_ideas pb-5`}
        >
          <BtnGreen onClick={(e) => setShow(true)} message="publish" />
          <BtnGreen
            onClick={(e) => setShowDraft(true)}
            message="save as draft"
          />
          <button className="hover:font-semibold py-2 px-2">
            <Link to={`${RouteName.userLatestChallenges}/${slug.id}`}>
              Discard
            </Link>
          </button>
        </div>

        <ConfirmPopup
          show={show}
          setShow={setShow}
          onSave={onPublish}
          message="Are you sure you want to publish this idea?"
        />
        <ConfirmPopup
          show={showDraft}
          setShow={setShowDraft}
          onSave={onDraft}
          message="The idea will be saved as a draft"
        />
      </div>
    </div>
  );
};

export default CreateChallenge;
