import React, { useRef, useEffect } from 'react';
import 'quill/dist/quill.snow.css'; 
import Quill from 'quill';

const RichTextEditor = ({ value, onChange }) => {
  const quillRef = useRef(null);
  const editorRef = useRef(null);

  const toolbarOptions = [
    [{ 'font': [] },{ 'align': [] },{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' },'blockquote', 'code-block'],
    ['bold', 'italic', 'underline', 'strike',{ 'color': [] }, { 'background': [] }],   
    ['link', 'image', 'video', 'formula','clean',{ 'direction': 'rtl' }],
    [{ 'script': 'sub'}, { 'script': 'super' },{ 'indent': '-1'}, { 'indent': '+1' }],     
  ];

  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = new Quill(quillRef.current, {
        theme: 'snow',
        placeholder: "Type something...",
        modules: {
          toolbar: toolbarOptions
        },
      });

      editorRef.current.on('text-change', () => {
        onChange(editorRef.current.root.innerHTML);
      });
    }

    if (value && editorRef.current && editorRef.current.root.innerHTML !== value) {
      editorRef.current.root.innerHTML = value;
    }
  }, [value, onChange]);


  return (
    <div style={{backgroundColor: "white",border: "none !important"}}>
      <div ref={quillRef} style={{ backgroundColor: 'white', minHeight: '200px' }} />
    </div>
  );
}

export default RichTextEditor;
