import CallApi from "../../services/request-helper";

const API_PROFILE = process.env.REACT_APP_PROFILE;

const postProfile = async (data) => {
    const url = API_PROFILE;
    const response = await CallApi({ url, method: "POST" ,data});
    return response;
  };

  const getProfile = async () => {
    const url = API_PROFILE;
    const response = await CallApi({ url, method: "GET"});
    return response;
  };

const ProfileRepo = {
    getProfile,
    postProfile
};

export default ProfileRepo;