import React, { useEffect, useState } from 'react'
import NotificationRepo from '../../../repositories/admin-repo/NotificationRepo'
import Notification from './Notification'
import GeneralUtility from '../../../utility/GeneralUtility'

const Communication = () => {
  let subMenus = [
    "Notification",
  ]


  const [selectedMenu, setSelectedMenu] = useState("Notification");

  const renderTab = () => {
    let component = <div></div>;
    switch (selectedMenu) {
        case "Notification":
            component = (
              <Notification />
            );
            break;
        default:
            break;
    }
    return component;
  }

  return (
    <>
      <div className={`container transition-opacity mx-auto`}>
        <div className={`pb-10 mx-6 bg-baseGray`}>
          <div className="m-16 flex border-b-2 border-gray-500">
            {subMenus.map((res, index) => {
              return (
                <div key={index} className={`mx-4 mt-8 flex-row border-b-2 font-Montserrat-medium transition-colors duration-300 ${res === selectedMenu ? "border-none" : "border-none"} ${res === selectedMenu ? "text-[#6cb556]" : "text-none"} cursor-pointer`}
                  onClick={() => setSelectedMenu(res)}>
                  {res}
                </div>
              )
            })}
          </div>
          {renderTab()}
        </div>
      </div>
    </>
  )
}

export default Communication

