import React, { useState, useEffect, useCallback } from "react";
import { Link,useNavigate,useLocation } from "react-router-dom";
import All from "./components/All";
import Closed from "./components/Closed";
import Upcoming from "./components/Upcoming";
import Active from "./components/Active";
import RouteAdm from "../../../services/routeAdm";
import ChallengeAdmRepo from "../../../repositories/adm-repo/ChallengeAdmRepo";
import FilterChallengeAdmRepo from "../../../repositories/adm-repo/FilterChallengeAdmRepo";
import Paginate from "../../../components/paginate/Paginate";
import GeneralUtility from "../../../utility/GeneralUtility";
import Skeleton from "../../../components/skeleton/Skeleton";

const Challenges = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isTabInfo = queryParams.get('tab'); 
  const isPageInfo = queryParams.get('page'); 
  const isyearInfo = queryParams.get('year'); 
  const isType = queryParams.get("type")
  
  const yearNumber = isyearInfo && !isNaN(Number(isyearInfo)) ? Number(isyearInfo) : null;
  const pageNumber = isPageInfo && !isNaN(Number(isPageInfo)) ? Number(isPageInfo) : null;
  
  const [search, setSearch] = useState("");
  const [page, setPage] = useState([]);
  const [tabChallenge, setTabChallenge] = useState("active");
  const [sortYear, setSortYear] = useState(false);
  const [sortChall, setSortChall] = useState(false);
  const [listChallenge, setListChallenge] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [filListYear, setFilListYear] = useState([]);
  const [typeChallenges, setTypeChallenges] = useState(isType);
  const [listTypeChal, setlistTypeChal] = useState([]);
  const [limit] = useState(12);
  const [loadChallenge, setLoadChallenge] = useState(true);

  let filterTimeout;

  const getChallengesAdm = useCallback(async ({
    pageNumber,
    search,
    status,
    year,
    typeChallenges,
  }) => {
    setLoadChallenge(true);
    try{
      const result = await ChallengeAdmRepo.getChallengesAdm({
        offset: pageNumber,
        limit: limit,
        category: typeChallenges,
        status,
        year: year,
        search: search,
      });
  
      let listChallenge = result.data.data;
      setListChallenge(listChallenge);
      setPage(listChallenge);
      setLoadChallenge(false);
    }catch(error){
      setLoadChallenge(false)
    }
  }, [limit,setLoadChallenge,setPage]);

  const getFilterTypeChallengeAdm = async () => {
    const result = await FilterChallengeAdmRepo.getFilterType();
    let listTypeCategory = result.data.data;
    setlistTypeChal(listTypeCategory);
  };

  const getFilterYear = async () => {
    const result = await FilterChallengeAdmRepo.getFilterListYears();
    let listYears = result.data.data;
    setFilListYear(listYears);
  };

  const tab = [
    { title: "All", route: "all" },
    { title: "Closed", route: "closed" },
    { title: "Active", route: "active" },
    { title: "Up Coming", route: "up coming" },
  ];

  const Show = () => {
    if (isTabInfo === "all") {
      return (
        loadChallenge ? <Skeleton col={4} height={350}/>
          :
        <All listChallenge={listChallenge} tabChallenge={setTabChallenge} loading={loadChallenge} page={isPageInfo} tab={isTabInfo} year={isyearInfo}/>
      );
    } else if (isTabInfo  === "closed") {
      return (
        loadChallenge ? <Skeleton col={4} height={350}/>  :
        <Closed listChallenge={listChallenge} tabChallenge={setTabChallenge} loading={loadChallenge} page={isPageInfo} tab={isTabInfo} year={isyearInfo} />
      );
    } else if (isTabInfo  === "active") {
      return (
        loadChallenge ? <Skeleton col={4} height={350}/> :
        <Active listChallenge={listChallenge} tabChallenge={setTabChallenge} loading={loadChallenge} page={isPageInfo} tab={isTabInfo} year={isyearInfo}/>
      );
    } else if (isTabInfo  === "up coming") {
      return   loadChallenge ? <Skeleton col={4} height={350}/> : <Upcoming listChallenge={listChallenge} loading={loadChallenge} page={isPageInfo} tab={isTabInfo} year={isyearInfo} />;
    }
  };

  const paginate = (event) => {
    navigate(RouteAdm.challenges+"?tab=" + isTabInfo+ "&"+"page="+event.selected.toString() + "&year="+yearNumber)
    getChallengesAdm({
      pageNumber: event.selected,
      search: search,
      status: isTabInfo === "all" ? "" : isTabInfo,
      year: yearNumber,
      typeChallenges,
    });
  };
  

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    setYear(yearNumber);
    getChallengesAdm({
      pageNumber: pageNumber,
      search: search,
      status: isTabInfo === "all" ? "" : isTabInfo,
      year: yearNumber,
      typeChallenges,
    });
    getFilterYear();
    getFilterTypeChallengeAdm();
  }, [yearNumber, typeChallenges, tabChallenge, search, pageNumber]);

  const handleIsTab = (item) => {
    setTabChallenge(item);
    navigate(`${RouteAdm.challenges}?tab=${item}&page=0&year=${year}&type=${isType}`);
    setLoadChallenge(true)
  };

  const handleSetYear = (y) => {
    setYear(y);
    navigate(`${RouteAdm.challenges}?tab=${isTabInfo}&page=0&year=${y}&type=${isType}`);
    getChallengesAdm({
      pageNumber: 0,
      search: search,
      status: isTabInfo === "all" ? "" : isTabInfo,
      year: y,
      typeChallenges,
    });
  };

  return (
    <div className="container mx-auto">
      <div className="m-6 bg-baseGray p-6">
        <div className="border-b-2 border-gray-400 w-full">
          <ul className="inline-flex items-center space-x-3">
            {tab.map((items) => (
              <p
                key={items.route}
                className="cursor-pointer"
                onClick={() => handleIsTab(items.route)}
              >
                <div
                  className={`${isTabInfo === items.route
                    ? "text-futureGreen border-b-2 border-futureGreen"
                    : "text-textGray border-none"
                    } text-sm font-semibold px-1`}
                >
                  {items.title}
                </div>
              </p>
            ))}
          </ul>
        </div>
        <div className="py-5">
          <div className="justify-between flex flex-col lg:flex lg:flex-row space-y-2">
            <div className="relative w-60">
              <input
                autoComplete="off"
                type="text"
                className="border border-gray-300 text-gray-800 text-xs rounded-sm focus:ring-freshGreen focus:border-freshGreen block w-full pr-10 p-2"
                placeholder="What are you looking for? ..."
                onChange={(e) => {
                  clearTimeout(filterTimeout);
                  filterTimeout = setTimeout(() => {
                    setSearch(e.target.value);
                  }, 500);
                }}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 lg:-top-2 md:-top-2 flex items-center pr-3"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="flex items-center space-x-3 self-end">
              <div className="flex space-x-2 hidden">
                {/* calendar */}
                <Link
                  to={RouteAdm.callendar}
                  className="bg-gray-300 text-baseWhite w-8 h-8"
                >
                  <img
                    src="/assets/global/img/iconCalendar.svg"
                    className="mt-1 ml-1"
                    alt="icon"
                    style={{ width: 23, height: 23 }}
                  />
                </Link>
              </div>
              <div className="flex justify-self-end ">
                <div className="flex space-x-3 ">
                  <div
                    className={`items-center bg-white h-[35px] w-32 lg:w-44 md:w-44 py-2 border text-[10px] z-10 bx_filter_sortby `}
                  >
                    <div
                      className="flex flex-row cursor-pointer justify-between w-fill font-Montserrat"
                      onClick={() => setSortChall(!sortChall)}
                    >
                      <span className="text-[10px] mt-0.5 ml-3 uppercase">
                        SHOW :{" "}
                        {listTypeChal && typeChallenges !== "all"
                          ? listTypeChal.find((ev) => ev.id === typeChallenges)
                            ?.name?.substring(0, 14) + ".."
                          : "ALL CHALLENGES"}
                      </span>
                      <img
                        src="/assets/global/img/up-arrow.svg"
                        className={`h-3 w-2 stroke-black mr-2 mt-0.5 uppercase ${sortChall
                          ? "transition-width duration-300 easy rotate-180"
                          : "transition-width duration-300 easy rotate-0"
                          }`}
                        alt=""
                        onClick={() => setSortChall(!sortChall)}
                      />
                    </div>
                    <div
                      className="z-50"
                    >
                      {sortChall && (
                        <div
                          className="bg-white shadow-md lg:w-[175px] md:w-[175px] w-[128px] transition-opacity ease-in duration-700 opacity-100 flex flex-row text-[10px] uppercase"
                          onClick={() => setTypeChallenges("all")}
                        >
                          <img
                            src={`${typeChallenges === "all"
                              ? "/assets/global/img/toggle - check.svg"
                              : "/assets/global/img/toggle.svg"
                              }`}
                            className={`h-4 w-3 stroke-black mt-0.5 ml-3 mr-1`}
                            alt=""
                            onClick={() => setSortChall(!sortChall)}
                          />
                          <div className="mt-0.5 hover:font-semibold" onClick={() => setSortChall(!sortChall)}>
                            {" "}
                            ALL CHALLENGES
                          </div>
                        </div>
                      )}
                      {sortChall &&
                        listTypeChal &&
                        listTypeChal.map((items, index) => {
                          return (
                            <div key={items.id}
                              className="bg-white shadow-md lg:w-[175px] md:w-[175px] w-[128px] transition-opacity ease-in duration-700 opacity-100 flex flex-row text-[10px] uppercase"
                              onClick={() => {
                                setTypeChallenges(items.id);
                                navigate(`${RouteAdm.challenges}?tab=${isTabInfo}&page=0&year=${year}&type=${items.id}`);
                              }}
                            >
                              <img
                                src={`${items.id === typeChallenges
                                  ? "/assets/global/img/toggle - check.svg"
                                  : "/assets/global/img/toggle.svg"
                                  }`}
                                className={`h-4 w-3 stroke-black mt-1 ml-3 mr-1 uppercase`}
                                alt="arrow"
                                onClick={() => setSortChall(!sortChall)}
                              />
                              <div className="mt-1 hover:font-semibold" onClick={() => setSortChall(!sortChall)}>
                                {items.name}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div
                    className={`items-center bg-white h-[35px] w-32 lg:w-44 md:44 py-2 border text-[10px] z-10 bx_filter_sortby transition-opacity ease-in duration-300 opacity-100`}
                  >
                    <div
                      className="flex flex-row cursor-pointer justify-between w-fill font-Montserrat"
                      onClick={() => setSortYear(!sortYear)}
                    >
                      <span className="text-[10px] mt-0.5  ml-3 uppercase">
                        SORT BY : {year}{" "}
                      </span>
                      <img
                        src="/assets/global/img/up-arrow.svg"
                        className={`h-3 w-2 stroke-black mt-0.5 mr-3 uppercase ${sortYear
                          ? "transition-width duration-300 easy rotate-180"
                          : "transition-width duration-300 easy rotate-0"
                          }`}
                        alt=""
                        onClick={() => setSortYear(!sortYear)}
                      />
                    </div>
                    <div
                      className="z-50"
                    >
                      {sortYear &&
                        filListYear &&
                        filListYear.map((items) => {
                          return (
                            <div key={items.year}
                              className="bg-white border-1 lg:w-[175px] md:w-[175px] w-[128px] shadow-md transition-opacity ease-in duration-700 opacity-100 flex flex-row text-[10px] uppercase"
                              onClick={() => handleSetYear(items.year)}
                            >
                              <img
                                src={`${items.year === year
                                  ? "/assets/global/img/toggle - check.svg"
                                  : "/assets/global/img/toggle.svg"
                                  }`}
                                className={`h-4 w-3 stroke-black ml-3 mr-1 uppercase`}
                                alt=""
                                onClick={() => setSortYear(!sortYear)}
                              />
                              <div className=" mb-1 hover:font-semibold" onClick={() => setSortYear(!sortYear)}>
                                {items.year}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-2">
          <Show listChallenge={listChallenge} />
        </div>
        <div className="mt-3 mx-auto">
          <Paginate
            paginate={paginate} page={page} defPage={pageNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default Challenges;
