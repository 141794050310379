import React from "react";

const LinkReference = ({ links, title }) => {

    const getEmbedUrl = (url) => {
        try {
          const parsedUrl = new URL(url);
          if (parsedUrl.hostname.includes('youtube.com') && parsedUrl.searchParams.get('v')) {
            const videoId = parsedUrl.searchParams.get('v');
            return `https://www.youtube.com/embed/${videoId}`;
          }
          return null;
        } catch (error) {
          console.error('Invalid URL:', url);
          return null;
        }
      };

  return (
    <div className="pb-5 bg-baseGray">
      <div className="p-3 mb-4 flex flex-col gap-6">
        <h3 className="text-md md:text-lg lg:text-lg font-semibold pt-3 text-center md:text-left lg:text-left">
          Link Reference
        </h3>

        <div className="flex flex-col gap-6">
        {links.map((item,i) => {
      const embedUrl = getEmbedUrl(item.source);
      return (
        <div key={i} className="">
          {embedUrl ? (
            <>
        
         <p>{ i + 1 + ". " + item.resourceName}</p>
            <iframe
              src={embedUrl}
              title={`link reference ${item.id}`}
                className="h-56	min-w-72"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            </>
          ) : (
            <>
            <p>{ i + 1 + ". " + item.resourceName}</p>
               <a className="text-blue-600" href={item.source}  rel={item.source}>{item.source}</a>
            </>
          )}
        </div>
      );
    })}
        </div>
      </div>
    </div>
  );
};

export default LinkReference;
