import React,{useCallback,useRef} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const RichTextQuill = ({ value, onChange }) => {
    const quill = useRef();
    const imageHandler = useCallback(() => {

        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
    
        input.onchange = () => {
          const file = input.files[0];
          const reader = new FileReader();
    
          reader.onload = () => {
            const imageUrl = reader.result;
            const quillEditor = quill.current.getEditor();
    
            const range = quillEditor.getSelection(true);
            quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
          };
    
          reader.readAsDataURL(file);
        };
      }, []);

      const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "clean",
      ];
    

    const modules = {
        toolbar: {
          container: [
            [{ header: [2, 3, 4, false] }],
            ["bold", "italic", "underline", "blockquote"],
            [{ color: [] }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["link", "image"],
            ["clean"],
          ],
          handlers: {
            image: imageHandler,
          },
        },
        clipboard: {
          matchVisual: true,
        },
      }
    return (
        <ReactQuill
        placeholder='Type something...'
        formats={formats}
        ref={(el) => (quill.current = el)}
        modules={modules}
        theme="snow"
        value={value === undefined ? '' : value}
        onChange={onChange}
        style={{ height: '100%', backgroundColor: 'white', border: 'none' }}
        />
    );
};

export default RichTextQuill;
