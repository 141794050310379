import React, { useRef } from 'react'
import AlertUtility from '../utility/alert-utility';

const DragDropAtch = ({ docs, setDocs }) => {
    const inputRef = useRef(null);

    // delete links row links
    const deleteFile = index => {
        let data = [...docs];
        data.splice(index, 1);
        setDocs(data);
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i].size < 5000000) {
                    setDocs(docs => [...docs, e.target.files[i]])
                } else {
                    AlertUtility.errorAlert({ title: `File ` + e.target.files[i].name + ` More Than 5 Mb.` })
                }
            }
        }
    };

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 items-center">
                {docs.map((items, index) => (
                    <div key={index} className="flex relative border border-gray-300 h-12" >
                        <div
                            className="flex p-1 items-center space-x-3 cursor-pointer"
                        >
                            <div className="text-xs text-gray-500">
                                <h6 className="font-semibold">{items.name.length > 30 ? items.name.substring(0, 20) + "..." : items.name}</h6>
                            </div>
                        </div>
                        <img
                            className="right-1.5 top-1.5 absolute w-2.5 h-2.5 cursor-pointer"
                            onClick={() => deleteFile(index)}
                            src="/assets/global/img/close.svg"
                            alt="close"
                        />
                    </div>
                ))}
            </div>

            <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} accept=".jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx" />
            <button className="upload-button inline-flex float-right" onClick={onButtonClick}>
                <img
                    className="cursor-pointer w-5 h-5"
                    src="/assets/global/img/attachment.svg"
                    alt="attachment"
                />
            </button>
        </>
    )
}

export default DragDropAtch;
