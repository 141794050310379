import React from 'react'
// import Column from '../../../components/chart/Column'
import Donut from '../../../components/chart/Donut'
import Chart from "react-apexcharts";
import ColumnArea from '../../../components/chart/ColumnArea';

const Communication = () => {

    var options = {
        options: {
            chart: {
                id: "basic-bar",
                toolbar: {
                    show: false
                },
            },
            legend:{
                fontFamily: 'Montserrat',
                offsetX: -100,
                width: '100%'
            },
            grid: {
                show: false,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    distributed: true,
                    display: false
                    
                }
            },
            colors: ['#66bf40', '#F44336','#6649e4'],
            xaxis: {
                labels: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                type: 'category',
                categories: ["Total Emails Sent", "Total Notifications Sent"],
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                labels: {
                    show: false,
                    style: {
                        color: undefined,
                        fontSize: '12px',
                        fontFamily: 'Montserrat',
                        fontWeight: 200,
                        cssClass: 'apexcharts-yaxis-title',
                    },
            
                },
            },
            fill: {
                colors: ['#66bf40', '#F44336','#6649e4']
            }
        },
        series: [
            {
                name: "",
                data: [1000,500]
            }
        ]
    };

    return (
        <div className="container mx-auto pb-16">
            <div className='flex flex-row  mt-[-20px]'>
                <div className="ml-16 h-[350px] w-[28%] bg-white drop-shadow-lg">
                    <div className='flex text-sm mt-5 ml-5 font-Montserrat'>
                        Ideas by Stages
                    </div>
                    <Donut
                        height="300"
                        width="400"
                        labels={["Emails", "User Comments", "Notifications", "User Likes", "Shares"]}
                        value={[24, 41, 15, 13, 7]}
                    />
                </div>
                <div className='bg-white drop-shadow-xl w-[61%] h-[300px] ml-8'>
                    <div className='flex text-sm mt-5 ml-5 font-Montserrat'>
                        Weekly Number of Ideas Submitted
                    </div>
                    <div className='mixed-chart'>
                        <ColumnArea
                            height="250"
                            width="800"
                            labels={["ss", "ssss", "ssss", "sssss"]}
                            current={[10, 20, 30, 40]}
                            previous={[60, 10, 40, 70]}

                        />
                    </div>
                </div>
            </div>
            <div className="ml-16 h-[250px] w-[40%] bg-white drop-shadow-lg mt-10">
            <div className='flex text-sm pt-2 ml-5 font-Montserrat-bold'>
                      Emails & Notifications
                  </div>
                  <div className='mixed-chart'>
                      <Chart
                          options={options.options}
                          series={options.series}
                          type="bar"
                          width="450"
                          height="180"
                      />
                  </div>
            </div>
        </div>
    )
}

export default Communication