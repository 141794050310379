import CallApi from "../../services/request-helper";

const API_LIKE_CHALLENGE = process.env.REACT_APP_LIKECHALLENGE;
const API_DISLIKE_CHALLENGE = process.env.REACT_APP_DISLIKECHALLENGE;
const API_UNLIKE_CHALLENGE = process.env.REACT_APP_UNLIKE;
const API_CHECKED_CHALLENGE = process.env.REACT_APP_STATUSLIKE;

/**
 * Post Like Challenge
 * @param {*} data 
 * @returns 
 */
const postLikeChallenge = async (data) => {
    const url = API_LIKE_CHALLENGE;
    const response = await CallApi({ url, method: "POST", data });
    return response;
};

/**
 * Post Dislike Challenge
 * @param {*} data 
 * @returns 
 */
const postDislikeChallenge = async data => {
    const url = API_DISLIKE_CHALLENGE;
    const response = await CallApi({ url, method: "POST", data });
    return response;
};

/**
 * Post Unlike Challenge
 * @param {*} data 
 * @returns 
 */
const postUnlikeChallenge = async data => {
    const url = API_UNLIKE_CHALLENGE;
    const response = await CallApi({ url, method: "POST", data });
    return response;
};

/**
 * To checked status checked
 * @param {*} id 
 * @returns 
 */
const getStatusChallenge = async (id) => {
    const url = API_CHECKED_CHALLENGE + "/" + id;
    const response = await CallApi({ url, method: "GET" });
    const data = typeof response != "undefined" ? response.data : null;
    const data2 = typeof data != "undefined" && data != null ? data.data : {}
    return data2;
};


const LikeChallengeRepo = {
    postLikeChallenge,
    postDislikeChallenge,
    postUnlikeChallenge,
    getStatusChallenge
};

export default LikeChallengeRepo;
