import CallApi from "../../services/request-helper";

const API_GET_USER = process.env.REACT_APP_GET_USER;
// const API_GET_SESSIONLOGIN = process.env.REACT_APP_CEKSESSION;
const API_LOGIN = process.env.REACT_APP_LOGIN;
const API_CEK_SESSION = process.env.REACT_APP_CEK_SESSION;
const API_ACCESS_TOKEN = process.env.REACT_APP_GET_ACCESS_TOKEN;
const API_REFRESH_TOKEN = process.env.REACT_APP_GET_REFRESH_TOKEN;
const API_LOGOUT_TOKEN = process.env.REACT_APP_LOGOUT;

const getUserLogin = async () => {
    const url = API_GET_USER;
    const response = await CallApi({ url, method: "GET" });
    return response;
};

const getLogoutSSO = async (data) => {
    const url = API_LOGOUT_TOKEN;
    const response = await CallApi({ url, method: "POST", data});
    return response;
};

const getSession = async (data) => {
    const url = API_CEK_SESSION;
    const response = await CallApi({ url, method: "POST", data });
    return response;
};

const accessToken = async (data) => {
    const url = API_ACCESS_TOKEN;
    const response = await CallApi({ url, method: "POST", data });
    return response;
}
const refreshToken = async (data) => {
    const url = API_REFRESH_TOKEN;
    const response = await CallApi({ url, method: "POST", data });
    return response;
}

const userLogin = async (data) => {
    const url = API_LOGIN;
    const response = await CallApi({ url, method: "POST", data });
    return response;
}

const LoginRepo = {
    getUserLogin,
    accessToken,
    refreshToken,
    userLogin,
    getSession,
    getLogoutSSO,
};

export default LoginRepo;
