import React, { useEffect } from "react";
import GeneralUtility from "../../../utility/GeneralUtility";

const MyTeams = () => {
  
  useEffect(() => {
    GeneralUtility.countViewPage("Coming_Soon_Page");
  });

  return (
    <>
      <div className="container bg-baseGray m-auto">
        <img
          src="/assets/global/img/Coming_Soon2.jpg"
          className="w-[80%] mx-auto"
          alt="comingsoon"
        />
      </div>
    </>
  );
};

export default MyTeams;
