// for detail idea
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Comments from "../../../../components/comments/Comments";
import DetailSubmittedRepo from "../../../../repositories/user-repo/DetailSubmittedRepo";
import ChallengeRepo from "../../../../repositories/user-repo/ChallengeRepo";
import CommentRepo from "../../../../repositories/user-repo/CommentRepo";
import Breadcrumbs from "./components/Breadcrumbs";
import DetailContent from "./components/DetailContent";
import SideContent from "./components/SideContent";
import DragDropAtch from "../../../../components/DragDropAtch";
import EncryptDecrypt from "../../../../utility/encrypt-decrypt";
import InfiniteScroll from "react-infinite-scroller";
import GeneralUtility from "../../../../utility/GeneralUtility";
import DateUtility from "../../../../utility/date-utility";
import ConfirmPopup from "../../../../components/ConfirmPopup";
import AlertUtility from "../../../../utility/alert-utility";
import Banner from "../../../../components/Banner";
import Cookies from "js-cookie";
import disableComment from "../../../../utility/disableComment";
import RichTextEditor from "../../../../components/RichTextEditor";
import RichTextComment from "../../../../components/RichTextComment";

const DetailIdea = () => {
  let id = useParams();
  let location = useLocation();
  let identification = location.pathname.includes("archived-challenges");
  let typeDetail = location.pathname.includes("myideas");
  let getIdChallenge = Cookies.get("idChallenge");
  let getSlugChallenge = Cookies.get("slugChallenge");
  let idChallenge = EncryptDecrypt.dec(getIdChallenge);
  let slugChallenge = EncryptDecrypt.dec(getSlugChallenge);
  // eslint-disable-next-line
  const [like, setLike] = useState("");
  const [limit] = useState(3);
  const [detailIdea, setDetailIdea] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [manager, setManagers] = useState([]);
  const [challenge, setChallenge] = useState([]);
  const [message, setMessage] = useState("");
  const [documents, setDocuments] = useState([]);
  const [editorKey, setEditorKey] = useState(4);
  const [pageNumber, setPageNumber] = useState(0);
  const [page, setPage] = useState([]);
  const [com, setCom] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  /**
   * function re-render the editor to empty comment field
   */
  const clearEditor = () => {
    const newKey = editorKey * 43;
    setEditorKey(newKey);
    setMessage("");
  };

  /**
   * function to get the detail idea
   */
  const getDetailIdea = async () => {
    setLoading(true);
    const result = await DetailSubmittedRepo.getDetail(id.id);
    if (result.status === 200) {
      let getDetail = result.data.data;
      let getDetailManager = result.data.data.managers;
      let getDetailAnswer = result.data.data.answers;
      setDetailIdea(getDetail);
      setManagers(getDetailManager);
      setAnswers(getDetailAnswer);
    } else {
      setDetailIdea(null);
    }
    setLoading(false);
  };

  /**
   * post new comments
   * @param {*} e
   */
  const addComment = async (e) => {
    e.preventDefault();
    let data = { message, submited_id: id, documents };
    setShow(false);
    const res = await CommentRepo.postComment(data);
    if (res.status !== 200) {
      AlertUtility.errorAlert({
        title: "Sorry, there is some problems. Please try again.",
      });
    }
    clearEditor();
    setDocuments([]);
    setCom([]);
    setPageNumber(0);
    setHasMore(true);
    getCommentary(pageNumber, com);
  };

  /**
   * get detail challenge
   */
  const getDetailChallenge = async () => {
    if (idChallenge !== "") {
      const result = await ChallengeRepo.getDetailChallenges({
        id: idChallenge,
      });
      let listChallenges = result;
      setChallenge({ title: listChallenges.title,status: listChallenges.status });
    } else {
      //
    }
  };
  /**
   * funtion to handle like comment
   * @param {*} id
   */
  const postLike = async (id) => {
    setLike(true);
    const result = await CommentRepo.likeComment({ commentary: { id: id } });
    if (result.status === 200) {
      let arr = [...com];
      //Find index of specific object using findIndex method.
      let objIndex = arr.findIndex((obj) => obj.id === id);
      //Update object's name property.
      arr[objIndex].isLike = { id: result.data.data, status: true };
      setCom(arr);
    } else {
      setCom([]);
      setPageNumber(0);
      setHasMore(true);
      getCommentary(pageNumber, com);
    }
  };

  /**
   * funtion to handle unlike comment
   * @param {*} id
   */
  const postUnLike = async (idLike, id) => {
    setLike(false);
    const result = await CommentRepo.checkLike({ id: idLike });
    if (result.status === 200) {
      let arr = [...com];
      //Find index of specific object using findIndex method.
      let objIndex = arr.findIndex((obj) => obj.id === id);
      //Update object's name property.
      arr[objIndex].isLike = null;
      setCom(arr);
    } else {
      setCom([]);
      setPageNumber(0);
      setHasMore(true);
      getCommentary(pageNumber, com);
    }
  };

  const getCommentary = async (pageNumber, com) => {
    const result = await CommentRepo.getComment({
      offset: pageNumber,
      limit: limit,
      id: id.id,
    });
    if (result.status === 200) {
      let listComment = result.data.data.data;
      if (listComment.length > 0) {
        setPageNumber(pageNumber + 1);
        let resultPaginate = result.data.data;
        setPage(resultPaginate);
        setCom([...com, ...listComment]);
      } else {
        setHasMore(false);
      }
    } else {
      //
    }
  };

  /**
   * function to open file in comment section
   * @param {*} source
   * @param {*} name
   */
  const fileDownload = async (source, name) => {
    const downloadLink = document.createElement("a");
    downloadLink.target = "_blank";
    downloadLink.href = source;
    downloadLink.download = name;
    downloadLink.click();
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getCommentary(0, com);
    getDetailIdea();
    getDetailChallenge();
  }, []);

  return (
    <>
      <div className="container mx-auto">
        {detailIdea !== null ? (
          <>
            <div className="m-4">
              <Breadcrumbs
                identification={identification}
                detailIdea={detailIdea}
                challenge={challenge}
                idChallenge={idChallenge}
                slugChallenge={slugChallenge}
                typeDetail={typeDetail}
              />
            </div>
            <div>
              <Banner
                detail={{
                  banner: detailIdea.banner_card,
                  title: detailIdea.title,
                }}
                loading={loading}
              />
            </div>
            <div className="m-4 md:m-6 space-y-4">
              <div className="flex flex-col-reverse md:flex-row gap-y-3 gap-3">
                <div className="md:basis-3/4">
                <DetailContent detailIdea={detailIdea} answers={answers} />
                </div>
                <div className="md:basis-1/4">
                  <SideContent
                    detailIdea={detailIdea}
                    manager={manager}
                    id={id}
                    getDetailIdea={getDetailIdea}
                  />
                </div>
              </div>

              <div className="bg-baseGray p-4 md:p-6 space-y-4">
                <h4 className="font-bold text-lg">
                  Comments  ({page.length !== 0 ? page.total_data : "0"})
                </h4>
                <div
                  className={`${page.length === 0 ? "hidden" : " "} ${
                    page.total_data === 1 ? "h-[10em]" : "h-[20em]"
                  } overflow-y-auto space-y-3`}
                >
                  <InfiniteScroll
                    loadMore={async () => {
                      getCommentary(pageNumber, com);
                    }}
                    hasMore={hasMore}
                    useWindow={false}
                    threshold={100}
                  >
                    {com.map((items) => (
                      <div
                        key={items.id}
                        className="bg-white shadow-sm p-3 mb-3"
                      >
                        <div className="flex w-full space-x-2 items-center">
                          <div className="flex w-full justify-between items-center">
                            <div className="flex flex-row items-center space-x-2">
                              <div className="h-8 w-8 rounded-full">
                                <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-bold justify-center">
                                  {GeneralUtility.getInitials(items.user_name)}
                                </div>
                              </div>
                              <p className="font-bold text-[10px] lg:text-xs">
                                {items.user_name}{" "}
                              </p>
                              <p className="text-[10px] lg:text-xs">
                                {DateUtility.formatDate(items.created_at)}
                              </p>
                            </div>
                            {items.isLike === null ? (
                              <>
                                <button onClick={(_e) => postLike(items.id)}>
                                  <img
                                    src="/assets/global/img/like1.svg"
                                    className="w-7 h-7"
                                    alt="like"
                                  />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={(e) =>
                                    postUnLike(items.isLike.id, items.id)
                                  }
                                >
                                  <img
                                    src="/assets/global/img/like2.svg"
                                    className="w-7 h-7"
                                    alt="like"
                                  />
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="text-sm p-3">
                          <div
                            dangerouslySetInnerHTML={{ __html: items.message }}
                          />
                        </div>
                        <div className="p-3 space-y-2">
                          {items.document.map((docs, index) => (
                            <div
                              key={index}
                              className="flex border border-gray-300 h-12 w-56 mb-4"
                            >
                              <div
                                onClick={() =>
                                  fileDownload(docs.source, docs.filename)
                                }
                                className="flex p-1 items-center w-full space-x-3 cursor-pointer"
                                name={docs.filename}
                              >
                                <img
                                  src={` ${
                                    docs.type_document === "pdf"
                                      ? "/assets/global/img/pdfIco.svg"
                                      : ""
                                  } ${
                                    docs.type_document === "pptx" ||
                                    docs.type_document === "ppt"
                                      ? "/assets/global/img/pptxIco.svg"
                                      : ""
                                  } ${
                                    docs.type_document === "xlsx" ||
                                    docs.type_document === "xls" ||
                                    docs.type_document === "csv"
                                      ? "/assets/global/img/xlsxIco.svg"
                                      : ""
                                  } ${
                                    docs.type_document === "docx" ||
                                    docs.type_document === "doc"
                                      ? "/assets/global/img/docxIco.svg"
                                      : ""
                                  } ${
                                    docs.type_document === "png" ||
                                    docs.type_document === "jpg" ||
                                    docs.type_document === "jpeg"
                                      ? "/assets/global/img/imageIco.png"
                                      : ""
                                  }`}
                                  className="h-8 w-8"
                                  alt="ico"
                                />
                                <div className="text-xs text-gray-500">
                                  <h6 className="font-semibold">
                                    {docs.filename
                                      ? docs.filename.length > 15
                                        ? docs.filename.substring(0, 15) +
                                          "..." +
                                          docs.type_document
                                        : docs.filename
                                      : ""}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>

                  {disableComment(detailIdea.status_transaction,challenge.status) &&   (
                         <div className="bg-white shadow-sm p-3 space-y-3">
                         <div className="flex w-full space-x-2">
                           <div className="text-sm inline-flex items-center rounded-full bg-heritageGreen h-8 w-8 text-white font-bold justify-center">
                             {GeneralUtility.getUserInitials()}
                           </div>
                           <div className="w-full justify-between">
                           <RichTextComment
                              value={message}
                              onChange={(content) => {
                                setMessage(content);
                              }}
                            />
                              <div className="mt-3">
                                <DragDropAtch docs={documents} setDocs={setDocuments} />
                              </div>
                            </div>
                         </div>
                         <div className="w-full text-right">
                           {
                               message !== "" ? (
                                 <button
                                   onClick={(e) => setShow(true)}
                                   className="bg-gradient-to-r from-green-500 to-freshGreen text-white px-4 py-1 text-sm tracking-[0.5px]"
                                 >
                                   COMMENT
                                 </button>
                               ) : (
                                 <button className="bg-gray-300 text-white px-4 py-1 text-sm tracking-[0.5px]">
                                   COMMENT
                                 </button>
                               )
                           }
                         </div>
                       </div>
                  )}
              </div>
            </div>
            <ConfirmPopup
              show={show}
              setShow={setShow}
              onSave={addComment}
              message="Are you sure you want to add the comment?"
            />
          </>
        ) : (
          <>
            <div className="text-center font-bold text-xl mt-10">Idea Not Found</div>
          </>
        )}
      </div>
    </>
  );
};

export default DetailIdea;
