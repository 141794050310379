import React from "react";
import Skeleton from "./skeleton/Skeleton";

const Banner = ({ detail, loading, type }) => {
  return (
    <>
      {!loading ? (
        <div
          className="c-image-container flex items-center bg-gray-200 z-[-2] overflow-hidden justify-center"
          style={{
            backgroundImage: "url(" + detail.banner + ")",
          }}
        >
          <img
            className={`${
              type === "homepage"
                ? "max-h-[300px] w-full"
                : "h-[150px] md:h-[250px] lg:h-[300px] xl:h-[350px] w-full"
            } overflow-hidden bg-gray-200 m-auto`}
            src={detail.banner}
            alt={detail.title}
          />
          <div className="c-text-overlay w-full text-center font-bold text-xl md:text-2xl text-white">
            {detail.title}
          </div>
        </div>
      ) : (
        <div className="c-image-container">
          <Skeleton col={1} height={300} />
        </div>
      )}
    </>
  );
};

export default Banner;
