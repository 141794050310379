import React from "react";
import ButtonEdit from "./ButtonEdit";
import ButtonDelete from "./ButtonDelete";
import BtnWhite from "../../../../../components/buttons/BtnWhite";
import BtnGreen from "../../../../../components/buttons/BtnGreen";

const ButtonGroupEditNews = ({ onClickEdit, onClickUpload }) => {
  return (
    <>
      <div className="h-7 w-30 controlhover-rewards">
        <ButtonEdit onClick={onClickEdit} isEdit={true} />
        <BtnGreen onClick={onClickUpload} message="Upload Image" />
      </div>
    </>
  );
};

export default ButtonGroupEditNews;
